import styled from "styled-components";

export const UploadImageComponentWrapper = styled.div`
  .card {
    width: 80%;
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    gap: 20px;
    padding: 0 50px 50px;
    background: #fff;
    transition: background 1s ease-in;
    border-radius: 50px;
    -webkit-box-shadow: 0 0 30px 0 rgba(43, 86, 112, 0.1);
    box-shadow: 0 0 30px 0 rgba(43, 86, 112, 0.1);
    position: relative;
  }

  .info {
    position: absolute;
    top: 20px;
    right: 50px;
  }

  .infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    width: 30%;
    margin: 20px 0;
  }

  .infoContainer button {
    align-self: flex-end;
  }

  .infoContent {
    background-color: rgb(235 232 232 / 50%);
    padding: 10px 20px;
    overflow: scroll;
    max-height: 350px;
    width: 100%
    height: 100%
  }

  .leftContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }

  .selectHub {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .selectHub > div,
  .selectType > div {
    width: 85%;
    // outline: 1px solid black;
  }

  .selectType {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .selectType > div > label {
    margin: 0 25px;
  }
`;
