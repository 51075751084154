import styled from "styled-components";

export const PrimaryInsightsWrapper = styled.div`
  .primary_insights_container {
    height: 663px;
  }

  .primary_insights_container img {
    width: 100%;
    height: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
