import styled from "styled-components";

export const CompanyMarketSpecificWrapper = styled.div`
  .stats-card {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #d7f6eb;
    text-align: center;
  }
  .stats_content_card {
    font-family: "Avenir Roman";
    font-size: 14px;
    color: #002f75;
    font-style: normal;
    font-weight: normal;
    width: 180px;
    text-align: center;
    color: rgb(0, 47, 117);
  }
  .info_content_card {
    font-family: "Avenir Roman";
    font-size: 14px;
    color: #ffffff;
    font-style: normal;
    font-weight: normal;
    text-align: center;
  }

  .profile_container {
    margin-top: 15px;
    width: 100%;
    height: 100%;
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 20px;
  }

  .company_bio {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #e6e3fc;
    padding: 20px;

    .company_content {
      margin-top: 18px;
      font-family: "Avenir Roman";
      font-size: 14px;
      line-height: 18px;
      color: #002f75;
      font-style: normal;
      font-weight: normal;
    }
  }

  .middle_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    max-width: 100%;

    .inner-stats-container {
      display: flex;
      flex-direction: row;
      gap: 10px;
      justify-content: center;
      flex-wrap: wrap;
      height: 520px;
    }
  }

  .info-card-1 {
    width: 100%;
    background: #539de4;
    height: 127px;
    margin-bottom: 10px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .info-card-2 {
    width: 100%;
    background: #3fd0d9;
    height: 127px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .info_content_card {
      font-family: "Avenir Roman";
      font-size: 14px;
      color: #ffffff;
      font-style: normal;
      font-weight: normal;
      text-align: center;
    }
  }

  .left_container {
    max-width: 100% !important;
    height: 100%;

    img {
      width: 100%;
    }
  }

  .right_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    max-width: 100%;
  }

  .data-container-1 {
    background: #eef1f2;
    padding: 20px;
    height: 402px;
  }

  .data-container-2 {
    background: #3e87dd;
    padding: 25px;
    height: 402px;
    width: 100%;
  }

  .white_heading {
    text-transform: uppercase;
    font-size: 16px;
    color: #ffffff !important;
    text-align: center;
    margin: 10px;
  }
  p1 {
    font-family: "Avenir Roman";
    font-size: 14px;
    color: #002f75;
    font-weight: 300;
  }

  p2 {
    font-family: "Avenir Roman";
    font-weight: 800;
    font-style: normal;
    font-size: 32px;
    color: #002f75;
  }

  p4 {
    font-family: "Avenir Roman";
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
  }

  h4 {
    font-family: "Avenir Roman";
    font-size: 14px;
    color: #002f75 !important;
    font-weight: 800;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 480px) {
    .profile_container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .page {
      padding: 0px !important;
    }
    .container {
      padding: 20px !important;
      width: 100%;
    }
    .company_bio {
      margin-bottom: 10px;
    }
    .right_container {
      margin-top: 20px;
      height: auto;
      width: 100%;
    }

    .middle_container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column-reverse;
    }

    .left_container {
      max-width: 100% !important;
      width: 100%;
      img {
        width: 100% !important;
      }
    }
    .info-card-1 {
      margin-bottom: -10px;
      margin-top: 10px;
    }
  }

  @media only screen and (max-width: 991px) {
    .profile_container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }
    .company_bio {
      width: 100%;
      height: auto;
      margin-bottom: 10px;
    }

    .left_container {
      max-width: 100% !important;
      width: 100%;
      img {
        width: 100%;
      }
    }
    .right_container {
      width: 100%;
      margin-top: 20px;
    }

    .middle_container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column-reverse;
    }
    .data-container-1 {
      .subheading {
        margin-bottom: 30px;
      }
    }
    .info-card-1 {
      margin-bottom: -10px;
      margin-top: 10px;
    }
  }
`;
