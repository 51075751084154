import React, { useState, useEffect } from "react";
import xlsx from "json-as-xlsx";
import axios from "axios";
import config from "../../config/Api";
import { Select, Spin, notification } from "antd";
import { connect } from "react-redux";
import {
  DownloadReportsContainer,
  DownloadReportsForm,
  CloseModal,
} from "./styles";
import {
  setSearchParams,
  setMarketActivityParams,
  setMasterMEParams,
  setDownloadReportsParams,
  setDraftStage,
} from "../../reducersAndActions/Actions";

const base_url = config.api.base_url;
const { Option } = Select;

const DownloadReports = (props) => {
  let [hubList, setHubList] = useState([]);
  let [currentHub, setCurrentHub] = useState(null);
  let [clicked, setClicked] = useState(false);

  let closeModal = () => {
    props.setSearchParams(false);
    props.setMarketActivityParams(false);
    props.setDownloadReportsParams(false);
    props.setMasterMEParams(false);
    props.setDraftStage("INITIAL");
  };

  useEffect(() => {
    let full_access = false;
    axios
      .get(`${base_url}/api/hubs/list?full_access=${full_access}`)
      .then((response) => {
        if (response.status === 200) {
          let hubs = [...response.data.obj].filter((hub) => {
            return hub.name.toLowerCase() !== "zero";
          });
          let args = {
            message: "Success",
            description: "Hub List Found.",
            duration: 2,
            placement: "topRight",
          };
          notification.success(args);
          setHubList(hubs);
        }
      })
      .catch((error) => {
        let args = {
          message: error,
          description: "Hub List Not Found.",
          duration: 1,
          placement: "topRight",
        };
        notification.error(args);
        console.log(error);
      });
  }, []);

  let hubChange = (value) => {
    let hub_id = value.split("+")[0];
    let name = value.split("+")[1];
    setCurrentHub({ hub_id, name });
  };

  let handleSubmit = (e) => {
    e.preventDefault();
    setClicked(true);

    let config = {
      method: "post",
      url: `${base_url}/api/interfaceQueries/downloadReports`,
      data: {
        hub_id: currentHub.hub_id,
        name: currentHub.name,
      },
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          let args = {
            message: response.data.message,
            description: "Reports Will be sent to your Mail",
            duration: 4,
            placement: "topRight",
          };
          notification.success(args);
          setClicked(false);
          setTimeout(() => {
            closeModal();
          }, 2500);
        }
      })
      .catch((error) => {
        let args = {
          message: error,
          description: "Fail To Send Reports",
          duration: 2,
          placement: "topRight",
        };
        notification.error(args);
        console.log(error);
        setClicked(false);
        setTimeout(() => {
          closeModal();
        }, 2500);
      });
  };
  return (
    <DownloadReportsContainer>
      <DownloadReportsForm>
        <CloseModal onClick={closeModal}>X</CloseModal>

        <legend>Download Reports</legend>
        <div>
          {hubList.length ? (
            <>
              <Select
                value={currentHub?.name}
                style={{ width: 300, marginRight: "10px" }}
                onSelect={hubChange}
                placeholder={"Select Hub To Download Reports"}
              >
                {hubList.length &&
                  hubList.map((hub) => {
                    return (
                      <>
                        <Option value={`${hub.id}+${hub.name}`}>
                          {hub.name.toUpperCase()}
                        </Option>
                      </>
                    );
                  })}
              </Select>

              {currentHub !== null ? (
                !clicked ? (
                  <button onClick={(e) => handleSubmit(e)}>Download</button>
                ) : (
                  <button>
                    <Spin />
                  </button>
                )
              ) : null}
            </>
          ) : (
            <Spin />
          )}
        </div>
      </DownloadReportsForm>
    </DownloadReportsContainer>
  );
};

function mapStateToPros(state) {
  return {
    IS_AUTH: state.IS_AUTH,
    SEARCH_PARAMS: state.SEARCH_PARAMS,
    MARKET_ACTIVITY_PARAMS: state.MARKET_ACTIVITY_PARAMS,
    DOWNLOAD_REPORTS_PARAMS: state.DOWNLOAD_REPORTS_PARAMS,
    MASTER_ME_PARAMS: state.MASTER_ME_PARAMS,
    TOC_DATA: state.TOC_DATA,
    USER_DATA: state.USER_DATA,
  };
}

const mapDispatchToProps = {
  setSearchParams,
  setMarketActivityParams,
  setMasterMEParams,
  setDownloadReportsParams,
  setDraftStage,
};

export default connect(mapStateToPros, mapDispatchToProps)(DownloadReports);
