import React, { Component } from "react";
import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom";
import "./App.css";
import FinalReport from "./components/FinalReport";
//import DetailedToc from "./pages/DetailedToc";
import Interface from "./components/Interface";
import BulkUpload from "./components/BulkUpload/BulkUploadContainer";
import Home from "./pages/Home";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Layout from "./components/Layout/Layout";
import SearchComponent from "./components/Search";
import Introduction from "./pages/Introduction";
import RegulatoryFramework from "./pages/RegulatoryFramework";
import RegulatoryFrameworkV2 from "./pages/RegulatoryFrameworkv2";
import KeyStrategicThemesForFeedAdditiveCEOS from "./pages/KeyStrategicThemesForFeedAdditiveCEOS";
import FeedProduction from "./pages/FeedProduction";
import FeedbackAndAudit from "./pages/FeedbackAndAudit";
import GlobalOverview from "./pages/GlobalOverview";
import RegionOverview from "./pages/RegionOverview";
import CompanyProfile from "./pages/CompanyProfileContainer";
import MarketShare from "./pages/MarketShare";
import RecentDevelopment from "./pages/RecentDevelopment";
import SegmentDefinition from "./pages/SegmentDefinition";
import RdGraph from "./pages/RdGraph";
import ProductAndServices from "./pages/ProductsAndServices";
import ProductAndServicesV2 from "./pages/ProductAndServicesV2";
import ForMoreInformationPleaseContact from "./pages/ForMoreInformationPleaseContact";
import GrowthStrategyMatrix from "./pages/GrowthStrategyMatrix";
import EditableExecutiveSummary from "./pages/ExecutiveSummary/EditableExecutiveSummary";
import StaticExecutiveSummary from "./pages/ExecutiveSummary/StaticExecutiveSummary";
import ReportOffers from "./pages/ReportOffers";
import StudyAssumptions from "./pages/StudyAssumptions";
import ResearchMethodology from "./pages/ResearchMethodology";
import DecisionMaker from "./pages/DecisionMaker";
import ProteinsVC from "./pages/ValueChain/Proteins";
import ArchitecturalCoatingVC from "./pages/ValueChain/Architectural_Coating";
import LubricantVC from "./pages/ValueChain/Lubricant";
import ScopeStudy from "./pages/ScopeStudyStatic";
import ScopeStudyRegional from "./pages/ScopeStudy";
import Image from "./pages/GenerateImage";
import Disclaimer from "./pages/Disclaimer";
import Sources from "./pages/Sources";
import CompanyProfileOverview from "./pages/CompanyProfileOverview";
import CompanyMarketSpecific from "./pages/CompanyMarketSpecific";
import { AuthProvider } from "./firebase/contexts/AuthContext";
import ForgotPassword from "./pages/ForgotPassword";
import ProtectedRoute from "./components/ProtectedRoute";
import AnswerEngine from "./pages/AnswerEngine";
import Template1100 from "./Templates/Template1100";
import Template1111 from "./Templates/Template1111";
import Template1000 from "./Templates/Template1000";
import Template1101r from "./Templates/Template1101R";
import Template1101c from "./Templates/Template1101C";
import Template1001 from "./Templates/Template1001";
import Template1011 from "./Templates/Template1011";
import HLPDataUpload from "./components/HLPDataUpload";
import CommentsList from "./components/CommentsList/CommentsList";
// import NewHome from "./components/NewHome";
import NewLayout from "./components/Layout/NewLayout";
import BulkExportsContainer from "./components/BulkExports/BulkExportsContainer";
import UpdateMiraContainer from "./components/UpdateMiraContainer/UpdateMira";
import MarketActivities from "./components/MarketActivities";
import HubDetails from "./components/HLPDataUpload";
import UploadSheetContainer from "./components/UploadSheet/UploadSheetContainer";
import UploadImageComponent from "./components/UploadImage/UploadImageComponent";
import UploadStaticImageComponent from "./components/UploadStaticImage/UploadStaticImageComponent";
import LeadAutomationComponent from "./components/LeadAutomation/LeadAutomationComponent";
import PurgeReportsComponent from "./components/PurgeReports/PurgeReportsComponent";
import DocumentationComponent from "./components/Documentaion/DocumentationComponent";
import HubConfig from "./components/HubConfig";
import DeleteDataComponent from "./components/DeleteData";

export default class App extends Component {
  render() {
    return (
      <React.Fragment>
        <BrowserRouter>
          <Switch>
            <AuthProvider>
              <Route path="/login" component={Login}></Route>
              <Route path="/signup" component={SignUp} />
              <Route path="/forgotpassword" component={ForgotPassword} />
              <Route exact path="/">
                <Redirect to="/login" />
              </Route>
              <Route path="/answer-engine" component={AnswerEngine} />
              <ProtectedRoute exact path="/dashboard" component={NewLayout} />
              <ProtectedRoute exact path="/home" component={Home} />
              <ProtectedRoute
                path="/market-activities"
                component={MarketActivities}
              />
              <ProtectedRoute
                path="/hubs-landing-page"
                component={HubDetails}
              />
              <ProtectedRoute path="/editing/:tab" component={Layout} />
              <ProtectedRoute
                path="/comments-dashboard"
                component={CommentsList}
              />
              <ProtectedRoute
                path="/upload-sheet"
                component={UploadSheetContainer}
              />
              <ProtectedRoute
                path="/upload-image"
                component={UploadImageComponent}
              />
              <ProtectedRoute
                path="/upload-static-image"
                component={UploadStaticImageComponent}
              />
              <ProtectedRoute
                path="/delete-data"
                component={DeleteDataComponent}
              />
              <ProtectedRoute
                path="/lead-automation"
                component={LeadAutomationComponent}
              />
              <ProtectedRoute
                path="/purge-reports"
                component={PurgeReportsComponent}
              />
              <ProtectedRoute
                path="/documentation"
                component={DocumentationComponent}
              />
              <ProtectedRoute path="/hub-config" component={HubConfig} />
              <ProtectedRoute path="/bulk-upload" component={BulkUpload} />
              <ProtectedRoute path="/interface/:report" component={Interface} />
              <ProtectedRoute path="/search" component={SearchComponent} />
              <ProtectedRoute path="/reports/search" component={Layout} />

              <ProtectedRoute
                path="/bulk-exports"
                component={BulkExportsContainer}
              />
              <ProtectedRoute
                path="/update-mira"
                component={UpdateMiraContainer}
              />
              <ProtectedRoute
                path="/sample-mes-upload"
                component={BulkUpload}
              />
              <Route path="/final-report/:folder" component={FinalReport} />
              <ProtectedRoute
                path="/scopeofthestudycountry"
                component={ScopeStudy}
              />
              <ProtectedRoute
                path="/scopeofthestudyregional"
                component={ScopeStudyRegional}
              />
              <ProtectedRoute path="/reportoffers" component={ReportOffers} />
              <ProtectedRoute path="/disclaimer" component={Disclaimer} />
              <ProtectedRoute path="/sources" component={Sources} />
              <ProtectedRoute
                path="/studyassumptions"
                component={StudyAssumptions}
              />
              <ProtectedRoute
                path="/researchmethodology"
                component={ResearchMethodology}
              />
              <ProtectedRoute path="/image" component={Image} />
              <ProtectedRoute path="/decisionmaker" component={DecisionMaker} />
              <ProtectedRoute
                path="/lubricants-valuechain"
                component={LubricantVC}
              />
              <ProtectedRoute
                path="/architecturalcoating-valuechain"
                component={ArchitecturalCoatingVC}
              />
              <ProtectedRoute path="/hlpdataupload" component={HLPDataUpload} />
              <ProtectedRoute
                path="/proteins-valuechain"
                component={ProteinsVC}
              />
              <ProtectedRoute path="/introduction" component={Introduction} />
              <ProtectedRoute
                path="/feedproduction"
                component={FeedProduction}
              />
              <ProtectedRoute
                path="/ForMoreInformationPleaseContact"
                component={ForMoreInformationPleaseContact}
              />
              <ProtectedRoute
                path="/regulatoryframework"
                component={RegulatoryFramework}
              />
              <ProtectedRoute
                path="/regulatoryframeworkv2"
                component={RegulatoryFrameworkV2}
              />
              <ProtectedRoute
                path="/KeyStrategicThemesForFeedAdditiveCEOS"
                component={KeyStrategicThemesForFeedAdditiveCEOS}
              />
              <ProtectedRoute
                path="/FeedbackAndAudit"
                component={FeedbackAndAudit}
              />
              <ProtectedRoute path="/template1100" component={Template1100} />
              <ProtectedRoute path="/template1111" component={Template1111} />
              <ProtectedRoute path="/template1000" component={Template1000} />
              <ProtectedRoute path="/template1101r" component={Template1101r} />
              <ProtectedRoute path="/template1101c" component={Template1101c} />
              <ProtectedRoute path="/template1001" component={Template1001} />
              <ProtectedRoute path="/template1011" component={Template1011} />
              <ProtectedRoute
                path="/globaloverview"
                component={GlobalOverview}
              />
              <ProtectedRoute
                path="/regionoverview"
                component={RegionOverview}
              />
              <ProtectedRoute
                path="/companyprofile"
                component={CompanyProfile}
              />
              <ProtectedRoute path="/marketshare" component={MarketShare} />
              <ProtectedRoute
                path="/recentdevelopment"
                component={RecentDevelopment}
              />
              <ProtectedRoute
                path="/SegmentDefinition"
                component={SegmentDefinition}
              />
              <ProtectedRoute
                path="/editableexecutiveSummery"
                component={EditableExecutiveSummary}
              />
              <ProtectedRoute
                path="/staticexecutiveSummery"
                component={StaticExecutiveSummary}
              />
              <Route exact path="/rd-graph" component={RdGraph} />
              <ProtectedRoute
                path="/productandservices"
                component={ProductAndServices}
              />
              <ProtectedRoute
                path="/productandservicesv2"
                component={ProductAndServicesV2}
              />
              <ProtectedRoute
                path="/growthstrategymatrics"
                component={GrowthStrategyMatrix}
              />
              <ProtectedRoute
                path="/companyoverview"
                component={CompanyProfileOverview}
              />
              <ProtectedRoute
                path="/companymarketspecific"
                component={CompanyMarketSpecific}
              />
            </AuthProvider>
          </Switch>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}
