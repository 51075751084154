import React, { Component } from "react";
import Mordor_icon from "../favicon.png";
//import { Button} from "antd";
import Config from "../../../config/Config";
import config from "../../../config/Api";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";

import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";

import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";

import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";
import axios from "axios";
import font_html from "../../font_style.js";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);
// FusionCharts.options.export.useCanvas = "true";

const COLORS = Config.COLORS;

class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          label: "Venezuela",
          value: "290",
        },
        {
          label: "Saudi",
          value: "260",
        },
        {
          label: "Canada",
          value: "180",
        },
        {
          label: "Iran",
          value: "140",
        },
        {
          label: "Russia",
          value: "115",
        },
        {
          label: "UAE",
          value: "100",
        },
        {
          label: "US",
          value: "30",
        },
        {
          label: "China",
          value: "30",
        },
      ],
      datas: "",
      button_click: false,
      loading: false,
      loadingData: true,
    };
    this.resetChart = this.resetChart.bind(this);
    this.bytesToString = this.bytesToString.bind(this);
    this.removeLegends = this.removeLegends.bind(this);
    this.data_length = this.data_length.bind(this);
  }
  componentDidMount() {
    let breakLegends = parseData(this.props.data.data);
    let datas = [];
    for (let item of breakLegends) {
      item.label = this.bytesToString(item.label);
      datas.push(item);
    }
    if (datas.length > 6) datas = this.removeLegends(datas);
    this.setState({ datas: datas, loadingData: false, data: this.props.data });
    datas = datas.reverse();
    setTimeout(() => {
      this.resetChart();
    }, 1000);
  }
  componentDidUpdate(prevProps) {
    let breakLegends = parseData(this.props.data.data);

    if (this.props.data !== prevProps.data) {
      let datas = [];
      for (let item of breakLegends) {
        item.label = this.bytesToString(item.label);
        datas.push(item);
      }
      if (datas.length > 6) datas = this.removeLegends(datas);
      datas = datas.reverse();
      this.setState({
        datas: datas,
        loadingData: false,
        data: this.props.data,
      });
      setTimeout(() => {
        this.resetChart();
      }, 500);
    }
  }
  bytesToString(d) {
    let k = "";
    let computed_array = [];

    let number_of_letter = 200 / 7 + 10;

    let counter = 1,
      line = "";
    d = d + "";
    for (let item of d) {
      line += item;
      if (isNaN(item)) {
        counter++;
      } else {
        counter += 2;
      }
      if (counter >= number_of_letter) {
        if (
          d.charAt(k.length + line.length - 1) !== " " &&
          d.charAt(k.length + line.length) !== " " &&
          k.length + line.length !== d.length
        ) {
          k += line;
          line += "-";
        } else k += line;
        computed_array.push(line);
        line = "";
        counter = 1;
      }
    }
    if (counter < number_of_letter) computed_array.push(line);
    let caption = "";
    for (let i = 0; i < computed_array.length; i++) {
      if (i !== computed_array.length - 1)
        caption += computed_array[i] + "<br/>";
      else caption += computed_array[i];
    }
    return caption;
  }
  removeLegends(data) {
    // let total = 0;
    // for (let item of data) {
    //   total += parseInt(item.value);
    // }
    // for (let item of data) {
    //   let percent = (item.value / total) * 100;
    //   if (percent < 6) item.label = "";
    // }
    return data;
  }
  data_length(data) {
    let datalength = 0;
    for (let item of data) {
      let count = (item.label + "").match(/<br/g);
      if (count !== null) datalength += count.length;
      if (item.label !== "") datalength++;
    }
    return datalength;
  }
  resetChart(event) {
    let a = window.btoa(JSON.stringify(this.props.data));
    var revenueChart = FusionCharts(
      `${this.props.data.graph_type + this.props.data.component}`
    );
    // get chart xml data
    var svgString = revenueChart.getSVGString();
    let svgdata = new DOMParser().parseFromString(svgString, "text/xml");
    var doc = new DOMParser().parseFromString(font_html, "text/html");
    // svgdata.prepend(doc.documentElement)
    let defs_data = svgdata.getElementsByTagName("defs")[0];
    defs_data.prepend(doc.documentElement);
    axios
      .post(`${config.api.base_url}/api/graphs/data?data=${a}`, {
        stream: window.btoa(svgdata.documentElement.outerHTML),
      })
      .then((res) => {});
    // FusionCharts.batchExport({
    //   charts: [
    //     {
    //       id: this.props.data.graph_type + this.props.data.component,
    //     },
    //   ],
    //   exportFormat: "png",
    //   exportHandler: `${config.api.base_url}/api/graphs/data?data=${a}`,
    //   exportAction: "SAVE",
    //   exportMode: "client",
    // });
  }
  render() {
    let data = "",
      datas = "",
      datalength = 0;
    if (!this.state.loadingData) {
      data = this.state.datas;
      datas = this.state.data;
      datalength = this.data_length(this.state.datas);
    }
    let legendNumColumns = 1;
    if (data.length >= 8) {
      legendNumColumns = 1;
    }
    let width = 1366,
      height = 655;
    let headers = "Countries With Most Oil Reserves [2017-18]";
    let footers = "Source:",
      reseller = false;
    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (datas && datas.heading) headers = datas.heading;
    if (datas && datas.source) footers = datas.source;
    if (datas && datas.reseller) reseller = datas.reseller;
    let chartConfigs = {
      type: "doughnut2d",
      width: width,
      height: height,
      dataFormat: "json",
      id: this.props.data.graph_type + this.props.data.component,
      dataSource: {
        chart: {
          theme: "fusion",
          doughnutRadius: 100,
          usedataplotcolorforlabels: "1",

          pieRadius: 215,
          captionFontColor: "#6a6a6a",
          showValues: true,
          divLineColor: "#ffffff",
          showPlotBorder: "0",
          color: "white",
          legendCaptionFont: "regular_medium_rd",
          labelFontSize: 24,
          showLegend: true,
          captionFontSize: 34,
          showPercentValues: true,
          showLabels: false,
          legendPosition: "absolute",
          legendItemFontSize: 28,
          legendItemFont: "regular_medium_rd",
          alignCaptionWithCavas: "0",
          legendNumColumns: legendNumColumns,
          legendCaptionFontBold: false,
          chartLeftMargin: -400,
          maxLabelWidthPercent: 90,
          animation: false,
          legendXPosition: 800,
          legendYPosition: 220 + (80 - datalength * 13),
          captionAlignment: "center",
          valueFontColor: "#444444",
          valueFont: "regular_medium_rd",
          valuePosition: "Inside",
          labelFontColor: "#ffffff",
          exportEnabled: 0,
          chartBottomMargin: 10,
          alignLegendWithCanvas: false,
          paletteColors: COLORS,
          decimals: 1,

          minAngleForValue: 15,
          reverseLegend: "1",
          showValueAsPercentInLegend: "0",
          showValuesinLegend: "0",
          baseFont: "regular_medium_rd",
        },
        annotations: {
          autoScale: "1",
          groups: [
            {
              id: "caption",
              items: [
                {},
                {
                  id: "dyn-label",
                  type: "text",
                  align: "center",
                  fillcolor: "#6a6a6a",
                  fontsize: 34,
                  font: "regular_medium_rd",
                  text: headers,
                  bold: "1",
                  wrap: "1",
                  wrapWidth: "1100",
                  x: "$canvasEndY+40",
                  y: "$canvasEndY -600",
                },
              ],
            },
            {
              id: "footer",
              items: [
                {
                  id: "dyn-label-bg",
                  showBorder: "0",
                  borderColor: "12345d",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#6a6a6a",
                  fontsize: 28,
                  font: "regular_semibold_rd",
                  text: "Source :",
                  bold: "1",
                  wrap: "1",
                  wrapWidth: "100%",
                  x: "50",
                  y: height - 32,
                },
              ],
            },
            {
              id: "sub-footer",
              items: [
                {
                  id: "dyn-label-bg",
                  showBorder: "0",
                  borderColor: "12345d",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  font: "regular_regular_rd",
                  fillcolor: "#6a6a6a",
                  fontsize: 28,
                  text: footers,
                  bold: "0",
                  wrap: "1",
                  wrapWidth: "100%",
                  x: "150",
                  y: height - 32,
                },
              ],
            },

            {
              id: "mordor-icon",
              xScale_: "5",
              yScale_: "5",
              items: [
                {
                  id: "Brian-user-icon",
                  type: "image",
                  url: Mordor_icon,
                  x: "1180",
                  y: height - 72,
                  xScale: "200",
                  yScale: "200",
                },
              ],
            },
          ],
        },

        data: data,
      },
    };
    if (reseller === true) {
      chartConfigs.dataSource.annotations.groups = [
        {
          id: "caption",
          items: [
            {},
            {
              id: "dyn-label",
              type: "text",
              align: "center",
              fillcolor: "#6a6a6a",
              fontsize: 34,
              font: "regular_medium_rd",
              text: headers,
              bold: "1",
              wrap: "1",
              wrapWidth: "1100",
              x: "$canvasEndY+40",
              y: "$canvasEndY -600",
            },
          ],
        },
      ];
    }
    return (
      <div className="bar" id="bar">
        <ReactFC {...chartConfigs} />
      </div>
    );
  }
}

function parseData(data) {
  let change_data = [];
  for (let item of data) {
    let temp = {};
    temp.label = item.label;
    temp.value = item.value;
    change_data.push(temp);
  }
  return change_data;
}

function bytesToString(d) {
  let k = "";
  let computed_array = [];

  let number_of_letter = 200 / 7 + 10;

  let counter = 1,
    line = "";
  d = d + "";
  for (let item of d) {
    line += item;
    if (isNaN(item)) {
      counter++;
    } else {
      counter += 2;
    }
    if (counter >= number_of_letter) {
      if (
        d.charAt(k.length + line.length - 1) !== " " &&
        d.charAt(k.length + line.length) !== " " &&
        k.length + line.length !== d.length
      ) {
        k += line;
        line += "-";
      } else k += line;
      computed_array.push(line);
      line = "";
      counter = 1;
    }
  }
  if (counter < number_of_letter) computed_array.push(line);

  let caption = "";
  for (let i = 0; i < computed_array.length; i++) {
    if (i !== computed_array.length - 1) caption += computed_array[i] + "<br/>";
    else caption += computed_array[i];
  }
  return caption;
}
export default Chart;
