import React from "react";

export default function ExecutiveSummaryCard(props) {
  let summaryClass = {};
  summaryClass["block"] = props.summaryClass ?? "segment-block";
  summaryClass["value"] = props.valueClass ?? "segblk-value";
  summaryClass["label"] = props.labelClass ?? "segblk-label";

  return (
    <div
      className={summaryClass["block"]}
      style={{ ...props.customStyle, backgroundColor: props.segmentBgcolor }}
    >
      <p className={summaryClass["value"]}>
        {!isNaN(props.segmentBlockValue)
          ? Number(props.segmentBlockValue).toLocaleString("en-US")
          : props.segmentBlockValue}
      </p>
      <p className={summaryClass["label"]}>{props.segmentBlockLabel}</p>
    </div>
  );
}
