import React from "react";
import { CompanyMarketSpecificWrapper } from "../styles/CompanyMarketSpecific.style";
import CardComponent from "../components/CompanyProfiles/CardComponent.js";
import OverviewComponent from "../components/CompanyProfiles/OverviewComponent.js";
import Header from "../components/Header";
import Footer from "../components/Footer";

var Base64 = require("js-base64").Base64;

export const companyProfileSegmentData = {
  title: "Agropur Cooperative",
  subtitle: "Agropur Cooperative",
  market_company: {
    meta: {
      company_highlights: [
        "Agropur is the largest dairy processing co-operative in Canada and among the top 20 players in the global dairy market.​ The company made significant investments in 2018, such as tripling its plant production capacity in South Dakota.",
      ],
      other_key_information: {
        heading: "KEY INFORMATION -slug",
        key_information: [
          "The commissioning of the Lake Norden plant in 2019 enabled the company to increase its market presence and meet the growing demand for cheese and whey products.​",
          "In 2019, Agropur became the first and only major Canadian processor to use only Canadian milk in all products manufactured under its brands in Canada.",
        ],
      },
      market_segment: "Whey Protein",
      company_vertical: "Ingredients",
    },
    core_products: {
      products: [
        "The company majorly offers whey proteins under ingredient divisions, namely Nutrition Solutions, Dairy Solutions, and Custom Solutions. Through the first two divisions, the company offers a wide range of whey isolates, concentrates, and hydrolysates, whereas the latter mostly offers tailor-made solutions to food manufacturers.​​",
        "The brands marketing whey proteins include BioZate, ISO Chill, and BiPRO. Products offered under these brands are widely used in different food and beverage industries, followed by supplement industries.​​",
      ],
      heading: "CORE SOLUTIONS & PRODUCT OFFERINGS",
    },
    market_share: {
      value: "10980 %",
      year: "2020",
    },
    rank: {
      value: 2,
    },
  },
  global_company: {
    analysis: {
      heading: "OVERVIEW",
      analysis: [
        "Agropur is a dairy cooperative, owned by 2,974 dairy farmers, that manages dairy ingredients and retail products for both businesses and end consumers through its brand portfolio.",
        "Its production operations are primarily established in North America​, from where 75% of its offerings are shipped to over 50 countries.​",
        "Agropur brands include Natrel, Quebon, Lextra, Farmers, BiPro, Island Farms, and others.",
      ],
    },
    revenue: {
      currency: "Million",
      source:
        "https://www.agropur.com/sites/default/files/2021-02/rapport%20annuel%202020_EN_WEB_4.pdf",
      units: "USD",
      value: 5784.98,
      year: "1938-2020",
    },
    core_business_segments: {
      heading: "CORE BUSINESS SEGMENTS",
      business_segments: [
        {
          heading: "Ingredients",
          products: [
            "Nutrition Solutions",
            "Dairy Solutions",
            "Custom Solutions",
          ],
        },
        {
          heading: "Retail Products",
          products: [
            "Fresh Dairy Products",
            "Cheese",
            "Yogurt and Frozen Desserts",
            "Butter",
          ],
        },
      ],
    },
    employees: {
      text: "Employees",
      value: 8376,
    },
    founded: {
      text: "Founded",
      value: 1938,
    },
    headquarters: {
      text: "Headquarters",
      value: "Longueuil, Canada",
    },
    logo_url:
      "https://mordorintelligence1-my.sharepoint.com/personal/jafar_mordorintelligence_com/_layouts/15/onedrive.aspx?e=5%3A458720415d87431a823fcf1c30cf2a22&at=9&CT=1611231805612&OR=OWA%2DNT&CID=249f3763%2D9abd%2De1ec%2Db248%2Dba647a70f617&FolderCTID=0x012000078EDC468F9D764CB2C6541C6E6579B9&id=%2Fpersonal%2Fjafar%5Fmordorintelligence%5Fcom%2FDocuments%2FHub%2DProtein%20Market%2FLogos%2FAgropur%2Epng&parent=%2Fpersonal%2Fjafar%5Fmordorintelligence%5Fcom%2FDocuments%2FHub%2DProtein%20Market%2FLogos",
  },
};

class CompanyMarketSpecific extends React.Component {
  state = {
    graphFooterText:
      "Source: Mordor Intelligence Analysis based on Alltech Feed Survey, FAO, Other Sources & Primary Research",
    data: this.props.data ? this.props.data : companyProfileSegmentData,
    reseller: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.meta) {
      if (props.data.meta.sample && props.data.meta.reseller) {
        return {
          reseller: true,
        };
      }
    }
    return null;
  }

  render() {
    const { market_company } = this.props.data
      ? this.props.data
      : this.state.data;
    const { section_id } = this.props;
    const pageId = section_id ? Base64.encode(section_id) : undefined;
    const { slideNo } = this.props;
    return (
      <>
        <div className="page">
          <CompanyMarketSpecificWrapper>
            {!this.props.fromInterface ||
            this.props.fromInterface === undefined ? (
              <Header slideTitle={this.state.data.type} />
            ) : null}
            <div
              className="container marketoverview-container"
              style={{ height: this.props.fromInterface ? "626px" : "626px" }}
            >
              <h2 className="company_profile_heading">
                {!this.props.fromInterface ||
                this.props.fromInterface === undefined
                  ? this.props.data.title
                  : this.props.title}{" "}
                - MARKET OVERVIEW
              </h2>
              <div className="profile_container">
                <div className="left_container">
                  <OverviewComponent
                    heading={
                      market_company &&
                      market_company.core_products &&
                      market_company.core_products.heading
                    }
                    analysis={
                      market_company &&
                      market_company.core_products &&
                      market_company.core_products.products
                    }
                  />
                </div>
                {this.props.template === "companyProfile1" ? (
                  <>
                    <div className="middle_container">
                      <div className="inner-stats-container">
                        {market_company.middle_container.map((item, index) => {
                          return (
                            <CardComponent
                              heading={item.heading}
                              text={item.text}
                              type={item.type}
                              bgColor={item.bgColor}
                            />
                          );
                        })}
                      </div>
                    </div>

                    <div className="right_container">
                      <CardComponent
                        className="info_content_card"
                        heading={
                          market_company &&
                          market_company.meta &&
                          market_company.meta.company_vertical
                        }
                        text="COMPANY VERTICAL"
                        type="card-3"
                        bgColor="rgb(63, 208, 217)"
                      />

                      <OverviewComponent
                        heading={
                          market_company &&
                          market_company.meta &&
                          market_company.meta.other_key_information.heading
                        }
                        analysis={
                          market_company &&
                          market_company.meta &&
                          market_company.meta.other_key_information &&
                          market_company.meta.other_key_information
                            .key_information
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="middle_container">
                      <CardComponent
                        className="info_content_card"
                        heading={
                          market_company &&
                          market_company.meta &&
                          market_company.meta.market_segment
                        }
                        text="MARKET SEGMENT"
                        type="card-3"
                        bgColor="rgb(83, 157, 228)"
                      />

                      <OverviewComponent
                        heading="COMPANY HIGHLIGHTS"
                        analysis={
                          market_company &&
                          market_company.meta &&
                          market_company.meta.company_highlights
                        }
                        bgColor="rgb(62, 135, 221)"
                        color="white"
                      />
                    </div>

                    <div className="right_container">
                      <CardComponent
                        className="info_content_card"
                        heading={
                          market_company &&
                          market_company.meta &&
                          market_company.meta.company_vertical
                        }
                        text="COMPANY VERTICAL"
                        type="card-3"
                        bgColor="rgb(63, 208, 217)"
                      />

                      <OverviewComponent
                        heading={
                          market_company &&
                          market_company.meta &&
                          market_company.meta.other_key_information.heading
                        }
                        analysis={
                          market_company &&
                          market_company.meta &&
                          market_company.meta.other_key_information &&
                          market_company.meta.other_key_information
                            .key_information
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </CompanyMarketSpecificWrapper>
          {!this.props.fromInterface ||
          this.props.fromInterface === undefined ? (
            <Footer slideNo={slideNo} reseller={this.state.reseller} />
          ) : null}
        </div>
      </>
    );
  }
}

export default CompanyMarketSpecific;
