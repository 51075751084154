import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { SoftDrinkVCWrapper } from "./styles";
var Base64 = require("js-base64").Base64;
let color_array = ["#E3F2F9", "#D5F1E7", "#E2E0F6", "#DBE7F2"];

function IndicatorsOne(props) {
  const [state, setState] = useState({
    data: props.data ? props.data : "",
    reseller: false,
  });
  const addNext = (str) => {
    let finalstr = "";
    for (let i = 0; i < str.length; i++) {
      if (str[i] === ":") {
        finalstr += ":</br>";
      } else if (`${str[i] + str[i + 1]}` === "/n") {
        finalstr += "<div style='height:10px' ></div>";
      } else if (`${str[i] + str[i + 1]}` === "<b") {
        finalstr += `• <`;
      } else if (`${str[i - 1] + str[i]}` === "/n") {
        finalstr += "";
      } else {
        finalstr += str[i];
      }
    }
    return finalstr;
  };

  return (
    <div style={{ marginTop: "20px" }} className="page" id={""}>
      {!props.fromInterface || props.fromInterface === undefined ? (
        <Header slideTitle={state.data.type} />
      ) : null}
      <div>
        <h2 className="heading valuechain_title">
          {props.data && props.data
            ? (props.data.title + "").toUpperCase()
            : (state.data.title + "").toUpperCase()}
        </h2>

        <SoftDrinkVCWrapper
          style={{
            position: "relative",
            height: "530px",
            overflow: "hidden",
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
            columnGap: "12px",
            margin: "15px",
          }}
          className="vc_table_container"
        >
          {state.data?.data?.softDrink_dataCBB
            ? Object.keys(state.data?.data?.softDrink_dataCBB).map(
                (cb, index) => (
                  <div
                    style={{
                      backgroundColor: `${color_array[index]}`,
                      padding: "10px",
                    }}
                  >
                    <h1 style={{ fontSize: "14px", textAlign: "center" }}>
                      {(cb + "").toUpperCase()}
                    </h1>
                    <div>
                      {state.data?.data?.softDrink_dataCBB[cb]
                        .split(";")
                        .filter((item) => (item + "").trim() !== "")
                        .map((elem) => (
                          <div
                            className="consumer_buying_para"
                            style={{
                              textAlign: "justify",
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: " #1f4986",
                            }}
                          >
                            <div
                              style={{
                                marginLeft: "5px",
                                marginBottom: "10px",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: `${(addNext(elem) + "").trim()}`,
                              }}
                            ></div>
                          </div>
                        ))}
                    </div>
                  </div>
                )
              )
            : null}
          {/* {state.data.data.map((vc_list, index) => (
        <div
          style={{
            backgroundColor: `${color_array[index]}`,
            padding: "12px",
          }}
        >
          <h2 className="vc_table_type">{vc_list.type}</h2>

          <table>
            <tr className="vc_table_row">
              <th>Dish/Item Names</th>
              <th>Avg Price</th>
            </tr>

            {renderTableList(vc_list.list)}
          </table>
        </div>
      ))} */}
        </SoftDrinkVCWrapper>

        {!props.fromInterface || props.fromInterface === undefined ? (
          <Footer
            // slug={slug}
            // slideNo={slideNo}
            reseller={state.reseller}
          />
        ) : null}
      </div>
    </div>
  );
}

export default IndicatorsOne;
