import styled from "styled-components";

const TocUpdatedWrapper = styled.div`
  padding: 20px 50px 0px 50px;
  background-color: #ddefff;
  width: 100%;
  position: relative;
  height: 663px;

  img {
    position: absolute;
    right: 0;
    top: -3%;
    z-index: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  p {
    font-family: "Avenir Roman";
    font-size: 14px;
    color: #002f75;
    margin: 0;
    font-weight: normal;
  }

  p1 {
    font-family: "Avenir Roman";
    font-size: 16px;
    color: #002f75;
    line-height: 25px;
  }

  h3 {
    font-family: "Avenir Roman";
    font-size: 18px;
    color: #002f75;
    font-weight: 800;
    line-height: 24px;
    margin-top: 10px;

    margin-bottom: 0;
    text-transform: uppercase;
  }

  h1 {
    font-family: "Avenir Heavy";
    font-size: 26px;
    color: #002f75;
    font-weight: 1200;
  }

  .toc_container {
    width: 100%;
    column-count: 4;
    background: #ffffff;
    flex-direction: column;
    flex-wrap: wrap;
    display: flex;
    height: 630px;
    padding: 18px 10px 15px 15px;
    z-index: 15;
    position: relative;
  }
  .toc_container h3 + p {
    margin-top: 10px;
  }
  .toc_element {
    width: 33%;
  }
  h3.toc_element {
    text-transform: uppercase !important;
  }
  h3.toc_element:first-child {
    margin-top: 0;
  }

  .inner_div1 {
    height: 530px;
    width: 282px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background: #ffffff;
    padding: 20px 20px;
    margin: 0 10px;
    z-index: 1;
  }
`;

export default TocUpdatedWrapper;
