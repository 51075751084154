import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  CloseCircleOutlined,
  FileAddOutlined,
  SendOutlined,
} from "@ant-design/icons";

function MentionBox({
  baseUrl,
  onSendMessage,
  loading,
  selectedMessage,
  setSelectedMessage,
}) {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  // const [showAllSuggestions, setShowAllSuggestions] = useState(false);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
  const [nameSelected, setNameSelected] = useState(false);
  const inputRef = useRef(null);
  // const [department, setDepartment] = useState("?department=EDITING");
  const [names, setNames] = useState([]);
  const [selectedMentionedList, setSelectedMentionedList] = useState([]);
  const fileRef = useRef(null);
  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "ArrowUp") {
        event.preventDefault();
        setActiveSuggestionIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1
        );
      } else if (event.key === "ArrowDown") {
        event.preventDefault();
        setActiveSuggestionIndex((prevIndex) =>
          prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0
        );
      } else if (event.key === "Enter") {
        event.preventDefault();
        if (activeSuggestionIndex !== -1) {
          setActiveSuggestionIndex(-1);
          handleNameClick(suggestions[activeSuggestionIndex]);
        } else if (inputValue.trim() !== "") {
          handleSendMessage({ type: null, file: null });
        }
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [suggestions, activeSuggestionIndex, inputValue, nameSelected]);
  useEffect(() => {
    if (selectedMessage) {
      inputRef.current.focus();
    }
  }, [selectedMessage]);
  function handleInputChange(event) {
    const value = event.target.value;
    setInputValue(value);

    if (value.includes("@")) {
      const keyword = value.split("@").pop().trim();
      if (keyword === "") {
        setSuggestions(names);
      } else {
        const matchedNames = names.filter((name) =>
          name.display_name.toLowerCase().includes(keyword.toLowerCase())
        );
        setSuggestions(matchedNames);
      }
    } else {
      setSuggestions([]);
    }

    if (nameSelected) {
      setSuggestions([]);
      setNameSelected(false);
    }

    setActiveSuggestionIndex(-1);
  }

  function handleNameClick(name) {
    setInputValue((prevValue) => {
      const prefix = prevValue.split("@").slice(0, -1).join("@");
      return `${prefix}${name.display_name}`;
    });
    setSelectedMentionedList((prevList) => [...prevList, name]);
    setSuggestions([]);
    inputRef.current.focus();
    setNameSelected(true);
  }

  function handleSendMessage({ type, file }) {
    const sortedNames = [...selectedMentionedList].sort((a, b) =>
      b.display_name.localeCompare(a.display_name)
    );
    if (inputValue.trim() !== "" || file) {
      const message = inputValue.trim();
      let copyMessage = message;
      const mentionedPeople = sortedNames.reduce((acc, name) => {
        if (copyMessage.includes(name.display_name)) {
          // Remove the name from the copyMessage
          copyMessage = copyMessage.replace(
            new RegExp(name.display_name, "g"),
            ""
          );
          acc.push(name.email_id);
        }
        return acc;
      }, []);
      onSendMessage({
        newMessage: message,
        newMentionedPeople: mentionedPeople,
        ...(type && { type }),
        ...(file && { file }),
      });
      setInputValue("");
    }
    setSuggestions([]);
    setInputValue("");
    setNameSelected(false);
    setSelectedMentionedList([]);
  }
  // function handleShowAllSuggestions(event) {
  //   setShowAllSuggestions(event.target.checked);
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //     const inputValue = inputRef.current.value;
  //     inputRef.current.setSelectionRange(inputValue.length, inputValue.length);
  //   }
  // }

  function getToken() {
    return window.localStorage.getItem("token");
  }

  const fetchUsers = () => {
    const token = getToken();
    axios
      .get(`${baseUrl}/api/comments/get-users`, {
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setNames(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  //   useEffect(() => {

  //   if (showAllSuggestions) {
  //       setDepartment("?department=TECH");
  //   } else {
  //       setDepartment("");
  //   }
  //   fetchUsers();
  // }, [showAllSuggestions]);
  const selectFile = (e) => {
    const file = e.target.files[0];
    let fileSize = file.size;
    let sizeInMB = fileSize / (1024 * 1024);
    if (sizeInMB > 10) {
      alert("File size should be less than 10 MB");
      return;
    } else {
      handleSendMessage({ type: "attachment", file });
    }
  };
  return (
    <div style={{ position: "relative" }}>
      {(selectedMessage || suggestions.length > 0) && (
        <div
          style={{
            position: "absolute",
            bottom: "40px",
            left: "0",
            width: "100%",
            maxHeight: "300px",
            overflowY: "auto",
            borderRadius: "10px",
            backgroundColor: "white",
            boxShadow: "#00000038 0px 1px 13px 0px",
          }}
        >
          {suggestions.length > 0 && (
            <div>
              <p
                style={{
                  backgroundColor: "#5b5fc7",
                  color: "white",
                  padding: "5px 20px",
                }}
              >
                Suggestions
              </p>
              {suggestions.slice(0, 4).map((name, index) => (
                <div
                  key={name._id}
                  onClick={() => handleNameClick(name)}
                  style={{
                    cursor: "pointer",
                    padding: "5px 20px",
                    border: "1px solid #ccc",
                    backgroundColor:
                      index === activeSuggestionIndex
                        ? "lightblue"
                        : "transparent",
                  }}
                  onMouseEnter={() => setActiveSuggestionIndex(index)}
                  onMouseLeave={() => setActiveSuggestionIndex(-1)}
                >
                  {name.display_name} ({name.email_id})
                </div>
              ))}
              {/* <div style={{ padding: '5px 20px' }}>
            <input type="checkbox" checked={showAllSuggestions} onChange={handleShowAllSuggestions} />
            Show All
          </div> */}
            </div>
          )}
          {selectedMessage && (
            <div
              style={{
                padding: "5px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                borderLeft: "5px solid rgb(24, 144, 255)",
                borderRadius: "10px",
                margin: "5px",
              }}
            >
              <div>
                <b>{selectedMessage.user_name}</b>
                {selectedMessage.messageType === "attachment" ? (
                  <div>attachment</div>
                ) : (
                  <div style={{ maxHeight: "60px", overflow: "scroll" }}>
                    {selectedMessage.comment}
                  </div>
                )}
              </div>
              <CloseCircleOutlined
                onClick={() => setSelectedMessage(null)}
                style={{ color: "red", fontSize: "large" }}
              />
            </div>
          )}
        </div>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #939393",
          borderRadius: "3px",
          outline: "none",
        }}
      >
        <input
          className="chatbox-input"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          ref={inputRef}
          style={{ width: "90%", padding: "4px", border: "none" }}
          disabled={loading === true}
        />
        <input
          onChange={selectFile}
          ref={fileRef}
          style={{
            display: "none",
          }}
          type="file"
        />
        <button
          style={{
            padding: "4px",
            background: "transparent",
            border: "none",
          }}
          // disabled={loading === true}
          onClick={() => fileRef.current.click()}
        >
          <FileAddOutlined />
        </button>
        <button
          style={{
            padding: "4px",
            background: "transparent",
            border: "none",
          }}
          disabled={loading === true}
          onClick={() => handleSendMessage({ type: null, file: null })}
        >
          <SendOutlined />
        </button>
      </div>
    </div>
  );
}

export default MentionBox;
