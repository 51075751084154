import styled from "styled-components";

export const EngineeringPlasticsVCWrapper = styled.div`
  .section_data1 {
    width: 14%;
    max-width: 180px;
    position: absolute;
    word-break: break-word;
  }

  .section1_list1 {
    top: 27.5%;
    left: 5%;
    width: 20%;
    max-width: 250px;
  }

  .section1_list2 {
    top: 72%;
    left: 5%;
    width: 20%;
    max-width: 250px;
  }

  .section1_list3 {
    top: 71%;
    left: 12%;
  }

  .section2_list1 {
    top: 59%;
    left: 30%;
  }

  .section2_list2 {
    top: 59%;
    left: 47%;
  }

  .section3_list1 {
    top: 64%;
    left: 66%;
  }

  .section3_list2 {
    top: 64%;
    left: 82%;
  }
`;
