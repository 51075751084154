import styled from "styled-components";

export const DeleteDataStyleWrapper = styled.div`

> .wrapper{
  margin-top: 5rem;
  display: flex;
  justify-content: center;
}

.card {
    width: 80%;
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    gap: 20px;
    padding: 0 50px 50px;
    background: #fff;
    transition: all 0.7s ease-out;
    border-radius: 50px;
    -webkit-box-shadow: 0 0 30px 0 rgba(43, 86, 112, 0.1);
    position: relative;
    box-shadow: rgba(43, 86, 112, 0.1) 0px 0px 30px 0px;
  }

  .info {
    position: absolute;
    top: 20px;
    right: 50px;
  }

  .infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    width: 30%;
    margin: 20px 0;
  }

  .infoContainer button {
    align-self: flex-end;
  }

  .infoContent {
    background-color: rgb(235 232 232 / 50%);
    padding: 10px 20px;
    overflow: scroll;
    max-height: 350px;
    width: 100%
    height: 100%
  }

  .dropdownContainer, 
  .inputFieldContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
  }

  .dimensions-wrapper{
    padding: 10px;
    margin: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dimensions-heading{
    text-align:center;
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: underline;
    text-underline-offset: 4px;
  }

  .dimensions-container{
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: end;
    gap: 10px;
    max-height: 320px;
    overflow-y: auto;
  }

  .tab-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }

  .tab-options{
    display:flex;
    gap: 10px;
  }


`;
