import styled from "styled-components";

export const GraphConfigComponentWrapper = styled.div`
  width: 100%;

  .card {
    width: 80%;
    max-height:80vh;
    margin-top: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    gap: 20px;
    padding: 25px;
    background: #fff;
    transition: background 1s ease-in;
    border-radius: 50px;
    -webkit-box-shadow: 0 0 30px 0 rgba(43, 86, 112, 0.1);
    box-shadow: 0 0 30px 0 rgba(43, 86, 112, 0.1);
    position: relative;
  }

  .info {
    position: absolute;
    top: 20px;
    right: 50px;
  }

  .infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    width: 30%;
    margin: 20px 0;
  }

  .infoContainer button {
    align-self: flex-end;
  }

  .infoContent {
    background-color: rgb(235 232 232 / 50%);
    padding: 10px 20px;
    overflow: scroll;
    max-height: 350px;
    width: 100%
    height: 100%
  }

  .leftContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }

  #form-wrapper{
    max-height: 600px;
    padding: 30px 0;
    overflow-y: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 50px;
  }

  #form-container{
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    padding: 20px;
    box-shadow: 0 0 5px 0 lightgray;
  }

  label{
    disply: block;
    width: max-content;
    min-width: 200px;
    font-size: 16px;
    width: 100%;
  }

  input {
    border: 1px solid gray;
    border-radius: 4px;
    padding: 5px;
    margin-top: 5px;
    width: 100%;
  }

  .save-btn{
    display: block;
    align-self: center;
    padding: 4px 12px;
    border: 1px solid #7bb9f3;
    font-size: 16px;
    color: #1890ff;
    cursor: pointer;
    background-color: white;
    transition: 600ms;
    border-radius: 4px;
  }

  .save-btn:hover{
    border-color: #1890ff;
    background-color: #1890ff;
    color: white;
  }

`;
