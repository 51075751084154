import React, { useEffect, useState } from "react";
import InterfaceHeader from "../InterfaceHeader";
import { Button, Select, notification, Switch } from "antd";
import axios from "axios";
import config from "../../config/Api";
import { UploadStaticImageComponentWrapper } from "./UploadStaticImages.style";
import Dragger from "antd/lib/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import DragList from "../../Common/DragList";
import xlsx from "json-as-xlsx";

const openNotification = (msg) => {
  let args,
    key = msg;
  if (key === true) {
    args = {
      message: "Success",
      description: "Image upload successful.",
      duration: 3.5,
      placement: "bottomLeft",
    };
    notification.success(args);
  } else if (key === false) {
    args = {
      message: "Failure",
      description: "Failed to upload SVGs.",
      duration: 3.5,
      placement: "bottomLeft",
    };
    notification.error(args);
  } else {
    args = {
      message: "Warning",
      description: key,
      duration: 3.5,
      placement: "bottomLeft",
    };
    notification.warning(args);
  }
};

function UploadStaticImageComponent() {
  const [hubData, setHubData] = useState([]);
  const [selectedHub, setSelectedHub] = useState(null);

  const [loading, setLoading] = useState(false);
  const [onGoingApiCall, setOnGoingApiCall] = useState(false);
  const [dimensionBasedFlag, setDimensionBasedFlag] = useState(false);

  const [typeData, setTypeData] = useState([]);
  const [selectedType, setSelectedType] = useState(null);

  const [imageData, setImageData] = useState(null);
  const [selectedImage, setSelectededImage] = useState(null);
  const [dimensionsBasedUrlsFile, setDimensionsBasedUrlsFile] = useState(null);
  const [bufferedFile, setBufferedFile] = useState(null);
  const [errors, setErrors] = useState(null);

  const [infoTabVis, setInfoTabVis] = useState(false);
  const [uploadSamplePDF, setUploadSamplePDF] = useState(false);

  const [disableBtns, setDisableBtns] = useState(false);

  const getHubList = async () => {
    setLoading(true);
    if (hubData.length == 0) {
      let full_access = false;
      await axios
        .get(`${config.api.base_url}/api/hubs/list?full_access=${full_access}`)
        .then((response) => {
          if (response.status === 200) {
            setHubData(response.data.obj);
          }
          setLoading(false);
        })
        .catch((error) => {
          openNotification("Unable to load hub list");
          setLoading(false);
        });
    }
  };

  const getTypeList = async (hubId) => {
    setTypeData([]);
    setLoading(true);
    setOnGoingApiCall(true);
    await axios
      .get(
        `${config.api.base_url}/api/getImageType/${hubId}?dimensionFlag=${dimensionBasedFlag}`
      )
      .then((response) => {
        if (response.status === 200) {
          setTypeData(response.data.typeArray);
          setLoading(false);
          setOnGoingApiCall(false);
        }
      })
      .catch((error) => {
        openNotification("Unable to load hub list");
        setLoading(false);
        setOnGoingApiCall(false);
      });
    setLoading(false);
    setOnGoingApiCall(false);
  };

  const getImageList = async (type) => {
    let restrictedHubs = [18],
      restrictedHubSections = ["sample_staticPdf"];
    if (
      uploadSamplePDF &&
      restrictedHubs.includes(selectedHub) &&
      restrictedHubSections.includes(type)
    ) {
      setDisableBtns(true);
      openNotification(
        "PDF Upload for this section is restricted by Tech Team. Please contact Tech Team to upload PDF for this section."
      );
      return;
    }

    setDisableBtns(false);
    setLoading(true);
    setOnGoingApiCall(true);

    await axios
      .get(
        `${config.api.base_url}/api/getImageUrl/?id=${selectedHub}&type=${type}&sampleURL=${uploadSamplePDF}`
      )
      .then((response) => {
        if (response.status === 200) {
          if (uploadSamplePDF) {
            setBufferedFile(response.data.data);
          } else {
            setImageData((prev) => [...prev, ...response.data]);
          }
        }
        setLoading(false);
        setOnGoingApiCall(false);
      })
      .catch((error) => {
        openNotification("Unable to load hub list");
        setLoading(false);
        setOnGoingApiCall(false);
      });
  };

  const preventRequest = () => false;

  const toTitleCase = (str) => {
    if (str === "sample_staticPdf") {
      return "SUFFIX PDF";
    } else {
      return (str + "").toUpperCase().split("_").join(" ");
    }
  };

  function getToken() {
    return window.localStorage.getItem("token");
  }

  const uploadImage = async () => {
    try {
      if (!selectedHub || !selectedType) {
        openNotification("Required fields are missing to upload Svgs.");
        return;
      }
      const formData = new FormData();

      setOnGoingApiCall(true);
      setErrors(null);

      if (uploadSamplePDF == true) {
        formData.append("type", "static-slides");
        formData.append("hub_id", selectedHub);
        selectedImage.forEach((file, index) => {
          formData.append("file", file.originFileObj);
        });
        axios
          .post(
            `${config.api.base_url}/api/additional_routes/upload-static-sample-pdf`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getToken()}`,
              },
            }
          )
          .then((response) => {
            console.log("DATA", response);
            setBufferedFile(response.data.url);
            setSelectededImage([]);
            setOnGoingApiCall(false);
          })
          .catch((err) => {
            console.log("ERROR FOUND", err);
            openNotification("Something went wrong. Please try again.");
            setOnGoingApiCall(false);
          });
        return;
      }

      if (selectedImage && selectedImage[0]) {
        formData.append("type", "static-slides");
        formData.append("hub_id", selectedHub);
        selectedImage.forEach((file, index) => {
          formData.append("image", file.originFileObj);
        });
        axios
          .post(
            `${config.api.base_url}/api/additional_routes/upload_files`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getToken()}`,
              },
            }
          )
          .then(async (response) => {
            const urls = response.data.urls;
            let imageArr = Object.entries(urls).map((item) => ({
              url: item[1],
              name: item[0],
            }));

            if (dimensionBasedFlag) {
              const columns = [
                {
                  label: "URL",
                  value: "url",
                },
              ];
              const content = Object.entries(urls).map((item) => ({
                url: item[1],
              }));
              const uploadedSlides = [
                {
                  sheet: "Sheet 1",
                  columns,
                  content,
                },
              ];
              const settings = {
                fileName: "Uploaded Slides Data",
                extraLength: 0,
                writeMode: "writeFile",
                writeOptions: {},
                RTL: false,
              };

              let callback = function (sheet) {
                notification.success({
                  type: "info",
                  message: "Deleted Data downloaded successfully.",
                });
              };

              xlsx(uploadedSlides, settings, callback);
            } else {
              setImageData((prev) => [...prev, ...imageArr]);
            }
            setSelectededImage([]);
            setOnGoingApiCall(false);
          })
          .catch((error) => {
            openNotification("Something went wrong. Please try again.");
            setOnGoingApiCall(false);
            console.error(error);
          });
      } else {
        setOnGoingApiCall(false);
        openNotification("Please upload SVGs to continue.");
      }
    } catch (e) {
      console.log("ERROR", e);
      setOnGoingApiCall(false);
      openNotification("Sorry! Something went wrong.");
    }
  };

  useEffect(() => {
    getHubList();
  }, []);

  const handleHubChange = (value) => {
    if (uploadSamplePDF) {
      setDisableBtns(false);
      setSelectedHub(value);
      setImageData([]);
      setSelectedType(null);
      setTypeData([
        "global_overview",
        "portor_forces",
        "global_value_chain",
        "global_marketSize_DROs",
        "technological_advancements",
        "primary_insights",
        "static_globalDataPack",
        "glossary_of_terms",
        "sample_staticPdf",
      ]);
      setImageData([]);
      return;
    }

    setSelectedHub(value);
    setSelectedType(null);
    getTypeList(value);
    setImageData([]);
  };

  const handleTypeChange = (value) => {
    setSelectedType(value);
    setImageData([]);
    getImageList(value);
    setBufferedFile(null);
  };

  const saveImages = async () => {
    try {
      if (
        !selectedHub ||
        !selectedType ||
        (dimensionBasedFlag === false &&
          uploadSamplePDF == false &&
          !imageData?.length) ||
        (dimensionBasedFlag === true && !dimensionsBasedUrlsFile?.length)
      ) {
        openNotification("Required fields are misssing.");
        return;
      }
      setOnGoingApiCall(true);

      let payload = {
        hub_id: selectedHub,
        type: selectedType,
        data: imageData?.map((item) => ({ url: item.url })),
        dimensionBasedFlag,
        uploadSamplePDF,
      };

      if (dimensionBasedFlag) {
        payload["data"] = bufferedFile;
      }

      if (uploadSamplePDF) {
        payload["sample_url"] = bufferedFile;
      }

      await axios
        .post(`${config.api.base_url}/api/uploadImage`, payload, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status == 200) {
            setImageData([]);
            setTypeData([]);
            setSelectedHub(null);
            setSelectedType(null);
            openNotification(true);
            setBufferedFile(null);
            setDimensionBasedFlag(false);
            setDimensionsBasedUrlsFile(null);
          }
          setOnGoingApiCall(false);
          setLoading(false);
        })
        .catch((error) => {
          openNotification(false);
          setOnGoingApiCall(false);
          setLoading(false);
        });
    } catch (error) {
      openNotification("Unable to save data");
      setOnGoingApiCall(false);
      console.log("ERROR -->", error);
    }
  };

  const handleFileUpload = async (e) => {
    setDimensionsBasedUrlsFile(e.fileList);
    const file = e.fileList[0].originFileObj;
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = e.target.result;
      const base64Data = Buffer.from(data).toString("base64");
      setBufferedFile(base64Data);
    };
    reader.readAsArrayBuffer(file);
  };

  const extractPdfName = (url) => {
    if (!url) {
      return;
    }

    const arr = url?.split("/") || [];

    return arr[arr.length - 1];
  };

  return (
    <>
      <InterfaceHeader />
      <UploadStaticImageComponentWrapper>
        <div
          style={{
            marginTop: "8rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              boxShadow: dimensionBasedFlag
                ? "rgb(37 118 167 / 40%) 0px 0px 30px 20px"
                : uploadSamplePDF
                  ? "rgb(94 230 106 / 54%) 0px 0px 20px 20px"
                  : "0 0 30px 0 rgba(43, 86, 112, 0.1)",
            }}
            className="card"
          >
            <div
              className="leftContainer"
              style={{
                width: infoTabVis ? "70%" : "100%",
              }}
            >
              <h2
                style={{
                  textAlign: "center",
                  flexGrow: 1,
                  margin: "20px 0 30px",
                }}
              >
                Upload{" "}
                {dimensionBasedFlag
                  ? "Dimensions Base Static Slides"
                  : uploadSamplePDF
                    ? "Sample Static PDFs"
                    : "Static Slides"}
              </h2>{" "}
              <div className="contentWrapper">
                <div className="sectionContainer">
                  {/* LEFT SECTION */}
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "inherit",
                        justifyContent: "space-between",
                        gap: "10px",
                        width: "100%",
                      }}
                    >
                      <h3 style={{ margin: "0px" }}>Dimension Based Slides</h3>{" "}
                      <Switch
                        checked={dimensionBasedFlag}
                        onChange={() => {
                          setDimensionBasedFlag(!dimensionBasedFlag);
                          setSelectedHub(null);
                          setSelectedType(null);
                          setTypeData([]);
                        }}
                        disabled={Boolean(uploadSamplePDF)}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "inherit",
                        justifyContent: "space-between",
                        gap: "10px",
                        width: "100%",
                      }}
                    >
                      <h3 style={{ margin: "0px" }}>Upload Sample PDF</h3>{" "}
                      <Switch
                        checked={uploadSamplePDF}
                        onChange={() => {
                          setUploadSamplePDF(!uploadSamplePDF);
                          setSelectedHub(null);
                          setSelectedType(null);
                          setTypeData([]);
                          setImageData([]);
                        }}
                        disabled={Boolean(dimensionBasedFlag)}
                      />
                    </div>
                    <div className="dropdownContainer">
                      <Select
                        disabled={onGoingApiCall}
                        style={{
                          display: "block",
                          width: "200px",
                        }}
                        id="selectHub"
                        placeholder="Select Hub"
                        value={selectedHub}
                        loading={loading}
                        maxLength=""
                        onChange={handleHubChange}
                        options={hubData.map((item, index) => ({
                          label: item.name,
                          value: item.id,
                        }))}
                      />
                      <Select
                        disabled={onGoingApiCall}
                        style={{
                          display: "block",
                          width: "200px",
                        }}
                        id="selectType"
                        placeholder="Select Type"
                        loading={loading}
                        value={selectedType}
                        maxLength=""
                        onChange={handleTypeChange}
                        options={typeData.map((item) => ({
                          label: toTitleCase(item),
                          value: item,
                        }))}
                      />
                    </div>
                    <Dragger
                      disabled={onGoingApiCall || disableBtns}
                      accept={uploadSamplePDF ? ".pdf" : ".svg"}
                      maxCount={20}
                      multiple={true}
                      fileList={selectedImage}
                      onChange={(e) => setSelectededImage(e.fileList)}
                      beforeUpload={preventRequest}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Only {uploadSamplePDF ? ".PDF" : ".SVG image"} types
                      </p>
                    </Dragger>

                    <Button
                      key="Upload Logo"
                      type="primary"
                      onClick={uploadImage}
                      loading={onGoingApiCall}
                      disabled={onGoingApiCall || disableBtns}
                    >
                      {onGoingApiCall
                        ? "Uploading"
                        : `Upload ${uploadSamplePDF ? "PDF" : "SVGs"}`}
                    </Button>
                  </div>

                  {/* DRAG LIST COMPONENT */}
                  <div>
                    {dimensionBasedFlag ? (
                      <UploadSection
                        onGoingApiCall={onGoingApiCall}
                        dimensionBasedFlag={dimensionBasedFlag}
                        dimensionsBasedUrlsFile={dimensionsBasedUrlsFile}
                        handleFileUpload={handleFileUpload}
                      />
                    ) : uploadSamplePDF && bufferedFile ? (
                      <>
                        <div
                          style={{
                            height: "300px",
                            padding: "10px",
                            fontSize: "24px",
                          }}
                        >
                          <div key={bufferedFile} className="list-item">
                            <p title={extractPdfName(bufferedFile)}>
                              {extractPdfName(bufferedFile)}
                            </p>
                            <button
                              className="delete-button"
                              onClick={() => setBufferedFile(null)}
                              style={{
                                fontSize: "16px",
                              }}
                            >
                              Delete
                            </button>
                            <button
                              className="prv-button"
                              onClick={() =>
                                window.open(bufferedFile, "_blank")
                              }
                              style={{
                                fontSize: "16px",
                              }}
                            >
                              Preview
                            </button>
                          </div>
                        </div>{" "}
                      </>
                    ) : (
                      <div>
                        {!imageData ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "300px",
                              fontSize: "24px",
                            }}
                          >
                            Please select the fields.
                          </div>
                        ) : imageData?.length ? (
                          <DragList
                            dragList={imageData}
                            setDragList={setImageData}
                            preview={true}
                          />
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "300px",
                              fontSize: "24px",
                            }}
                          >
                            {disableBtns
                              ? "PDF Upload for this section is restricted by Tech Team. Please contact Tech Team to upload PDF for this section."
                              : onGoingApiCall
                                ? "Loading..."
                                : "No Data Found"}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    key="Upload Logo"
                    type="primary"
                    onClick={saveImages}
                    loading={onGoingApiCall}
                    disabled={onGoingApiCall || disableBtns}
                  >
                    {onGoingApiCall
                      ? "Uploading"
                      : dimensionBasedFlag
                        ? "Save Excel"
                        : "Save"}
                  </Button>
                </div>
              </div>
            </div>

            <div className={infoTabVis ? "infoContainer" : "info"}>
              <Button
                type="default"
                danger={infoTabVis}
                onClick={() => setInfoTabVis(!infoTabVis)}
              >
                {infoTabVis ? "Close Guide" : "Open Guide"}
              </Button>
              {infoTabVis && (
                <div className="infoContent">
                  {dimensionBasedFlag ? (
                    <b>
                      Upload Static Slides which depends on dimensions
                      <br />
                      <div>
                        <p>⏺ Select your hub.</p>
                        <p>⏺ Select the section </p>
                        <p>⏺ Select and upload all svgs you want to upload</p>
                        <p>
                          ⏺ click on upload SVGs button, after that you will
                          get an excel file
                        </p>
                        <p>
                          ⏺ open that Excel file add the dimensions columns, if
                          needed to show multiple SVGs at a specific dimension
                          add another column (named URL2) and add the url.
                        </p>
                        <p>
                          ⏺ if wanted to add Sample Slides, create a column
                          (named sample_slide) and add the url.
                        </p>
                        <p>
                          ⏺ Save that Excel and upload in XLSX file upload
                          area.
                        </p>
                        <p>⏺ Finally click on Save</p>
                        <p>
                          ⏺{" "}
                          <a href="https://parse-excel-log-files.s3.amazonaws.com/1719913236020_Reports%20List%20with%20dimensions.xlsx">
                            here
                          </a>{" "}
                          is the sample excel file
                        </p>
                        <p>⏺ here is a quick demo video</p>
                        <a href="https://lorien.mordorintelligence.com/documentation">
                          ⏺ for more Info checkout the Document section{" "}
                        </a>
                        <hr></hr>
                        <h4>
                          <center>
                            Please contact Tech in case of any query.
                          </center>
                        </h4>
                      </div>
                    </b>
                  ) : uploadSamplePDF ? (
                    <b>
                      Upload Static files Pdfs for samples
                      <br />
                      <div>
                        <p>⏺ Select your hub.</p>
                        <p>⏺ Select the section </p>
                        <p>⏺ upload the pdf </p>
                        <p>
                          ⏺ you can delete the existing image and preview the
                          file.
                        </p>

                        <p>⏺ Finally click on Save</p>
                        <hr></hr>
                        <h4>
                          <center>
                            Please contact Tech in case of any query.
                          </center>
                        </h4>
                      </div>
                    </b>
                  ) : (
                    <b>
                      Upload Static files like DROs, Value Chain, etc.
                      <br />
                      <div>
                        <p>⏺ Select your hub.</p>
                        <p>⏺ Select the section </p>
                        <p>⏺ All uploaded images will be shown.</p>
                        <p>
                          ⏺ you can delete the existing image, preview them or
                          change their order
                        </p>
                        <p>⏺ To change their order drage and drop the row.</p>
                        <p>
                          ⏺ To upload new image select image and click on
                          upload image.
                        </p>
                        <p>
                          ⏺ After clicking on upload SVGs, it will be shown
                          with the existing images.
                        </p>
                        <p>⏺ Finally click on Save</p>
                        <hr></hr>
                        <h4>
                          <center>
                            Please contact Tech in case of any query.
                          </center>
                        </h4>
                      </div>
                    </b>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </UploadStaticImageComponentWrapper>
    </>
  );
}

export default UploadStaticImageComponent;

function UploadSection({
  onGoingApiCall,
  dimensionsBasedUrlsFile,
  handleFileUpload,
}) {
  const preventRequest = () => false;
  return (
    <div
      style={{
        height: "300px",
      }}
    >
      <Dragger
        disabled={onGoingApiCall}
        accept={".xlsx"}
        maxCount={1}
        multiple={false}
        fileList={dimensionsBasedUrlsFile}
        onChange={handleFileUpload}
        beforeUpload={preventRequest}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">Only .XLXS file types</p>
      </Dragger>{" "}
    </div>
  );
}
