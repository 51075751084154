import React, { Component } from "react";
import Mordor_icon from "../mordorLogo.svg";
import config from "../../../config/Api";
import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";

import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";

import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";

import arrow from "../arrow.png";

import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";
import axios from "axios";
import font_html from "../../font_style.js";
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);
// FusionCharts.options.export.useCanvas = "true";
class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          label: "",
          value: "290",
        },
      ],
      slug: "",
      reseller: false,
      concentration: 0,
    };
    this.resetChart = this.resetChart.bind(this);
  }
  componentDidMount() {
    if (this.props.data && this.props.data.market) {
      this.setState({
        slug: this.props.data.market,
        reseller: this.props.data.reseller ? this.props.data.reseller : false,
        concentration: this.props.data.concentration,
      });
    }
    setTimeout(() => {
      this.resetChart();
    }, 1000);
  }
  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        slug: this.props.data.market,
        reseller: this.props.data.reseller ? this.props.data.reseller : false,
        concentration: this.props.data.concentration,
      });
      setTimeout(() => {
        this.resetChart();
      }, 1000);
    }
  }
  resetChart(event) {
    let a = window.btoa(JSON.stringify(this.props.data));
    let data = "market";
    var revenueChart = FusionCharts(`market_concentration`);
    var svgString = revenueChart.getSVGString();
    let svgdata = new DOMParser().parseFromString(svgString, "text/xml");
    var doc = new DOMParser().parseFromString(font_html, "text/html");
    // svgdata.prepend(doc.documentElement)
    let defs_data = svgdata.getElementsByTagName("defs")[0];
    defs_data.prepend(doc.documentElement);
    axios
      .post(
        `${config.api.base_url}/api/graphs/market_concentration?data=${a}`,
        { stream: window.btoa(svgdata.documentElement.outerHTML) }
      )
      .then((res) => {});
    // FusionCharts.batchExport({
    //   charts: [
    //     {
    //       id: "market_concentration",
    //     },
    //   ],
    //   exportFormat: "png",

    //   exportHandler: `${config.api.base_url}/api/graphs/market_concentration?data=${a}`,
    //   exportAction: "SAVE",
    //   exportMode: "client",
    // });
  }
  render() {
    let arrow_alignment =
      this.state.slug && this.state.slug.length > 55 ? 20 : 5;
    if (this.state && this.state.concentration) {
      if (this.state.concentration < 65 && this.state.concentration >= 40)
        arrow_alignment = 80;
      else if (this.state.concentration < 40)
        arrow_alignment =
          this.state.slug && this.state.slug.length > 55 ? 160 : 180;
    }
    let width = 550,
      height = 405;
    let footers = "Source:";
    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (this.props.footers) footers = this.props.footers;
    let chartConfigs = {
      type: "column2d",
      width: width - 100,
      height: height,
      dataFormat: "json",
      id: "market_concentration",
      dataSource: {
        chart: {
          caption: "",
          paletteColors: "#ff210d",
          divLineColor: "#ffffff",
          captionFontColor: "#6a6a6a",
          showValues: false,
          usePlotGradientColor: 1,
          plotFillRatio: [0, 70, 30],
          plotGradientColor: "#ffc135,#3a8900",
          chartTopMargin: 35,
          showPlotBorder: "0",
          maxBarHeight: 2,
          animation: false,
          labelFontSize: 34,
          showYAxisValues: false,
          captionFontSize: 34,
          valueFontBold: true,
          theme: "fusion",
          canvasRightPadding: 140,
          canvasLeftMargin: 50,
          chartRightMargin: 450,
          captionAlignment: "center",
          valueFontColor: "#6a6a6a",
          placeValuesInside: "0",
          // captionFont: "regular_medium",
          yAxisNamePadding: 0,
          // xAxisNameFont: "regular_medium",
          chartBottomMargin: 40,
          xAxisNameFontSize: 12,
          xAxisNameFontColor: "#6a6a6a",
          centerXaxisName: false,
          // valueFont: "regular_medium",
          valueFontSize: 35,
        },
        colorrange: {
          minvalue: "0",
          startlabel: "Low",
          endlabel: "High",
          code: "#FF4411",
          gradient: "1",
          color: [
            {
              maxvalue: "25",
              code: "#FFDD44",
              displayValue: "Median",
            },
            {
              maxvalue: "100",
              code: "#6baa01",
            },
          ],
        },
        annotations: {
          autoScale: "0",
          groups: [
            {
              id: "caption",
              items: [
                {
                  id: "dyn-label-bg",
                  showBorder: "0",
                  borderColor: "12345d",

                  fillcolor: "#eeeeee",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "center",
                  fillcolor: "#6a6a6a",
                  fontsize: "20",
                  font: "regular_bold_rd",
                  text: "Market Concentration",
                  bold: "1",
                  wrap: "1",
                  wrapWidth: "1200",
                  x: "140",
                  y: "20",
                },
              ],
            },
            {
              id: "consolidated",
              items: [
                {
                  id: "dyn-label-bg",
                  showBorder: "0",
                  borderColor: "12345d",
                  font: "regular_medium_rd",
                  fillcolor: "#eeeeee",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "center",
                  fillcolor: "#ff210d",
                  fontsize: "18",
                  font: "regular_medium_rd",
                  text: "Consolidated-",
                  bold: "1",
                  wrap: "1",
                  wrapWidth: "1200",
                  x: "150",
                  y: "60",
                },
              ],
            },
            {
              id: "marketdominated",
              items: [
                {
                  id: "dyn-label-bg",
                  showBorder: "0",
                  borderColor: "12345d",

                  fillcolor: "#eeeeee",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "center",
                  fillcolor: "#6a6a6a",
                  font: "regular_medium_rd",
                  fontsize: "18",
                  text: "Market domination",
                  bold: "0",
                  wrap: "1",
                  wrapWidth: "1200",
                  x: "286",
                  y: "60",
                },
              ],
            },

            {
              id: "marketdominatedsub",
              items: [
                {
                  id: "dyn-label-bg",
                  showBorder: "0",
                  borderColor: "12345d",
                  fillcolor: "#eeeeee",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "center",
                  fillcolor: "#6a6a6a",
                  font: "regular_medium_rd",
                  fontsize: "18",
                  text: "by major players",
                  bold: "0",
                  wrap: "1",
                  wrapWidth: "1200",
                  x: "276",
                  y: "85",
                },
              ],
            },

            {
              id: "fragmented",
              items: [
                {
                  id: "dyn-label-bg",
                  showBorder: "0",
                  borderColor: "12345d",
                  fillcolor: "#eeeeee",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "center",
                  fillcolor: "#3a8900",
                  font: "regular_medium_rd",
                  fontsize: "18",
                  text: "Fragmented-",
                  bold: "0",
                  wrap: "1",
                  wrapWidth: "1200",
                  x: "145",
                  y: "326",
                },
              ],
            },
            {
              id: "fragmenteddominated",
              items: [
                {
                  id: "dyn-label-bg",
                  showBorder: "0",
                  borderColor: "12345d",
                  fillcolor: "#eeeeee",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "center",
                  fillcolor: "#6a6a6a",
                  fontsize: "18",
                  font: "regular_medium_rd",
                  text: "Highly competitive market",
                  bold: "0",
                  wrap: "1",
                  wrapWidth: "1200",
                  x: "305",
                  y: "326",
                },
              ],
            },

            {
              id: "fragmenteddominatedsub",
              items: [
                {
                  id: "dyn-label-bg",
                  showBorder: "0",
                  borderColor: "12345d",

                  fillcolor: "#eeeeee",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "center",
                  fillcolor: "#6a6a6a",
                  fontsize: "18",
                  font: "regular_medium_rd",
                  text: "without dominant players",
                  bold: "0",
                  wrap: "1",
                  wrapWidth: "1200",
                  x: "300",
                  y: "356",
                },
              ],
            },
            {
              id: "arrow-icon",
              xScale_: "5",
              yScale_: "5",
              items: [
                {
                  id: "Brian-user-icon",
                  type: "image",
                  url: arrow,
                  x: "90",
                  y: 100 + arrow_alignment,
                  xScale: "40",
                  yScale: "30",
                },
              ],
            },
            {
              id: "slug",
              items: [
                {
                  id: "dyn-label-bg",
                  showBorder: "0",
                  borderColor: "12345d",

                  fillcolor: "#eeeeee",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#6a6a6a",
                  fontsize: "18",
                  font: "regular_medium_rd",
                  text: this.state.slug,
                  bold: "0",
                  wrap: "1",
                  wrapWidth: "260",
                  x: "190",
                  y: 112 + arrow_alignment,
                },
              ],
            },
          ],
        },
        data: this.state.data,
      },
    };
    if (this.state.reseller === false) {
      chartConfigs.dataSource.annotations.groups.push({
        id: "user-image",
        items: [
          {
            id: "dyn-label-bg",
            type: "rectangle",
            showBorder: "0",
            //"borderColor": "12345d",

            fillcolor: "#ffffff",
            x: "$canvasEndY-245",
            y: "$canvasEndY+45",
            tox: "$canvasEndX+10",
            toy: "$canvasEndY + 80",
          },
          {
            id: "dyn-label",
            type: "text",
            align: "left",
            fillcolor: "#6a6a6a",
            fontsize: "15",
            font: "regular_medium_rd",
            text: footers,
            bold: "1",
            wrap: "1",
            wrapWidth: width - 20,
            x: "50",
            y: 385,
          },
        ],
      });
      chartConfigs.dataSource.annotations.groups.push({
        id: "sub-footer",
        items: [
          {
            id: "dyn-label-bg",
            showBorder: "1",
            fillcolor: "#ffffff",
            x: "$canvasEndY-245",
            y: "$canvasEndY+45",
            tox: "$canvasEndX+10",
            toy: "$canvasEndY + 80",
          },
          {
            id: "dyn-label",
            type: "text",
            align: "left",
            fillcolor: "#6a6a6a",
            fontsize: "15",
            // font: "regular_regular",
            text: "Mordor Intelligence",
            wrap: "1",
            wrapWidth: "100%",
            x: 102,
            y: 385,
          },
        ],
      });
    }
    return (
      <div className="bar" id="bar">
        <ReactFC {...chartConfigs} />
      </div>
    );
  }
}

export default Chart;
