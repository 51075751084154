import styled from "styled-components";

export const FertilizerVCWrapper = styled.div`
  .section_data1 {
    width: 10%;
    max-width: 150px;
    position: absolute;
    word-break: break-word;

    .font_design {
      font-size: 10px;
    }
  }

  .section_data2 {
    width: 18%;
    max-width: 250px;
    position: absolute;
    word-break: break-word;

    .font_design {
      font-size: 10px;
    }
  }

  .section1_list {
    top: 55%;
    left: 10%;
  }

  .section2_list {
    top: 58%;
    left: 34%;
  }

  .section3_list1 {
    top: 42%;
    left: 60%;
  }

  .section3_list2 {
    top: 42%;
    left: 71%;
  }

  .section3_list3 {
    top: 42%;
    left: 81%;
  }
`;
