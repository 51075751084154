import styled from "styled-components";

export const DairyAndDairyAlternativeVCWrapper = styled.div`
  .section_data1 {
    top: 75%;
    width: 16%;
    max-width: 230px;
    position: absolute;
    word-break: break-word;
  }
  .section_data11 {
    top: 75%;
    width: 20%;
    max-width: 260px;
    position: absolute;
    word-break: break-word;
  }
  .section_data2 {
    top: 75%;
    width: 20%;
    max-width: 250px;
    position: absolute;
    word-break: break-word;
  }

  .section1_list {
    left: 4%;
  }

  .section2_list {
    left: 31%;
  }

  .section3_list {
    left: 53%;
  }

  .section4_list {
    left: 80%;
  }
`;
