import styled from "styled-components";

export const EBikesVCWrapper = styled.div`
  .section_data1 {
    top: 72%;
    width: 13%;
    max-width: 150px;
    position: absolute;
    word-break: break-word;
  }

  .section_data2 {
    top: 72%;
    width: 20%;
    max-width: 200px;
    position: absolute;
    word-break: break-word;
  }

  .section1_list1 {
    left: 5%;
  }

  .section1_list2 {
    left: 19%;
  }

  .section2_splitted_data1 {
    left: 35%;
  }

  .section2_splitted_data2 {
    left: 52%;
  }
  .section3_list {
    left: 77%;
  }
`;
