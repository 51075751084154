import React, { Component } from "react";
import { MobileOutlined, MailFilled, PrinterFilled } from "@ant-design/icons";
import companyLogo from "../images/mordorLogo.png";
import location_logo from "../images/locationlogo.svg";
import leaf from "../images/leaf.svg";
import { ForMoreInformationPleaseContactWrapper } from "../styles/ForMoreInformationPleaseContact.style";
var Base64 = require("js-base64").Base64;

export const ForMoreInformationPleaseContactDemoData = {
  title: "Key Strategic Themes For Feed Additive CEOS",
  slideName: "ForMoreInformationPleaseContact",
  id: "1. ForMoreInformationPleaseContact",
};

class ForMoreInformationPleaseContact extends Component {
  state = {
    data: this.props.data || ForMoreInformationPleaseContactDemoData,
  };
  render() {
    let { id } = this.state.data;
    const { slideNo } = this.props;
    const pageId = id ? Base64.encode(id) : undefined;
    let slideNoElementId;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }

    return (
      <div className="page">
        <ForMoreInformationPleaseContactWrapper>
          <div className="ForMoreInformationPleaseContact">
            <h1 className="ForMoreInformationPleaseContactTitle">
              FOR MORE INFORMATION PLEASE CONTACT
            </h1>
            <div className="leaf-images">
              <img className="leaf1" src={leaf} alt="" />
              <img className="leaf2" src={leaf} alt="" />
            </div>
            <div className="ForMoreInformationPleaseContactContact">
              <div className="contact1 contact">
                <img alt="" src={location_logo} />
                11th Floor, Rajapushpa Summit Nanakramguda Rd, Financial
                District, Gachibowli, Hyderabad - 500008, India
              </div>
              <div className="contact contact1">
                <MobileOutlined />
                +1 617 765 2493
              </div>
              <div className="contact contact2">
                <MailFilled />
                info@mordorintelligence.com
              </div>
              <div className="contact contact3">
                <PrinterFilled />
                www.mordorintelligence.com{" "}
              </div>
            </div>
          </div>

          <div
            className="formoreinformationFooter"
            id="formoreinformationFooter"
          >
            <img src={companyLogo} alt="logo" style={{ width: "180px" }} />
            <div style={{ width: "25%" }} className="footerurl">
              <span>Market Intelligence/Advisory</span>
            </div>
          </div>
        </ForMoreInformationPleaseContactWrapper>
      </div>
    );
  }
}

export default ForMoreInformationPleaseContact;
