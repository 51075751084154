import styled from "styled-components";

export const CompanyProfileOverviewUpdatedWrapper = styled.div`
  padding-bottom: ${(props) => (props.interface ? "20px" : 0)};

  .stats-card {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #d7f6eb;
    text-align: center;
  }

  .stats_content_card {
    font-family: "Avenir Roman";
    font-size: 14px;
    color: #002f75;
    font-style: normal;
    font-weight: normal;
    width: 180px;
    text-align: center;
    color: rgb(0, 47, 117);
  }

  .info_content_card {
    font-family: "Avenir Roman";
    font-size: 14px;
    color: #ffffff;
    font-style: normal;
    font-weight: normal;
    text-align: center;
  }

  .company_bio {
    width: 100%;
    height: 100%;
    background: #e6e3fc;
    padding: 20px;

    .company_content {
      margin-top: 18px;
      font-family: "Avenir Roman";
      font-size: 13px;
      line-height: 18px;
      color: #002f75;
      font-style: normal;
      font-weight: normal;
    }
  }

  .left_container img {
    margin-bottom: 20px;
    width: 100%;
    height: 130px;
    object-fit: contain;
  }

  .profile_container {
    margin-top: 15px;
    width: 100%;
    height: 100%;
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 20px;
  }

  .middle_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    width: 100%;
    .inner-stats-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      height: 520px;
      gap: 10px;
    }
  }

  .right_container {
    max-width: 100%;
    height: 100%;
    background: #eef1f2;
    padding: 20px;
  }

  p1 {
    font-family: "Avenir Roman";
    font-size: 14px;
    color: #002f75;
    font-weight: 300;
  }

  p2 {
    font-family: "Avenir Roman";
    font-weight: 800;
    font-style: normal;
    font-size: 28px;
    color: #002f75;
    text-align: center;
  }

  .right_container h4 {
    font-family: "Avenir Roman" !important;
    font-size: 14px !important;
    color: #002f75 !important;
    font-weight: 800 !important;
    margin: 8px 0px 0px 0px;
  }

  .left_container {
    max-width: 100% !important;
    img {
      width: 100%;
    }
  }

  @media only screen and (max-width: 480px) {
    .profile_container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .page {
      padding: 0px !important;
    }
    .container {
      padding: 20px !important;
      width: 100%;
    }

    .profile_container {
      height: auto;
    }
    .company_bio {
      margin-bottom: 10px;
    }
    .right_container {
      margin-top: 20px;
      height: auto;
      width: 100%;
    }

    .middle_container {
      width: 100%;
      height: 100%;
    }

    .left_container {
      max-width: 100% !important;
      width: 100%;
      img {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .profile_container {
      height: auto;
    }
    .company_bio {
      height: auto;
    }
    .right_container {
      height: auto !important;
    }
  }
`;
