import React, { Component } from "react";
import Mordor_icon from "../favicon.png";
import Config from "../../../config/Config";
import config from "../../../config/Api";
import {
  roundOfMaxYAxisValue,
  parseDataForMSColumn,
} from "../../../Graphs/graphs.helperFunctions";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";

import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";

import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";

import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";
import axios from "axios";
import font_html from "../../font_style.js";
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

const COLORS = Config.COLORS;

//Your react component
class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: "",
      button_click: false,
      loading: false,
      loadingData: true,
      data: [
        {
          seriesname: "Food Products",
          data: [
            {
              value: "121000",
            },
            {
              value: "135000",
            },
            {
              value: "123500",
            },
            {
              value: "145000",
            },
          ],
        },
        {
          seriesname: "Non-Food Products",
          data: [
            {
              value: "131400",
            },
            {
              value: "154800",
            },
            {
              value: "98300",
            },
            {
              value: "131800",
            },
          ],
        },
      ],
      catagory: [
        {
          label: "Q1 ljkshdlkjsbd slkkkkdnlkmsf klshd.kjsdbms",
        },
        {
          label: "Q2",
        },
        {
          label: "Q3",
        },
        {
          label: "Q4",
        },
      ],
    };
    this.resetChart = this.resetChart.bind(this);
  }
  // Called by FC-React component to return the rendered chart
  // componentDidMount() {
  //   if (this.props.data && this.props.data.data) {
  //     let data=this.props.data.data;
  //     let allKeys = Object.keys(data[0]).filter((val)=>val!=="label");
  //     let alllabels = [];
  //     for(let item of data){
  //       alllabels.push({
  //         "label":item.label
  //       });
  //     }
  //     let dataset=[];

  //       for(let item_key of allKeys){
  //         let dataname={
  //           "seriesname":item_key,
  //           "data":[]
  //         }
  //         dataset.push(dataname);
  //       }
  //       for(let item of data){
  //         for(let item_label of allKeys){
  //           for(let item_key of dataset){
  //             if(item_key.seriesname===item_label){
  //               let value={
  //                 "value":item[item_label]
  //               }
  //               item_key.data.push(value);
  //             }
  //           }
  //         }

  //       }

  //     this.setState({
  //       data: dataset,
  //       catagory:alllabels,
  //       datas:this.props.data
  //     })
  //   }

  // }
  componentDidMount() {
    // let datas = parseData(this.props.data.data);
    // let catagory = parseCatagory(this.props.data.data);
    let datas = this.props.data.data.data;
    // let arr = parseDataForMSColumn(this.props.data.data);
    // let maxValue = roundOfMaxYAxisValue(arr[0] * 1.7);
    let catagory = this.props.data.data.catagory;
    this.setState({
      datas: datas,
      loadingData: false,
      data: this.props.data,
      catagory: catagory,
    });
    setTimeout(() => {
      this.resetChart();
    }, 1000);
  }
  // componentDidUpdate(prevProps) {
  //   let datas = parseData(this.props.data.data);
  //   let catagory = parseCatagory(this.props.data.data);
  //   if (this.props.data !== prevProps.data) {
  //     this.setState({ datas: datas, loadingData: false, data: this.props.data, catagory: catagory });
  //     setTimeout(() => {
  //       this.resetChart();
  //     }, 1000);
  //   }

  // }
  resetChart(c) {
    let a = window.btoa(JSON.stringify(this.props.data));
    var revenueChart = FusionCharts(
      `${this.props.data.graph_type + this.props.data.component}`
    );
    // get chart xml data
    var svgString = revenueChart.getSVGString();
    let svgdata = new DOMParser().parseFromString(svgString, "text/xml");
    var doc = new DOMParser().parseFromString(font_html, "text/html");
    // svgdata.prepend(doc.documentElement)
    let defs_data = svgdata.getElementsByTagName("defs")[0];
    defs_data.prepend(doc.documentElement);
    axios
      .post(`${config.api.base_url}/api/graphs/data?data=${a}`, {
        stream: window.btoa(svgdata.documentElement.outerHTML),
      })
      .then((res) => {});
    // FusionCharts.batchExport({
    //   charts: [
    //     {
    //       id: this.props.data.graph_type + this.props.data.component
    //     }
    //   ],
    //   exportFormat: "png",

    //   exportHandler: `${config.api.base_url}/api/graphs/data?data=${a}`,
    //   exportAction: "SAVE",
    //   // exportTargetWindow: 'BLANK',

    //   exportMode: "client",
    // });
  }
  //Create buttons
  render() {
    let datas = "",
      data = "",
      catagory = "",
      maxVal = 0,
      rotateValues = 0,
      legendsPositionDynamic = false,
      max_legend_length = 0,
      valueFontSize = 12,
      id = "1",
      width = 1310,
      height = 655,
      headers = "",
      legendLength = 14;
    if (!this.state.loadingData) {
      data = this.state.datas;
      datas = this.state.data;
      catagory = this.state.catagory;
    }

    let footers = "Source:";
    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (datas && datas.heading) headers = `${datas.heading}{br}{br}`;
    if (datas && datas.heading) footers = datas.source;

    if (data.length > 5) legendLength = 11;
    if (valueFontSize === 11) legendLength = data.length > 5 ? 11 : 14;
    if (legendsPositionDynamic) legendLength = 11;
    if (this.state.max_legend_length < 35)
      max_legend_length = 35 - this.state.max_legend_length;

    let chartConfigs = {
      type: "mscolumn2d", // The chart type
      width: width, // Width of the chart
      height: height, // Height of the chart
      dataFormat: "json",
      // Data type
      id: this.props.data.graph_type + this.props.data.component,
      dataSource: {
        // Chart Configuration
        chart: {
          theme: "fusion",
          caption: headers,
          canvasTopPadding: 100,
          captionFont: "regular_bold_rd",
          captionFontColor: "#6a6a6a",
          captionFontBold: 1,
          captionFontSize: 34,
          // "captionFont":"Avenir Medium",
          animation: false,
          showanchors: 1,

          formatNumberScale: 1,
          numberScaleValue: "1000,1000,1000",
          numberScaleUnit: "K,M,B",

          adjustDiv: "0",
          numDivLines: "4",
          divLineColor: "#E5E5E5",
          divLineAlpha: "80",
          divLineDashed: "0",

          decimals: 1,
          paletteColors: COLORS,
          showPlotBorder: "0",
          plotSpacePercent: 40,
          minPlotHeightForValue: 10,

          labelFontSize: 28,
          labelFontColor: "#7f7f7f",

          legendItemFont: "regular_semibold_rd",
          legendItemFontColor: "#7f7f7f",
          legendItemFontSize: 28,
          legendPosition: "right",
          legendXPosition: this.props.legendsXPosition
            ? this.props.legendsXPosition
            : 370 + max_legend_length,
          legendYPosition: 50 + this.props.legendsYPosition,

          showValues: !this.props.dataHidden,
          valueFontBold: true,
          valueFont: "regular_semibold_rd",
          valueFontSize: 24,
          valueFontColor: "#6a6a6a",
          placeValuesInside: "0",

          rotateValues: 1,

          showsum: !this.props.dataHidden,
          sumPadding: "15",

          showYAxisLine: false,
          yAxisLineColor: "#4A90E2",
          yAxisLineThickness: 1,
          yAxisNamePadding: 0,
          showYAxisValues: false,
          yAxisValueFontSize: 28,
          yAxisValueFontColor: "#7f7f7f",
          yAxisValueDecimals: 1,
          yAxisMaxValue: maxVal * 1.44, //this.state.maxValue,

          showXAxisLine: false,
          xAxisLineThickness: 1,
          xAxisLineColor: "#4A90E2",
          // xAxisNameFont: "Avenir Medium",
          xAxisValueFontSize: 28,
          xAxisNameFontColor: "#444444",
          centerXAxisName: false,

          // chartTopMargin: 90,
          chartLeftMargin: 70,
          chartBottomMargin: 90,

          baseFont: "regular_semibold_rd",
          baseFontSize: 28,
          baseFontColor: "#7f7f7f",
        },
        annotations: {
          autoScale: "0",
          groups: [
            {
              id: "footer",
              items: [
                {
                  id: "dyn-label-bg",
                  // "type": "rectangle",
                  showBorder: "0",
                  //"borderColor": "12345d",

                  //"fillcolor": "#ffffff",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#6a6a6a",
                  fontsize: 28,
                  font: "regular_semibold_rd",
                  text: "Source:",
                  bold: "1",
                  wrap: "1",
                  wrapWidth: width - 20,
                  x: "70",
                  y: height - 32,
                },
              ],
            },
            {
              id: "sub-footer",
              items: [
                {
                  id: "dyn-label-bg",
                  //"type": "rectangle",
                  showBorder: "1",
                  // "borderColor": "12345d",

                  //"fillcolor": "#ffffff",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#6a6a6a",
                  fontsize: 28,
                  font: "regular_regular_rd",
                  text: footers,
                  //"bold": "0",
                  wrap: "1",
                  wrapWidth: "100%",
                  x: 170,
                  y: height - 32,
                },
              ],
            },
            {
              id: "mordor-icon",
              xScale_: "5",
              yScale_: "5",
              items: [
                {
                  id: "Brian-user-icon",
                  type: "image",
                  url: Mordor_icon,
                  x: "1180",
                  y: height - 72,
                  xScale: "200",
                  yScale: "200",
                },
              ],
            },
          ],
        },
        categories: [
          {
            category: catagory,
          },
        ],
        // Chart Data
        dataset: data,
      },
    };
    if (this.props.data?.reseller === true) {
      chartConfigs.dataSource.annotations.groups = [];
    }

    return (
      <div>
        <ReactFC {...chartConfigs} />
      </div>
    );
  }
}
function parseData(data) {
  // let data=this.props.data.data;
  const sortingArrayObjectByValue = (arr) => {
    return arr.sort((a, b) => {
      return b.value - a.value;
    });
  };

  let allKeysmod = Object.keys(data[0]).filter((val) => val !== "label");

  let allKeys = [];

  let finalkeys = [];

  for (let item of allKeysmod) {
    let obj = {
      label: item,
      value: Number(data[0][item]),
    };

    finalkeys.push(obj);
  }
  finalkeys = sortingArrayObjectByValue(finalkeys);

  allKeys = finalkeys.map((item) => item.label);

  let dataset = [];

  for (let item_key of allKeys) {
    let dataname = {
      seriesname: item_key,
      data: [],
    };
    dataset.push(dataname);
  }
  for (let item of data) {
    for (let item_label of allKeys) {
      for (let item_key of dataset) {
        if (item_key.seriesname === item_label) {
          let value = {
            value: item[item_label],
          };
          item_key.data.push(value);
        }
      }
    }
  }
  return dataset;
}
function parseCatagory(data) {
  let alllabels = [];
  for (let item of data) {
    alllabels.push({
      label: item.label,
    });
  }
  return alllabels;
}
export default Chart;
