import styled from "styled-components";

export const FeedProdctionContainer = styled.div`
  .main-container {
    display: flex;
    justify-content: space-between;
  }
  .main-container .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 20px 16px;
  }
  .card .img {
    margin-bottom: 10px;
    min-height: 70px;
  }
  .card p {
    margin-bottom: 0;
  }
  .card .card-number {
    font-family: "Avenir Roman";
    font-weight: 300;
    font-size: 36px;
    line-height: 18px;
    letter-spacing: 0.01em;
    margin-bottom: 21px;
  }
  .rowHeading {
    font-family: "Avenir Roman";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #002f75;
  }

  ${(props) =>
    props.data && props.data.graphs
      ? `.left-container{
      width:48%;
      text-align:justify
    }
    .right-container{
      width:48%;
      text-align:justify
    }`
      : `.left-container{
      width:100%;
      text-align:justify
    }
    `}

  .subheading.cardheading {
    margin: 0px 0px 0px 0px;
  }

  li.feedproductionList {
    color: #002f75;
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  }
  .cagr {
    font-family: "Avenir Roman";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #002f75;
    margin-bottom: 22px;
    display: flex;
    align-items: center;
  }
  .card-name {
    font-family: "Avenir Roman";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #002f75;
  }
`;
