import React from "react";
import InterfaceHeader from "../InterfaceHeader";
import { DeleteDataStyleWrapper } from "./DeleteData.style";
import config from "../../config/Api";
import { Button, Select, notification, Input, Modal, Switch } from "antd";
import axios from "axios";
import Dragger from "antd/lib/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";

const openNotification = (msg, type) => {
  let args,
    key = msg;
  if (key === true) {
    args = {
      message: "Success",
      description: "Data Deleted successful.",
      duration: 3.5,
      placement: "topRight",
    };
    notification.success(args);
  } else if (key === false) {
    args = {
      message: "Failure",
      description: "Failed to delete data.",
      duration: 3.5,
      placement: "topRight",
    };
    notification.error(args);
  } else if (type) {
    args = {
      message: type,
      description: key,
      duration: 3.5,
      placement: "topRight",
    };
    notification[type](args);
  } else {
    args = {
      message: "Warning",
      description: key,
      duration: 3.5,
      placement: "topRight",
    };
    notification.warning(args);
  }
};

const formatName = (name) => {
  let temp = name
    .split("_")
    .map((item) => item[0].toUpperCase() + item.slice(1));

  return temp.join(" ");
};

function DeleteDataComponent() {
  const [infoTabVis, setInfoTabVis] = React.useState(false);
  const [hubData, setHubData] = React.useState([]);
  const [dimensionsArr, setDimensionsArr] = React.useState([]);
  const [selectedHub, setSelectedHub] = React.useState();

  const [dimensionData, setDimensionData] = React.useState({});
  const [resultCount, setResultCount] = React.useState(0);

  const [onGoingApiCall, setOnGoingApiCall] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [openModal, setOpenModal] = React.useState({});
  const [jsonFileData, setJsonFileData] = React.useState(null);

  const [type, setType] = React.useState(null);
  const [indicator, setIndicator] = React.useState(null);

  const [indicatorOptions, setIndicatorOptions] = React.useState(null);
  const [indicatorList, setIndicatorList] = React.useState(null);

  let typeList = [
    {
      label: "Segmentation",
      value: "segmentation",
    },
    {
      label: "Key Industry Trends",
      value: "indicator",
    },
    {
      label: "Value Chain",
      value: "value_chain",
    },
  ];

  const getHubList = async () => {
    setLoading(true);
    if (hubData.length == 0) {
      let full_access = false;
      await axios
        .get(`${config.api.base_url}/api/hubs/list?full_access=${full_access}`)
        .then((response) => {
          if (response.status === 200) {
            setHubData(response.data.obj);
          }
          setLoading(false);
        })
        .catch((error) => {
          openNotification("Unable to load hub list");
          setLoading(false);
        });
    }
  };

  React.useEffect(() => {
    getHubList();
  }, []);

  const fetchDimensions = async (hub_id, dataType) => {
    try {
      setOnGoingApiCall({ type: "dimensionsList" });
      setLoading(true);

      const dimensionsList = await axios.get(
        `${config.api.base_url}/api/editing/hub-dimensions/${hub_id}${
          dataType ? `/?type=${dataType}` : ""
        }`
      );

      if (
        dataType == "indicator" &&
        Object.keys(dimensionsList?.data?.data || {}).length
      ) {
        setIndicatorOptions(dimensionsList.data.data);
        setOnGoingApiCall(null);
        setLoading(false);
        return;
      }

      if (dimensionsList?.data?.data?.length) {
        setDimensionsArr(dimensionsList.data.data);
        setOnGoingApiCall(null);
        setLoading(false);
      }
    } catch (error) {
      console.log("ERROR", error);
      openNotification(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong while fetching dimensions"
      );
      setOnGoingApiCall(null);
      setLoading(false);
    }
  };

  const handleHubChange = (value) => {
    if ([3, 4].includes(value)) {
      openNotification(
        "Bulk deletion or json upload is not allowed for this Hub.",
        "error"
      );
      return;
    }

    setSelectedHub(value);
    setDimensionData({});
    setDimensionsArr([]);
    setResultCount(0);
    fetchDimensions(value);
    setType("segmentation");
    setIndicator(null);
    setIndicatorOptions(null);
    setIndicatorList(null);
  };

  const handleChangeDimensionData = (key, value) => {
    let temp = { ...dimensionData };
    temp[key] = value;
    setDimensionData(temp);
    setResultCount(0);
  };

  const handleCountResult = async () => {
    try {
      if (type == "indicator" && !indicator) {
        openNotification("Required details are missing");
        return;
      }
      setOnGoingApiCall({ type: "count" });

      let dimensions = {};
      Object.entries(dimensionData).forEach((item) => {
        if (item[1].trim()) {
          dimensions[`dimensions.${item[0]}`] = item[1];
        }
      });

      if (!Object.keys(dimensions).length) {
        openNotification("Please add dimensions.");
        setOnGoingApiCall(null);
        return;
      }

      let payload = {
        ...dimensions,
      };

      if (type == "indicator") {
        payload["hubs"] = selectedHub;
        payload["name"] = indicator;
      } else {
        payload["hub_id"] = selectedHub;
      }

      const matchesCount = await axios.post(
        `${config.api.base_url}/api/editing/matches-count/?type=${type}`,
        payload
      );

      if (matchesCount?.data?.data > 0) {
        setResultCount(matchesCount.data.data);
      } else {
        openNotification("No Matching result found.");
      }

      setOnGoingApiCall(null);
    } catch (error) {
      console.log("ERROR", error);
      openNotification(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong while counting results."
      );
      setOnGoingApiCall(null);
    }
  };

  const handleDeletion = async () => {
    try {
      setOnGoingApiCall({ type: "delete" });

      let dimensions = {};

      Object.entries(dimensionData).forEach((item) => {
        if (item[1].trim()) {
          dimensions[`dimensions.${item[0]}`] = item[1];
        }
      });

      let payload = {
        ...dimensions,
      };

      if (type == "indicator") {
        payload["hubs"] = selectedHub;
        payload["name"] = indicator;
      } else {
        payload["hub_id"] = selectedHub;
      }

      const deleteData = await axios.post(
        `${config.api.base_url}/api/editing/delete-data/?type=${type}`,
        payload
      );

      if (!(deleteData?.data?.count > 0)) {
        openNotification(false);
        setOnGoingApiCall(null);
        setOpenModal({});
        return;
      }

      downloadJsonFile(deleteData?.data?.data);

      openNotification(true);
      setOnGoingApiCall(null);
      setSelectedHub();
      setDimensionData({});
      setDimensionsArr([]);
      setResultCount(0);
      setOpenModal({});
    } catch (error) {
      console.log("ERROR", error);
      openNotification(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong while deleting data."
      );
      setOnGoingApiCall(null);
      setOpenModal({});
    }
  };

  function downloadJsonFile(jsonData) {
    try {
      const blob = new Blob([JSON.stringify(jsonData)], {
        type: "application/json",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "data.json";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.log("ERROR in downloadJsonFile", error);
      throw error;
    }
  }

  const handleUploadData = async () => {
    try {
      setOnGoingApiCall({
        type: "upload",
      });

      if (!selectedHub || !jsonFileData?.length) {
        openNotification("Please select Hub and JSON File.", "error");
        setOnGoingApiCall(null);
        return;
      }

      if (jsonFileData.length > 10000) {
        openNotification(
          "Please contact TECH Team to upload this data.",
          "error"
        );
        setOnGoingApiCall(null);
        return;
      }

      for (let data of jsonFileData) {
        if (data[type == "indicator" ? "hubs" : "hub_id"] != selectedHub) {
          openNotification(
            "Hub Id doesn't match with JSON File data Hub Id.",
            "error"
          );
          setOnGoingApiCall(null);
          return;
        }

        if (type == "value_chain" && !data.metrics) {
          openNotification(
            "Metrics doesn't exists in the JSON File data. Please check the data.",
            "error"
          );
          setOnGoingApiCall(null);
          return;
        }

        if (
          type == "indicator" &&
          (!data.name || data.name != formatName(indicator))
        ) {
          openNotification(
            "Indicator name doesn't exists in the JSON File data or is different from the one selected.",
            "error"
          );
          setOnGoingApiCall(null);
          return;
        }

        for (let dimension of Object.keys(data.dimensions)) {
          if (
            !(type == "indicator" ? indicatorList : dimensionsArr).includes(
              dimension
            )
          ) {
            openNotification(
              "Extra/ Wrong dimension found in the JSON File data.",
              "error"
            );
            setOnGoingApiCall(null);
            return;
          }
        }
      }

      await axios.post(
        `${config.api.base_url}/api/editing/upload-json-data/?type=${type}`,
        {
          data: jsonFileData,
        }
      );

      setOnGoingApiCall(null);
      setSelectedHub();
      setDimensionsArr([]);
      openNotification("Data uploaded successfully", "success");
    } catch (error) {
      console.log("ERROR", error);
      openNotification(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong while deleting data."
      );
      setOnGoingApiCall(null);
    }
  };

  const preventRequest = () => false;

  const readJsonFileData = (e) => {
    const file = e.file;
    if (!file || file?.status == "removed") return;

    const reader = new FileReader();
    reader.onload = function (e) {
      const contents = e.target.result;
      const data = JSON.parse(contents);
      setJsonFileData(data);
    };
    reader.readAsText(file);
  };

  const handleChangeType = (type) => {
    setType(type);

    if (type == "indicator") {
      fetchDimensions(selectedHub, type);
    }

    setResultCount(0);
    setDimensionData({});
  };

  return (
    <>
      <InterfaceHeader />
      <DeleteDataStyleWrapper>
        <div className="wrapper">
          <div className="card">
            <div
              className="leftContainer"
              style={{
                width: infoTabVis ? "70%" : "100%",
              }}
            >
              <h2
                style={{
                  textAlign: "center",
                  flexGrow: 1,
                  margin: "20px 0 30px",
                }}
              >
                Delete Data in Bulk
              </h2>{" "}
              <div className="contentWrapper">
                <div className="sectionContainer">
                  {/* LEFT SECTION */}
                  <div>
                    <div className="dropdownContainer">
                      <label htmlFor="selectHub">Select Hub :</label>
                      <Select
                        disabled={onGoingApiCall}
                        style={{
                          display: "block",
                          width: "200px",
                        }}
                        id="selectHub"
                        placeholder="select"
                        value={selectedHub}
                        loading={loading}
                        maxLength=""
                        onChange={handleHubChange}
                        options={hubData.map((item, index) => ({
                          label: item.name,
                          value: item.id,
                        }))}
                        autoFocus={true}
                      />
                      <Button
                        type="primary"
                        style={{
                          display: "block",
                          margin: "10px 10px 10px auto",
                        }}
                        onClick={() => setOpenModal({ type: "upload" })}
                      >
                        Upload Data
                      </Button>
                    </div>

                    <div className="dimensions-wrapper">
                      {dimensionsArr.length ? (
                        <>
                          <div className="tab-container">
                            <div className="tab-options">
                              {typeList.map((item) => (
                                <Button
                                  type={
                                    item.value == type ? "primary" : "default"
                                  }
                                  onClick={() => handleChangeType(item.value)}
                                  key={item.value}
                                >
                                  {item.label}
                                </Button>
                              ))}
                            </div>

                            <h3 className="dimensions-heading">Dimensions</h3>
                            <div className="dimensions-container">
                              {type == "indicator" ? (
                                <div className="inputFieldContainer">
                                  <label htmlFor="indicator-input">
                                    Indicator Name
                                  </label>
                                  <Select
                                    disabled={onGoingApiCall}
                                    style={{
                                      display: "block",
                                      width: "200px",
                                    }}
                                    id="indicator-input"
                                    placeholder="select"
                                    value={indicator}
                                    loading={loading}
                                    maxLength="1"
                                    onChange={(value) => {
                                      setDimensionData({});
                                      setIndicator(value);
                                      setIndicatorList(
                                        indicatorOptions[value] || []
                                      );
                                    }}
                                    options={Object.keys(
                                      indicatorOptions || {}
                                    )?.map((item) => ({
                                      label: formatName(item),
                                      value: item,
                                    }))}
                                    autoFocus={true}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              {type == "indicator" && indicatorList?.length ? (
                                <RenderDimensions
                                  dimensionsArr={indicatorList}
                                  handleChange={handleChangeDimensionData}
                                  dimensionData={dimensionData}
                                />
                              ) : type != "indicator" &&
                                !indicatorList?.length ? (
                                <RenderDimensions
                                  dimensionsArr={dimensionsArr}
                                  handleChange={handleChangeDimensionData}
                                  dimensionData={dimensionData}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div>
                          <p
                            style={{
                              fontSize: "20px",
                              textAlign: "center",
                            }}
                          >
                            {onGoingApiCall?.type == "dimensionsList"
                              ? "Fetching list..."
                              : "Select Hub to get dimension list."}
                          </p>
                          <p
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <b>Note:-</b> Please make sure the dimensions value
                            spellings are correct, with case sensitiveness.
                            Please cross check with the ME sheet.
                          </p>
                        </div>
                      )}
                    </div>

                    <Button
                      hidden={!dimensionsArr?.length}
                      type="primary"
                      loading={onGoingApiCall?.type == "count"}
                      disabled={
                        onGoingApiCall || !Object.keys(dimensionData)?.length
                      }
                      style={{
                        display: "block",
                        margin: "auto",
                      }}
                      onClick={handleCountResult}
                    >
                      {onGoingApiCall?.type == "count"
                        ? "Counting"
                        : "Count Result"}
                    </Button>
                    {resultCount > 0 ? (
                      <p
                        style={{
                          color: "red",
                          textAlign: "center",
                          padding: "5px 0 10px",
                          userSelect: "none",
                        }}
                      >
                        Total {resultCount} matching results found.
                      </p>
                    ) : (
                      ""
                    )}

                    <Button
                      hidden={!resultCount > 0}
                      disabled={onGoingApiCall || !resultCount > 0}
                      danger
                      type="primary"
                      loading={onGoingApiCall?.type == "delete"}
                      style={{
                        display: "block",
                        margin: "10px 10px 0 auto",
                      }}
                      onClick={() => setOpenModal({ type: "delete" })}
                    >
                      {onGoingApiCall?.type == "delete" ? "Deleting" : "Delete"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className={infoTabVis ? "infoContainer" : "info"}>
              <Button
                type="default"
                danger={infoTabVis}
                onClick={() => setInfoTabVis(!infoTabVis)}
              >
                {infoTabVis ? "Close Guide" : "Open Guide"}
              </Button>
              {infoTabVis && (
                <div className="infoContent">
                  <b>
                    <h4>Delete Segmentation Data in Bulk.</h4>
                    <br />
                    <div>
                      <p>⏺ Select your hub.</p>
                      <p>
                        ⏺ Enter the dimensions of the report which you would
                        like to delete.
                      </p>
                      <p>
                        ⏺ Click on Count Results and verify the count with the
                        number of reports data you want to delete.
                      </p>
                      <p>
                        ⏺ Click Delete and then confirm to delete the data.
                      </p>
                      <p>
                        ⏺ A copy of deleted data will be downloaded on your
                        device.
                      </p>
                      <p>
                        ⏺ Deleted data can be uploaded back using Upload Data
                        button.
                      </p>
                      <br />
                      <h4>Steps to Reupload Deleted Segmentation Data.</h4>
                      <br />
                      <div>
                        <p>
                          ⏺ To upload the deleted data you can either upload
                          the ME data sheet from the Upload Sheet section or you
                          can Select Hub for which you want to upload the data.
                        </p>
                        <p>
                          ⏺ Select the JSON file and then click on Upload
                          button.
                        </p>
                      </div>
                      <hr></hr>
                      <h4>
                        <center>
                          Please contact Tech in case of any query.
                        </center>
                      </h4>
                    </div>
                  </b>
                </div>
              )}
            </div>
          </div>
        </div>
      </DeleteDataStyleWrapper>
      <Modal
        open={openModal?.type == "upload" ? true : false}
        onCancel={() => setOpenModal({})}
        footer={[
          <Button
            type="primary"
            style={{
              backgroundColor: "gray",
              borderColor: "gray",
            }}
            onClick={() => setOpenModal({})}
            disabled={onGoingApiCall}
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            onClick={handleUploadData}
            disabled={onGoingApiCall}
          >
            Upload
          </Button>,
        ]}
      >
        <div>
          <h3>Upload JSON file here</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px 0",
            }}
          >
            <div>
              <label
                style={{
                  fontWeight: 600,
                }}
                htmlFor="selectHub"
              >
                Select Hub :
              </label>
              <Select
                disabled={onGoingApiCall}
                style={{
                  display: "block",
                  width: "200px",
                }}
                id="selectHub"
                placeholder="select"
                value={selectedHub}
                loading={loading}
                onChange={handleHubChange}
                options={hubData.map((item, index) => ({
                  label: item.name,
                  value: item.id,
                }))}
                autoFocus={true}
              />
            </div>
            <div>
              <label
                htmlFor="data-type"
                style={{
                  fontWeight: 600,
                }}
              >
                Type
              </label>
              <Select
                disabled={onGoingApiCall}
                style={{
                  display: "block",
                  width: "200px",
                }}
                id="data-type"
                placeholder="select"
                value={type}
                loading={loading}
                maxLength="1"
                onChange={(value) => {
                  handleChangeType(value);
                }}
                options={typeList.map(({ label, value }) => ({
                  label,
                  value,
                }))}
                autoFocus={true}
              />
            </div>
          </div>
          <div
            style={{
              display: type == "indicator" ? "flex" : "none",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px 0",
            }}
          >
            <label
              htmlFor="indicator-input"
              style={{
                fontWeight: 600,
              }}
            >
              Indicator Name
            </label>
            <Select
              disabled={onGoingApiCall}
              style={{
                display: "block",
                width: "200px",
              }}
              id="indicator-input"
              placeholder="select"
              value={indicator}
              loading={loading}
              maxLength="1"
              onChange={(value) => {
                setDimensionData({});
                setIndicator(value);
                setIndicatorList(indicatorOptions[value] || []);
              }}
              options={Object.keys(indicatorOptions || {})?.map((item) => ({
                label: formatName(item),
                value: item,
              }))}
              autoFocus={true}
            />
          </div>
          <Dragger
            disabled={onGoingApiCall}
            accept=".json"
            maxCount={1}
            multiple={true}
            onChange={readJsonFileData}
            beforeUpload={preventRequest}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">Only .JSON file type</p>
          </Dragger>{" "}
        </div>
      </Modal>
      <Modal
        open={openModal?.type == "delete" ? true : false}
        onCancel={() => setOpenModal({})}
        footer={[
          <Button
            type="primary"
            style={{
              backgroundColor: "gray",
              borderColor: "gray",
              // marginRight: "10px",
            }}
            onClick={() => setOpenModal({})}
          >
            Cancel
          </Button>,
          <Button type="primary" danger onClick={handleDeletion}>
            Delete
          </Button>,
        ]}
      >
        <p>
          Are you sure you want to delete <b>{resultCount}</b> matching data?
        </p>
      </Modal>
    </>
  );
}

export default DeleteDataComponent;

const RenderDimensions = ({ dimensionsArr, handleChange, dimensionData }) => {
  return dimensionsArr?.map((item) => (
    <div key={item} className="inputFieldContainer">
      <label htmlFor={`${item}-input`}>{formatName(item)} : </label>
      <Input
        style={{
          display: "block",
          width: "200px",
        }}
        type="text"
        id={`${item}-input`}
        value={dimensionData[item] || ""}
        onChange={(e) => handleChange(item, e.target.value)}
      />
    </div>
  ));
};
