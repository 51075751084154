import styled from "styled-components";

export const DisclaimerWrapper = styled.div`
  .disclaimer {
    height: 663px;
    padding: 25px 50px;
    line-height: 3rem;
  }

  .disclaimer p {
    font-size: 16px;
    text-align: justify;
    padding: 4px 0px;
  }
`;
