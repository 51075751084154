import React, { Component } from "react";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";

import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";

import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";

import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";
import config from "../../../config/Api";
import axios from "axios";
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

class MyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          label: "",
          value: "",
        },
      ],
      logos: [
        // "https://s3.mordorintelligence.com/Collins_Aerospace_Logo/1679290537167_Collins_Aerospace_Logo_Collins_Aerospace_Logo.png",
        // "https://s3.mordorlabs.com/Diehl_Aerosystems_Logo%202/1679295316413_test~Diehl_Aerosystems_Logo_2_Diehl_Aerosystems_Logo_2.png",
        // "https://s3.mordorintelligence.com/Recaro_Logo%201/1679294506953_Recaro_Logo_1_Recaro_Logo_1.png",
        // "https://s3.mordorintelligence.com/FACC_AG_logo%201/1679294544334_FACC_AG_logo_1_FACC_AG_logo_1.png",
        // "https://s3.mordorintelligence.com/General-Dynamics-Logo%201/1679294544334_General-Dynamics-Logo_1_General-Dynamics-Logo_1.png"
      ],
    };
    this.resetChart = this.resetChart.bind(this);
  }
  componentDidMount() {
    if (this.props?.data?.cp_logos) {
      this.setState({
        logos: this.props.data.cp_logos,
      });
    }
  }

  resetChart(event) {
    let a = window.btoa(JSON.stringify(this.props.data));
    var revenueChart = FusionCharts("cp_logos");
    var svgString = revenueChart.getSVGString();
    let svgdata = new DOMParser().parseFromString(svgString, "text/xml");
    axios
      .post(`${config.api.base_url}/api/graphs/merge_CP_Logos?data=${a}`, {
        stream: window.btoa(svgdata.documentElement.outerHTML),
      })
      .then((res) => {});
  }
  render() {
    const chartConfigs = {
      id: "cp_logos",
      type: "column2d", // The chart type
      width: "1280", // Width of the chart
      height: "720", // Height of the chart
      dataFormat: "json", // Data type
      dataSource: {
        // Chart Configuration
        chart: {
          caption: "", //Set the chart caption
          subCaption: "", //Set the chart subcaption
          // //Set the y-axis name
          // numberSuffix: "K",
          theme: "fusion",
          // exportEnabled: "1",
          showYAxisValues: false,
          bgImage:
            "https://s3.mordorintelligence.com/CP_background/1686133643182_CP_background_CP_background.png",
          // exportMode: "client"                 //Set the theme for your chart
        },
        annotations: {
          autoScale: "0",
          groups: [
            {
              id: "logo1",
              items: [
                {
                  id: "dyn-label1",
                  type: "image",
                  align: "center",
                  fillcolor: "#6a6a6a",
                  fontsize: "20",
                  // font: "Regular_Semibold",
                  url: this.state.logos[0],
                  xScale: 90,
                  yScale: 90,
                  // bold: "1",
                  wrap: "1",
                  // wrapWidth: "1200",
                  x: "300",
                  y: "20",
                },
                {
                  id: "dyn-label2",
                  type: "image",
                  align: "center",
                  // fillcolor: "#6a6a6a",
                  // fontsize: "20",
                  // font: "Regular_Semibold",
                  url: this.state.logos[1],
                  xScale: 90,
                  yScale: 90,
                  // bold: "1",
                  // wrap: "1",
                  x: 970,
                  y: 20,
                },
                {
                  id: "dyn-label3",
                  type: "image",
                  align: "center",
                  fillcolor: "#6a6a6a",
                  fontsize: "20",
                  // font: "Regular_Semibold",
                  url: this.state.logos[2],
                  xScale: 55,
                  yScale: 60,
                  // bold: "1",
                  wrap: "1",
                  x: 190,
                  y: 480,
                },
                {
                  id: "dyn-label4",
                  type: "image",
                  align: "center",
                  fillcolor: "#6a6a6a",
                  fontsize: "20",
                  // font: "Regular_Semibold",
                  url: this.state.logos[3],
                  xScale: 75,
                  yScale: 90,
                  // bold: "1",
                  wrap: "1",
                  x: 640,
                  y: 400,
                },
                {
                  id: "dyn-label5",
                  type: "image",
                  align: "center",
                  fillcolor: "#6a6a6a",
                  fontsize: "20",
                  // font: "Regular_Semibold",
                  url: this.state.logos[4],
                  xScale: 55,
                  yScale: 60,
                  // bold: "1",
                  wrap: "1",
                  x: 1100,
                  y: 480,
                },
              ],
            },
          ],
        },
        // Chart Data - from step 2
        data: this.state.data,
      },
    };
    return (
      <div>
        <ReactFC {...chartConfigs} />
      </div>
    );
  }
}

export default MyComponent;
