import styled from "styled-components";

export const RoadFreightVCWrapper = styled.div`
  .section_data1 {
    width: 13%;
    max-width: 170px;
    position: absolute;
    word-break: break-word;

    .font_design {
      font-size: 13px;
    }
  }

  .section_data2 {
    top: 68%;
    width: 18%;
    max-width: 250px;
    position: absolute;
    word-break: break-word;

    .font_design {
      font-size: 13px;
    }
  }

  .section1_list1 {
    top: 68%;
    left: 5%;
  }

  .section1_list2 {
    top: 68%;
    left: 20%;
  }

  .section2_list {
    left: 43%;
  }

  .section3_list1 {
    top: 58%;
    left: 68%;
  }

  .section3_list2 {
    top: 58%;
    left: 81%;

    .font_design {
      color: #e6894a;
    }
  }

  .section3_list3 {
    top: 75%;
    left: 68%;

    .font_design {
      color: #000000;
    }
  }

  .section3_list4 {
    top: 75%;
    left: 81%;

    .font_design {
      color: #0cb258;
    }
  }
`;
