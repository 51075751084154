import React, { Component } from "react";
import Footer from "../components/Footer";
import { PrimaryInsightsWrapper } from "../styles/PrimaryInsights.style";
var Base64 = require("js-base64").Base64;

export class PrimaryInsights extends Component {
  state = {
    data: this.props.data.data,
  };

  render() {
    let { slug } = this.state.data;
    const { slideNo } = this.props;
    const { section_id } = this.props.data;
    const pageId = section_id ? Base64.encode(section_id) : undefined;
    let slideNoElementId = undefined;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }

    return (
      <div>
        <PrimaryInsightsWrapper>
          <div className="primary_insights_container" id={pageId}>
            {this.state.data.map((item, idx) => (
              <img src={item.url} alt="Primary Insights" key={idx} />
            ))}
          </div>

          {!this.props.fromInterface ||
          this.props.fromInterface === undefined ? (
            <Footer
              slug={slug}
              slideNo={slideNo}
              reseller={this.state.reseller}
            />
          ) : null}
        </PrimaryInsightsWrapper>
      </div>
    );
  }
}

export default PrimaryInsights;
