import React, { Component } from "react";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";
import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";
import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";
import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";

import { COLORS } from "../graphs.configs";
import {
  breakLabels,
  data_length,
  placeValueOutside,
  removeLegends,
} from "../graphs.helperFunctions";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);
// FusionCharts.options.export.useCanvas = "true";

export default class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          label: "Venezuela",
          value: "290",
        },
        {
          label: "Saudi",
          value: "260",
        },
        {
          label: "Canada",
          value: "180",
        },
        {
          label: "Iran",
          value: "140",
        },
        {
          label: "Russia",
          value: "115",
        },
        {
          label: "UAE",
          value: "100",
        },
        {
          label: "US",
          value: "30",
        },
        {
          label: "China",
          value: "30",
        },
      ],
    };
    this.bytesToString = this.bytesToString.bind(this);
  }

  componentDidMount() {
    if (this.props.data) {
      let datas = [];
      for (let item of this.props.data) {
        // if (this.props.width) item.label = this.bytesToString(item.label, 2);
        // else item.label = this.bytesToString(item.label, 1);

        if (this.props.width < 600 && item.label.length >= 24) {
          item.label = breakLabels(item.label, 24);
        }

        if (item.value < 0) {
          item.value = 0 - item.value;
        }

        datas.push(item);
      }
      let legends_zero_elimination =
        this.props.legends_zero_elimination === false ? false : true;

      if (legends_zero_elimination === true) datas = removeLegends(datas);
      placeValueOutside(datas);
      datas = datas.reverse();
      this.setState({
        data: datas,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps &&
      JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)
    ) {
      let datas = [];
      for (let item of this.props.data) {
        // if (this.props.width) item.label = this.bytesToString(item.label, 2);
        // else item.label = this.bytesToString(item.label, 1);
        if (this.props.width < 600 && item.label.length >= 24) {
          item.label = breakLabels(item.label, 24);
        }

        if (item.value < 0) {
          item.value = 0 - item.value;
        }
        datas.push(item);
      }

      let legends_zero_elimination =
        this.props.legends_zero_elimination === false ? false : true;

      if (legends_zero_elimination === true) datas = removeLegends(datas);
      placeValueOutside(datas);
      datas = datas.reverse();
      this.setState({
        data: datas,
      });
    }
  }

  bytesToString(d, width) {
    let number_of_letter = width / 7 + 10;
    if (width === 1) number_of_letter = (400 - 150) / 9;
    if (width === 2) number_of_letter = this.props.width / 16;

    let str = "";
    let computed_array = [];
    let string_arr = d.trim().split(" ");
    for (let item of string_arr) {
      if ((item + str).length >= number_of_letter) {
        computed_array.push(str);
        str = "";
      }
      str += " " + item;
    }
    if (str !== "") computed_array.push(str);

    let caption = "";
    for (let i = 0; i < computed_array.length; i++) {
      if (i !== computed_array.length - 1) {
        caption += computed_array[i] + "<br/>";
      } else caption += computed_array[i];
    }
    return caption;
  }

  render() {
    let width = 400,
      height = 250,
      labels = false,
      radius = 85,
      centerlabel = "",
      legends = true,
      headers = "",
      legendNumColumns = 1,
      datalength = 0;

    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (this.props.headers) headers = this.props.headers;
    if (this.props.labels) labels = this.props.labels;
    if (this.props.radius) radius = this.props.radius;
    if (this.props.centerlabel) centerlabel = this.props.centerlabel;
    if (this.props && this.props.legends === false)
      legends = this.props.legends;
    if (this.state.data.length >= 11) legendNumColumns = 2;

    datalength = data_length(this.state.data);

    let chartConfigs = {
      type: "doughnut2d",
      dataFormat: "json",
      width: width,
      height: height,
      dataSource: {
        chart: {
          theme: "fusion",
          animation: false,
          showAnchors: 1,

          decimals: 1,
          showPlotBorder: "0",
          paletteColors: (COLORS + "")
            .split(",")
            .slice(0, this.state.data.length)
            .reverse()
            .join(","),

          pieBorderThickness: "1",
          pieBorderColor: "#ffffff",
          minAngleForValue: 3,
          pieRadius: radius + 1,

          showValues: !this.props.dataHidden,
          showPercentValues: !this.props.dataHidden,
          valuePosition: "Inside",
          showpercentvalues: "1",

          showLabels: labels,
          labelFontSize: 11,
          labelFontBold: true,
          labelFontColor: "#ffffff",
          labelFont: "Avenir Heavy",
          labelPosition: "Inside",
          labelDistance: "-5",
          smartLabelClearance: "5",
          enableSmartLabels: "1",
          skipOverlapLabels: "0",
          usedataplotcolorforlabels: "1",

          defaultCenterLabel: "" + centerlabel,
          centerLabel: "" + centerlabel,
          doughnutRadius: "30",
          showLegend: true,
          legendItemFont: "Avenir Medium",
          legendItemFontColor: "#7f7f7f",
          reverseLegend: "1",
          legendposition: "absolute",
          legendNumColumns: 1,
          legendCaptionFontBold: true,
          legendXPosition: width / 2 + 20,
          legendYPosition: 75 + this.props.legendsYPosition,
          legendItemFontSize: this.state.data.length > 8 ? 11 : 14,

          chartTopMargin:
            height <= 268
              ? 90 + this.props.chartHeadingGap
              : 10 + this.props.chartHeadingGap,
          chartLeftMargin: !legends
            ? 0
            : -210 + (width / 2 - 173) + (400 - width) + 20,

          baseFont: "Avenir Medium",
          baseFontSize: 14,
          baseFontColor: "#7f7f7f",
        },
        annotations: {
          autoScale: "1",
          groups: [
            {
              id: "caption",
              items: [
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#002F75",
                  fontsize: 16,
                  font: "Avenir Medium",
                  text: (headers + "").toUpperCase(),
                  bold: "0",
                  wrap: "1",
                  wrapWidth: `${540 + this.props.wrapHeadingWidth}`,
                  x: `${this.props.xHeadingMargin}`,
                  y: `${this.props.yHeadingMargin + 15}`,
                },
              ],
            },
          ],
        },
        data: this.state.data,
      },
    };
    return (
      <div>
        <ReactFC {...chartConfigs} />
      </div>
    );
  }
}
