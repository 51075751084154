import React, { Component } from "react";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";
import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";
import Maps from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.maps";
import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/maps/fusioncharts.middleeast";
import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.gammel";

import { maxObject, minObject, intToString } from "../graphs.helperFunctions";
import { MIDDLEEAST_IDCODE_CONFIG } from "../graphs.configs";
import CONFIG from "../../config/Config";

ReactFC.fcRoot(FusionCharts, Maps, Charts, FusionTheme);
// FusionCharts.options.export.useCanvas = "true";

export default class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        { id: "001", value: 2, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "002", value: 2, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "003", value: 3, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "004", value: 4, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "005", value: 5, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "006", value: 6, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "007", value: 7, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "008", value: 8, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "009", value: 9, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "010", value: 10, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "011", value: 11, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "012", value: 12, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "013", value: 13, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "014", value: 14, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "015", value: 15, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "016", value: 16, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "017", value: 17, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "018", value: 18, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "019", value: 19, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "020", value: 20, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "021", value: 21, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "022", value: 22, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "023", value: 23, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "024", value: 24, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "025", value: 25, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "026", value: 26, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "027", value: 27, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "028", value: 28, showLabel: "1", fontcolor: "#7f7f7f" },
        {
          id: "029",
          value: 29,
          showLabel: "1",
          fontcolor: "#ffffff",
          displayValue: "Rajasthan",
        },
        { id: "030", value: 30, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "031", value: 31, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "032", value: 32, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "033", value: 33, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "034", value: 34, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "035", value: 35, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "036", value: 36, showLabel: "1", fontcolor: "#7f7f7f" },
        { id: "037", value: 37, showLabel: "1", fontcolor: "#7f7f7f" },
      ],
      range: [40, 20, 0],
      datas: "",
      button_click: false,
      loading: false,
      loadingData: true,
    };
  }

  componentDidMount() {
    if (this.props.data) {
      let datas = parseContinentDataForGeography(
        this.props.data,
        this.props?.graph_config
      );
      let range = [];
      let max = maxObject(this.props.data).value;
      let min = minObject(this.props.data).value;
      range.push(max + 1);
      range.push((max + min) / 2);
      range.push(min);
      this.setState({ data: datas, loadingData: false, range });
    } else {
      this.setState({ loadingData: false });
    }
  }
  render() {
    let data = "",
      width = 571,
      height = 393,
      footers = "",
      headers = "";

    if (!this.state.loadingData) data = this.state.data;
    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (this.props.footers) footers = this.props.footers;
    if (this.props.headers) headers = this.props.headers;

    let chartConfigs = {
      type: "middleeast",
      dataFormat: "json",
      width: width,
      height: height,
      dataSource: {
        chart: {
          theme: "gammel",
          animation: true,
          showanchors: 1,
          caption: (headers + "").toUpperCase(),
          captionFontSize: 24,
          captionFontColor: "#002F75",
          captionFont: "Avenir bold",
          color: "#ffffff",
          legendPosition: "absolute",
          legendXPosition: 760,
          legendItemFontSize: 16,
          legendNumColumns: 1,
          includeNameInLabels: 1,
          legendYPosition: height - 220,
          showLabels: 0,
          useSNameInLabels: 1,
          legendIconScale: 2,
          chartBottomMargin: 50,
          interactiveLegend: "1",
          showToolTip: 1,
          showToolTipShadow: 1,
          reverseLegend: "0",
          showCanvasBorder: "0",
          showBorder: 1,
          borderColor: "#ffffff",
          borderAlpha: 90,
          fillColor: "#eeeeee",
          labelConnectorAlpha: "100",
          labelConnectorColor: "#000000",
          bgcolor: "#ffffff",
          formatNumberScale: 1,
          numberScaleValue: "1000,1000,1000",
          numberScaleUnit: "k,M,B",
          baseFont: "Avenir Medium",
          baseFontSize: 11,
          baseFontColor: "#7f7f7f",
          nullEntityColor: "#81E0F0",
          nullEntityAlpha: "100",
          legendIconSides: "4",
        },
        colorrange: {
          minvalue: this.state.range[2],
          maxvalue: this.state.range[0],
          startlabel: "Low",
          endlabel: "High",
          code: "#81E0F0",
          gradient: "0",
          color: [
            {
              maxvalue: this.state.range[2],
              code: "#81E0F0",
              displayValue: `Data Not Available`,
            },
            {
              minvalue: this.state.range[2],
              maxvalue: this.state.range[1],
              code: "#539DE4",
            },
            {
              minvalue: this.state.range[1],
              maxvalue: this.state.range[0],
              code: "#3E87DD",
            },
          ],
        },
        data: data,
        annotations: {
          autoScale: "0",
          groups: [
            {
              id: "user-images",
              items: [
                {
                  id: "dyn-label-bg",
                  showBorder: "0",
                  borderColor: "12345d",

                  fillcolor: "#eeeeee",
                  x: "$canvasEndX-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#7f7f7f",
                  fontsize: "8",
                  font: "Avenir Medium",
                  text: footers,
                  bold: "1",
                  wrap: "1",
                  wrapWidth: width - 20,
                  x: "$canvasStartX+10",
                  y: `$canvasEndY+55`,
                },
              ],
            },
          ],
        },
      },
    };

    return (
      <div>
        <ReactFC {...chartConfigs} />
      </div>
    );
  }
}

function parseContinentDataForGeography(data, graph_config) {
  let arr = [];
  for (let item of data) {
    if (MIDDLEEAST_IDCODE_CONFIG[item.label.toLowerCase()]) {
      if (graph_config?.showRegionCodeWithValue) {
        arr.push({
          id: MIDDLEEAST_IDCODE_CONFIG[item.label.toLowerCase()],
          value: item.value,
          //displayValue:`${item.label}`,
          //displayValue: `${EUROPE_IDCODE_CONFIG[item.label.toLowerCase()]}, ${intToString(Number(item.value))}`,
          displayValue: `${CONFIG.COUNTRY_CONFIG[item.label.toLowerCase()]}, ${intToString(Number(item.value))}`,
          showLabel: "1",
          fontcolor: "#ffffff",
        });
      } else
        arr.push({
          id: MIDDLEEAST_IDCODE_CONFIG[item.label.toLowerCase()],
          value: item.value,
          //displayValue:`${item.label}`,
          //displayValue: `${item.label}, ${intToString(Number(item.value))}`,
          showLabel: "1",
          fontcolor: "#ffffff",
        });
    }
  }
  return arr;
}
