import styled from "styled-components";

export const FreighAndLogisticsVCWrapper = styled.div`
  .section_data1 {
    width: 15%;
    max-width: 160px;
    position: absolute;
    word-break: break-word;

    .font_design {
      font-size: 12px;
    }
  }

  .section_data2 {
    width: 20%;
    max-width: 210px;
    position: absolute;
    word-break: break-word;

    .font_design {
      font-size: 12px;
    }
  }

  .section1_list1 {
    top: 55%;
    left: 5%;
  }

  .section1_list2 {
    top: 55%;
    left: 20%;

    .font_design {
      color: #ed7d31;
    }
  }

  .section1_list3 {
    top: 71%;
    left: 5%;

    .font_design {
      color: #000000;
    }
  }

  .section1_list4 {
    top: 71%;
    left: 20%;

    .font_design {
      color: #00b050;
    }
  }

  .section2_list {
    top: 65%;
    left: 44%;
  }

  .section3_list1 {
    top: 58%;
    left: 68%;
  }

  .section3_list2 {
    top: 58%;
    left: 84%;

    .font_design {
      color: #ed7d31;
    }
  }

  .section3_list3 {
    top: 78%;
    left: 68%;

    .font_design {
      color: #000000;
    }
  }

  .section3_list4 {
    top: 78%;
    left: 84%;

    .font_design {
      color: #00b050;
    }
  }
`;
