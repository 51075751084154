import { Button, Modal, Checkbox, List, notification, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../../config/Api";
function SectionPdf({
  openSectionPDFModal,
  handleResetUrlsModalCancel,
  downloadloader,
  onSectionWisePDFDownload,
  states,
  reset,
  downloadReport,
}) {
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSections, setSelectedSections] = useState([]);

  useEffect(() => {
    if (sections.length === 0) {
      setSections(
        states.TOC_RAW_DATA.map((element) => {
          return { title: element.title, id: element.id };
        })
      );
    }
  }, [states.TOC_RAW_DATA]);

  const handleSectionSelect = (checkedValues) => {
    console.log(checkedValues);
    setSelectedSections(checkedValues);
  };

  const handleSave = async () => {
    //if itm regex KEY STRATEGIC QUESTIONS find KSM
    let KSQ = "";
    sections.forEach((itm) => {
      if (/KEY STRATEGIC QUESTIONS/i.test(itm.title)) {
        KSQ = itm.title;
      }
    });
    console.log(KSQ);
    setLoading(true);
    const payload = {
      hub_id: states.TOC_DATA.hub_id,
      dimensions: states.TOC_DATA.dimensions,

      meta: {
        sample: false,
        reseller: false,
        selectedSections: [...selectedSections, KSQ],
      },
    };

    try {
      let url = config.api.puppeteer_ms_url;
      let response = await axios.post(`${url}/api/createReport`, payload);
      setLoading(false);
      message.success("Success");
      downloadReport(response.data.pdfUrl);
    } catch (e) {
      setLoading(false);
      const error = "some error occurred please try again";
      console.log(error);
      notification.error({
        message: error,
      });
      console.log("Selected Sections:", selectedSections);
    }
  };

  return (
    <Modal
      title="Please select the section you want to generate in the PDF"
      visible={openSectionPDFModal}
      onCancel={handleResetUrlsModalCancel}
      centered
      footer={[
        <Button key="cancel" onClick={handleResetUrlsModalCancel}>
          Cancel
        </Button>,
        <Button
          disabled={selectedSections.length < 1 || loading}
          key="save"
          type="primary"
          onClick={handleSave}
          loading={loading}
        >
          Generate
        </Button>,
      ]}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
        }}
      >
        <Checkbox.Group onChange={handleSectionSelect} error={"ddd"}>
          <List
            dataSource={sections}
            renderItem={(item) => (
              <List.Item>
                <Checkbox
                  value={item.title}
                  disabled={/KEY STRATEGIC QUESTIONS/i.test(item.title)} // Disable if the title is "hello"
                  defaultChecked={
                    item.title === "KEY STRATEGIC QUESTIONS FOR CEP CEOs"
                  }
                  checked={selectedSections.includes(item.title)}
                >
                  {item.title}
                </Checkbox>
              </List.Item>
            )}
          />
        </Checkbox.Group>
        {selectedSections.length < 1 && (
          <p
            style={{
              color: "red",
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            Minimum 1 section is required
          </p>
        )}
      </div>
    </Modal>
  );
}

export default SectionPdf;
