import React, { Component } from "react";
import BulkUploadComponent from "./BulkUploadComponent";
import { BulkUploadContainerWrapper } from "./BulkUpload.style";
import InterfaceHeader from "../InterfaceHeader";

class BulkUploadContainer extends Component {
  render() {
    return (
      <>
        <InterfaceHeader />
        <BulkUploadContainerWrapper>
          <BulkUploadComponent />
        </BulkUploadContainerWrapper>
      </>
    );
  }
}

export default BulkUploadContainer;
