import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "antd/dist/antd.css";
import "./index.css";
import App from "./App";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "./reducersAndActions/Store";
import ErrorBoundary from "./components/ErrorBoundary";
import d from "./static/d.jpeg";
ReactDOM.render(
  <React.StrictMode>
    <Provider store={configureStore.store}>
      <PersistGate loading={null} persistor={configureStore.persistor}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
