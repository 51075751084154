import React, { Component } from "react";
import Mordor_icon from "../favicon.png";
import config from "../../../config/Api";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";

import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";

import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";

import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";
import axios from "axios";
import textBox from "../text-box.svg";
import font_html from "../../font_style.js";
// ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);
FusionCharts.options.export.useCanvas = "true";
//Your react component
class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          label: "Venezuela",
          value: "290",
        },
        {
          label: "Saudi",
          value: "260",
        },
        {
          label: "Canada",
          value: "180",
        },
        {
          label: "Iran",
          value: "140",
        },
        {
          label: "Russia",
          value: "115",
        },
        {
          label: "UAE",
          value: "100",
        },
        {
          label: "US",
          value: "30",
        },
        {
          label: "China",
          value: "30",
        },
      ],
      datas: "",
      button_click: false,
      loading: false,
      loadingData: true,
    };
    this.resetChart = this.resetChart.bind(this);
  }
  componentDidMount() {
    let datas = parseData(this.props.data);
    this.setState({ datas: datas, loadingData: false, data: this.props.data });
    setTimeout(() => {
      this.resetChart();
    }, 1000);
  }
  resetChart(event) {
    let a = window.btoa(JSON.stringify(this.props.data));
    var revenueChart = FusionCharts("cagr");
    // get chart xml data
    var svgString = revenueChart.getSVGString();
    let svgdata = new DOMParser().parseFromString(svgString, "text/xml");
    var doc = new DOMParser().parseFromString(font_html, "text/html");
    // svgdata.prepend(doc.documentElement)
    let defs_data = svgdata.getElementsByTagName("defs")[0];
    defs_data.prepend(doc.documentElement);
    axios
      .post(`${config.api.base_url}/api/graphs/cagr?data=${a}`, {
        stream: window.btoa(svgdata.documentElement.outerHTML),
      })
      .then((res) => {});
    // let data = "cagr";
    // FusionCharts.batchExport({
    //   charts: [
    //     {
    //       id: data,
    //     },
    //   ],
    //   exportFormat: "png",
    //   exportHandler: `${config.api.base_url}/api/graphs/${data}?data=${a}`,
    //   exportAction: "SAVE",
    //   exportMode: "client",
    // });
  }
  render() {
    let data = "",
      datas = "",
      line_Yvalue,
      yAxisValue;
    if (!this.state.loadingData) {
      data = this.state.datas;
      datas = this.state.data;
      line_Yvalue =
        data[0].value > data[1].value
          ? "$dataset.0.set.1.STARTY"
          : "$dataset.0.set.0.STARTY";
      yAxisValue = data[1].value + 3;
      if (yAxisValue <= 12) {
        yAxisValue = 12;
      }
    }

    let width = 553,
      height = 720;

    let headers = "Countries With Most Oil Reserves [2017-18]";
    let footers = "Source:",
      reseller = false;
    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (datas && datas.heading) headers = datas.heading;
    if (datas && datas.heading) footers = datas.source;
    if (datas && datas.reseller) reseller = datas.reseller;
    let chartConfigs = {
      id: "cagr",
      type: "column2d",
      width: width,
      height: height,
      dataFormat: "json",
      dataSource: {
        chart: {
          // subCaption: `CAGR ${datas.cagr}%`,
          // subCaptionFontSize: 28,
          // subCaptionFont: "regular_regular_rd",
          // subCaptionFontColor: "#7f7f7f",
          // divLineColor: "#ffffff",
          // paletteColors: "#00CED1",
          // captionFontColor: "#7f7f7f",

          caption: datas.caption,
          captionFont: "regular_bold_rd",
          captionFontBold: 1,
          captionPadding: "100",
          captionAlignment: "left",
          paletteColors: "#5E91B8",
          usePlotGradientColor: "1",
          plotGradientColor: "#82C4CA",
          captionFontColor: "#080808",

          showValues: this.props.data.labels === true ? true : false,
          showPlotBorder: "0",
          maxBarHeight: 2,
          animation: false,
          labelFontSize: 28,
          labelFont: "regular_semibold_rd",
          showYAxisValues: false,
          captionFontSize: 30,
          labelFontBold: 1,
          valueFontBold: true,
          xAxisNameFontColor: "#444444",
          labelFontColor: "#080808",
          theme: "fusion",
          plotSpacePercent: 40,
          chartLeftMargin: 20,
          valueFontColor: "#080808",
          placeValuesInside: "0",
          yAxisNamePadding: 0,
          xAxisNameFont: "regular_medium_rd",
          chartBottomMargin: 90,
          xAxisNameFontSize: 12,
          // xAxisNameFontColor: "#7f7f7f",
          centerXaxisName: false,
          valueFont: "regular_semibold_rd",
          valueFontSize: 24,
        },
        annotations: {
          autoScale: "0",
          groups: [
            {
              id: "cagr-text",
              items: [
                {
                  id: "subCaption--text",
                  type: "text",
                  align: "left",
                  x: "$canvasStartX",
                  y: "$CaptionEndY + 25",
                  bold: "0",
                  wrap: "1",
                  fillcolor: "#080808",
                  fontsize: 24,
                  font: "regular_medium_rd",
                  text: `${datas.market_parameter} in ${datas.units}`,
                },
                {
                  id: "cage--text",
                  type: "text",
                  align: "left",
                  x: "$canvasStartX + 70",
                  y: "$CaptionEndY + 75",
                  bold: "0",
                  wrap: "1",
                  fillcolor: "#5E91B8",
                  fontsize: 24,
                  font: "regular_medium_rd",
                  text: `${datas.cagr}%`,
                },
                {
                  id: "unit-text",
                  type: "text",
                  align: "left",
                  x: "$canvasStartX",
                  y: "$CaptionEndY +75",
                  bold: "0",
                  wrap: "1",
                  fillcolor: "#080808",
                  fontsize: 24,
                  font: "regular_medium_rd",
                  text: "CAGR",
                },
              ],
            },
            {
              id: "line",
              items: [
                {
                  id: "annotationLine",
                  type: "line",
                  x: "$canvasStartX",
                  y: line_Yvalue,
                  toX: "$canvasEndX",
                  toY: line_Yvalue,
                  dashed: "1",
                  dashLen: "15",
                  dashGap: "5",
                  color: "#41759D",
                  thickness: "2",
                  showBelow: "1",
                },
              ],
            },
            {
              id: "annotationGroup",
              items: [
                {
                  id: "annotationPath",
                  type: "path",
                  path: "M81.5 51L88.8182 41H154.682C159.561 41 162 37.6667 162 31V11C162 4.33333 159.561 1 154.682 1H8.31818C3.43939 1 1 4.33333 1 11V31C1 37.6667 3.43939 41 8.31818 41H74.1818L81.5 51Z",
                  color: "#DFEAED",
                  alpha: "100",
                  borderColor: "#000000",
                  borderThickness: "2",
                  x: "$canvasStartX+50",
                  y: "$dataset.0.set.0.STARTY-60",
                },

                {
                  id: "cagr--text",
                  type: "text",
                  align: "center",
                  x: "$dataset.0.set.0.STARTX + 80",
                  y: "$dataset.0.set.0.STARTY - 38",
                  bold: "1",
                  wrap: "1",
                  fillcolor: "#080808",
                  fontsize: 20,
                  font: "regular_semibold_rd",
                  text: datas.display_value1,
                },
                {
                  id: "annotationPath2",
                  type: "path",
                  path: "M81.5 51L88.8182 41H154.682C159.561 41 162 37.6667 162 31V11C162 4.33333 159.561 1 154.682 1H8.31818C3.43939 1 1 4.33333 1 11V31C1 37.6667 3.43939 41 8.31818 41H74.1818L81.5 51Z",
                  color: "#DFEAED",
                  alpha: "100",
                  borderColor: "#000000",
                  borderThickness: "2",
                  x: "$canvasStartX+305",
                  y: "$dataset.0.set.1.STARTY-60",
                },
                {
                  id: "cagr--text1",
                  type: "text",
                  align: "center",
                  x: "$dataset.0.set.1.STARTX + 70",
                  y: "$dataset.0.set.1.STARTY - 38",
                  bold: "1",
                  wrap: "1",
                  fillcolor: "#080808",
                  fontsize: 20,
                  font: "regular_semibold_rd",
                  text: datas.display_value2,
                },
              ],
            },
          ],
        },
        data: data,
      },
    };
    if (reseller === false) {
      let non_reseller = [
        {
          id: "footer",
          items: [
            {
              id: "dyn-label-bg",
              showBorder: "0",
              x: "$canvasEndY-245",
              y: "$canvasEndY+45",
              tox: "$canvasEndX+10",
              toy: "$canvasEndY + 80",
            },
            {
              id: "dyn-label",
              type: "text",
              align: "left",
              fillcolor: "#7f7f7f",
              fontsize: 19,
              font: "regular_semibold_rd",
              text: "Source :",
              bold: "1",
              wrap: "1",
              wrapWidth: width - 20,
              x: 10,
              y: height - 32,
            },
          ],
        },
        {
          id: "sub-footer",
          items: [
            {
              id: "dyn-label-bg",
              showBorder: "1",
              x: "$canvasEndY-245",
              y: "$canvasEndY+45",
              tox: "$canvasEndX+10",
              toy: "$canvasEndY + 80",
            },
            {
              id: "dyn-label",
              type: "text",
              align: "left",
              font: "regular_regular_rd",
              fillcolor: "#7f7f7f",
              fontsize: 19,
              text: footers,
              wrap: "1",
              wrapWidth: "100%",
              x: 80,
              y: height - 32,
            },
          ],
        },
        {
          id: "mordor-icon",
          xScale_: "5",
          yScale_: "5",
          items: [
            {
              id: "Brian-user-icon",
              type: "image",
              url: Mordor_icon,
              x: 380,
              y: 660,
              xScale: 140,
              yScale: 140,
            },
          ],
        },
      ];
      chartConfigs.dataSource.annotations.groups = [
        ...chartConfigs.dataSource.annotations.groups,
        ...non_reseller,
      ];
    }

    return (
      <div className="bar1" id="bar1">
        <div>
          <ReactFC {...chartConfigs} />
        </div>
      </div>
    );
  }
}
function parseData(data) {
  return data.data;
}
export default Chart;
