import React, { Component } from "react";
import Config from "../../../config/Config";
import Mordor_icon from "../favicon.png";
import config from "../../../config/Api";
import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";
import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";
import Maps from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.maps";
import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/maps/fusioncharts.worldwithcountries";
import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";
ReactFC.fcRoot(FusionCharts, Maps, Charts, FusionTheme);
// FusionCharts.options.export.useCanvas = "true";
const CODE_COUNTRY_CONFIG = Config.CODE_COUNTRY_CONFIG;

class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      datas: "",
      button_click: false,
      loading: false,
      loadingData: true,
    };
    this.resetChart = this.resetChart.bind(this);
  }
  resetChart(event) {
    let a = window.btoa(JSON.stringify(this.props.data));

    FusionCharts.batchExport({
      imageWidth: 1366,
      charts: [
        {
          id:
            this.props.data.graph_type +
            this.props.data.component +
            (this.props.data.heading + "").split(" ", ","),
        },
      ],
      exportFormat: "png",

      exportHandler: `${config.api.base_url}/api/graphs/data?data=${a}`,
      exportAction: "SAVE",
      exportMode: "client",
    });
  }
  componentDidMount() {
    let datas = parseData(this.props.data.data);
    this.setState({ datas: datas, loadingData: false, data: this.props.data });
    setTimeout(() => {
      this.resetChart();
    }, 1000);
  }
  componentDidUpdate(prevProps) {
    let datas = parseData(this.props.data.data);

    if (this.props.data !== prevProps.data) {
      this.setState({
        datas: datas,
        loadingData: false,
        data: this.props.data,
      });
      setTimeout(() => {
        this.resetChart();
      }, 1000);
    }
  }
  render() {
    let data = "",
      datas = "";
    if (!this.state.loadingData) {
      data = this.state.datas;
      datas = this.state.data;
    }
    let width = 1506,
      height = 655;
    let headers = "Countries With Most Oil Reserves [2017-18]";
    let footers = "Source:";
    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (datas && datas.heading) headers = datas.heading;
    if (datas && datas.heading) footers = datas.source;
    let chartConfigs = {
      id:
        this.props.data.graph_type +
        this.props.data.component +
        (this.props.data.heading + "").split(" ", ","),
      type: "worldwithcountries",
      width: width,
      height: height,
      dataFormat: "json",
      dataSource: {
        chart: {
          captionFontSize: 36,
          captionFontColor: "#6a6a6a",
          animation: false,
          gradient: "1",
          legendPosition: "absolute",
          legendXPosition: 100,
          legendBorderColor: "#C2C5CC",
          legendShadow: "0",
          legendItemFontSize: 34,
          legendYPosition: 440,
          legendNumColumns: 1,
          showLabels: this.props.data.labels === true ? true : false,
          chartBottomMargin: 50,
          entityFillHoverColor: "#C2C5CC",
          legendBorderColor: "#FFFFFF",
          legendShadow: false,
          interactiveLegend: "0",
          legendShadow: "0",
          chartTopMargin: 70,
          reverseLegend: "0",
          showCanvasBorder: "0",
          fillColor: "#C2C5CC",
        },
        colorrange: {
          minvalue: "0",
          startlabel: "Low",
          endlabel: "High",
          code: "#C2C5CC",
          gradient: "0",
          color: [
            {
              maxvalue: "2.6",
              displayvalue: "Low",
              gradient: "0",
              font: "regular",
              code: "#FF6357",
            },
            {
              maxvalue: "1.6",
              displayvalue: "Medium",
              font: "regular",
              code: "#FFC000",
            },
            {
              maxvalue: "0.6",
              displayvalue: "High",
              font: "regular",
              code: "#6F9C3C",
            },
          ],
        },
        annotations: {
          autoScale: "0",
          groups: [
            {
              id: "mordor-icon",
              xScale_: "5",
              yScale_: "5",
              items: [
                {
                  id: "Brian-user-icon",
                  type: "image",
                  url: Mordor_icon,
                  x: "1022",
                  y: height - 72,
                  xScale: "200",
                  yScale: "200",
                },
              ],
            },
            {
              id: "footer",
              items: [
                {
                  id: "dyn-label-bg",
                  showBorder: "0",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#6a6a6a",
                  fontsize: 36,
                  font: "regular",
                  text: "Source :",
                  bold: "1",
                  wrap: "1",
                  wrapWidth: width - 20,
                  x: "115",
                  y: height - 32,
                },
              ],
            },
            {
              id: "sub-footer",
              items: [
                {
                  id: "dyn-label-bg",
                  showBorder: "1",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#6a6a6a",
                  fontsize: 36,
                  text: footers,
                  font: "regular",
                  bold: "0",
                  wrap: "1",
                  wrapWidth: "100%",
                  x: 245,
                  y: height - 32,
                },
              ],
            },
            {
              id: "caption",
              items: [
                {
                  id: "dyn-label-bg",
                  showBorder: "0",
                  borderColor: "12345d",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "center",
                  fillcolor: "#6a6a6a",
                  fontsize: 36,
                  font: "regular",
                  text: headers,
                  bold: "1",
                  wrap: "1",
                  wrapWidth: "1000",
                  x: "$canvasEndY+40",
                  y: "50",
                },
              ],
            },
          ],
        },

        data: data,
      },
    };
    return (
      <div
        className="bar"
        id="bar"
        style={{
          textAlign: "center",
          marginBottom: 700,
          fontFamily: "regular",
          marginLeft: 300,
          height: 300,
          width: 500,
          marginTop: -740,
        }}
      >
        <div
          style={{ transform: `scale(0.4)`, marginLeft: -200, marginTop: -900 }}
        >
          <ReactFC {...chartConfigs} />
        </div>
      </div>
    );
  }
}
function parseData(data) {
  let data_arr = [];

  for (let item of data) {
    let temp = {
      id: CODE_COUNTRY_CONFIG[item.id],
      value: item.value,
    };
    data_arr.push(temp);
  }
  return data_arr;
}

export default Chart;
