import React, { Component } from "react";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";
import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";
import Maps from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.maps";
import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/maps/fusioncharts.world";
import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";

import config from "../../config/Api";
import {
  parseContinentDataForExperimental,
  maxObject,
  minObject,
} from "../graphs.helperFunctions";

ReactFC.fcRoot(FusionCharts, Maps, Charts, FusionTheme);
// FusionCharts.options.export.useCanvas = "true";

export default class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      range: [],
      datas: "",
      button_click: false,
      loading: false,
      loadingData: true,
    };
    this.resetChart = this.resetChart.bind(this);
  }

  componentDidMount() {
    if (this.props.data) {
      let datas = parseContinentDataForExperimental(this.props.data);
      let range = [];
      let max = maxObject(this.props.data).value;
      let min = minObject(this.props.data).value;
      range.push(max);
      range.push((max + min) / 2);
      range.push(min);
      this.setState({ data: datas, loadingData: false, range });
    }
  }

  resetChart(event) {
    let a = window.btoa(
      JSON.stringify({
        slug: "aquaculture",
        graph_type: "aquaculture",
        heading: "aquaculture",
        component: "aquaculture",
        time: Date.now(),
      })
    );

    FusionCharts.batchExport({
      charts: [
        {
          id: "image",
        },
      ],
      exportFormat: "png",

      exportHandler: `${config.api.base_url}/api/graphs/data?data=${a}`,
      exportAction: "SAVE",

      exportMode: "client",
    });
  }

  render() {
    let data = "",
      width = 571,
      height = 393;

    if (!this.state.loadingData) data = this.state.data;
    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;

    let chartConfigs = {
      type: "world",
      dataFormat: "json",
      width: width,
      height: height,
      dataSource: {
        chart: {
          theme: "fusion",
          animation: false,
          showanchors: 1,

          captionFontSize: 14,
          captionFontColor: "#7f7f7f",
          includeValueInLabels: "0",
          showlabel: "0",
          nullEntityAlpha: "0",
          nullEntityColor: "#ffffff",
          captionFont: "Avenir bold",
          showLegend: false,
          bgColor: "#FFEDC7",
          interactiveLegend: "0",
          reverseLegend: "0",
          showCanvasBorder: "0",
          fillColor: "#6C27FF",

          baseFont: "Avenir Medium",
          baseFontSize: 14,
          baseFontColor: "#7f7f7f",
        },
        colorrange: {
          minvalue: this.state.range[2],
          maxvalue: this.state.range[0],
          gradient: "0",
          color: [
            {
              minvalue: this.state.range[2],
              maxvalue: this.state.range[1],
              code: "#EFD699",
            },
            {
              minvalue: this.state.range[1],
              maxvalue: this.state.range[0],
              code: "#FCA31E",
            },
          ],
        },
        data: data,
      },
    };

    return (
      <div>
        <ReactFC {...chartConfigs} />
        <button onClick={this.resetChart}>Click</button>
      </div>
    );
  }
}
