import styled from "styled-components";

export const FeedbackAndAuditWrapper = styled.div`
  .FeedbackAndAudit {
    overflow-y: hidden;
    top: 0;
    max-height: 720px;
    position: relative;
    display: block;
  }
  .linkContainer {
    position: absolute;
    top: 380px;
    left: 70px;
  }
  .FeedbackAndAuditframe {
    width: 100%;
    z-index: -1;
  }
  .FeedbackAndAuditFooter {
    position: relative;
    top: -52px;
  }
`;
