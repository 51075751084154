import firebase from "firebase/app";
import "firebase/auth";

const app = firebase.initializeApp({
  apiKey:
    process.env.REACT_APP_STAGE === "PRODUCTION"
      ? process.env.REACT_APP_LIVE_API_KEY
      : process.env.REACT_APP_STAGE === "TEST"
        ? process.env.REACT_APP_TEST_API_KEY
        : process.env.REACT_APP_TEST_API_KEY,

  authDomain:
    process.env.REACT_APP_STAGE === "PRODUCTION"
      ? process.env.REACT_APP_LIVE_AUTH_DOMAIN
      : process.env.REACT_APP_STAGE === "TEST"
        ? process.env.REACT_APP_TEST_AUTH_DOMAIN
        : process.env.REACT_APP_TEST_AUTH_DOMAIN,

  projectId:
    process.env.REACT_APP_STAGE === "PRODUCTION"
      ? process.env.REACT_APP_LIVE_PROJECT_ID
      : process.env.REACT_APP_STAGE === "TEST"
        ? process.env.REACT_APP_TEST_PROJECT_ID
        : process.env.REACT_APP_TEST_PROJECT_ID,

  storageBucket:
    process.env.REACT_APP_STAGE === "PRODUCTION"
      ? process.env.REACT_APP_LIVE_STORAGE_BUCKET
      : process.env.REACT_APP_STAGE === "TEST"
        ? process.env.REACT_APP_TEST_STORAGE_BUCKET
        : process.env.REACT_APP_TEST_STORAGE_BUCKET,

  messagingSenderId:
    process.env.REACT_APP_STAGE === "PRODUCTION"
      ? process.env.REACT_APP_LIVE_MESSAGING_SENDER_ID
      : process.env.REACT_APP_STAGE === "TEST"
        ? process.env.REACT_APP_TEST_MESSAGING_SENDER_ID
        : process.env.REACT_APP_TEST_MESSAGING_SENDER_ID,

  appId:
    process.env.REACT_APP_STAGE === "PRODUCTION"
      ? process.env.REACT_APP_LIVE_APP_ID
      : process.env.REACT_APP_STAGE === "TEST"
        ? process.env.REACT_APP_TEST_APP_ID
        : process.env.REACT_APP_TEST_APP_ID,

  measurementId:
    process.env.REACT_APP_STAGE === "PRODUCTION"
      ? process.env.REACT_APP_LIVE_MEASUREMENT_ID
      : process.env.REACT_APP_STAGE === "TEST"
        ? process.env.REACT_APP_TEST_MEASUREMENT_ID
        : process.env.REACT_APP_TEST_MEASUREMENT_ID,
});

export const auth = app.auth();
export default app;
