import styled from "styled-components";

export const AgricuturalBiologicalVCWrapper = styled.div`
  .section_data1 {
    position: absolute;
    word-break: break-word;
    width: 13%;
    max-width: 150px;

    .font_design {
      font-size: 11px;
    }
  }

  .section_data2 {
    top: 50%;
    width: 24%;
    max-width: 250px;
    position: absolute;
    word-break: break-word;

    .font_design {
      font-size: 11px;
    }
  }

  .section1_list {
    left: 7%;
  }

  .section2_list {
    left: 42%;
  }

  .section3_list1 {
    top: 47%;
    left: 68%;
  }

  .section3_list2 {
    top: 47%;
    left: 85%;
  }

  .section3_splitted_data1 {
    top: 67%;
    left: 68%;
  }

  .section3_splitted_data2 {
    top: 67%;
    left: 85%;
  }
`;
