import styled from "styled-components";

export const EVBatteryPackVCWrapper = styled.div`
  .section_data1 {
    top: 76%;
    width: 13%;
    max-width: 165px;
    position: absolute;
    word-break: break-word;
  }

  .section1_list1 {
    left: 5%;
  }

  .section1_list2 {
    left: 19%;
  }

  .section2_list1 {
    left: 35%;
  }

  .section2_list2 {
    left: 50%;
  }

  .section3_list1 {
    left: 66%;
  }

  .section3_list2 {
    left: 81%;
  }
`;
