import React from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import { FoodServicesVCWrapper } from "./styles";

var Base64 = require("js-base64").Base64;
let color_array = ["#E3F2F9", "#D5F1E7", "#E2E0F6", "#DBE7F2"];

class Food_Services_VC extends React.Component {
  state = {
    data: this.props.data ? this.props.data : "",
    reseller: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.meta) {
      if (props.data.meta.sample && props.data.meta.reseller) {
        return {
          reseller: true,
        };
      }
    }
    return null;
  }

  renderTableList(vc_list) {
    let new_list;

    new_list = vc_list.map((list, index) => {
      list = list = list
        .replaceAll("/", "/ ")
        .replace("(", "")
        .replace(")", "")
        .split(",");

      return (
        <tr className="vc_table_row">
          <td>{list[0]}</td>
          <td>{list[1]}</td>
        </tr>
      );
    });
    return new_list;
  }

  render() {
    const { section_id } = this.state.data;
    const { slideNo } = this.props;
    const slug = this.state.data.slug;
    const pageId = Base64.encode(section_id);

    return (
      <div className="page" id={pageId}>
        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Header slideTitle={this.state.data.type} />
        ) : null}
        <FoodServicesVCWrapper>
          <h2 className="heading valuechain_title">
            {this.props.data
              ? (this.props.data.title + "").toUpperCase()
              : (this.state.data.title + "").toUpperCase()}
          </h2>

          <div className="vc_table_container">
            {this.state.data?.data?.consumer_buying_data
              ? Object.keys(this.state.data?.data?.consumer_buying_data).map(
                  (cb, index) => (
                    <div
                      style={{
                        backgroundColor: `${color_array[index]}`,
                        padding: "12px",
                      }}
                    >
                      <h1 style={{ fontSize: "16px", textAlign: "center" }}>
                        {(cb + "").toUpperCase()}
                      </h1>
                      <div>
                        {this.state.data?.data?.consumer_buying_data[cb]
                          .split(";")
                          .filter((item) => (item + "").trim() != "")
                          .map((elem) => (
                            <div
                              className="consumer_buying_para"
                              style={{ textAlign: "justify" }}
                            >
                              <div
                                style={{
                                  marginLeft: "5px",
                                  marginBottom: "10px",
                                  color: "#27518c",
                                  fontFamily: "Avenir",
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                }}
                              >
                                • {(elem + "").trim()}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )
                )
              : null}
            {/* {this.state.data.data.map((vc_list, index) => (
              <div
                style={{
                  backgroundColor: `${color_array[index]}`,
                  padding: "12px",
                }}
              >
                <h2 className="vc_table_type">{vc_list.type}</h2>

                <table>
                  <tr className="vc_table_row">
                    <th>Dish/Item Names</th>
                    <th>Avg Price</th>
                  </tr>

                  {this.renderTableList(vc_list.list)}
                </table>
              </div>
            ))} */}
          </div>

          {!this.props.fromInterface ||
          this.props.fromInterface === undefined ? (
            <Footer
              slug={slug}
              slideNo={slideNo}
              reseller={this.state.reseller}
            />
          ) : null}
        </FoodServicesVCWrapper>
      </div>
    );
  }
}

export default Food_Services_VC;
