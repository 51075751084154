import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setSelectedCustomSlideData } from "../../../../reducersAndActions/Actions";
import { Select } from "antd";
import CustomTempOne from "../../../../pages/CustomSlideTemplate/CustomTempOne";
import CustomTempTwo from "../../../../pages/CustomSlideTemplate/CustomTempTwo";
import axios from "axios";
import configApi from "../../../../config/Api";
const { Option } = Select;
function getToken() {
  return window.localStorage.getItem("token");
}
const TEMPLATE_COMPONENTS = {
  Temp1: CustomTempOne,
  Temp2: CustomTempTwo,
};

const createConfig = (template, data) => {
  switch (template) {
    case "companyProfile1":
    case "companyProfile2":
      return {
        company_id: data.meta.company_id,
      };
    default:
      return {};
  }
};

const TemplateRenderer = ({ template, data, edit, onSave }) => {
  const TemplateComponent = TEMPLATE_COMPONENTS[template];
  return TemplateComponent ? (
    <TemplateComponent
      data={data}
      edit={edit}
      onSave={onSave}
      fromInterface={true}
      setSelectedCustomSlideData={null}
    />
  ) : null;
};

function CustomSlideModal({ SELECTED_CUSTOM_SLIDE_DATA, currentSection }) {
  const [modalStates, setModalStates] = useState({
    ...SELECTED_CUSTOM_SLIDE_DATA.data,
  });
  const list = [
    { name: "Temp1", id: "Temp1" },
    { name: "Temp2", id: "Temp2" },
  ];

  const handleChangeTemplate = (value) => {
    setModalStates((prev) => ({
      ...prev,
      data: { ...prev.data, template: value },
    }));
  };

  console.log(modalStates, "modalStates=====");
  const onSave = async (id, data) => {
 
    if (id) {
      await axios.patch(
        `${configApi.api.base_url}/api/additional_routes/customSlideUpdate`,
        { id, data: { ...data, template: modalStates.data.template } },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      window.location.reload();
    } else {
      console.log(currentSection, "---", data);
      const { template, report_id } = currentSection.meta;
      const config = createConfig(template, currentSection);
      await axios.post(
        `${configApi.api.base_url}/api/additional_routes/customSlideCreate`,
        {
          data: { ...data, template: modalStates.data.template },
          report_id,
          template_name: template,
          config,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      window.location.reload();
    }
  };
  return (
    <div>
      <div className="uploadsection">
        <p className="exportLabels">
          <span className="mandatory">*</span>Select Template
        </p>
        <Select
          placeholder="Select Template"
          optionFilterProp="children"
          showSearch
          onChange={handleChangeTemplate}
          value={modalStates?.data?.template}
          style={{ width: "100%" }}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }>
          {list.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>

        <div style={{ height: "720px", width: "auto" }}>
          {" "}
          {modalStates?.data?.template && (
            <TemplateRenderer
              template={modalStates.data.template}
              data={modalStates}
              fromInterface={true}
              edit={true}
              onSave={onSave}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  SELECTED_CUSTOM_SLIDE_DATA: state.SELECTED_CUSTOM_SLIDE_DATA,
});

export default connect(mapStateToProps, { setSelectedCustomSlideData })(
  CustomSlideModal
);
