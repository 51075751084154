import styled from "styled-components";

export const GrowthStrategyContainer = styled.div`
  .main-container {
    display: flex;
    justify-content: space-around;
    flex-flow: wrap;
  }
  .matrix-block {
    width: 48%;
    margin: 10px;
    padding: 20px;
    height: 26vh;
  }

  .heading {
    color: #002f75;
    font-family: "Avenir Roman";
    padding: 25px 0;
    text-transform: uppercase;
  }

  .subheading {
    color: #002f75;
    font-family: "Avenir Roman";
    text-transform: uppercase;
    padding: 5px 0px 10px 0px;
    font-size: 18px;
    line-height: 28px;
  }

  .block-title {
    font-family: "Avenir Roman";
    text-transform: uppercase;
    padding: 5px 0px 10px 0px;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
  }

  p.block-content {
    font-family: "Avenir Roman";
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
  }
  .align-center {
    text-align: center;
  }

  #color0 {
    background-color: #e0f9ff;
  }

  #color0 > h3 {
    color: #189cde;
  }

  #color1 {
    background-color: #ffedc7;
  }

  #color1 > h3 {
    color: #b17800;
  }

  #color2 {
    background-color: #d7f6eb;
  }

  #color2 > h3 {
    color: #379f7a;
  }

  #color3 {
    background-color: #e6e3fc;
  }

  #color3 > h3 {
    color: #4737c1;
  }
`;
