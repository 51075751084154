import React, { useEffect, useState, useRef } from "react";
import { Row, Column } from "./style";
import Heading from "../../components/Heading";
import Graph from "../../Graphs/index";
import { Button, Select } from "antd";
import Analysis from "../../components/Analysis";
import Subheading from "../../components/Subheading";
import EditorJS from "@editorjs/editorjs";
import { EditOutlined } from "@ant-design/icons";
const { Option } = Select;
var Base64 = require("js-base64").Base64;
const defaultAnalysis = [
  {
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor sapien ut felis faucibus, ac pulvinar orci fermentum.",
    hyperLink: "",
    counter: 1,
  },
];
function CustomTempOne({
  data,
  fromInterface,
  edit,
  setSelectedCustomSlideData,
  onSave,
}) {
  console.log(data, "data from temp");
  const {
    graphs = [],
    analysis = [{ heading: "Loriem" }],
    slideHeading,
  } = data.data;
  const editorInstance = useRef(null); // Reference for EditorJS instance
  const [tempConfig, setTempConfig] = useState({
    slideHeading,
    graphs: {
      ...graphs[0],
    },
    analysis: {
      ...analysis[0],
      data: {
        analysis: analysis[0]?.data?.analysis || defaultAnalysis,
      },
    },
  });

  const list = [{ name: "pie", id: "pie" }];

  useEffect(() => {
    if (edit) {
      editorInstance.current = new EditorJS({
        holder: "editorjs-container",
        placeholder: "Edit analysis here...",
        data: tempConfig.analysis.data,
        onChange: async () => {
          const content = await editorInstance.current.save();
          setTempConfig((prev) => ({
            ...prev,
            analysis: {
              ...prev.analysis,
              data: content,
            },
          }));
        },
      });
    }

    return () => {
      if (editorInstance.current) {
        editorInstance.current.isReady
          .then(() => editorInstance.current.destroy())
          .catch((error) => console.error("Error destroying EditorJS:", error));
        editorInstance.current = null;
      }
    };
  }, [edit]);

  const handleChangeGraph = (value) => {
    setTempConfig((prev) => ({
      ...prev,
      graphs: { ...prev.graphs, graphType: value },
    }));
  };

  const handleAnalysisChange = (index, value) => {
    setTempConfig((prev) => {
      const newAnalysisData = [...prev.analysis.data.analysis];
      newAnalysisData[index].text = value;
      return {
        ...prev,
        analysis: {
          ...prev.analysis,
          data: { ...prev.analysis.data, analysis: newAnalysisData },
        },
      };
    });
  };

  const handleKeyPress = (e, index) => {
    if (e.key === "Enter") {
      setTempConfig((prev) => ({
        ...prev,
        analysis: {
          ...prev.analysis,
          data: {
            ...prev.analysis.data,
            analysis: [
              ...prev.analysis.data.analysis,
              {
                text: "",
                hyperLink: "",
                counter: prev.analysis.data.analysis.length + 1,
              },
            ],
          },
        },
      }));
    }
  };

  return (
    <>
      {edit && (
        <div className="uploadsection">
          <p className="exportLabels">
            <span className="mandatory">*</span>Select a Graph Type
          </p>
          <Select
            placeholder="Select Graph"
            optionFilterProp="children"
            showSearch
            onChange={handleChangeGraph}
            value={tempConfig.graphType}
            style={{ width: "100%" }}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }>
            {list.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </div>
      )}
      {!edit && fromInterface && (
        <div
          style={{
            fontSize: "20px",

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
          }}
          onClick={() => setSelectedCustomSlideData({ modal: true, data })}>
          <Button>
            Edit <EditOutlined />
          </Button>
        </div>
      )}

      {edit ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            marginBottom: "10px",
          }}>
          <input
            placeholder="slide heading"
            style={{ width: "100%" }}
            value={tempConfig.slideHeading}
            onChange={(e) =>
              setTempConfig((p) => {
                return { ...p, slideHeading: e.target.value };
              })
            }
          />
        </div>
      ) : (
        <Heading heading={tempConfig.slideHeading} />
      )}
      <Row>
        <Column>
          {edit && (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginBottom: "10px",
              }}>
              <input
                style={{
                  width: "100%",
                }}
                placeholder="Graph heading"
                value={tempConfig.graphs.heading}
                onChange={(e) =>
                  setTempConfig((p) => {
                    return {
                      ...p,
                      graphs: {
                        ...p.graphs,
                        heading: e.target.value,
                      },
                    };
                  })
                }
              />
            </div>
          )}
          <Graph
            legendsPositionDynamic={true}
            rotateValues={0}
            chartHeadingGap={tempConfig.graphs.heading?.length > 60 ? 10 : 0}
            yHeadingMargin={tempConfig.graphs.heading?.length > 60 ? 30 : 25}
            graph_config={tempConfig.graphs.graph_config}
            xHeadingMargin={0}
            wrapHeadingWidth={-100}
            legendsYPosition={100}
            headers={tempConfig.graphs.heading}
            data={tempConfig.graphs.data}
            meta={tempConfig?.graphs?.meta ? tempConfig.graph.meta : {}}
            width={
              !fromInterface || fromInterface === undefined ? "566" : "500"
            }
            height={550}
            totalData={tempConfig.graphs.data}
            graphType={tempConfig.graphs.graphType}
            dataType={tempConfig.graphs.dataType}
            fromInterface={fromInterface}
            slideNo
          />
        </Column>
        <Column>
          {edit ? (
            <>
              <input
                placeholder="Analysis heading"
                value={tempConfig.analysis.heading}
                onChange={(e) =>
                  setTempConfig((p) => {
                    return {
                      ...p,
                      analysis: {
                        ...p.analysis,
                        heading: e.target.value,
                      },
                    };
                  })
                }
              />
              <div>
                <p>Analysis:</p>
                {tempConfig.analysis.data.analysis.map((item, index) => (
                  <input
                    key={index}
                    style={{ width: "100%", marginBottom: "10px" }}
                    value={item.text}
                    onChange={(e) =>
                      handleAnalysisChange(index, e.target.value)
                    }
                    onKeyPress={(e) => handleKeyPress(e, index)}
                  />
                ))}
              </div>
            </>
          ) : (
            <div>
              <Subheading subheading={tempConfig.analysis.heading} />
              <Analysis
                trends={tempConfig.analysis.data}
                edit_mode={edit}
                section_id={Base64.encode(
                  `${tempConfig.analysis.heading}custom slide${Math.random}`
                )}
              />
            </div>
          )}
        </Column>
      </Row>

      {edit && (
        <button
          onClick={() =>
            onSave(data._id, {
              ...tempConfig,
              graphs: [tempConfig.graphs],
              analysis: [tempConfig.analysis],
            })
          }>
          Save
        </button>
      )}
    </>
  );
}

export default CustomTempOne;
