import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import key_industry_trends_hidden from "../../../images/key_industry_trends_hidden.svg";
import { LubricantVCWrapper } from "./styles";

var Base64 = require("js-base64").Base64;

export const ValueChainDemoData = {
  slideReference: "2. KEY INDUSTRY TRENDS",
  id: "2.5 VALUE CHAIN & DISTRIBUTION CHANNEL ANALYSIS",
  title: "2.5 VALUE CHAIN & DISTRIBUTION CHANNEL ANALYSIS",
  slug: "North America Enzymes Market",
  slideName: "valuechain",
};

export const ValueChainDemoData2 = {
  title: "Value Chain & Distribution Channel Analysis",
  subtitle: "Value Chain & Distribution Channel Analysis",
  type: "valueChain",
  section_id: "",
  template: "valueChainlubricant",
  url: "https://s3.mordorlabs.com/Value%20Chain/1625051083229_test~Value_Chain_Value_Chain.svg",
  data: [
    {
      type: "Base Oil Companies",
      list: [
        "Chevron Corporation",
        "Holly Frontier",
        "Exxon Mobil Corporation",
      ],
    },
    {
      type: "Additive Companies",
      list: [
        "Afton Chemical",
        "The Lubrizol Corporation",
        "Chevron Corporation",
      ],
    },
    {
      type: "Lubricant Companies",
      list: ["Royal Dutch Shell Plc", "BP p.l.c.", "Valvoline Inc."],
    },
    {
      type: "OEMs",
      list: [
        "Honda Motor Co.",
        "Ltd.",
        "Ford Motor Company",
        "Daimler Trucks North America LLC",
      ],
    },
    {
      type: "Distributors",
      list: [
        "U.S. Venture",
        "Inc.",
        "Brenntag SE",
        "Comercial Importadora",
        "(CISA)",
      ],
    },
  ],
};

class LubricantVC extends React.Component {
  state = {
    data: this.props.data ? this.props.data : ValueChainDemoData2,
    section1_data: [],
    section2_data: [],
    section3_data: [],
    reseller: false,
  };

  componentDidMount = () => {
    this.splitToChunks();
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.meta) {
      if (props.data.meta.sample && props.data.meta.reseller) {
        return {
          reseller: true,
        };
      }
    }
    return null;
  }

  splitToChunks = () => {
    const { data } = this.props.data ? this.props.data : this.state.data;
    let section1_data = data && data.length > 1 ? data.slice(0, 2) : [];
    let section2_data = data && data.length > 1 ? data.slice(2, 3) : [];
    let section3_data = data && data.length > 1 ? data.slice(3, 5) : [];
    this.setState({
      section1_data,
      section2_data,
      section3_data,
    });
  };

  render() {
    const { section_id } = this.state.data;
    const { section1_data, section2_data, section3_data } = this.state;
    const { slideNo } = this.props;
    const slug = this.state.data.slug;
    const pageId = Base64.encode(section_id);
    const { multi_urls, url } = this.state.data;

    return (
      <div className="page" id={pageId}>
        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Header slideTitle={this.state.data.type} />
        ) : null}
        <LubricantVCWrapper
          fromInterface={
            !this.props.fromInterface || this.props.fromInterface === undefined
              ? true
              : false
          }
        >
          <h2 className="heading valuechain_title">
            {this.props.data ? this.props.data.title : this.state.data.title}
          </h2>

          <div style={{ height: "560px", position: "relative" }}>
            {Array.isArray(multi_urls) && multi_urls?.length ? (
              multi_urls.map((item) => (
                <img
                  src={item.url}
                  alt="Value Chain"
                  style={{ width: "100%", height: "100%", marginTop: "10px" }}
                />
              ))
            ) : (
              <img
                src={url}
                alt="Value Chain"
                style={{ width: "100%", height: "100%", marginTop: "10px" }}
              />
            )}

            {section1_data.slice(0, 1).map((item) =>
              this.state.data &&
              this.state.data.meta &&
              !this.state.data.meta.sample ? (
                <div className="section1_list1">
                  {item.list.map((elem) => (
                    <div className="font_design">
                      <div>•</div>
                      <div style={{ marginLeft: "5px" }}>{elem}</div>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )
            )}

            {section1_data.slice(1, 2).map((item) =>
              this.state.data &&
              this.state.data.meta &&
              !this.state.data.meta.sample ? (
                <div className="section1_list2">
                  {item.list.map((elem) => (
                    <div className="font_design">
                      <div>•</div>
                      <div style={{ marginLeft: "5px" }}>{elem}</div>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )
            )}

            {section2_data.map((item) =>
              this.state.data &&
              this.state.data.meta &&
              !this.state.data.meta.sample ? (
                <div className="section2_list">
                  {item.list.map((elem) => (
                    <div className="font_design">
                      <div>•</div>
                      <div style={{ marginLeft: "5px" }}>{elem}</div>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )
            )}

            {section3_data.slice(0, 1).map((item) =>
              this.state.data &&
              this.state.data.meta &&
              !this.state.data.meta.sample ? (
                <div className="section3_list1">
                  {item.list.map((elem) => (
                    <div className="font_design">
                      <div>•</div>
                      <div style={{ marginLeft: "5px" }}>{elem}</div>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )
            )}

            {section3_data.slice(1, 2).map((item) => (
              <div className="section3_list2">
                {item.list.map((elem) => (
                  <div className="font_design">
                    <div>•</div>
                    <div style={{ marginLeft: "5px" }}>{elem}</div>
                  </div>
                ))}
              </div>
            ))}
          </div>

          {!this.props.fromInterface ||
          this.props.fromInterface === undefined ? (
            <Footer
              slug={slug}
              slideNo={slideNo}
              reseller={this.state.reseller}
            />
          ) : null}
        </LubricantVCWrapper>
      </div>
    );
  }
}

export default LubricantVC;
