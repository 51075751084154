import React, { Component } from "react";
import {
  MobileOutlined,
  MailFilled,
  PrinterFilled,
  HomeFilled,
} from "@ant-design/icons";
import Footer from "../components/Footer";
import { KeyStrategicThemesCEOSWrapper } from "../styles/KeyStrategicCEOS.style";

var Base64 = require("js-base64").Base64;

export const KeyStrategicThemesForFeedAdditiveCEOSDemoData = {
  title: "Key Strategic Themes For Feed Additive CEOS",
  slideName: "KeyStrategicThemesForFeedAdditiveCEOS",
  id: "KEY STRATEGIC THEMES FOR FEED ADDITIVE CEOS",
};

class KeyStrategicThemesForFeedAdditiveCEOS extends Component {
  state = {
    data: this.props.data || KeyStrategicThemesForFeedAdditiveCEOSDemoData,
    reseller: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.meta) {
      if (props.data.meta.sample && props.data.meta.reseller) {
        return {
          reseller: true,
        };
      }
    }
    return null;
  }

  render() {
    let { section_id, slug } = this.state.data;
    const str = this.state.data.title;
    const { slideNo } = this.props;

    const slide_title =
      str.slice(0, str.length - 1).toUpperCase() +
      str.charAt(str.length - 1).toLowerCase();

    const pageId = section_id ? Base64.encode(section_id) : undefined;
    let slideNoElementId;

    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }

    return (
      <div className="page KeyStrategicThemesForFeedAdditiveCEOS" id={pageId}>
        <KeyStrategicThemesCEOSWrapper
          fromInterface={
            !this.props.fromInterface || this.props.fromInterface === undefined
              ? true
              : false
          }
        >
          {!this.props.fromInterface ||
          this.props.fromInterface === undefined ? (
            <header className="key_strategic_header">
              <a href={"#" + Base64.encode("toc")}>
                <HomeFilled
                  style={{
                    color: "#189CDE",
                    padding: "0 20px",
                    fontSize: "20px",
                  }}
                />
              </a>
            </header>
          ) : null}

          <main className="key_strategic_container">
            <div className="key_strategic_title">
              <h1>{this.state.data.title}</h1>
            </div>

            {Array.isArray(this.state.data?.url) &&
            this.state.data?.url?.length ? (
              <img src={this.state.data.url[0].url} alt={slide_title} />
            ) : (
              <img src={this.state.data.url} alt={slide_title} />
            )}

            <div className="key_strategic_footer">
              {this.state.reseller ? (
                ""
              ) : (
                <div className="KeyStrategicThemesForFeedAdditiveCEOSContact">
                  <div className="heading">For more information:</div>
                  <div className="contact contact1">
                    <MobileOutlined />
                    +1 617 765 2493
                  </div>
                  <div className="contact contact2">
                    <MailFilled />
                    info@mordorintelligence.com
                  </div>
                  <div className="contact contact3">
                    <PrinterFilled />
                    www.mordorintelligence.com
                  </div>
                </div>
              )}
            </div>
          </main>

          {!this.props.fromInterface ||
          this.props.fromInterface === undefined ? (
            <Footer
              slug={slug}
              slideNo={slideNo}
              reseller={this.state.reseller}
            />
          ) : null}
        </KeyStrategicThemesCEOSWrapper>
      </div>
    );
  }
}

export default KeyStrategicThemesForFeedAdditiveCEOS;

/* { this.state.reseller ? "" : <div style={{ color: "white", padding:"5px 40px", marginBottom: "-15px" }}>
  Mordor Intelligence's customized consulting solution can help you
  provide answers to these questions, based on your company's internal
  strategy.
</div>} */
