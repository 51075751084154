import styled from "styled-components";

export const Page = styled.div`
  display: block;
  page-break-after: always;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 50px;
  height: ${({ formInterface }) => (formInterface ? "auto" : "626px")};
  width: 100%;
`;
