import React, { useEffect, useState } from "react";
import { DocumentationComponentWrapper } from "./DocumentationComponent.style";
import TOC from "./TOC";
import { Select, notification } from "antd";
import axios from "axios";
import configApi from "../../config/Api";
import InterfaceHeader from "../InterfaceHeader";
import comingSoonImg from "../../static/coming-soon.webp";

const data = [
  {
    _id: "1",
    title: "Executive Summary & Key Findings",
    content: "Executive Summary & Key Findings",
    children: [],
  },
  {
    _id: "2",
    title: "Report Offers",
    content: "Report Offers",
    children: [],
  },
  {
    _id: "3",
    title: "Introduction",
    content: "Introduction",
    children: [
      {
        _id: "3.1",
        title: "Study Assumptions & Market Definition",
        content: "Study Assumptions & Market Definition",
        children: [],
      },
      {
        _id: "3.2",
        title: "Scope of the Study",
        content: "Scope of the Study",
        children: [],
      },
      {
        _id: "3.3",
        title: "Research Methodology",
        content: "Research Methodology",
        children: [],
      },
    ],
  },
  {
    _id: "4",
    title: "Key Industry Trends",
    content: "Key Industry Trends",
    children: [
      {
        _id: "4.1",
        title: "End Use Sector Trends​",
        content: "End Use Sector Trends​",
        children: [
          {
            _id: "4.1.1",
            title: "Commercial",
            content: "Commercial",
            children: [],
          },
          {
            _id: "4.1.2",
            title: "Industrial and Institutional",
            content: "Industrial and Institutional",
            children: [],
          },
          {
            _id: "4.1.3",
            title: "Infrastructure",
            content: "Infrastructure",
            children: [],
          },
          {
            _id: "4.1.4",
            title: "Residential",
            content: "Residential",
            children: [],
          },
        ],
      },
      {
        _id: "4.2",
        title: "Major Infrastructure Projects (current And Announced)",
        content: "Major Infrastructure Projects (current And Announced)",
        children: [],
      },
      {
        _id: "4.3",
        title: "Regulatory Framework",
        content: "Regulatory Framework",
        children: [
          {
            _id: "4.3.1",
            title: "India",
            content: "India",
            children: [],
          },
          {
            _id: "4.3.2",
            title: "India",
            content: "India",
            children: [],
          },
        ],
      },
      {
        _id: "4.4",
        title: "Value Chain & Distribution Channel Analysis",
        content: "Value Chain & Distribution Channel Analysis",
        children: [],
      },
    ],
  },
  {
    _id: "5",
    title: "Market Segmentation",
    content: "Market Segmentation",
    children: [
      {
        _id: "5.1",
        title: "Market Snapshot",
        content: "Market Snapshot",
        children: [],
      },
      {
        _id: "5.2",
        title: "End Use Sector Trends",
        content: "End Use Sector Trends",
        children: [
          {
            _id: "5.2.1",
            title: "Commercial",
            content: "Commercial",
            children: [],
          },
          {
            _id: "5.2.2",
            title: "Industrial and Institutional",
            content: "Industrial and Institutional",
            children: [],
          },
          {
            _id: "5.2.3",
            title: "Infrastructure",
            content: "Infrastructure",
            children: [],
          },
          {
            _id: "5.2.4",
            title: "Residential",
            content: "Residential",
            children: [],
          },
        ],
      },
      {
        _id: "5.3",
        title: "Region",
        content: "Region",
        children: [
          {
            _id: "5.3.1",
            title: "Europe",
            content: "Europe",
            children: [],
          },
          {
            _id: "5.3.2",
            title: "Africa",
            content: "Africa",
            children: [],
          },
        ],
      },
    ],
  },
];

const openNotification = (msg) => {
  let args,
    key = msg;
  if (key === true) {
    args = {
      message: "Success",
      description: "Purge successful",
      duration: 0,
      placement: "bottomLeft",
    };
    notification.success(args);
  } else if (key === false) {
    args = {
      message: "Failure",
      description: "Failed to purge titles",
      duration: 3.5,
      placement: "bottomLeft",
    };
    notification.error(args);
  } else {
    args = {
      message: "Warning",
      description: key,
      duration: 3.5,
      placement: "bottomLeft",
    };
    notification.warning(args);
  }
};

function DocumentationComponent() {
  const [content, setContent] = useState("");
  const [HubWiseContent, setHubWiseContent] = useState("");

  const [selectedSection, setSelectedSection] = useState("");
  const [selectedSHubWiseection, setSelectedHubWiseSection] = useState("");

  const [hubWiseTOC, setHubWiseTOC] = useState([]);
  const [generalTOC, setGeneralTOC] = useState([]);

  const [selectedHub, setSelectedHub] = useState(null);
  const [hubData, setHubData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [onGoingApiCall, setOnGoingApiCall] = useState(false);

  const getHubList = async () => {
    if (!hubData) {
      setLoading(true);
      setOnGoingApiCall(true);
      let full_access = false;
      await axios
        .get(
          `${configApi.api.base_url}/api/hubs/list?full_access=${full_access}`
        )
        .then((response) => {
          if (response.status === 200) {
            setHubData(response.data.obj);
          }
          setLoading(false);
          setOnGoingApiCall(false);
        })
        .catch((error) => {
          openNotification("Unable to load hub list");
          setLoading(false);
          setOnGoingApiCall(false);
        });
    }
  };

  const toTitleCase = (str) => {
    return (str + "").toUpperCase();
  };

  const getHubsDocumentation = async (e) => {
    setLoading(true);
    setOnGoingApiCall(true);
    await axios
      .get(
        `${configApi.api.base_url}/api/additional_routes/documentations/${e}`
      )
      .then((response) => {
        if (response.status === 200) {
          if (e == 0) {
            setGeneralTOC(response.data.data);
          } else setHubWiseTOC(response.data.data);
        }
        setLoading(false);
        setOnGoingApiCall(false);
      })
      .catch((error) => {
        openNotification("Unable to load hub list");
        setLoading(false);
        setOnGoingApiCall(false);
      });
  };

  useEffect(() => {
    getHubList();
    getHubsDocumentation(0);
  }, []);

  // return (
  //   <>
  //     <InterfaceHeader />
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         marginTop: "150px",
  //       }}
  //     >
  //       <img src={comingSoonImg} alt="coming-soon-image" />
  //     </div>
  //   </>
  // );

  return (
    <>
      <InterfaceHeader />
      <DocumentationComponentWrapper>
        <h1>Documentation</h1>

        <div className="accordian-container">
          <h2>General Documentation</h2>
          <div className="accordian-body">
            {loading ? (
              "Loading..."
            ) : (
              <div className="toc-container">
                {generalTOC?.length &&
                  generalTOC.map((item) => (
                    <TOC
                      data={item}
                      setContent={setContent}
                      selectedSection={selectedSection}
                      setSelectedSection={setSelectedSection}
                    />
                  ))}
              </div>
            )}
            <div className="documentation-container">
              <code
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              ></code>
            </div>
          </div>
        </div>

        <h1>Hub Wise Documentation</h1>

        <div className="hub-dropdown">
          <p>
            Select Hub{"    "}
            <span className="mandatory">*</span> :{" "}
          </p>
          <Select
            disabled={onGoingApiCall}
            id="SelectHub"
            placeholder="Select Hub"
            defaultValue="Select Hub"
            value={selectedHub}
            loading={loading}
            onSelect={(e) => {
              setSelectedHub(hubData.filter((item) => item.id == e)[0].name);
              getHubsDocumentation(e);
              setHubWiseContent("");
            }}
            options={
              hubData
                ?.filter((item, index) => item.name !== "ZEROHUB")
                ?.map((option) => ({
                  value: option.id,
                  label: toTitleCase(option.name),
                })) || []
            }
          />
        </div>

        {selectedHub && (
          <div className="accordian-container">
            <>
              <h2>{selectedHub} Hub Documentation</h2>
              <div className="accordian-body">
                {loading ? (
                  "Loading..."
                ) : (
                  <div className="toc-container">
                    {hubWiseTOC?.length
                      ? hubWiseTOC.map((item) => (
                          <TOC
                            data={item}
                            setContent={setHubWiseContent}
                            selectedSection={selectedSHubWiseection}
                            setSelectedSection={setSelectedHubWiseSection}
                          />
                        ))
                      : "No Documentation Found"}
                  </div>
                )}
                <div className="documentation-container">
                  <code
                    dangerouslySetInnerHTML={{
                      __html: `${HubWiseContent}`,
                    }}
                  ></code>
                </div>
              </div>
            </>
          </div>
        )}
      </DocumentationComponentWrapper>
    </>
  );
}

export default DocumentationComponent;
