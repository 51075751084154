import React from "react";

export default function CardComponent(props) {
  const strHide = (val) => {
    if (val.includes("XX")) {
      return val;
    } else {
      return parseInt(val).toLocaleString("en-US");
    }
  };
  return (
    <>
      {props.type === "card-4" ? (
        <div className="stats-card" style={{ background: props.bgColor }}>
          <div className="heading">{strHide(props.heading)}</div>
          <div className="stats_content_card">{props.text}</div>
        </div>
      ) : props.type === "card-2" ? (
        <div className="stats-card" style={{ background: props.bgColor }}>
          <div className="heading">{props.heading}</div>
          <div className="stats_content_card">{props.text}</div>
        </div>
      ) : props.type === "card-3" ? (
        <div className="stats-card" style={{ background: props.bgColor }}>
          <div className="white_heading" style={{ margin: "8px" }}>
            {props.heading}
          </div>
          <div className="info_content_card" style={{ margin: "8px" }}>
            {props.text}
          </div>
        </div>
      ) : (
        <div className="stats-card">
          <div className="heading">
            {props.revenue?.includes("XX") ? "XX" : props.revenue}{" "}
            {props.revenue?.includes("XX") ? "" : props.currencyDenominator}
          </div>
          <div className="stats_content_card">
            <p>Global Revenue in {props.currency}</p>
            <p>FY {props.year} ( All Segments )</p>
          </div>
        </div>
      )}
    </>
  );
}
