import React, { Component } from "react";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";
import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";
import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";
import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";

import { COLORS } from "../graphs.configs";
import {
  roundOfMaxYAxisValue,
  parseDataForMSLine,
  breakLabels,
} from "../graphs.helperFunctions";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);
// FusionCharts.options.export.useCanvas = "true";

export default class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxValue: 0,
      total_legend_length: "",
      max_legend_length: 0,
      datas: "",
      button_click: false,
      loading: false,
      loadingData: false,
      data: [
        {
          seriesname: "Food Products",
          data: [
            {
              value: "121000",
            },
            {
              value: "135000",
            },
            {
              value: "123500",
            },
            {
              value: "145000",
            },
          ],
        },
        {
          seriesname: "Non-Food Products",
          data: [
            {
              value: "131400",
            },
            {
              value: "154800",
            },
            {
              value: "98300",
            },
            {
              value: "131800",
            },
          ],
        },
      ],
      catagory: [
        {
          label: "Q1",
          showValue: true,
        },
        {
          label: "Q2",
        },
        {
          label: "Q3",
        },
        {
          label: "Q4",
        },
      ],
    };
    this.bytesToString = this.bytesToString.bind(this);
  }

  componentDidMount() {
    if (this.props.data && this.props.data.data) {
      let datas = this.props.data.data;
      let arr = parseDataForMSLine(this.props.data.data);
      let maxValue = roundOfMaxYAxisValue(arr[0] * 1.7);
      let catagory = this.props.data.catagory,
        new_data = [...this.props.data.data];
      let total_legend_length = "",
        max_legend_length = 0;
      new_data = new_data.map((item) => {
        if (item.seriesname.length > max_legend_length)
          max_legend_length = item.seriesname.length;
        item.seriesname = breakLabels(item.seriesname, 30); // this.bytesToString(item.seriesname, 35);
        if (this.props.data.data.length <= 3)
          total_legend_length += item.seriesname;
        return item;
      });

      this.setState({
        datas: datas,
        loadingData: false,
        data: new_data,
        catagory: catagory,
        maxValue,
        total_legend_length,
        max_legend_length,
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState &&
      JSON.stringify(prevState.data) !== JSON.stringify(this.props.data.data)
    ) {
      let datas = this.props.data.data;
      let arr = parseDataForMSLine(this.props.data.data);
      let maxValue = roundOfMaxYAxisValue(arr[0]);
      let catagory = this.props.data.catagory,
        new_data = [...this.props.data.data];
      let total_legend_length = "",
        max_legend_length = 0;
      new_data = new_data.map((item) => {
        if (item.seriesname.length > max_legend_length)
          max_legend_length = item.seriesname.length;
        item.seriesname = item.seriesname.replace(/<br\/>/g, "");
        item.seriesname = breakLabels(item.seriesname, 30); // this.bytesToString(item.seriesname, 35);
        if (this.props.data.data.length <= 3)
          total_legend_length += item.seriesname;
        return item;
      });

      this.setState({
        datas: datas,
        loadingData: false,
        data: new_data,
        catagory: catagory,
        maxValue,
        total_legend_length,
        max_legend_length,
      });
    }
  }

  bytesToString(d, width) {
    let str = "";
    let computed_array = [];

    let string_arr = d.trim().split(" ");
    for (let item of string_arr) {
      if ((item + str).length >= width) {
        computed_array.push(str);
        str = "";
      }
      str += " " + item;
    }
    if (str !== "") computed_array.push(str);

    let caption = "";
    for (let i = 0; i < computed_array.length; i++) {
      if (i !== computed_array.length - 1)
        caption += computed_array[i] + "<br/>";
      else caption += computed_array[i];
    }
    return caption;
  }

  render() {
    let diff = 0,
      avgDiff = 0,
      yMinVal = 0,
      yMaxVal = 0,
      valArr = [],
      data = "",
      catagory = "",
      rotateValues = 0,
      legendsPositionDynamic = false,
      max_legend_length = 0,
      valueFontSize = 12,
      width = 740,
      height = 220,
      id = "1",
      legendLength = 14,
      headers = "";

    if (this.props?.data?.data) {
      let vall = this.props?.data?.data;
      for (let i = 0; i < vall.length; i++) {
        for (let j = 0; j < vall[i].data.length; j++) {
          valArr.push(vall[i].data[j]);
        }
      }

      valArr = valArr.sort((a, b) => {
        return a.value - b.value;
      });

      yMinVal = valArr[0].value;
      yMaxVal = valArr[valArr.length - 1].value;

      for (let i = 0; i < valArr.length - 2; i++) {
        diff = diff + (valArr[i + 1].value - valArr[i].value);
      }
      avgDiff = diff / (valArr.length - 2);
      yMinVal = yMinVal - avgDiff;

      if (yMinVal - avgDiff < 0 && valArr[0].value > 0) {
        yMinVal = 0;
      } else {
        yMinVal = yMinVal - avgDiff;
      }
      yMaxVal = yMaxVal + avgDiff;
    }

    data = this.state.data;
    catagory = this.state.catagory;

    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (this.props.headers) headers = this.props.headers;
    if (this.props.valueFontSize) valueFontSize = this.props.valueFontSize;
    if (this.props.rotateValues === 1) rotateValues = this.props.rotateValues;
    if (this.props.legendsPositionDynamic)
      legendsPositionDynamic = this.props.legendsPositionDynamic;
    if (this.props.id) id = this.props.id;

    if (data.length > 5) legendLength = 11;
    if (valueFontSize === 11) legendLength = data.length > 5 ? 11 : 14;
    if (legendsPositionDynamic) legendLength = 11;
    if (this.state.max_legend_length < 35)
      max_legend_length = 35 - this.state.max_legend_length;

    let chartConfigs = {
      type: "msline",
      dataFormat: "json",
      width: width,
      height: height,
      id: id,
      dataSource: {
        chart: {
          theme: "fusion",
          animation: false,
          showanchors: 1,

          formatNumberScale: 1,
          numberScaleValue: "1000,1000,1000",
          numberScaleUnit: "k,M,B",

          adjustDiv: "0",
          numDivLines: "4",
          divLineColor: "#E5E5E5",
          divLineAlpha: "80",
          divLineDashed: "0",

          decimals: 3,
          paletteColors: COLORS,
          showPlotBorder: "0",
          minPlotHeightForValue: 10,
          plotSpacePercent: 40,

          showValues: false,
          valueFont: "Avenir Heavy",
          valueFontSize: valueFontSize,
          valueFontBold: true,
          placeValuesInside: "0",
          valueFontColor: "#002F75",
          rotateValues: rotateValues,

          showsum: !this.props.dataHidden,
          sumPadding: "15",

          labelFontSize: 14,
          labelFontColor: "#7f7f7f",

          legendItemFont: "Avenir Medium",
          legendItemFontColor: "#7f7f7f",
          legendPosition: "bottom",
          legendXPosition: this.props.legendsXPosition
            ? this.props.legendsXPosition
            : width <= 566
              ? 370
              : 370 + max_legend_length,
          legendYPosition: 55 + this.props.legendsYPosition,
          legendItemFontSize: legendLength,
          legendIconBorderThickness: 5,

          showYAxisLine: !this.props.dataHidden,
          yAxisLineColor: "#4A90E2",
          yAxisNamePadding: 0,
          showYAxisValues: !this.props.dataHidden,
          yAxisValueFontSize: 14,
          yAxisValueDecimals: 1,
          yAxisValueFontColor: "#7f7f7f",
          yAxisLineThickness: 1,
          numbersuffix: this.props?.yAxisPercentage ? "%" : "",
          yAxisMinValue:
            (this.props?.yAxisPercentage || this.props?.maxMin) && `${yMinVal}`,
          // yAxisMaxValue: (this.props?.yAxisPercentage || this.props?.maxMin) && `${yMaxVal}`,

          showXAxisLine: !this.props.dataHidden,
          xAxisLineColor: "#4A90E2",
          xAxisLineThickness: 1,
          showXAxisValues: true,
          xAxisValueFontSize: 14,
          xAxisNameFont: "Avenir Medium",
          xAxisNameFontColor: "#444444",
          centerXAxisName: false,

          chartTopMargin: 60 + this.props.chartHeadingGap,
          chartLeftMargin: 4,

          baseFont: "Avenir Medium",
          baseFontSize: 14,
          baseFontColor: "#7f7f7f",
        },
        annotations: {
          autoScale: "1",
          groups: [
            {
              id: "caption",
              items: [
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#002F75",
                  fontsize: 16,
                  font: "Avenir Medium",
                  text: (headers + "").toUpperCase(),
                  bold: "0",
                  wrap: "1",
                  wrapWidth: `${525 + this.props.wrapHeadingWidth}`,
                  x: `${this.props.xHeadingMargin}`,
                  y: `${this.props.yHeadingMargin}`,
                },
              ],
            },
          ],
        },
        categories: [
          {
            category: catagory,
          },
        ],
        dataset: data,
      },
    };

    if (legendsPositionDynamic) {
      chartConfigs["dataSource"]["chart"] = {
        ...chartConfigs["dataSource"]["chart"],
        legendPosition: "absolute",
        legendNumColumns: 1,
        chartRightMargin: this.state.max_legend_length > 35 ? 220 : 200,
      };
    } else if (!legendsPositionDynamic && width > 566) {
      chartConfigs["dataSource"]["chart"] = {
        ...chartConfigs["dataSource"]["chart"],
        legendPosition: "absolute",
        legendNumColumns: 1,
        chartRightMargin: 310 - max_legend_length,
        legendXPosition: 980 + max_legend_length,
      };
    } else if (!legendsPositionDynamic) {
      chartConfigs["dataSource"]["chart"] = {
        ...chartConfigs["dataSource"]["chart"],
        legendPosition: "absolute",
        legendNumColumns: 1,
        legendXPosition: 335 + max_legend_length,
        chartRightMargin: 310 - max_legend_length,
      };
    }

    return (
      <div>
        <ReactFC {...chartConfigs} />
      </div>
    );
  }
}
