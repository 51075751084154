import React, { useState, useEffect } from "react";
import Analysis from "../components/Analysis";
import Subheading from "../components/Subheading";
import ExecutiveSummaryCard from "../components/ExecutiveSummary/ExecutiveSummaryCard";
import SourcesEditor from "../components/Editor";
import { Tabs, Button, Spin } from "antd";
import { Input } from "antd";

import { setDraftDataHeading } from "../reducersAndActions/Actions";

const { TabPane } = Tabs;

export function theConsole(API, data) {
  console.table(data);
}

export function editorTabChange(tab, func) {
  func((prevState) => {
    return {
      editor_active_tab: tab,
    };
  });
}

export const handleSaveDraft = (props, item) => {
  let headingData;

  if (props.analysis_heading_editor_state_for_draft === null) {
    headingData = item.data.meta.heading;
  }
  // else if(props.analysis_heading_editor_state_for_draft === ""){
  //   headingData = null;
  // }
  else {
    headingData = props.analysis_heading_editor_state_for_draft;
  }

  const data = {
    parent_id: props.parent_id,
    type: props.template_type,
    analysis: props.analysis_editor_state,
    source: props.sources_editor_state,
    stage: props.draft_versions.stage ? props.draft_versions.stage : "INITIAL",
    hub_id: props.TOC_DATA.hub_id,
    dimensions: props.TOC_DATA.dimensions,
    heading: headingData,
  };

  theConsole("Save Draft heading", data);
  props.saveDraftApi(data);
  props.setAnalysisHeadingEditorState("");
};

export const handleDirectPublish = (props, item) => {
  let headingData;
  if (props.analysis_heading_editor_state_for_published === null) {
    headingData = item.data.meta.heading;
  } else if (props.analysis_heading_editor_state_for_published === "") {
    headingData = null;
  } else {
    headingData = props.analysis_heading_editor_state_for_published;
  }

  const data = {
    parent_id: props.parent_id,
    analysis: props.analysis_editor_state,
    source: props.sources_editor_state,
    directPublished: true,
    type: props.template_type,
    stage: "PUBLISHED",
    cacheId: props.cache_id,
    hub_id: props.TOC_DATA.hub_id,
    dimensions: props.TOC_DATA.dimensions,
    heading: headingData,
  };
  theConsole("Direct Publish", data);
  props.directPublishApi(data);
  props.setAnalysisHeadingEditorState("");
};

export const handleEditoverlayClick = (props) => {
  props.setNoDraftsFound(false);
  props.setDraftStage("INITIAL");
};

export const generateExecuteSummary = (
  item,
  template = "",
  customStyle = {},
  customClass = null,
  segmentBgcolor = []
) => {
  let cardData = item.data.data;

  if (template === "marketShares") {
    let total = 0;
    cardData.map((item) => {
      total = total + Number(item.value);
    });
    cardData = cardData.map((item) => {
      let val = (Number(item.value) * 100) / total;
      if (val < 0.01) {
        val = ((Number(item.value) * 100) / total).toFixed(3);
      } else {
        val = ((Number(item.value) * 100) / total).toFixed(2);
      }

      return { ...item, value: `${val} %` };
    });
  }

  let collection = cardData.map((ksm, k) => {
    return (
      <ExecutiveSummaryCard
        summaryClass={customClass}
        customStyle={customStyle}
        segmentBgcolor={segmentBgcolor[k]}
        segmentBlockValue={ksm.value}
        segmentBlockLabel={ksm.label}
      />
    );
  });

  return collection;
};

export const tabsElement = (item, data, state, props, func, charCount) => {
  let headingValue;

  if (props.ACTIVE_TAB === "published") {
    headingValue =
      props.analysis_heading_editor_state_for_published !== null
        ? props.analysis_heading_editor_state_for_published
        : props?.draft_data_heading ||
          (item?.data?.meta.hasOwnProperty("heading")
            ? item.data.meta.heading
            : "");
  } else if (props.ACTIVE_TAB === "drafts") {
    headingValue =
      props.analysis_heading_editor_state_for_draft !== null
        ? props.analysis_heading_editor_state_for_draft
        : props?.draft_data_heading ||
          (item?.data?.meta.hasOwnProperty("heading")
            ? item.data.meta.heading
            : "");
  }

  let setHeading = (e) => {
    e.preventDefault();

    if (props.ACTIVE_TAB === "published") {
      props.setAnalysisHeadingEditorStateForPublished(e.target.value);
    } else if (props.ACTIVE_TAB === "drafts") {
      props.setAnalysisHeadingEditorStateForDraft(e.target.value);
    }
    props.setAnalysisHeadingEditorState(e.target.value);
  };

  return (
    <>
      <div style={{ marginBottom: "15px" }}>
        {["typedetail", "typeoverview", "indicator"].includes(
          props.template
        ) ? (
          props.edit_mode ? (
            <>
              <p>
                Key Takeaway{" "}
                <p style={{ fontSize: 8, color: "red" }}>
                  {" "}
                  * All Caps not Allowed{" "}
                </p>
              </p>
              <Input
                placeholder="Key Takeaway not added yet..."
                value={headingValue}
                onChange={setHeading}
              />
            </>
          ) : item.data?.meta?.heading ? (
            <Subheading subheading={item.data.meta.heading} />
          ) : (
            ""
          )
        ) : item.data?.meta?.heading ? (
          <Subheading subheading={item.data.meta.heading} />
        ) : (
          ""
        )}
      </div>

      {
        // this.props.USER_DATA.obj.designation !== "QC" &&
        state.editor_active_tab === "analysis" ? (
          <p>
            Character Count: {props.analysis_char_count}/{charCount}
          </p>
        ) : (
          ""
        )
      }

      <Tabs
        activeKey={state.editor_active_tab}
        tabPosition={state.tabPosition}
        onChange={(tab) => func.editorTabChange(tab, func.setState)}
        tabBarExtraContent={
          <div style={{ display: "flex", gap: "20px" }}>
            {props.ACTIVE_TAB === "drafts" ? (
              <Button
                type="primary"
                onClick={() => {
                  localStorage.removeItem("autoSaveData");
                  func.handleSaveDraft(props, item);
                  //clear localStorage autoSaveData
                }}
                loading={props.save_draft_loader}
                disabled={props.character_count_error}
              >
                Save
              </Button>
            ) : (
              ""
            )}
            {props.ACTIVE_TAB === "published" ? (
              <Button
                type="primary"
                onClick={() => func.handleDirectPublish(props, item)}
                loading={props.direct_publish_loader}
                disabled={props.character_count_error}
              >
                DIRECT PUBLISH
              </Button>
            ) : (
              ""
            )}
          </div>
        }
        type="card"
        style={{ height: "100%" }}
      >
        <TabPane tab="Analysis" key="analysis" forceRender={true}>
          {props.specific_draft_loading ? (
            <Spin style={{ display: "flex", justifyContent: "center" }} />
          ) : (
            <>
              <Analysis
                trends={item.data}
                edit_mode={props.edit_mode}
                section_id={`${data.section_id}-analysis`}
                character_count={`${charCount}`}
              />
            </>
          )}
        </TabPane>
        <TabPane tab="Sources" key="sources" forceRender={true}>
          {/* Please enter list of Sources */}
          {props.specific_draft_loading ? (
            <Spin style={{ display: "flex", justifyContent: "center" }} />
          ) : (
            <SourcesEditor
              section_id={`${data.section_id}-source`}
              sources={props.data.source}
            />
          )}
        </TabPane>
      </Tabs>
    </>
  );
};
