import styled from "styled-components";

export const SignupWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("https://i.imgur.com/FA3xCOx.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100vh;
  .login-form {
    width: 100%;
    max-width: 300px;
    margin: 20px;
    .ant-form-item {
      margin-bottom: 18px;
    }
    .login-title {
      color: #fff;
      font-family: "Avenir Roman";
      font-size: 18px;
      margin-bottom: 15px;
      text-align: center;
    }
    .ant-input {
      background-color: transparent;
      border: 1px solid #fff;
      color: #fff;
      font-family: "Avenir Roman";
      border-radius: 3px;
    }
    .ant-input::placeholder {
      color: #fff;
    }
    .buttons {
      display: flex;
      justify-content: center;
      .ant-btn {
        background-color: transparent;
        border: 1px solid #fff;
        color: #fff;
        font-family: "Avenir Roman";
      }
      .signup_login_form_button {
        margin-left: 20px;
      }
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent;
      border: 1px solid #fff;
    }
    .ant-select-selection-placeholder {
      color: #fff;
      font-family: "Avenir Roman";
    }
    .ant-select {
      color: #fff;
      font-family: "Avenir Roman";
    }
    .ant-select-arrow {
      color: #fff;
    }
  }
`;
