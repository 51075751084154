import React from "react";
import { useState, useEffect, useContext, createContext, useMemo } from "react";
import moment from "moment";
import {
  Button,
  Select,
  Radio,
  Spin,
  Tooltip,
  notification,
  Input,
  Checkbox,
} from "antd";
import CellEditor from "../components/CellEditor";
import DateEditor from "../components/DateEditor";
import {
  setCompanyProfileData,
  setCompanyType,
  setUpdateCompanyProfileData,
  setFetchFulldata,
} from "../reducersAndActions/Actions";

import { countries, listOfRegionFilters } from "../countries";
import cloneDeep from "lodash/cloneDeep";
import startCase from "lodash/startCase";
import { useFocusRef } from "../hooks/useFocusRef";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import axios from "axios";
import config from "../config/Api";
import DropDownEditor from "./DropDownEditor";
import CompanyTable, {
  EditableField,
  MultiSelectDropdown,
} from "./CompanyTables";
import { filterFn } from "@tanstack/react-table";

const { Option } = Select;

const options = [
  { label: "Global Company", value: "globalCompany" },
  { label: "Market Company", value: "marketCompany" },
  { label: "Product & Services", value: "productServices" },
  { label: "Recent Development", value: "recentDevelopment" },
];

const FilterContext = createContext(undefined);

function inputStopPropagation(event) {
  if (["ArrowLeft", "ArrowRight"].includes(event.key)) {
    event.stopPropagation();
  }
}

function CompanyProfileEditing(props) {
  const [rows, setRows] = useState([]);
  const [saveLoader, setSaveLoader] = useState([]);
  const [stageChangeLoader, setStageChangeLoader] = useState([]);
  const [directPublishLoader, setDirectPublishLoader] = useState([]);
  const [specificVersionLoader, setSpecificVersionLoader] = useState([]);
  const [deleteLoader, setDeleteLoader] = useState([]);
  const [resetFilter, setResetFilter] = useState(false);

  const [filters, setFilters] = useState({
    company_name: "",
    company_id: "",
    region: "",
    country: [],
    CountriesIncluded: [],
    CountriesExcluded: [],
    end_user: "",
    vehicle_type: "",
    product_type: "",
    product_grade: "",
  });

  const handleDraftVersionSelect = (draft_id, row) => {
    let loader_array_copy = specificVersionLoader.slice();
    loader_array_copy.push(row._id);
    setSpecificVersionLoader(loader_array_copy);
    let template_type =
      props.companyType === "regulatoryFramework2"
        ? "regulatoryFramework"
        : props.companyType;
    //specific draft api call
    axios({
      method: "GET",
      url: `${config.api.base_url}/api/editing/drafts?parent_id=${row._id}&type=${template_type}&draftId=${draft_id}`,
    })
      .then((response) => {
        setSpecificVersionLoader([]);
        if (response.status === 200 || response.status === 201) {
          notification.success({
            message: "Info",
            description: "Draft version fetched successfully",
          });
          let myArray = cloneDeep(props.company_profile_data);

          let objIndex = myArray.findIndex(
            (obj) => obj._id === response.data._id
          );

          let drafts_obj = myArray[objIndex].drafts; // Copy drafts key because its not coming from response
          myArray[objIndex] = response.data;
          myArray[objIndex].drafts = drafts_obj; // Append the stored drafts key
          myArray[objIndex].latest_version_id = draft_id;
          props.setCompanyProfileData(myArray);
        }
      })
      .catch((error) => {
        setSpecificVersionLoader([]);
        if (error.response) {
          notification.error({
            message: "Error",
            description: error.response.data.message,
          });
        }
      });
  };

  const handlePublishedVersionSelect = (publish_id, row) => {
    let loader_array_copy = specificVersionLoader.slice();
    loader_array_copy.push(row._id);
    setSpecificVersionLoader(loader_array_copy);
    axios({
      method: "GET",
      url: `${config.api.base_url}/api/editing/publish?publish_id=${publish_id}`,
    })
      .then((response) => {
        setSpecificVersionLoader([]);
        if (response.status === 200 || response.status === 201) {
          notification.success({
            message: "Info",
            description: "Published version fetched successfully",
          });

          let myArray = cloneDeep(props.company_profile_data);

          let objIndex = myArray.findIndex(
            (obj) => obj._id === response.data.parent_id
          );

          let published_versions_obj = myArray[objIndex].published; // Copy published versions key because its not coming from response
          myArray[objIndex] = renameKeys(response.data, { parent_id: "_id" }); // rename response parent_id key to _id
          myArray[objIndex].published = published_versions_obj; // Append the stored published versions key
          myArray[objIndex].latest_version_id = publish_id;
          props.setCompanyProfileData(myArray);
        }
      })
      .catch((error) => {
        setSpecificVersionLoader([]);
        if (error.response) {
          notification.error({
            message: "Error",
            description: error.response.data.message,
          });
        }
      });
  };

  const handleStageChange = (stage, row) => {
    let loader_array_copy = stageChangeLoader.slice();
    loader_array_copy.push(row._id);
    setStageChangeLoader(loader_array_copy);

    const data = {
      type: props.companyType,
      stage: stage,
      parent_id: row._id,
      draftId: row.latest_version_id,
      cacheId: localStorage.getItem("companyProfileCached")
        ? JSON.parse(localStorage.getItem("companyProfileCached"))[
            row.company_id
          ]
        : "",
    };

    /* CHECK IF USER HAS ACCESS */
    if (props.USER_DATA.obj.access.move.includes(stage || "INITIAL")) {
      axios({
        method: "PATCH",
        url: `${config.api.base_url}/api/editing/drafts`,
        data: data,
      })
        .then((response) => {
          // if (stage !== "PUBLISHED") {
          //Toggle to edit mode to fetch the updated stage except for PUBLISHED stage
          props.setUpdateCompanyProfileData(!props.update_company_profile_data);
          // } else {
          //   props.handleSwitchchange(false);
          // }
          setStageChangeLoader([]);
          if (response.status === 200 || response.status === 201) {
            // throw new Error('something went wrong');
            notification.success({
              message: "Info",
              description: response.data.message,
            });
          }
        })
        .catch((error) => {
          setStageChangeLoader([]);
          if (error.response) {
            notification.error({
              message: "Error",
              description: error.response.data.message,
            });
          }
        });
    } else {
      notification.warning({
        message: "Info",
        description: "You dont have access to move stage",
      });
    }
  };

  const handleMarketCompanySave = (row, company_profile_dimensions) => {
    let loader_array_copy = saveLoader.slice();
    loader_array_copy.push(row._id);
    setSaveLoader(loader_array_copy);
    const data = {
      parent_id: row._id,
      type: "marketCompany",
      obj: {
        _id: row._id,
        dimensions: Object.keys(company_profile_dimensions).reduce(
          (acc, key) => {
            acc[key] = row[key];
            return acc;
          },
          {}
        ),
        company_id: row.company_id,
        company_name: row.company_name,
        company_highlights: [
          row.company_highlights_1,
          row.company_highlights_2,
          row.company_highlights_3,
          row.company_highlights_4,
        ].filter(Boolean),
        key_information: [
          row.key_information_1,
          row.key_information_2,
          row.key_information_3,
          row.key_information_4,
        ].filter(Boolean),
        company_vertical: row.company_vertical,
        market_segment: row.market_segment,
        segmental_revenue: row.segmental_revenue,
        "Core Solutions/Product Offerings": [
          row.products_1,
          row.products_2,
          row.products_3,
          row.products_4,
        ].filter(Boolean),
      },
    };
    saveDraftAxiosCall(data, row._id);
  };

  const handleGlobalCompanySave = (row) => {
    let loader_array_copy = saveLoader.slice();
    loader_array_copy.push(row._id);
    setSaveLoader(loader_array_copy);
    const data = {
      parent_id: row._id,
      type: "globalCompany",
      obj: {
        _id: row._id,
        company_id: row.company_id,
        company_name: row.company_name,
        core_business_segments: row.core_business_segment,
        company_overview: [
          row.company_overview_1,
          row.company_overview_2,
          row.company_overview_3,
          row.company_overview_4,
        ].filter(Boolean),
        employees: row.employees,
        financial_year: row.financial_year,
        founded: row.founded,
        headquarters: row.headquarters,
        revenue: row.revenue,
        unit: row.unit,
        source: row.source,
      },
    };
    saveDraftAxiosCall(data);
  };

  const handleRecentDevelopmentSave = (row, company_profile_dimensions) => {
    let loader_array_copy = saveLoader.slice();
    loader_array_copy.push(row._id);
    setSaveLoader(loader_array_copy);
    const data = {
      parent_id: row._id,
      type: "recentDevelopment",
      obj: {
        _id: row._id,
        company_id: row.company_id,
        date: row.date,
        description: row.description,
        dimensions: Object.keys(company_profile_dimensions).reduce(
          (acc, key) => {
            acc[key] = row[key];
            return acc;
          },
          {}
        ),
        type: row.type,
        company_name: row.company_name,
        CountriesIncluded: row.CountriesIncluded,
        CountriesExcluded: row.CountriesExcluded,
      },
    };
    saveDraftAxiosCall(data);
  };

  const handleProductServicesSave = (row, company_profile_dimensions) => {
    let loader_array_copy = saveLoader.slice();
    loader_array_copy.push(row._id);
    setSaveLoader(loader_array_copy);
    const data = {
      parent_id: row._id,
      type: "productServices",
      obj: {
        _id: row._id,
        dimensions: Object.keys(company_profile_dimensions).reduce(
          (acc, key) => {
            acc[key] = row[key];
            return acc;
          },
          {}
        ),
        company_id: row.company_id,
        company_name: row.company_name,
        first_level_brand: row.first_level_brand,
        description: row.description,
        source: row.source,
        CountriesIncluded: row.CountriesIncluded,
        CountriesExcluded: row.CountriesExcluded,
      },
    };
    saveDraftAxiosCall(data);
  };

  const saveDraftAxiosCall = (data) => {
    axios({
      method: "POST",
      url: `${config.api.base_url}/api/editing/drafts`,
      data: data,
    })
      .then((response) => {
        props.setUpdateCompanyProfileData(!props.update_company_profile_data);
        setSaveLoader([]);
        if (response.status === 200 || response.status === 201) {
          notification.success({
            message: "Info",
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
        setSaveLoader([]);
        notification.error({
          message: "Error",
          description: error.response.data.message,
        });
      });
  };

  const handleMarketCompanyDelete = (row) => {
    let loader_array_copy = deleteLoader.slice();
    loader_array_copy.push(row._id);
    setDeleteLoader(loader_array_copy);
    const data = {
      parent_ids: [row._id],
      type: "marketCompany",
    };
    deleteAxiosCall(data, row._id);
  };

  const handleGlobalCompanyDelete = (row) => {
    let loader_array_copy = deleteLoader.slice();
    loader_array_copy.push(row._id);
    setDeleteLoader(loader_array_copy);
    const data = {
      parent_ids: [row._id],
      type: "globalCompany",
    };
    deleteAxiosCall(data, row._id);
  };

  const handleRecentDevelopmentDelete = (row) => {
    let loader_array_copy = deleteLoader.slice();
    loader_array_copy.push(row._id);
    setDeleteLoader(loader_array_copy);
    const data = {
      parent_ids: [row._id],
      type: "recentDevelopment",
    };
    deleteAxiosCall(data, row._id);
  };

  const handleProductServicesDelete = (row) => {
    let loader_array_copy = deleteLoader.slice();
    loader_array_copy.push(row._id);
    setDeleteLoader(loader_array_copy);
    const data = {
      parent_ids: [row._id],
      type: "productServices",
    };
    deleteAxiosCall(data, row._id);
  };

  const deleteAxiosCall = (data) => {
    axios({
      method: "DELETE",
      url: `${config.api.base_url}/api/editing/delete_doc`,
      data: data,
    })
      .then((response) => {
        props.setUpdateCompanyProfileData(!props.update_company_profile_data);
        setDeleteLoader([]);
        if (response.status === 200 || response.status === 201) {
          notification.success({
            message: "Info",
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
        setDeleteLoader([]);
        notification.error({
          message: "Error",
          description: error.response.data.message,
        });
      });
  };

  const directPublishAxiosCall = (data) => {
    axios({
      method: "POST",
      url: `${config.api.base_url}/api/editing/directPublish`,
      data: data,
    })
      .then((response) => {
        props.handleSwitchchange(false);
        setDirectPublishLoader([]);
        if (response.status === 200 || response.status === 201) {
          notification.success({
            message: "Info",
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
        setDirectPublishLoader([]);
        notification.error({
          message: "Error",
          description: error.response.data.message,
        });
      });
  };

  const handleMarketCompanyDirectPublish = (
    row,
    company_profile_dimensions
  ) => {
    let loader_array_copy = directPublishLoader.slice();
    loader_array_copy.push(row._id);
    setDirectPublishLoader(loader_array_copy);

    const data = {
      parent_id: row._id,
      type: "marketCompany",
      stage: "PUBLISHED",
      directPublished: true,
      obj: {
        _id: row._id,
        dimensions: Object.keys(company_profile_dimensions).reduce(
          (acc, key) => {
            acc[key] = row[key];
            return acc;
          },
          {}
        ),
        company_id: row.company_id,
        company_name: row.company_name,
        company_highlights: [
          row.company_highlights_1,
          row.company_highlights_2,
          row.company_highlights_3,
          row.company_highlights_4,
        ].filter(Boolean),
        key_information: [
          row.key_information_1,
          row.key_information_2,
          row.key_information_3,
          row.key_information_4,
        ].filter(Boolean),
        company_vertical: row.company_vertical,
        market_segment: row.market_segment,
        segmental_revenue: row.segmental_revenue,
        "Core Solutions/Product Offerings": [
          row.products_1,
          row.products_2,
          row.products_3,
          row.products_4,
        ].filter(Boolean),
      },
    };

    directPublishAxiosCall(data);
  };

  const handleGlobalCompanyDirectPublish = (row) => {
    let loader_array_copy = directPublishLoader.slice();
    loader_array_copy.push(row._id);
    setDirectPublishLoader(loader_array_copy);

    const data = {
      parent_id: row._id,
      type: "globalCompany",
      stage: "PUBLISHED",
      directPublished: true,
      obj: {
        _id: row._id,
        company_id: row.company_id,
        company_name: row.company_name,
        core_business_segments: row.core_business_segment,
        company_overview: [
          row.company_overview_1,
          row.company_overview_2,
          row.company_overview_3,
          row.company_overview_4,
        ].filter(Boolean),
        employees: row.employees,
        financial_year: row.financial_year,
        founded: row.founded,
        headquarters: row.headquarters,
        revenue: row.revenue,
        unit: row.unit,
        source: row.source,
      },
    };

    directPublishAxiosCall(data);
  };

  const handleRecentDevelopmentDirectPublish = (
    row,
    company_profile_dimensions
  ) => {
    let loader_array_copy = directPublishLoader.slice();
    loader_array_copy.push(row._id);
    setDirectPublishLoader(loader_array_copy);

    const data = {
      parent_id: row._id,
      type: "recentDevelopment",
      stage: "PUBLISHED",
      directPublished: true,
      obj: {
        _id: row._id,
        company_id: row.company_id,
        description: row.description,
        date: row.date,
        dimensions: Object.keys(company_profile_dimensions).reduce(
          (acc, key) => {
            acc[key] = row[key];
            return acc;
          },
          {}
        ),
        type: row.type,
        company_name: row.company_name,
        CountriesIncluded: row.CountriesIncluded,
        CountriesExcluded: row.CountriesExcluded,
      },
    };

    directPublishAxiosCall(data);
  };

  const handleProductServicesDirectPublish = (
    row,
    company_profile_dimensions
  ) => {
    let loader_array_copy = directPublishLoader.slice();
    loader_array_copy.push(row._id);
    setDirectPublishLoader(loader_array_copy);

    const data = {
      parent_id: row._id,
      type: "productServices",
      stage: "PUBLISHED",
      directPublished: true,
      obj: {
        _id: row._id,
        dimensions: Object.keys(company_profile_dimensions).reduce(
          (acc, key) => {
            acc[key] = row[key];
            return acc;
          },
          {}
        ),
        company_id: row.company_id,
        company_name: row.company_name,
        first_level_brand: row.first_level_brand,
        description: row.description,
        source: row.source,
        CountriesIncluded: row.CountriesIncluded,
        CountriesExcluded: row.CountriesExcluded,
      },
    };

    directPublishAxiosCall(data);
  };

  function EmptyRowsRenderer() {
    return <div style={{ textAlign: "center" }}>No Data Found</div>;
  }

  function rowKeyGetter(row) {
    return row.id;
  }

  function renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map((key) => {
      const newKey = newKeys[key] || key;
      return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
  }

  const columns = {
    globalCompany: {
      columns: [
        {
          accessorKey: "company_id",
          header: "Company ID",
        },
        {
          accessorKey: "company_name",
          header: "Company Name",
        },
        {
          accessorKey: "core_business_segment",
          header: "Core Business Segment",
        },
        {
          accessorKey: "company_overview_1",
          header: "Company Overview 1",
        },
        {
          accessorKey: "company_overview_2",
          header: "Company Overview 2",
        },
        {
          accessorKey: "company_overview_3",
          header: "Company Overview 3",
        },
        {
          accessorKey: "company_overview_4",
          header: "Company Overview 4",
        },
        {
          accessorKey: "employees",
          header: "Employees",
        },
        {
          accessorKey: "financial_year",
          header: "Financial Year",
        },
        {
          accessorKey: "founded",
          header: "Founded",
        },
        {
          accessorKey: "headquarters",
          header: "Headquarters",
        },
        {
          accessorKey: "revenue",
          header: "Revenue",
        },
        {
          accessorKey: "unit",
          header: "Unit",
        },
        {
          accessorKey: "source",
          header: "Source",
        },
      ],
      filterList: [
        {
          id: "company_name",
          type: "text",
        },
      ],
    },
    marketCompany: {
      columns: [
        ...Object.keys(props.company_profile_dimensions).map((dimension) => {
          if (dimension == "country") {
            return {
              accessorKey: dimension,
              header: startCase(dimension),
              filterFn: "dropdownFilter",
            };
          }
          return {
            accessorKey: dimension,
            header: startCase(dimension),
          };
        }),
        {
          accessorKey: "company_id",
          header: "Company ID",
        },
        {
          accessorKey: "company_name",
          header: "Company Name",
        },
        {
          accessorKey: "company_highlights_1",
          header: "Company Highlights 1",
        },
        {
          accessorKey: "company_highlights_2",
          header: "Company Highlights 2",
        },
        {
          accessorKey: "company_highlights_3",
          header: "Company Highlights 3",
        },
        {
          accessorKey: "company_highlights_4",
          header: "Company Highlights 4",
        },
        {
          accessorKey: "key_information_1",
          header: "Key Information 1",
        },
        {
          accessorKey: "key_information_2",
          header: "Key Information 2",
        },
        {
          accessorKey: "key_information_3",
          header: "Key Information 3",
        },
        {
          accessorKey: "key_information_4",
          header: "Key Information 4",
        },
        {
          accessorKey: "company_vertical",
          header: "Company Vertical",
        },
        {
          accessorKey: "market_segment",
          header: "Market Segment",
        },
        {
          accessorKey: "segmental_revenue",
          header: "Segmental Revenue",
        },
        {
          accessorKey: "products_1",
          header: "Core Solutions/Product Offerings 1",
        },
        {
          accessorKey: "products_2",
          header: "Core Solutions/Product Offerings 2",
        },
        {
          accessorKey: "products_3",
          header: "Core Solutions/Product Offerings 3",
        },
        {
          accessorKey: "products_4",
          header: "Core Solutions/Product Offerings 4",
        },
      ],
      filterList: [
        {
          id: "company_name",
          type: "text",
        },
        {
          id: "region",
          type: "text",
        },
        {
          id: "country",
          type: "dropdown",
          options: listOfRegionFilters,
        },
        {
          id: "tier_type",
          type: "text",
        },
        {
          id: "colocation_type",
          type: "text",
        },
        {
          id: "end_user",
          type: "text",
        },
        {
          id: "hotspot",
          type: "text",
        },
        {
          id: "absorption",
          type: "text",
        },
      ],
    },
    recentDevelopment: {
      columns: [
        ...Object.keys(props.company_profile_dimensions)
          .filter((item, index, array) => !["country", "region"].includes(item))
          .map((dimension, ind) => {
            return {
              accessorKey: dimension + ind,
              header: startCase(dimension),
            };
          }),
        {
          accessorKey: "company_id",
          header: "Company ID",
        },
        {
          accessorKey: "company_name",
          header: "Company Name",
        },
        {
          accessorKey: "type",
          header: "Type",
        },
        {
          accessorKey: "description",
          header: "Description",
        },
        {
          id: "CountriesIncluded",
          accessorFn: (row) =>
            row.original?.CountriesIncluded?.split(",") || [],
          header: "Countries Included",
          filterFn: "multiSelectFilter",
          cell: ({ getValue, row, column: { id }, table }) => {
            const defaultValue = row.original?.CountriesIncluded
              ? row.original?.CountriesIncluded?.split(",")
              : [];
            return (
              <MultiSelectDropdown
                options={listOfRegionFilters}
                columnValue={defaultValue}
                index={row.index}
                id={id}
                table={table}
                isFilter={false}
              />
            );
          },
        },
        {
          id: "CountriesExcluded",
          accessorFn: (row) =>
            row.original?.CountriesExcluded?.split(",") || [],
          header: "Countries Excluded",
          filterFn: "multiSelectFilter",
          cell: ({ getValue, row, column: { id }, table }) => {
            const defaultValue = row.original?.CountriesExcluded
              ? row.original?.CountriesExcluded.split(",")
              : [];
            return (
              <MultiSelectDropdown
                options={listOfRegionFilters}
                columnValue={defaultValue}
                index={row.index}
                id={id}
                table={table}
                isFilter={false}
              />
            );
          },
        },
        {
          accessorKey: "date",
          header: "Date",
        },
      ],
      filterList: [
        {
          id: "company_name",
          type: "text",
        },
        {
          id: "tier_type",
          type: "text",
        },
        {
          id: "colocation_type",
          type: "text",
        },
        {
          id: "end_user",
          type: "text",
        },
        {
          id: "hotspot",
          type: "text",
        },
        {
          id: "absorption",
          type: "text",
        },
        {
          id: "CountriesIncluded",
          type: "dropdown",
          options: listOfRegionFilters,
        },
        {
          id: "CountriesExcluded",
          type: "dropdown",
          options: listOfRegionFilters,
        },
      ],
    },
    productServices: {
      columns: [
        ...Object.keys(props.company_profile_dimensions)
          .filter((item, index, array) => !["country", "region"].includes(item))
          .map((dimension) => {
            return {
              accessorKey: dimension,
              header: startCase(dimension),
            };
          }),
        {
          accessorKey: "company_id",
          header: "Company ID",
        },
        {
          accessorKey: "company_name",
          header: "Company Name",
        },
        {
          accessorKey: "first_level_brand",
          header: "First Level Brand",
        },
        {
          accessorKey: "description",
          header: "Description",
        },
        {
          accessorKey: "source",
          header: "Source",
        },
        {
          id: "CountriesIncluded",
          accessorFn: (row) =>
            row.original?.CountriesIncluded?.split(",") || [],
          header: "Countries Included",
          filterFn: "multiSelectFilter",
          cell: ({ getValue, row, column: { id }, table }) => {
            const defaultValue = row.original?.CountriesIncluded
              ? row.original?.CountriesIncluded?.split(",")
              : [];
            return (
              <MultiSelectDropdown
                options={listOfRegionFilters}
                columnValue={defaultValue}
                index={row.index}
                id={id}
                table={table}
                isFilter={false}
              />
            );
          },
        },
        {
          id: "CountriesExcluded",
          accessorFn: (row) =>
            row.original?.CountriesExcluded?.split(",") || [],
          header: "Countries Excluded",
          filterFn: "multiSelectFilter",
          cell: ({ getValue, row, column: { id }, table }) => {
            const defaultValue = row.original?.CountriesExcluded
              ? row.original?.CountriesExcluded.split(",")
              : [];
            return (
              <MultiSelectDropdown
                options={listOfRegionFilters}
                columnValue={defaultValue}
                index={row.index}
                id={id}
                table={table}
                isFilter={false}
              />
            );
          },
        },
      ],
      filterList: [
        {
          id: "company_name",
          type: "text",
        },
        {
          id: "tier_type",
          type: "text",
        },
        {
          id: "colocation_type",
          type: "text",
        },
        {
          id: "end_user",
          type: "text",
        },
        {
          id: "hotspot",
          type: "text",
        },
        {
          id: "absorption",
          type: "text",
        },
        {
          id: "CountriesIncluded",
          type: "dropdown",
          options: listOfRegionFilters,
        },
        {
          id: "CountriesExcluded",
          type: "dropdown",
          options: listOfRegionFilters,
        },
      ],
    },
  };

  useEffect(() => {
    props.setCompanyType("globalCompany");
  }, []);

  useEffect(() => {
    setRows(createRows(props.company_profile_data));
  }, [JSON.stringify(props.company_profile_data)]);

  function createRows(data) {
    let company_type = props.companyType;
    let active_tab = props.ACTIVE_TAB;
    if (company_type === "marketCompany") {
      let rows = [];
      rows = data.map((item) => {
        let dimensionObj;
        dimensionObj = item.dimensions;

        let staticObj = {
          _id: item._id,
          company_id: item.company_id,
          company_name: item.company_name,
          company_highlights_1: item.meta.company_highlights[0],
          company_highlights_2: item.meta.company_highlights[1],
          company_highlights_3: item.meta.company_highlights[2],
          company_highlights_4: item.meta.company_highlights[3],
          key_information_1: item.meta.key_information[0],
          key_information_2: item.meta.key_information[1],
          key_information_3: item.meta.key_information[2],
          key_information_4: item.meta.key_information[3],
          company_vertical: item.meta.company_vertical,
          market_segment: item.meta.market_segment,
          segmental_revenue:
            item.segmental_revenue &&
            Object.keys(item.segmental_revenue) &&
            item.segmental_revenue.hasOwnProperty("value")
              ? item.segmental_revenue.value
              : "",
          products_1: item.products[0],
          products_2: item.products[1],
          products_3: item.products[2],
          products_4: item.products[3],
          versions: item[active_tab],
          latest_version_id: item.hasOwnProperty("latest_version_id")
            ? item.latest_version_id
            : "",
        };
        return { ...dimensionObj, ...staticObj };
      });
      return rows;
    } else if (company_type === "globalCompany") {
      let rows = [];
      rows = data.map((item) => {
        return {
          _id: item._id,
          company_id: item.company_id,
          company_name: item.company_name,
          core_business_segment: item.core_business_segments,
          company_overview_1: item.analysis[0],
          company_overview_2: item.analysis[1],
          company_overview_3: item.analysis[2],
          company_overview_4: item.analysis[3],
          employees: item.meta.employees,
          financial_year: item.meta.financial_year,
          founded: item.meta.founded,
          headquarters: item.meta.headquarters,
          revenue: item.revenue.value,
          unit: item.revenue.units,
          source: item.revenue.source,
          versions: item[active_tab],
          latest_version_id: item.hasOwnProperty("latest_version_id")
            ? item.latest_version_id
            : "",
        };
      });
      return rows;
    } else if (company_type === "productServices") {
      let rows = [];
      rows = data.map((item) => {
        let dimensionObj;

        dimensionObj = item.dimensions;

        let staticObj = {
          _id: item._id,
          company_id: item.company_id,
          company_name: item.company_name,
          first_level_brand: item.first_level_brand,
          description: item.description,
          source: item.source,
          CountriesIncluded: item.CountriesIncluded,
          CountriesExcluded: item.CountriesExcluded,
          versions: item[active_tab],
          latest_version_id: item.hasOwnProperty("latest_version_id")
            ? item.latest_version_id
            : "",
        };
        return { ...dimensionObj, ...staticObj };
      });
      return rows;
    } else if (company_type === "recentDevelopment") {
      let rows = [];
      rows = data.map((item) => {
        let dimensionObj;

        dimensionObj = item.dimensions;

        let staticObj = {
          _id: item._id,
          company_id: item.company_id,
          company_name: item.company_name,
          type: item.type,
          date: moment(item.date).format("YYYY-MM-DD"),
          description: item.description,
          CountriesIncluded: item.CountriesIncluded,
          CountriesExcluded: item.CountriesExcluded,
          versions: item[active_tab],
          latest_version_id: item.hasOwnProperty("latest_version_id")
            ? item.latest_version_id
            : "",
        };
        return { ...dimensionObj, ...staticObj };
      });
      return rows;
    }
  }

  const filteredRows = useMemo(() => {
    let filtered = rows.filter((r) => {
      return (
        (filters.company_name
          ? r.company_name
              .toLowerCase()
              .includes(filters.company_name.toLowerCase())
          : rows) &&
        (filters.region
          ? r.region.toLowerCase().includes(filters.region.toLowerCase())
          : true) &&
        (filters.country && filters.country.length
          ? filters.country.includes(r.country)
          : true) &&
        (filters.end_user
          ? r.end_user.toLowerCase().includes(filters.end_user.toLowerCase())
          : true) &&
        (filters.vehicle_type
          ? r.vehicle_type
              .toLowerCase()
              .includes(filters.vehicle_type.toLowerCase())
          : true) &&
        (filters.product_type
          ? r.product_type
              .toLowerCase()
              .includes(filters.product_type.toLowerCase())
          : true) &&
        (filters.product_grade
          ? r.product_grade
              .toLowerCase()
              .includes(filters.product_grade.toLowerCase())
          : true) &&
        (filters.CountriesIncluded &&
        filters.CountriesIncluded.length &&
        !filters.CountriesIncluded.includes("All")
          ? r.CountriesIncluded.split(",").some((item) =>
              filters.CountriesIncluded.includes(item)
            )
          : true) &&
        (filters.CountriesExcluded &&
        filters.CountriesExcluded.length &&
        !filters.CountriesExcluded.includes("All")
          ? r.CountriesExcluded.split(",").some((item) =>
              filters.CountriesExcluded.includes(item)
            )
          : true)
      );
    });
    return filtered;
  }, [rows, filters]);

  function handleFill({ columnKey, sourceRow, targetRow }) {
    return { ...targetRow, [columnKey]: sourceRow[columnKey] };
  }

  function handlePaste({
    sourceColumnKey,
    sourceRow,
    targetColumnKey,
    targetRow,
  }) {
    const incompatibleColumns = ["email", "zipCode", "date"];
    if (
      sourceColumnKey === "avatar" ||
      ["id", "avatar"].includes(targetColumnKey) ||
      ((incompatibleColumns.includes(targetColumnKey) ||
        incompatibleColumns.includes(sourceColumnKey)) &&
        sourceColumnKey !== targetColumnKey)
    ) {
      return targetRow;
    }

    return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey] };
  }

  const onChange = (e) => {
    props.setCompanyType(e.target.value);
    props.setFetchFulldata(false);
    //reset Filters
    setFilters({
      company_name: "",
      company_id: "",
      region: "",
      country: [],
      end_user: "",
      vehicle_type: "",
      product_type: "",
      product_grade: "",
      CountriesIncluded: [],
      CountriesExcluded: [],
    });
    setSaveLoader([]);
    setDirectPublishLoader([]);
  };

  let saveButton;
  let directPublishButton;
  let deleteButton;
  if (props.companyType === "marketCompany") {
    saveButton = handleMarketCompanySave;
    directPublishButton = handleMarketCompanyDirectPublish;
    deleteButton = handleMarketCompanyDelete;
  } else if (props.companyType === "globalCompany") {
    saveButton = handleGlobalCompanySave;
    directPublishButton = handleGlobalCompanyDirectPublish;
    deleteButton = handleGlobalCompanyDelete;
  } else if (props.companyType === "recentDevelopment") {
    saveButton = handleRecentDevelopmentSave;
    directPublishButton = handleRecentDevelopmentDirectPublish;
    deleteButton = handleRecentDevelopmentDelete;
  } else if (props.companyType === "productServices") {
    saveButton = handleProductServicesSave;
    directPublishButton = handleProductServicesDirectPublish;
    deleteButton = handleProductServicesDelete;
  }

  if (props.ACTIVE_TAB === "drafts") {
    columns[props.companyType]["columns"].push(
      {
        accessorKey: "save",
        header: "Save",
        cell: ({ row }) => (
          <Button
            type="primary"
            loading={saveLoader.includes(row.original._id)}
            onClick={() =>
              saveButton(row.original, props.company_profile_dimensions)
            }
          >
            Save
          </Button>
        ),
      },
      {
        accessorKey: "delete",
        header: "Delete",
        cell: ({ row }) => (
          <Button
            type="primary"
            loading={deleteLoader.includes(row.original._id)}
            onClick={() => deleteButton(row.original)}
          >
            Delete
          </Button>
        ),
      },
      {
        accessorKey: "stage",
        header: "Stage",
        cell: ({ row }) => (
          <CommonDropDown
            row={row}
            defaultValueKey={"versions.stage"}
            defaultValue={"NONE"}
            extras={{
              className:
                row.original.versions &&
                Object.keys(row.original.versions) &&
                row.original.versions.hasOwnProperty("stage")
                  ? `${row.original.versions.stage}-stage-color stage-dropdown-color black-loader`
                  : "draft-tab-dropdown black-loader",
              style: { width: "90%", minWidth: "max-content" },
            }}
            loading={stageChangeLoader.includes(row.original._id)}
            placeholder="Select Stage"
            onChange={handleStageChange}
          >
            {props.USER_DATA &&
              props.USER_DATA.obj &&
              props.USER_DATA.obj.access &&
              props.USER_DATA.obj.access.move &&
              props.USER_DATA.obj.access.move.length &&
              props.USER_DATA.obj.access.move.map((item) => {
                return <Option value={item}>{item}</Option>;
              })}
          </CommonDropDown>
        ),
      },
      {
        accessorKey: "drafts",
        header: "Draft Versions",
        cell: ({ row }) => (
          <CommonDropDown
            row={row}
            loading={specificVersionLoader.includes(row.original._id)}
            onChange={handleDraftVersionSelect}
            placeholder={"Select Draft Version"}
            defaultValueKey={"latest_version_id"}
            defaultValue={null}
          >
            {row.original.versions &&
              Object.keys(row.original.versions).length &&
              row.original.versions &&
              row.original.versions.versions &&
              row.original.versions.versions.length &&
              row.original.versions.versions.map((version) => (
                <Option value={version._id}>
                  {new Date(version.createdAt).toLocaleString()}, {version.name}
                </Option>
              ))}
          </CommonDropDown>
        ),
      }
    );
  } else {
    columns[props.companyType]["columns"].push(
      {
        accessorKey: "direct_publish",
        header: "Direct Publish",
        cell: ({ row }) => (
          <Button
            type="primary"
            loading={directPublishLoader.includes(row._id)}
            onClick={() =>
              directPublishButton(row, props.company_profile_dimensions)
            }
          >
            Direct Publish
          </Button>
        ),
        // width: 150,
        // cellClass(row) {
        //   return "centerClassname";
        // },
      },
      {
        accessorKey: "delete",
        header: "Delete",
        cell: ({ row }) => (
          <Button
            type="primary"
            loading={deleteLoader.includes(row._id)}
            onClick={() => deleteButton(row)}
          >
            Delete
          </Button>
        ),
        // width: 40,
        // cellClass(row) {
        //   return "centerClassname";
        // },
      },
      {
        accessorKey: "published_versions",
        header: "Published Versions",
        cell: ({ row }) => (
          <Select
            className="black-loader"
            style={{ width: "100%" }}
            loading={specificVersionLoader.includes(row._id)}
            value={row.latest_version_id || undefined}
            placeholder="Select Published Version"
            onChange={(publish_id) =>
              handlePublishedVersionSelect(publish_id, row)
            }
          >
            {row.versions &&
              row.versions.length &&
              row.versions.map((version) => (
                <Option value={version.publish_id}>
                  {new Date(version.date).toLocaleString()}, {version.name}
                </Option>
              ))}
          </Select>
        ),
        // width: 250,
        // cellClass(row) {
        //   return "centerClassname";
        // },
      }
    );
  }

  // const toggleRowColor = (row) => {
  // };

  const clearFilters = () => {
    //reset Filters
    setFilters({
      company_name: "",
      company_id: "",
      region: "",
      country: [],
      end_user: "",
      vehicle_type: "",
      product_type: "",
      product_grade: "",
    });
  };

  const filterList = {
    globalCompany: [],
    marketCompany: [],
    recentDevelopment: [],
    productServices: [],
  };

  return (
    <div
      className="company-profile-editing-container"
      style={{ height: "100%" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          margin: "20px 0",
        }}
      >
        <Radio.Group
          options={options}
          onChange={onChange}
          value={props.companyType}
          optionType="button"
          disabled={props.initial_draft_versions_loading}
        />
        {["productServices", "recentDevelopment"].includes(
          props.companyType
        ) ? (
          <Checkbox
            checked={props.fetch_full_data}
            onChange={(e) => props.setFetchFulldata(e.target.checked)}
            disabled={props.initial_draft_versions_loading}
          >
            Fetch Full Data
          </Checkbox>
        ) : (
          ""
        )}
        <Button type="primary" onClick={() => setResetFilter(true)}>
          Clear Filters
        </Button>
      </div>
      {props.initial_draft_versions_loading ? (
        <Spin
          style={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translateY(-50%)",
          }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            margin: "0 10px",
          }}
        >
          <div
            style={{ height: "100%", overflowX: "auto" }}
            className="react-grid-multiline-content"
          >
            <CompanyTable
              columns={columns[props.companyType]["columns"] || []}
              data={filteredRows || []}
              filterList={columns[props.companyType]["filterList"] || []}
              resetFilter={resetFilter}
              setResetFilter={setResetFilter}
            />
            {/* <FilterContext.Provider value={filters}>
              <DataGrid
                columns={columns[props.companyType]}
                rows={filteredRows}
                rowKeyGetter={rowKeyGetter}
                onRowsChange={setRows}
                onFill={handleFill}
                onPaste={handlePaste}
                rowHeight={80}
                rowScrollTimeout={200}
                minHeight={850}
                minColumnWidth={100}
                defaultColumnOptions={{
                  sortable: true,
                  resizable: true,
                }}
                headerRowHeight={70}
                className="fill-grid"
                noRowsFallback={<EmptyRowsRenderer />}
                // rowClass={(row) => toggleRowColor(row)}
              />
            </FilterContext.Provider> */}
          </div>
        </div>
      )}
    </div>
  );
}

function FilterRenderer({ isCellSelected, column, children }) {
  const filters = useContext(FilterContext);
  const { ref, tabIndex } = useFocusRef(isCellSelected);

  return (
    <>
      <div>{column.name}</div>
      {<div>{children({ ref, tabIndex, filters })}</div>}
    </>
  );
}

function mapStateToPros(state) {
  return {
    company_profile_data: state.company_profile_data,
    update_company_profile_data: state.update_company_profile_data,
    companyType: state.companyType,
    initial_draft_versions_loading: state.initial_draft_versions_loading,
    company_profile_dimensions: state.company_profile_dimensions,
    USER_DATA: state.USER_DATA,
    ACTIVE_TAB: state.ACTIVE_TAB,
    fetch_full_data: state.fetch_full_data,
  };
}

const mapDispatchToProps = {
  setCompanyProfileData,
  setCompanyType,
  setUpdateCompanyProfileData,
  setFetchFulldata,
};

export default withRouter(
  connect(mapStateToPros, mapDispatchToProps)(CompanyProfileEditing)
);

function CommonDropDown({
  row,
  children,
  loading,
  onChange,
  placeholder,
  defaultValueKey,
  defaultValue,
  extras,
}) {
  const { original } = row;
  const [value, setValue] = useState(
    () => formatValuekey(original, defaultValueKey) || undefined
  );

  function formatValuekey(original, key) {
    // return original[key];
    if (key?.includes(".")) {
      const keys = key.split(".");
      let value = original;

      for (let i = 0; i < keys.length; i++) {
        if (value && value.hasOwnProperty(keys[i])) {
          value = value[keys[i]];
        } else {
          return defaultValue;
        }
      }
      return value;
    } else {
      return original[key];
    }
  }

  useEffect(() => {
    setValue(() => formatValuekey(original, defaultValueKey) || defaultValue);
  }, [row]);

  return (
    <Select
      className="black-loader"
      style={{ width: "100%" }}
      loading={loading}
      value={value}
      placeholder={placeholder}
      onChange={(value) => {
        setValue(value);
        onChange(value, original);
      }}
      {...extras}
    >
      {children}
    </Select>
  );
}
