import styled from "styled-components";

export const GlobalOverviewContainer = styled.div`
  .main-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .left-container,
  .right-container {
    width: 45%;
  }

  .container.overview-container {
    padding-top: 0px;
  }
`;
