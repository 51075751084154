import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Typography, Switch } from "antd";
import "./comment.css";
import {
  CloseOutlined,
  MessageFilled,
  Loading3QuartersOutlined,
} from "@ant-design/icons";
import { notification } from "antd";
import { axios } from "../../components";

import MentionBox from "./MentionBox";
import ChatMessage from "./ChatMessage";
import { useSelector } from "react-redux";

const { Title } = Typography;

const ChatButton = ({
  hub_id,
  parent_id,
  template_type,
  user,
  hub_title,
  baseUrl,
  report_id,
}) => {
  const chatContainerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messageId, setMessageId] = useState(0);
  const [openChatId, setOpenChatId] = useState(null);
  const [isSearchBarOpen, setSearchBarOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedReply, setSelectedReply] = React.useState(null);
  const [filteredChats, setFilteredChats] = useState([]);
  const [chatID, setChatID] = useState(null);
  const draft_versions =
    useSelector((state) => state.draft_versions?.versions) || null;
  const currentUserEmail =
    useSelector((state) => state.USER_DATA?.obj?.email_id) || null;
  const [myEdits, setMyEdits] = useState({
    all: [],
    latest: null,
    dataAndTime: null,
  });
  const messageRef = useRef(null);
  // opening and closing od comment box
  const handleOpen = () => {
    fetchData();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // getting token from local storage
  function getToken() {
    return window.localStorage.getItem("token");
  }

  // fetching chats
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/api/comments`, {
        params: {
          hub_id: hub_id,
          parent_id: parent_id,
          report_section: template_type,
        },
      });

      const data = response.data;
      setChats(data[0]?.comments);
      setMessageId(data[0]?._id);
      setLoading(false);

      chatContainerRef.current.scrollTop =
        chatContainerRef?.current?.scrollHeight;
    } catch (error) {
      setLoading(false);
      console.error("Error fetching comments:", error);
    }
  };
  // remove highlighted reply
  useEffect(() => {
    if (selectedReply) {
      setTimeout(() => {
        setSelectedReply(null);
      }, 2000);
    }
  }, [selectedReply]);
  // sending chats
  const handleSend = async ({
    message,
    mentionedPeople,
    type = null,
    file = null,
  }) => {
    const token = getToken();
    setLoading(true);
    let url = null;
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      //call api to upload file
      try {
        const response = await axios.post(
          `${baseUrl}/api/comments/upload-file`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        url = response.data.res_url.Location;
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }

    const storedLocation = localStorage.getItem("location");
    try {
      const body = {
        hub_id: hub_id,
        hub_name: hub_title,
        comment: message,
        ...(type && { messageType: type }),
        ...(url && { file: url }),
        ...(selectedMessage && {
          reply: {
            comment: selectedMessage.comment,
            message_id: selectedMessage._id,
            messageType: selectedMessage?.messageType,
            user_name: selectedMessage?.user_name,
          },
        }),
        report_section: template_type,
        parent_id: parent_id,
        mentions: mentionedPeople,
        report_id: report_id,
        path: storedLocation,
      };
      // convert the file to
      const response = await axios.post(`${baseUrl}/api/comments`, body, {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${token}`,
        },
      });
      setSelectedMessage(null);
    } catch (error) {
      setLoading(false);
      console.error("Error creating comment:", error);
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
    fetchData();
  };

  // fetching users list for mentioning
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/comments/get-users?department=TECH`
        );
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  // send function being called from mention component
  const handleSendMessage = ({
    newMessage,
    newMentionedPeople,
    type,
    file,
  }) => {
    handleSend({
      message: newMessage,
      mentionedPeople: newMentionedPeople,
      ...(type && { type }),
      ...(file && { file }),
    });
  };

  // opening and closing of Edit dialog in comment Box
  const handleButtonClick = (chatId) => {
    setOpenChatId(chatId);
  };

  const handleBoxClose = () => {
    setOpenChatId(null);
  };

  const handleViewReplies = (chatId, searchStatus) => {
    setChatID(chatId);
    if (chatId) {
      const updatedFilteredChats = chats?.filter(
        (chat) => chatId === chat?.reply?.message_id || chat?._id === chatId
      );
      setFilteredChats(updatedFilteredChats);
    } else {
      const updatedFilteredChats = chats?.filter((chat) =>
        chat.comment.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredChats(updatedFilteredChats);
    }
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  };

  // sending status from Edit dialog in comment Box
  const handleMakeComment = (optionType, subtype, commentID, comment) => {
    if (optionType === "Status" && subtype) {
      setLoading(true);
      let sendingStatus = subtype;
      handleBoxClose();
      const payload = {
        status: sendingStatus,
        message_id: messageId,
        comment_id: commentID,
      };

      axios
        .patch(`${baseUrl}/api/comments`, payload)
        .then((response) => {
          // Perform any additional actions if needed
        })
        .catch((error) => {
          console.error("Error updating status:", error);
        });
      setTimeout(() => {
        fetchData();
      }, 1000);
    } else if (optionType === "Reply") {
      setSelectedMessage(comment);
    } else if (optionType === "Send as mail") {
      axios
        .post(`${baseUrl}/api/comments/send-mail`, {
          messageId: messageId,
          commentId: commentID,
        })
        .then((response) => {
          notification.success({
            message: "Mail sent successfully",
          });

          // Perform any additional actions if needed
        })
        .catch((error) => {
          notification.error({
            message: "Error sending mail",
          });
          console.error("Error sending mail:", error);
        });
    } else if (optionType === "emoji") {
      axios
        .post(`${baseUrl}/api/comments/add-emoji`, {
          messageId: messageId,
          commentId: commentID,
          emoji: comment,
        })
        .then((response) => {
          notification.success({
            message: "Emoji added successfully",
          });
          // Perform any additional actions if needed
          fetchData();
        })
        .catch((error) => {
          console.error("Error sending emoji:", error);
          notification.error({
            message: "Error sending emoji",
          });
        });
    }
  };

  const handleSearch = () => {
    // for later
  };

  // Function to handle reset button click
  const handleReset = () => {
    setSearchQuery("");
  };

  // filtering the chats
  useEffect(() => {
    // Filter the chats based on the searchQuery and update the filteredChats state
    if (searchQuery === "" && chatID) {
      return null;
    }
    const updatedFilteredChats = chats?.filter((chat) =>
      chat.comment.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredChats(updatedFilteredChats);
  }, [chats, searchQuery]);
  // const filteredChats = chats?.filter((chat) =>
  //   chat.comment.toLowerCase().includes(searchQuery.toLowerCase())
  // );
  // const filteredData = chats.filter(
  //   (chat) =>
  //     chat?._id === chat?.reply?.message_id
  //     || chat?._id === chatId
  // );

  const handleReplyClick = (id) => {
    const element = document.getElementsByClassName(`comment-${id}`)[0];
    if (element) {
      // element.scrollTop = element.scrollHeight;
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  useEffect(() => {
    if (draft_versions && currentUserEmail) {
      const drafts = [];
      draft_versions.forEach((draft) => {
        if (draft.email_id === currentUserEmail) {
          drafts.push({ ...draft, dataAndTime: new Date(draft.date) });
        }
      });
      if (drafts.length > 0) {
        setMyEdits({
          all: drafts,
          latest: { ...drafts[0] },
        });
      }
    }
  }, [draft_versions, currentUserEmail]);

  return (
    <div
      style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: 4 }}
    >
      {open ? (
        <Card
          headStyle={{ backgroundColor: "#1890ff" }}
          bodyStyle={{ padding: "10px" }}
          visible={open}
          onCancel={handleClose}
          title={
            <Title
              level={4}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "5px",
                color: "white",
              }}
            >
              Comments/Members
              <Button
                type="text"
                shape="circle"
                onClick={handleClose}
                style={{ color: "white" }}
                icon={<CloseOutlined />}
              />
            </Title>
          }
          style={{ height: "440px", borderRadius: "inherit", width: "350px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginBottom: "5px",
            }}
          >
            {/* Search Bar */}
            {isSearchBarOpen && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",

                  margin: "0px 5px",
                  background: "#80808038",
                  padding: "7px 5px 8px 6px",
                  borderRadius: "5px",
                }}
              >
                <input
                  style={{
                    width: "95%",
                    border: "none",
                    background: "transparent",
                  }}
                  type="text"
                  placeholder="Search chats..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />

                {/* <button onClick={handleSearch}>Search</button> */}

                <button
                  style={{
                    border: "none",
                    background: "#f64e4e",
                    color: "#fff",
                    padding: "2px 10px",
                    fontSize: "10px",
                    borderRadius: "5px",
                  }}
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            )}
            {/* Toggle Bar */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <Switch
                checked={isSearchBarOpen}
                onChange={() => {
                  setSearchBarOpen((prev) => !prev);
                  setSearchQuery("");
                }}
              />
              {/* <span>Toggle Search</span> */}
            </div>
          </div>
          {loading ? (
            <div
              style={{
                height: "300px",
                overflowY: "visible",
                overflowX: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              ref={chatContainerRef}
            >
              <Loading3QuartersOutlined />
            </div>
          ) : (
            <div
              style={{
                height: "300px",
                overflowY: "visible",
                overflowX: "hidden",
              }}
              ref={chatContainerRef}
            >
              {!chats ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <p>No comments found</p>
                </div>
              ) : (
                filteredChats?.map((chat) => (
                  <ChatMessage
                    key={chat._id}
                    selectedReply={selectedReply}
                    setSelectedReply={setSelectedReply}
                    messageRef={messageRef}
                    handleReplyClick={handleReplyClick}
                    user={user}
                    chat={chat}
                    handleButtonClick={handleButtonClick}
                    handleBoxClose={handleBoxClose}
                    handleMakeComment={handleMakeComment}
                    openChatId={openChatId}
                    handleViewReplies={handleViewReplies}
                    editedDraftDate={myEdits?.latest?.date}
                  />
                ))
              )}
            </div>
          )}
          <MentionBox
            baseUrl={baseUrl}
            onSendMessage={handleSendMessage}
            loading={loading}
            selectedMessage={selectedMessage}
            setSelectedMessage={setSelectedMessage}
          />
        </Card>
      ) : (
        <Button
          type="primary"
          shape="circle"
          className="fab"
          style={{
            width: "50px",
            height: "50px",
            transition: "transform 0.3s",
            "&:hover": {
              transform: "scale(1.1)",
            },
          }}
          onClick={handleOpen}
          icon={<MessageFilled style={{ fontSize: "30px" }} />}
        />
      )}
    </div>
  );
};

export default ChatButton;
