import styled from "styled-components";

export const AdhesivesAndSealantsVCWrapper = styled.div`
  .section_data1 {
    top: 64%;
    width: 14%;
    max-width: 165px;
    position: absolute;
    word-break: break-word;
  }

  .section_data2 {
    top: 64%;
    width: 18%;
    max-width: 250px;
    position: absolute;
    word-break: break-word;
  }

  .section1_list1 {
    left: 6%;
  }

  .section1_list2 {
    left: 21%;
  }

  .section2_list {
    left: 44%;
  }

  .section3_list1 {
    left: 69%;
  }

  .section3_list2 {
    left: 83%;
  }
`;
