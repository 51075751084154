import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { LEDVCWrapper } from "./styles";

var Base64 = require("js-base64").Base64;

class LED_VC extends React.Component {
  state = {
    data: this.props.data ? this.props.data : "",
    section1_data: [],

    reseller: false,
  };

  componentDidMount = () => {
    this.splitToChunks();
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.meta) {
      if (props.data.meta.sample && props.data.meta.reseller) {
        return {
          reseller: true,
        };
      }
    }
    return null;
  }

  splitToChunks = () => {
    const { data } = this.props.data ? this.props.data : this.state.data;

    let section1_data = data && data.length > 0 ? data[0] : [];
    this.setState({
      section1_data,
    });
  };

  render() {
    const { section_id } = this.state.data;
    const { section1_data } = this.state;
    const { slideNo } = this.props;
    const slug = this.state.data.slug;
    const pageId = Base64.encode(section_id);
    const { multi_urls, url } = this.state.data;

    return (
      <div className="page" id={pageId}>
        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Header slideTitle={this.state.data.type} />
        ) : null}
        <LEDVCWrapper>
          <h2 className="heading valuechain_title">
            {this.props.data ? this.props.data.title : this.state.data.title}
          </h2>

          <div style={{ height: "560px", position: "relative" }}>
            {Array.isArray(multi_urls) && multi_urls?.length ? (
              multi_urls.map((item) => (
                <img
                  src={item.url}
                  alt="Value Chain"
                  style={{ width: "100%", height: "100%", marginTop: "10px" }}
                />
              ))
            ) : (
              <img
                src={url}
                alt="Value Chain"
                style={{ width: "100%", height: "100%", marginTop: "10px" }}
              />
            )}

            {this.state.data &&
            this.state.data.meta &&
            section1_data?.list &&
            section1_data?.list.length > 0 &&
            !this.state.data.meta.sample ? (
              <div className="section1_list section_data1">
                {section1_data.list.map((elem) => (
                  <div className="font_design">
                    <div>•</div>
                    <div style={{ marginLeft: "5px" }}>{elem}</div>
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>

          {!this.props.fromInterface ||
          this.props.fromInterface === undefined ? (
            <Footer
              slug={slug}
              slideNo={slideNo}
              reseller={this.state.reseller}
            />
          ) : null}
        </LEDVCWrapper>
      </div>
    );
  }
}

export default LED_VC;
