import React, { Component } from "react";
import { Button, Upload, notification, Select, Spin, Tooltip } from "antd";
import Icon from "@ant-design/icons";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { BulkUploadComponentWrapper } from "./BulkExports.style";
import debounce from "lodash/debounce";
import config from "../../config/Api";
import axios from "axios";

const Option = Select.Option;

class BulkExportsComponent2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportFile: null,
      reports: {
        loading: false,
        list: [],
      },
      selected_reports: [],
      selected_title: [],
      uploadedUrl: null,
      uploadLoader: false,
      value: "",
      uploadFileList: [],
    };
    this.fetchReports = debounce(this.fetchReports, 800);
  }

  //Fetch list of report for upload sample
  fetchReports = async (value) => {
    this.setState({
      reports: {
        loading: true,
        list: [],
      },
    });
    try {
      let url = config.api.base_url;
      let response = await axios.get(`${url}/api/searchReport`, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        params: {
          title: value,
          pageNo: "1",
        },
      });

      const data = Array.isArray(response.data)
        ? response.data.map((report) => ({
            title: report.title,
            id: report.report_id,
          }))
        : [];
      this.setState({ reports: { loading: false, list: data } });
    } catch (error) {
      this.setState({
        reportFile: null,
        selected_reports: [],
        value: "search for reports",
      });
      notification.error({
        message: "Error",
        description: "Something went wrong!!!",
      });
    }
  };

  //   On select of a particular report
  onReportsChange = (value) => {
    if (value) this.setState({ value: value });
  };

  // Final form upload after uploading of csv or selection of reports
  handleUploadSample = () => {
    let { selected_reports, reportFile, uploadFileList } = this.state;

    if (uploadFileList && uploadFileList.length > 0) {
      let formData = new FormData();
      uploadFileList.map((file) => {
        return Object.keys(file).map((key) => {
          return formData.append(key, file[key]);
        });
      });

      this.setState({
        uploadLoader: true,
      });
      //API END POINT
      axios({
        method: "post",
        url: `${config.api.base_url}/api/upload_sample_ms`,
        data: formData,
      })
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              uploadLoader: false,
              uploadFileList: [],
            });
            notification.success({
              message: "Success",
              description: response.data && response.data.message,
            });
          }
        })
        .catch((error) => {
          console.log("Error", error);
          notification.error({
            message: "Error",
            description: "Error while uploading sample",
          });
        });
    }
  };

  // Prevent post api call on file change in the upload dragger
  dummyRequest = ({ file, onSuccess }) => {
    if (file.size < 100000000) {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    } else onSuccess("removed");
  };

  handleChange = ({ file }) => {
    if (file.size < 100000) {
      this.setState({
        reportFile: file.originFileObj,
      });
    }
  };

  handleAddUploadElements = () => {
    const { reportFile, value, uploadFileList } = this.state;
    let tempElementList = [];
    if (reportFile && value) {
      tempElementList = [...uploadFileList, { [value]: reportFile }];
      this.setState(
        {
          uploadFileList: tempElementList,
        },
        () => {
          this.setState({ reportFile: null, value: "" });
        }
      );
    }
  };

  handleRemoveUploadElements = (index) => {
    const { uploadFileList } = this.state;
    let tempElementList = uploadFileList;
    tempElementList.splice(index, 1);
    this.setState({
      uploadFileList: tempElementList,
    });
  };

  handleDisableUploadButton = () => {
    const { reportFile, value, uploadFileList } = this.state;
    if (uploadFileList && uploadFileList.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  disableAddButton = () => {
    const { reportFile, value, uploadFileList } = this.state;
    if (reportFile && value) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    const { uploadFileList } = this.state;

    //Upload file configuration
    const props = {
      multiple: false,
      customRequest: this.dummyRequest,
      onRemove: (file) => {
        this.setState((state) => {
          return {
            reportFile: {},
          };
        });
      },
      beforeUpload: (file) => {
        if (file.size < 100000000) {
          this.setState((state) => ({
            reportFile: file,
          }));
        } else {
          notification.error({
            message: "Error!",
            duration: 2,
            description: "Please Upload Something Under 100KB",
          });
          return false;
        }
      },
    };
    return (
      <BulkUploadComponentWrapper>
        <div style={{ marginTop: "6rem" }} className="card">
          <h2 style={{ textAlign: "center", marginTop: "20px" }}>
            Sample MEs Upload
          </h2>
          <div className="uploadsection">
            <Select
              placeholder="Search for reports"
              style={{ width: "653px" }}
              value={this.state.value || undefined}
              showSearch
              //   mode='multiple'
              notFoundContent={
                this.state.reports.loading ? <Spin size="small" /> : null
              }
              filterOption={false}
              onSearch={this.fetchReports}
              onChange={this.onReportsChange}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {this.state.reports.list.map((d) => (
                <Option key={d.title}>{d.title}</Option>
              ))}
            </Select>
            <Upload
              accept=".xlsx"
              {...props}
              multiple={false}
              showUploadList={false}
              onChange={this.handleChange}
              className="upload"
            >
              <Button>
                <Icon key="upload" type="upload" />
                {this.state.reportFile &&
                this.state.reportFile &&
                this.state.reportFile.name
                  ? this.state.reportFile.name
                  : "Select File"}
              </Button>
            </Upload>
            <Tooltip title="Add">
              <Button
                type="dashed"
                disabled={this.disableAddButton()}
                style={{ marginLeft: "5px" }}
                onClick={this.handleAddUploadElements}
                icon={<PlusOutlined />}
              />
            </Tooltip>
          </div>
          <div style={{ margin: "50px" }}>
            {uploadFileList &&
              uploadFileList.length > 0 &&
              uploadFileList.map((elem, index) => {
                return Object.keys(elem).map((key, idx) => {
                  return (
                    <div className="upload_list">
                      <div>
                        <span>
                          <strong>Title: </strong>
                          {key}
                        </span>
                        <span style={{ marginLeft: "20px" }}>
                          <strong>File:</strong> {elem[key].name}
                        </span>
                      </div>
                      <Button
                        style={{ marginLeft: "20px" }}
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => this.handleRemoveUploadElements(index)}
                      />
                    </div>
                  );
                });
              })}
          </div>
          <div style={{ textAlign: "center" }}>
            <div className="buttons">
              <Button
                type="primary"
                size="large"
                shape="round"
                loading={this.state.uploadLoader}
                onClick={this.handleUploadSample}
                disabled={this.handleDisableUploadButton()}
              >
                Upload
              </Button>
            </div>
          </div>
        </div>
      </BulkUploadComponentWrapper>
    );
  }
}

export default BulkExportsComponent2;
