import React from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import { MLCCVCWrapper } from "./styles";

var Base64 = require("js-base64").Base64;

class Mlcc_VC extends React.Component {
  state = {
    data: this.props.data ? this.props.data : "",
    section1_data: [],
    section2_data: [],
    section3_data: [],
    section4_data: [],
    reseller: false,
  };

  componentDidMount = () => {
    this.splitToChunks();
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.meta) {
      if (props.data.meta.sample && props.data.meta.reseller) {
        return {
          reseller: true,
        };
      }
    }
    return null;
  }

  splitToChunks = () => {
    const { data } = this.props.data ? this.props.data : this.state.data;

    let section1_data = data && data.length > 1 ? data.slice(0, 1) : [];
    let section2_data = data && data.length > 1 ? data.slice(1, 2) : [];
    let section3_data = data && data.length > 1 ? data.slice(2, 3) : [];
    let section4_data = data && data.length > 1 ? data.slice(3, 4) : [];
    this.setState({
      section1_data,
      section2_data,
      section3_data,
      section4_data,
    });
  };

  render() {
    const { section_id } = this.state.data;
    const { section1_data, section2_data, section3_data, section4_data } =
      this.state;
    const { slideNo } = this.props;
    const slug = this.state.data.slug;
    const pageId = Base64.encode(section_id);
    const { multi_urls, url } = this.state.data;

    return (
      <div className="page" id={pageId}>
        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Header slideTitle={this.state.data.type} />
        ) : null}
        <MLCCVCWrapper>
          <h2 className="heading valuechain_title">
            {this.props.data ? this.props.data.title : this.state.data.title}
          </h2>

          <div style={{ height: "560px", position: "relative" }}>
            {Array.isArray(multi_urls) && multi_urls?.length ? (
              multi_urls.map((item) => (
                <img
                  src={item.url}
                  alt="Value Chain"
                  style={{ width: "100%", height: "100%", marginTop: "10px" }}
                />
              ))
            ) : (
              <img
                src={url}
                alt="Value Chain"
                style={{ width: "100%", height: "100%", marginTop: "10px" }}
              />
            )}

            {section1_data.map((item) =>
              this.state.data &&
              this.state.data.meta &&
              !this.state.data.meta.sample ? (
                <div className="section1_list1 section_data1">
                  {item.list.map((elem) => (
                    <div className="font_design">
                      <div>•</div>
                      <div style={{ marginLeft: "5px" }}>{elem}</div>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )
            )}

            {section2_data.map((item) =>
              this.state.data &&
              this.state.data.meta &&
              !this.state.data.meta.sample ? (
                <div className="section2_list section_data3">
                  {item.list.map((elem) => (
                    <div className="font_design">
                      <div>•</div>
                      <div style={{ marginLeft: "5px" }}>{elem}</div>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )
            )}

            {section3_data.map((item) =>
              this.state.data &&
              this.state.data.meta &&
              !this.state.data.meta.sample ? (
                <div className="section3_list1 section_data3">
                  {item.list.map((elem) => (
                    <div className="font_design">
                      <div>•</div>
                      <div style={{ marginLeft: "5px" }}>{elem}</div>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )
            )}
            {section4_data.map((item) =>
              this.state.data &&
              this.state.data.meta &&
              !this.state.data.meta.sample ? (
                <div className="section4_list1 section_data2">
                  {item.list.map((elem) => (
                    <div className="font_design">
                      <div>•</div>
                      <div style={{ marginLeft: "5px" }}>{elem}</div>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )
            )}
          </div>

          {!this.props.fromInterface ||
          this.props.fromInterface === undefined ? (
            <Footer
              slug={slug}
              slideNo={slideNo}
              reseller={this.state.reseller}
            />
          ) : null}
        </MLCCVCWrapper>
      </div>
    );
  }
}

export default Mlcc_VC;
