import React, { Component } from "react";
import { connect } from "react-redux";
import { setReportHeaderData } from "../reducersAndActions/Actions";

class Footer extends Component {
  render() {
    const { slideNoElementId, reseller } = this.props;
    return (
      <div
        className={
          this.props.className ? this.props.className + " footer" : "footer"
        }
        frominterface={this.props.fromInterface}
      >
        <p className="page-number" id={slideNoElementId}>
          {this.props?.REPORT_HEADER_DATA?.header_navigation?.[0]?.meta?.sample
            ? ""
            : this.props.slideNo}
        </p>

        <p
          className="report-name"
          style={{ position: "relative", zIndex: "2" }}
        >
          {this.props.REPORT_HEADER_DATA.report_name} | Mordor Intelligence
        </p>

        {reseller ? (
          <div></div>
        ) : (
          <img
            src="../../../Logo.png"
            alt="footer-img"
            style={{ position: "relative", zIndex: "2" }}
          />
        )}
      </div>
    );
  }
}

function mapStateToPros(state) {
  return {
    REPORT_HEADER_DATA: state.REPORT_HEADER_DATA,
  };
}

const mapDispatchToProps = {
  setReportHeaderData,
};

export default connect(mapStateToPros, mapDispatchToProps)(Footer);
