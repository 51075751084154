import React, { useEffect, useState } from "react";
import InterfaceHeader from "../InterfaceHeader";
import { Select, Button, Checkbox } from "antd";
import axios from "axios";
import config from "../../config/Api";
import {
  ArrowDownOutlined,
  ArrowLeftOutlined,
  CloseOutlined,
  Loading3QuartersOutlined,
  TrophyOutlined,
} from "@ant-design/icons";

const { Option } = Select;

function CommentsList() {
  const [loading, setLoading] = useState(false);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [hubData, setHubData] = useState([]);
  const [selectedHubID, setSelectedHubID] = useState(null);
  const [selectedReportID, setSelectedReportID] = useState(null);
  const [commentList, setCommentList] = useState([]);
  const [reportList, setReportList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [commentsPerPage] = useState(10);
  const base_url = config.api.base_url;
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searched, setSearched] = useState(false);
  const [theId, setTheId] = useState("");
  const [lastHubId, setLastHubId] = useState("");
  const [lastParentId, setLastParentId] = useState("");
  const [lastReportSection, setLastReportSection] = useState("");
  const [showReportFilters, setShowReportFilters] = useState(false);
  const [showSectionalFilters, setShowSectionalFilters] = useState(false);
  const [sectionFilter, setSectionFilter] = useState("");

  // for Sectional filters
  const [statusFilter, setStatusFilter] = useState("");
  const [userFilter, setUserFilter] = useState("");
  const [departmentFilter, setDepartmentFilter] = useState("");
  const [mentionOptions, setMentionOptions] = useState([]);
  const [mentionId, setMentionId] = useState([]);
  const [downloadshow, setDownloadshow] = useState(false);
  const handleSectionChange = (value) => {
    setSectionFilter(value);
  };
  // sectional filters change handles
  const handleStatusChange = (value) => {
    setStatusFilter(value);
  };

  const handleDepartmentChange = (value) => {
    setDepartmentFilter(value);
  };

  function getToken() {
    return window.localStorage.getItem("token");
  }

  const fetchMentionOptions = () => {
    const token = getToken();
    axios
      .get(`${base_url}/api/comments/get-users`, {
        params: {
          department: "",
        },
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setMentionOptions(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getHubList = async () => {
    setLoading(true);
    if (hubData.length === 0) {
      try {
        const response = await axios.get(
          `${base_url}/api/hubs/list?full_access=${false}`
        );
        if (response.status === 200) {
          setHubData(response.data.obj);
        }
      } catch (error) {
        // Handle the error
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  function getToken() {
    return window.localStorage.getItem("token");
  }

  const commentCall = () => {
    setSearched(true);
    setCommentsLoading(true);
    setTheId("");
    let reportId = "";
    let report_section = "";
    if (selectedReportID) {
      reportId = `&report_id=${selectedReportID}`;
    }
    if (sectionFilter) {
      report_section = `&report_section=${sectionFilter}`;
    }
    const payload = {
      filters: {
        status: statusFilter,
        mention: mentionId,
        user: userFilter,
        department: departmentFilter,
      },
    };
    const token = getToken();
    axios
      // .get(`${base_url}/api/comments/get-reports?hub_id=${selectedHubID}`, {
      .post(
        `${base_url}/api/comments/get-reports-comments?hub_id=${selectedHubID}${reportId}${report_section}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // Handle the successful response
        setCommentList(response.data);
        setCurrentPage(1);
        setCommentsLoading(false);
      })
      .catch((error) => {
        // Handle the error
        setCommentsLoading(false);
        console.error(error);
      });
  };

  // for calling the comments related to the particular Report
  const handleSelectChange = async (
    hub_id,
    parent_id,
    report_section,
    theId
  ) => {
    const token = getToken();
    const payloadReport = {
      filters: {
        status: statusFilter,
        mention: mentionId,
        user: userFilter,
        department: departmentFilter,
      },
    };
    setTheId(theId);
    setComments([]);
    setIsLoading(true);
    setLastHubId(hub_id);
    setLastParentId(parent_id);
    setLastReportSection(report_section);
    // setParentidToFetch(parent_id)

    try {
      const response = await axios.post(
        `${base_url}/api/comments/get-cms-comments`,
        payloadReport,
        {
          params: {
            hub_id: hub_id,
            parent_id: parent_id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;
      setComments(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getReportList = () => {
    const token = getToken();
    axios
      // .get(`${base_url}/api/comments/get-reports?hub_id=${selectedHubID}`, {
      .get(`${base_url}/api/comments/get-reports?hub_id=${selectedHubID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the successful response
        setReportList(response.data);
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  };

  const handleApply = () => {
    if (!selectedHubID) {
      alert("Please select a hub");
      return;
    }
    commentCall();
  };

  // Get current comments based on pagination
  const indexOfLastComment = currentPage * commentsPerPage;
  const indexOfFirstComment = indexOfLastComment - commentsPerPage;
  const currentComments = commentList.slice(
    indexOfFirstComment,
    indexOfLastComment
  );

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (selectedHubID) {
      getReportList();
    }
  }, [selectedHubID]);

  const downloadComments = () => {
    const payload = {
      filters: {
        status: statusFilter,
        mention: mentionId,
        user: userFilter,
        department: departmentFilter,
      },
    };
    const token = getToken();
    let q = `${base_url}/api/comments/export-comments?hub_id=${selectedHubID}`;
    if (sectionFilter) {
      q += `&report_section=${sectionFilter}`;
    }
    if (selectedReportID) {
      q += `&report_id=${selectedReportID}`;
    }

    axios
      // .get(`${base_url}/api/comments/get-reports?hub_id=${selectedHubID}`, {
      .post(
        `${q}`,
        payload,
        // reportID ,filters as pay load
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // Handle the successful response
        // response will be a csv file
        //download the file
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "text/csv;charset=utf-8",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "comments.csv");
        document.body.appendChild(link);
        link.click();
        setDownloadshow(false);
      })

      .catch((error) => {
        // Handle the error
        setDownloadshow(false);
        console.error(error);
      });
  };

  return (
    <>
      <InterfaceHeader />
      <div style={{ display: "flex", marginTop: "100px" }}>
        {/* <Button
          style={{
            marginLeft: "20px",
          }}
          onClick={() => {
            //Go Back
            window.history.back();
          }}
        >
          Go Back <ArrowLeftOutlined />
        </Button> */}
        <div
          style={{
            width: "20%",
            padding: "0px 20px",
            position: "fixed",
            top: "140px",
            left: 0,
            bottom: 0,
            overflowY: "auto",
          }}
        >
          <div className="Sectional Filter">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {" "}
              <h3>Filters</h3>
            </div>
            <div style={{ display: "flex" }}>
              <Select
                showSearch
                placeholder="Status"
                style={{ width: "250px" }}
                onChange={handleStatusChange}
                value={statusFilter || undefined}
              >
                <Option value="Addressed">Addressed</Option>
                <Option value="Re-addressed">Re-addressed</Option>
                <Option value="Pending">Pending</Option>
                <Option value="Satisfactory">Satisfactory</Option>
              </Select>
              <button
                style={{ border: "none", background: "none", color: "grey" }}
                onClick={() => setStatusFilter("")}
              >
                <CloseOutlined />
              </button>
            </div>
            <div style={{ display: "flex" }}>
              <Select
                showSearch
                placeholder="Mentions"
                style={{ width: "250px" }}
                onFocus={() =>
                  mentionOptions.length === 0 ? fetchMentionOptions() : null
                }
                onChange={(value) => {
                  const selectedMentionOption = mentionOptions.find(
                    (item) => item.email_id === value
                  );
                  setMentionId(
                    selectedMentionOption
                      ? selectedMentionOption.email_id
                      : null
                  );
                }}
                value={mentionId || undefined}
                loading={loading}
                filterOption={(input, option) => {
                  const displayName = option.props.children[0].props.children;
                  const emailId = option.props.children[1].props.children;
                  const label = `${displayName} ${emailId}`;
                  return label.toLowerCase().includes(input.toLowerCase());
                }}
              >
                {mentionOptions.map((item) => (
                  <Option key={item.email_id} value={item.email_id}>
                    <div>{item.display_name}</div>
                    <div>{item.email_id}</div>
                  </Option>
                ))}
              </Select>
              <button
                style={{ border: "none", background: "none", color: "grey" }}
                onClick={() => setMentionId([])}
              >
                <CloseOutlined />
              </button>
            </div>
            <div style={{ display: "flex" }}>
              <Select
                showSearch
                placeholder="User"
                style={{ width: "250px" }}
                onFocus={() =>
                  mentionOptions.length === 0 ? fetchMentionOptions() : null
                }
                onChange={(value) => {
                  const selectedMentionOption = mentionOptions.find(
                    (item) => item.email_id === value
                  );
                  setUserFilter(
                    selectedMentionOption
                      ? selectedMentionOption.email_id
                      : null
                  );
                }}
                value={userFilter || undefined} // Conditionally set value prop to undefined if userFilter is empty
                loading={loading}
                filterOption={(input, option) =>
                  (option.props.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {mentionOptions.map((item) => (
                  <Option key={item.email_id} value={item.email_id}>
                    {item.display_name}
                  </Option>
                ))}
              </Select>
              <button
                style={{ border: "none", background: "none", color: "grey" }}
                onClick={() => setUserFilter("")}
              >
                <CloseOutlined />
              </button>
            </div>
            <div style={{ display: "flex" }}>
              <Select
                showSearch
                placeholder="Departments"
                style={{ width: "250px" }}
                onChange={handleDepartmentChange}
                value={departmentFilter || undefined}
              >
                <Option value="RESEARCH">Research</Option>
                <Option value="TECH">Tech</Option>
                <Option value="ANALYST">Anaylyst</Option>
                <Option value="EDITING">Editing</Option>
                <Option value="QC1">QC1</Option>
                <Option value="QC2">QC2</Option>
                <Option value="MARKETING">Marketing</Option>
              </Select>
              <button
                style={{ border: "none", background: "none", color: "grey" }}
                onClick={() => setDepartmentFilter("")}
              >
                <CloseOutlined />
              </button>
            </div>
            <Button type="primary" onClick={() => commentCall()}>
              Apply
            </Button>
            <Button
              type="secondary"
              onClick={() => {
                setStatusFilter("");
                setMentionId([]);
                setUserFilter("");
                setDepartmentFilter("");
              }}
            >
              Reset
            </Button>
          </div>
        </div>

        <div
          style={{
            width: "75%",
            borderLeft: "1px solid grey",
            marginLeft: "20%",
          }}
        >
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* Select Hubs */}
              <Select
                showSearch
                placeholder="Select Hubs"
                style={{ width: "40%" }}
                onFocus={() => (hubData.length === 0 ? getHubList() : null)}
                onChange={(value) => {
                  const selectedHubObject = hubData.find(
                    (item) => item.id === value
                  );
                  setSelectedHubID(
                    selectedHubObject ? selectedHubObject.id : null
                  );
                }}
                value={selectedHubID}
                loading={loading}
                filterOption={(input, option) =>
                  (option.props.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {hubData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>

              {/* Select Reports */}
              <Select
                showSearch
                placeholder="Select Reports (Optional)"
                style={{ width: "30%" }}
                onChange={(value) => {
                  setSelectedReportID(value);
                }}
                value={selectedReportID}
                filterOption={(input, option) =>
                  (option.props.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {reportList.map((item) => (
                  <Option key={item._id} value={item.report_id}>
                    {item.title}
                  </Option>
                ))}
              </Select>
              <Select
                showSearch
                placeholder="Report Section (Optional)"
                style={{ width: "30%" }}
                onChange={handleSectionChange}
                value={sectionFilter || undefined}
              >
                <Option value="indicators">Indicators</Option>
                <Option value="typedetail">Type Detail</Option>
                <Option value="typeoverview">Type Overview</Option>
                <Option value="marketShareAnalysis">
                  Market Share Analysis
                </Option>
                <Option value="keystretegicmoves">keyStretegicMoves</Option>
                <Option value="executivesummary">Executive Summary</Option>
                <Option value="regulatoryframework">
                  Regulatory Framework
                </Option>
                <Option value="regulatoryframework2">
                  Regulatory Framework 2
                </Option>
              </Select>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="primary" onClick={handleApply}>
                Apply
              </Button>
              <Button
                type="secondary"
                onClick={() => {
                  setSelectedHubID(null);
                  setSelectedReportID(null);
                  setCommentList([]);
                  setTheId("");
                  setStatusFilter("");
                  setMentionId([]);
                  setUserFilter("");
                  setDepartmentFilter("");
                  setSectionFilter("");
                  setSearched(false);
                }}
              >
                Reset
              </Button>
              {downloadshow ? (
                <Button type="secondary">
                  <Loading3QuartersOutlined spin />
                </Button>
              ) : (
                <Button
                  type="secondary"
                  onClick={() => {
                    setDownloadshow(true);
                    downloadComments();
                  }}
                >
                  Download
                </Button>
              )}
            </div>
          </div>

          <div>
            {!searched ? (
              <div
                style={{
                  width: "100%",
                  height: "80vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
              >
                <p>Please select hub and reports to see comments history</p>
              </div>
            ) : commentsLoading ? (
              <div
                style={{
                  width: "100%",
                  height: "80vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
              >
                <h3>Loading...</h3>
              </div>
            ) : searched && selectedHubID && commentList?.length < 1 ? (
              <div
                style={{
                  width: "100%",
                  height: "80vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
              >
                <p>No Records Found</p>
              </div>
            ) : (
              currentComments &&
              currentComments.map((e) => (
                <div style={{ border: "1px solid grey" }}>
                  <div
                    key={e.id}
                    style={{
                      padding: "16px",
                      width: "100%",
                      marginTop: "10px",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <h3 style={{ marginBottom: "0px" }}>{e.report_name}</h3>
                      <p>{e.report_section}</p>
                      <p>{e.path}</p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          padding: "0px 10px",
                          borderRight: "1px solid #002f75",
                        }}
                      >
                        <p>Total Comments:- {e.totalcomments}</p>
                        <p>Total Mentions:- {e.totalMentions?.length}</p>
                        <p style={{ fontSize: "10.5px" }}>
                          Last Updated At:-{" "}
                          {`${new Date(e.updatedAt).toLocaleDateString(
                            "en-US",
                            {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            }
                          )}, ${new Date(e.updatedAt).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            }
                          )}`}
                        </p>
                      </div>
                      <div
                        style={{
                          padding: "0px 10px",
                        }}
                      >
                        {Object.entries(e.totalStatus || {}).map(
                          ([key, value]) => {
                            if (key !== "_id") {
                              return (
                                <p key={key}>
                                  {key} Comments: {value}
                                </p>
                              );
                            }
                            return null;
                          }
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        style={{ background: "none", border: "none" }}
                        onClick={() =>
                          handleSelectChange(
                            e.hub_id,
                            e.parent_id,
                            e.report_section,
                            e._id
                          )
                        }
                      >
                        <ArrowDownOutlined />
                      </button>
                      {isLoading && e._id == theId && (
                        <p>Loading comments...</p>
                      )}
                    </div>
                    {comments.length > 0 && e._id == theId && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button onClick={() => setTheId("")}>
                            <CloseOutlined />
                          </button>
                        </div>
                        <table>
                          <thead>
                            <tr>
                              {/* <th>Report Name</th>
                       <th>Section</th> */}
                              <th>User</th>
                              <th>Date</th>
                              {/* <th>Location</th> */}
                              <th>Mentions</th>
                              <th>Comment</th>
                              <th>Status</th>
                              <th>Team</th>
                              <th>Addressed By</th>
                              <th>Addressed Date</th>
                            </tr>
                          </thead>
                          <tbody style={{ textAlign: "left" }}>
                            {comments.map((item, index) => (
                              <tr key={index}>
                                {/* <td>{item.reportName}</td>
                         <td>{item.section}</td> */}
                                <td>{item.user_name}</td>
                                <td>{`${new Date(
                                  item.created_at
                                ).toLocaleDateString("en-US", {
                                  month: "short",
                                  day: "numeric",
                                  year: "numeric",
                                })}, ${new Date(
                                  item.created_at
                                ).toLocaleTimeString("en-US", {
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                })}`}</td>
                                {/* <td>{item.location}</td> */}
                                <td>
                                  {item.mentions.map(
                                    (mention, mentionIndex) => (
                                      <div key={mentionIndex}>{mention}</div>
                                    )
                                  )}
                                </td>
                                <td>{item.comment}</td>
                                <td>{item.status}</td>
                                <td>{item.department}</td>
                                <td>{item.addressedBy}</td>

                                {!item.addressedDate ? (
                                  <td>Not Addressed</td>
                                ) : (
                                  <td>{`${new Date(
                                    item.addressedDate
                                  ).toLocaleDateString("en-US", {
                                    month: "short",
                                    day: "numeric",
                                    year: "numeric",
                                  })}, ${new Date(
                                    item.addressedDate
                                  ).toLocaleTimeString("en-US", {
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  })}`}</td>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>

          {/* Pagination */}
          {commentList?.length > 0 && (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Prev
              </button>
              <span style={{ margin: "0px 10px" }}>Page {currentPage}</span>
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={
                  currentPage ===
                  Math.ceil(commentList.length / commentsPerPage)
                }
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CommentsList;
