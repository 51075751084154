import React, { Component } from "react";
import Mordor_icon from "../favicon.png";
//import { Button} from "antd";
import config from "../../../config/Api";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";

import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";

import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";

import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";
import axios from "axios";
import font_html from "../../font_style.js";
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

export default class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxValue: 0,
      data: [
        {
          label: "2015",
          value: "100",
        },
        {
          label: "2016",
          value: "30",
        },
        {
          label: "2017",
          value: "30",
        },
      ],
    };
    this.datavalidate = this.datavalidate.bind(this);
    this.resetChart = this.resetChart.bind(this);
  }

  componentDidMount() {
    if (this.props.data.data) {
      this.setState({
        data: this.datavalidate(this.props.data.data),
      });
      setTimeout(() => {
        this.resetChart();
      }, 1000);
    }
  }

  resetChart(c) {
    let a = window.btoa(JSON.stringify(this.props.data));
    var revenueChart = FusionCharts(
      `${this.props.data.graph_type + this.props.data.component + "line"}`
    );
    // get chart xml data
    var svgString = revenueChart.getSVGString();
    let svgdata = new DOMParser().parseFromString(svgString, "text/xml");
    var doc = new DOMParser().parseFromString(font_html, "text/html");
    // svgdata.prepend(doc.documentElement)
    let defs_data = svgdata.getElementsByTagName("defs")[0];
    defs_data.prepend(doc.documentElement);
    axios
      .post(`${config.api.base_url}/api/graphs/data?data=${a}`, {
        stream: window.btoa(svgdata.documentElement.outerHTML),
      })
      .then((res) => {});
  }

  datavalidate(data) {
    for (let item of data) {
      item.label = item.label + "";
    }
    return data;
  }

  render() {
    let diff = 0,
      avgDiff = 0,
      yMinVal = 0,
      yMaxVal = 0,
      valArr = [],
      width = 1310,
      height = 655,
      headers = "",
      footers = "Mordor Intelligence";

    for (let i = 0; i < this.props.data.data.length; i++) {
      valArr.push(this.props.data.data[i]);
    }

    valArr = valArr.sort((a, b) => {
      return a.value - b.value;
    });

    for (let i = 0; i < valArr.length - 2; i++) {
      diff = diff + (valArr[i + 1].value - valArr[i].value);
    }

    avgDiff = diff / (valArr.length - 1);

    yMinVal = valArr[0].value;
    yMaxVal = valArr[valArr.length - 1].value;

    if (yMinVal - avgDiff < 0 && valArr[0].value > 0) {
      yMinVal = 0;
    } else {
      yMinVal = yMinVal - avgDiff;
    }
    yMaxVal = yMaxVal + avgDiff;

    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (this.props.headers) headers = this.props.headers;

    let chartConfigs = {
      type: "line",
      dataFormat: "json",
      width: width,
      height: height,
      id: this.props.data.graph_type + this.props.data.component + "line",
      dataSource: {
        chart: {
          theme: "fusion",
          caption: this.props.data.heading,
          canvasTopPadding: 100,
          captionFontColor: "#6a6a6a",
          captionFont: "regular_bold_rd",
          captionFontSize: 34,
          animation: false,
          showanchors: 1,

          anchorRadius: 3,
          anchorBorderColor: "#9e0e0e",
          anchorBorderThickness: 1,
          anchorBgColor: "#ffffff",
          anchorSides: 0,

          adjustDiv: "0",
          divLineDashed: "0",
          divLineColor: "#C0C0C0",
          divLineAlpha: "80",
          divLineDashed: "0",

          paletteColors: "#9e0e0e",
          captionFontColor: "#7f7f7f",
          showValues: false,
          showPlotBorder: "0",
          maxBarHeight: 2,

          showLabels: true,
          labelDisplay: "WRAP",
          labelFontSize: 24,

          valueFont: "regular_semibold_rd",
          valueFontColor: "#7f7f7f",
          valueFontBold: false,
          valueFontSize: 24,
          valuePosition: "outside",
          placeValuesInside: "0",
          rotateValues: false,

          bgColor: "#ffffff",
          bgImageAlpha: "60",
          bgImageScale: "400",
          bgImageVAlign: "bottom",
          bgImageHAlign: "middle",
          bgImageDisplayMode: "center",

          showYAxisLine: true,
          yAxisLineColor: "#4A90E2",
          showYAxisValues: true,
          yAxisValueFont: "regular_semibold_rd",
          yAxisValueFontSize: 24,
          yAxisValueFontColor: "#7f7f7f",
          numbersuffix: this.props?.yAxisPercentage ? "%" : "",
          yAxisMinValue:
            (this.props?.yAxisPercentage || this.props?.maxMin) && `${yMinVal}`,
          yAxisMaxValue:
            (this.props?.yAxisPercentage || this.props?.maxMin) && `${yMaxVal}`,

          showXAxisLine: false,
          xAxisLineColor: "#4A90E2",
          showXAxisValues: true,
          xAxisValueFontColor: "#7f7f7f",
          xAxisValueFontSize: 24,
          centerXAxisName: false,

          chartTopMargin: "50",
          chartLeftMargin: 70,
          chartBottomMargin: 90,

          baseFont: "regular_semibold_rd",
          baseFontSize: 24,
          baseFontColor: "#7f7f7f",
        },
        annotations: {
          autoScale: "0",
          groups: [
            {
              id: "footer",
              items: [
                {
                  id: "dyn-label-bg",
                  // "type": "rectangle",
                  showBorder: "0",
                  //"borderColor": "12345d",

                  //"fillcolor": "#ffffff",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#6a6a6a",
                  fontsize: 28,
                  font: "regular_semibold_rd",
                  text: "Source:",
                  bold: "1",
                  wrap: "1",
                  wrapWidth: width - 20,
                  x: "70",
                  y: height - 32,
                },
              ],
            },
            {
              id: "sub-footer",
              items: [
                {
                  id: "dyn-label-bg",
                  //"type": "rectangle",
                  showBorder: "1",
                  // "borderColor": "12345d",

                  //"fillcolor": "#ffffff",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#6a6a6a",
                  fontsize: 28,
                  font: "regular_semibold_rd",
                  text: footers,
                  //"bold": "0",
                  wrap: "1",
                  wrapWidth: "100%",
                  x: 170,
                  y: height - 32,
                },
              ],
            },
            {
              id: "mordor-icon",
              xScale_: "5",
              yScale_: "5",
              items: [
                {
                  id: "Brian-user-icon",
                  type: "image",
                  url: Mordor_icon,
                  x: "1180",
                  y: height - 72,
                  xScale: "200",
                  yScale: "200",
                },
              ],
            },
          ],
        },
        data: [
          ...this.state.data,
          {
            vLine: "true",
          },
        ],
      },
    };
    if (this.props.data?.reseller === true) {
      chartConfigs.dataSource.annotations.groups = [];
    }
    return (
      <div>
        <ReactFC {...chartConfigs} />
      </div>
    );
  }
}
