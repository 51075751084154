import React, { Component } from "react";
import { Table } from "antd";
import { Base64 } from "js-base64";

export class TableContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data ? this.props.data : [],
      column_title: this.props.column_title ? this.props.column_title : [],
      data_source: this.props.data_source ? this.props.data_source : [],
    };
  }

  render() {
    const { section_id } = this.props.data.meta;
    const pageId = section_id && Base64.encode(section_id);

    return (
      <>
        <div id={pageId} className="page">
          <Table
            className="table_container"
            bordered={false}
            pagination={false}
            dataSource={this.state.data_source}
            columns={this.state.column_title.map((title) => ({
              title: title
                .replace(/_/g, " ")
                .replace(/(?: |\b)(\w)/g, function (str) {
                  return str.toUpperCase();
                }),
              dataIndex: title,
              key: title,
              align: "left",
              width: "auto",
            }))}
          />
        </div>
      </>
    );
  }
}

export default TableContainer;
