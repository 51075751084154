import React from "react";
import { Button, Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withWindowSizeHOC } from "../../Common/withWindowSizeHOC";
import {
  editorTabChange,
  handleSaveDraft,
  handleDirectPublish,
  handleEditoverlayClick,
  tabsElement,
} from "../template.helperFunctions";
import { Page, Container } from "./style";

import {
  setDraftVersions,
  getDrafts,
  getPublishedVersions,
  setNoDraftsFound,
  setDraftStage,
  setDraftId,
  saveDraftApi,
  directPublishApi,
  setAnalysisHeadingEditorState,
  setAnalysisHeadingEditorStateForDraft,
  setAnalysisHeadingEditorStateForPublished,
} from "../../reducersAndActions/Actions";

import Header from "../../components/Header";
import Heading from "../../components/Heading";
import Footer from "../../components/Footer";
import Analysis from "../../components/Analysis";
import Subheading from "../../components/Subheading";
import Graph from "../../Graphs";
import TableContainer from "../../pages/TableContainer";
import axios from "axios";
import config from "../../config/Api";
import _ from "lodash";

var Base64 = require("js-base64").Base64;

class Template1000 extends React.Component {
  state = {
    graphFooterText:
      "Source: Mordor Intelligence Analysis based on Alltech Feed Survey, FAO, Other Sources & Primary Research",
    data: this.props.data ? this.props.data : {},
    tabPosition: "top",
    editor_active_tab: "analysis",
    reseller: false,
    analysis_heading: "",
    previousDataArrays: [],
    isAnalysisPresent: false,
    timeoutId: null,
    isAllotment: false,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
    if (nextProps.data && nextProps.data.meta) {
      if (nextProps.data.meta.sample && nextProps.data.meta.reseller) {
        this.setState({ reseller: true });
      }
    }
  }

  // Function to call your autosave API using the data from local storage
  callAutosaveAPI() {
    let isConditionalTemplate = [
      "typedetail",
      "typeoverview",
      "indicator",
    ].includes(this.props.template_type);
    if (
      this?.props?.edit_mode === false ||
      !isConditionalTemplate ||
      !this.state.isAnalysisPresent
    )
      return; // Don't call the API if the user is not in edit mode
    const autosaveData = sessionStorage.getItem("autosaveData");
    //Convert autosaveData to Javascript array
    const autosaveDataArray = JSON.parse(autosaveData);
    //Compare new Array with previous Array
    if (
      _.isEqual(autosaveDataArray, this.state.previousDataArrays) ||
      autosaveDataArray?.length < 1
    )
      return; // Don't call the API if the data is the same as the previous data
    const data = {
      parent_id: this.props.parent_id,
      type: this.props.template_type,
      analysis: autosaveDataArray,
      source: this.props.sources_editor_state,
      stage: this.props.draft_versions.stage
        ? this.props.draft_versions.stage
        : "INITIAL",
      hub_id: this.props.TOC_DATA.hub_id,
      dimensions: this.props.TOC_DATA.dimensions,
      heading: this.props.analysis_heading_editor_state_for_draft,
    };
    if (autosaveData) {
      axios({
        method: "POST",
        url: `${config.api.base_url}/api/editing/autoSaveDraft`,
        data: data,
      })
        .then((response) => {
          //store the autosaveDataArray in previousDataArrays
          this.setState({ previousDataArrays: [...autosaveDataArray] });
          // Handle the response if needed
        })
        .catch((error) => {
          // Handle errors
          console.log("ERR:", error);
        });
      // let url = config.api.base_url;
      // Make an API call using Axios
      // axios.post(`${url}/api/editing/autoSaveDraft`, JSON.parse(autosaveData), {
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      // })
      // .then(response => {
      //   // Handle the response if needed
      // })
      // .catch(error => {
      //   // Handle errors
      // });
    }
  }

  componentDidMount() {
    const { data, template, idx } = this.props;
    let newTemplateData;
    if (["global_data_pack"].includes(template)) {
      newTemplateData =
        data &&
        data.data &&
        data.data[idx].multitemplate_names.map((elem) => elem)[0];
    } else {
      newTemplateData = data && data.data && data.data.multitemplate_names[idx];
    }
    let result = false;
    newTemplateData.config_data.forEach((item) => {
      if (item.type === "analysis") {
        result = true;
      }
    });
    if (result) {
      this.setState({ isAnalysisPresent: true });
    }

    // Set up the autosave interval to call the API every 5 seconds
    // this.autosaveInterval = setInterval(() => {
    //   this.callAutosaveAPI();
    //   //Do for 1minute time interval
    // }, 60000);
  }

  // componentWillUnmount() {
  //   //Clear the autosave interval and perform any cleanup
  //   clearInterval(this.autosaveInterval);
  // }

  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.data.title !== this.props.data.title) {
      this.setState({
        data: this.props.data,
      });

      if (this.props.data && this.props.data.meta) {
        if (this.props.data.meta.sample && this.props.data.meta.reseller) {
          this.setState({ reseller: true });
        }
      }
    }

    if (this.props.draft_drawer_visible !== prevProps.draft_drawer_visible) {
      if (this.props.draft_drawer_visible) {
        this.props.getDrafts(this.props.parent_id, this.props.template_type);
      }
    }

    if (
      this.props.published_drawer_visible !== prevProps.published_drawer_visible
    ) {
      if (this.props.published_drawer_visible) {
        this.props.getPublishedVersions(this.props.parent_id);
      }
    }

    if (this.props.edit_mode !== prevProps.edit_mode) {
      this.setState({
        editor_active_tab: "analysis",
      });
    }
    if (this.props.edit_mode) {
      if (
        this.props.analysis_editor_state !== prevProps.analysis_editor_state
      ) {
        sessionStorage.setItem(
          "autosaveData",
          JSON.stringify(this.props.analysis_editor_state)
        );
        if (!this.state.isAllotment) {
          if (this.state.timeoutId) {
            clearTimeout(this.state.timeoutId);
          }
          this.setState({ isAllotment: true });
          const timeoutId = setTimeout(() => {
            this.callAutosaveAPI();
            this.setState({ isAllotment: false });
          }, 60000);
          this.setState({ timeoutId });
        }
      }
    }
  }

  render() {
    const setState = this.setState.bind(this);
    const func = {
      editorTabChange,
      handleDirectPublish,
      handleSaveDraft,
      handleEditoverlayClick,
      setState,
    };
    const { idx, data, template, template_title, slideNo } = this.props;
    const pageId = data.section_id ? Base64.encode(data.section_id) : undefined;
    let slideNoElementId = undefined;
    let templateData;
    let template_name;

    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }

    if (["global_data_pack"].includes(template)) {
      templateData =
        data &&
        data.data &&
        data?.data?.[idx].multitemplate_names.map((elem) => elem)[0];
      template_name = templateData.template_name;
    } else {
      templateData = data && data.data && data.data.multitemplate_names[idx];
      template_name = templateData.template_name;
    }

    return (
      <Page className="page" id={pageId}>
        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Header slideTitle={data.type} />
        ) : null}

        {templateData.config_data.map((item, index) => {
          if (item.data) {
            if (item.type === "graph") {
              return (
                <Container formInterface={this.props?.fromInterface}>
                  <Heading heading={template_title} />
                  <Graph
                    chartHeadingGap={item.data.heading.length > 60 ? 10 : 0}
                    wrapHeadingWidth={400}
                    yHeadingMargin={item.data.heading.length > 60 ? 30 : 25}
                    xHeadingMargin={0}
                    legendsYPosition={100}
                    legendsXPosition={1200}
                    graph_config={item.data.graph_config}
                    headers={item.data.heading}
                    data={item.data.data}
                    width={
                      !this.props.fromInterface ||
                      this.props.fromInterface === undefined
                        ? "1200"
                        : "1080"
                    }
                    height={532}
                    totalData={item.data}
                    graphType={item.data.graphType}
                    dataType={item.data.dataType}
                    yAxisPercentage={item.data.YAxisPercentage}
                    legends={true}
                    legendsPositionDynamic={true}
                    dynamiclegends={item.data.legends}
                    template_name={template_name}
                    fromInterface={this.props.fromInterface ? true : false}
                    slideNo
                  />
                </Container>
              );
            } else if (item.type === "analysis") {
              return (
                <Container formInterface={this.props.fromInterface}>
                  <Heading heading={template_title} />
                  {!this.props.edit_mode ? (
                    item.data.meta.heading ? (
                      <Subheading subheading={item.data.meta.heading} />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {this.props.edit_mode ? (
                    this.props.no_drafts_found &&
                    this.props.ACTIVE_TAB === "drafts" ? (
                      <div
                        style={{ border: "1px solid grey", padding: "20px" }}
                        className="no-drafts-overlay"
                      >
                        <p>There is nothing in draft right now</p>
                        <Button
                          onClick={() => handleEditoverlayClick(this.props)}
                        >
                          Edit
                        </Button>
                      </div>
                    ) : this.props.initial_draft_versions_loading ? (
                      <Spin
                        style={{ display: "flex", justifyContent: "center" }}
                      />
                    ) : (
                      tabsElement(
                        item,
                        data,
                        this.state,
                        this.props,
                        func,
                        2700
                      )
                    )
                  ) : (
                    <>
                      <Analysis
                        trends={item.data}
                        edit_mode={this.props.edit_mode}
                        section_id={data.section_id}
                      />
                    </>
                  )}
                </Container>
              );
            } else if (item.type === "table") {
              return (
                <Container formInterface={this.props?.fromInterface}>
                  <Heading heading={template_title} />
                  <TableContainer
                    data={this.state.data}
                    column_title={Object.keys(item?.data[0][0])}
                    data_source={item.data[0]}
                  />
                </Container>
              );
            }
          }
        })}

        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Footer
            slug={data.slug}
            slideNo={slideNo}
            slideNoElementId={slideNoElementId}
            fromInterface={this.props.fromInterface}
          />
        ) : null}
      </Page>
    );
  }
}

function mapStateToPros(state) {
  return {
    ACTIVE_TAB: state.ACTIVE_TAB,
    draft_versions: state.draft_versions,
    no_drafts_found: state.no_drafts_found,
    user_edit_access: state.user_edit_access,
    specific_draft_loading: state.specific_draft_loading,
    initial_draft_versions_loading: state.initial_draft_versions_loading,
    TOC_DATA: state.TOC_DATA,
    cache_id: state.cache_id,
    parent_id: state.parent_id,
    character_count_error: state.character_count_error,
    template_type: state.template_type,
    save_draft_loader: state.save_draft_loader,
    direct_publish_loader: state.direct_publish_loader,
    analysis_editor_state: state.analysis_editor_state,
    sources_editor_state: state.sources_editor_state,
    analysis_heading_editor_state: state.analysis_heading_editor_state,
    analysis_heading_editor_state_for_published:
      state.analysis_heading_editor_state_for_published,
    analysis_heading_editor_state_for_draft:
      state.analysis_heading_editor_state_for_draft,
    analysis_char_count: state.analysis_char_count,
    USER_DATA: state.USER_DATA,
  };
}

const mapDispatchToProps = {
  setDraftVersions,
  getDrafts,
  getPublishedVersions,
  setNoDraftsFound,
  setDraftStage,
  setDraftId,
  saveDraftApi,
  directPublishApi,
  setAnalysisHeadingEditorState,
  setAnalysisHeadingEditorStateForDraft,
  setAnalysisHeadingEditorStateForPublished,
};

export default withWindowSizeHOC(
  withRouter(connect(mapStateToPros, mapDispatchToProps)(Template1000))
);
