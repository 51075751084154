import React from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import key_industry_trends_hidden from "../../../images/Architectural_Coatings_ValueChain.svg";
import { ArchitecturalCoatingVCWrapper } from "./styles";

var Base64 = require("js-base64").Base64;

export const ValueChainDemoData2 = {
  title: "Value Chain & Distribution Channel Analysis",
  subtitle: "Value Chain & Distribution Channel Analysis",
  type: "valueChain",
  section_id: "",
  template: "valueChainlubricant",
  url: "https://s3.mordorlabs.com/Value%20Chain/1625051083229_test~Value_Chain_Value_Chain.svg",
  data: [
    {
      type: "Base Oil Companies",
      list: [
        "Chevron Corporation",
        "Holly Frontier",
        "Exxon Mobil Corporation",
      ],
    },
    {
      type: "Additive Companies",
      list: [
        "Afton Chemical",
        "The Lubrizol Corporation",
        "Chevron Corporation",
      ],
    },
    {
      type: "Lubricant Companies",
      list: ["Royal Dutch Shell Plc", "BP p.l.c.", "Valvoline Inc."],
    },
    {
      type: "OEMs",
      list: [
        "Honda Motor Co.",
        "Ltd.",
        "Ford Motor Company",
        "Daimler Trucks North America LLC",
      ],
    },
    {
      type: "Distributors",
      list: [
        "U.S. Venture",
        "Inc.",
        "Brenntag SE",
        "Comercial Importadora",
        "(CISA)",
      ],
    },
  ],
};

class ArchitecturalCoatingVC extends React.Component {
  state = {
    data: this.props.data ? this.props.data : ValueChainDemoData2,
    section1_data: [],
    section2_data: [],
    section3_data: [],
    reseller: false,
  };

  componentDidMount = () => {
    this.splitToChunks();
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.meta) {
      if (props.data.meta.sample && props.data.meta.reseller) {
        return {
          reseller: true,
        };
      }
    }
    return null;
  }

  splitToChunks = () => {
    const { data } = this.props.data ? this.props.data : this.state.data;
    let section1_data = data && data.length > 1 ? data.slice(0, 3) : [];
    let section2_data = data && data.length > 1 ? data.slice(3, 4) : [];
    let section3_data = data && data.length > 1 ? data.slice(4, 7) : [];
    this.setState({
      section1_data,
      section2_data,
      section3_data,
    });
  };

  render() {
    const { section_id } = this.state.data;
    const { section1_data, section2_data, section3_data } = this.state;
    const { slideNo } = this.props;
    const slug = this.state.data.slug;
    const pageId = Base64.encode(section_id);
    const { multi_urls, url } = this.state.data;

    return (
      <div className="page" id={pageId}>
        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Header slideTitle={this.state.data.type} />
        ) : null}
        <ArchitecturalCoatingVCWrapper>
          <h2 className="heading valuchain_title">
            {this.props.data ? this.props.data.title : this.state.data.title}
          </h2>

          <div style={{ height: "560px", position: "relative" }}>
            {Array.isArray(multi_urls) && multi_urls?.length ? (
              multi_urls.map((item) => (
                <img
                  src={item.url}
                  alt="Value Chain"
                  style={{ width: "100%", height: "100%", marginTop: "10px" }}
                />
              ))
            ) : (
              <img
                src={url}
                alt="Value Chain"
                style={{ width: "100%", height: "100%", marginTop: "10px" }}
              />
            )}

            {section1_data.slice(0, 1).map((item) => (
              <div className="section1_list1 section_data1">
                {item.list.map((elem) => (
                  <div className="font_design_architectural_coating_vc">
                    <div>•</div>
                    <div style={{ marginLeft: "5px" }}>{elem}</div>
                  </div>
                ))}
              </div>
            ))}

            {section1_data.slice(1, 2).map((item) => (
              <div className="section1_list2 section_data1">
                {item.list.map((elem) => (
                  <div className="font_design_architectural_coating_vc">
                    <div>•</div>
                    <div style={{ marginLeft: "5px" }}>{elem}</div>
                  </div>
                ))}
              </div>
            ))}

            {section1_data.slice(2, 3).map((item) => (
              <div className="section1_list3 section_data1">
                {item.list.map((elem) => (
                  <div className="font_design_architectural_coating_vc">
                    <div>•</div>
                    <div style={{ marginLeft: "5px" }}>{elem}</div>
                  </div>
                ))}
              </div>
            ))}

            {section2_data.map((item) => (
              <div className="section2_list section_data2">
                {item.list.map((elem) => (
                  <div className="font_design_architectural_coating_vc">
                    <div>•</div>
                    <div style={{ marginLeft: "5px" }}>{elem}</div>
                  </div>
                ))}
              </div>
            ))}

            {section3_data.slice(0, 1).map((item) => (
              <div className="section3_list1 section_data1">
                {item.list.map((elem) => (
                  <div className="font_design_architectural_coating_vc">
                    <div>•</div>
                    <div style={{ marginLeft: "5px" }}>{elem}</div>
                  </div>
                ))}
              </div>
            ))}

            {section3_data.slice(1, 2).map((item) => (
              <div className="section3_list2 section_data1">
                {item.list.map((elem) => (
                  <div className="font_design_architectural_coating_vc">
                    <div>•</div>
                    <div style={{ marginLeft: "5px" }}>{elem}</div>
                  </div>
                ))}
              </div>
            ))}
          </div>

          {!this.props.fromInterface ||
          this.props.fromInterface === undefined ? (
            <Footer
              slug={slug}
              slideNo={slideNo}
              reseller={this.state.reseller}
            />
          ) : null}
        </ArchitecturalCoatingVCWrapper>
      </div>
    );
  }
}

export default ArchitecturalCoatingVC;
