import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Pie from "../Graphs/Pie";
var Base64 = require("js-base64").Base64;

export const marketShareDemoData = {
  slideName: "marketshare",
  title: "Market Share Analysis (%), 2019",
  slideNo: 10,
  slug: "US PROBIOTICS MARKET",
  slideReference: "competitivelandscape",
  trends: [
    {
      analysis: [
        {
          text: "Consumption of probiotics benefits the animals by strengthening their immune system, maintaining intestinal micro biota, and thereby, enhancing overall gut health in animals.",
          superScript: 1,
          hyperLink: "#abc",
        },
        {
          text: "Recent outbreaks of food-borne diseases highlighted the need for reducing bacterial pathogens in the foods of animal origin. In addition, owing to the risk of developing cross-resistance and multiple antibiotic resistance in pathogenic bacteria, in both humans and livestock, subsequent limitation and ban on antibiotics have fueled the market for probiotics.",
        },
        {
          text: "Lactobacilli probiotic strain is the major constituent of food technology. Easy extraction and its benefits on the health of human and animal stock, makes it a preferable strain for probiotics. Some of the major benefits of this strain in probiotics are reduction in acute diarrhea and improvement in constipation.  The lactobacilli strain is majorly used in the probiotic market, with a share of 44.3%. ",
        },
        {
          text: "According to the World Bank, the per capita consumption of meat in China, in 2015, is around 48.3 and is expected to grow further during forecast period. The factors, such as increasing awareness regarding the benefits of lactobacilli in animal feed and high per capita consumption of meat are driving the market for lactobacilli in Asia-Pacific, followed by Europe.",
        },
        {
          text: "The bifidobacteria segment accounts for 34.4% of the probiotics market, by type. Asia-Pacific and Europe are the major markets consuming bifidobacteria, they account for a share of more than 30%. Bifidobacteria is one of the most popular probiotic organisms. These are a large group of normal intestinal organisms, with a number of benefits, such as balancing of the good and bad bacteria, and maintaining the pH. ",
        },
      ],
    },
  ],
  graphs: [
    {
      graphType: "pie",
      data: [
        {
          label: "india",
          value: 20,
        },
        {
          label: "us",
          value: 25,
        },
      ],
      url: "https://i.ibb.co/xz5vcK5/Graph-1.png",
      heading: "graph heading2",
    },
  ],
};

class MarketShare extends React.Component {
  state = {
    graphFooterText:
      "Source: Mordor Intelligence Analysis based on Alltech Feed Survey, FAO, Other Sources & Primary Research",
    data: this.props.data || marketShareDemoData,
  };
  render() {
    const { id, title, subtitle, slug, trends, graphs } = this.state.data;
    const { slideNo } = this.props;
    const pageId = id ? Base64.encode(id) : undefined;
    let slideNoElementId = undefined;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }
    return (
      <>
        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Header heading={title} subheading={subtitle} />
        ) : null}
        <div className="page" id={pageId}>
          <div className="main-content">
            <div className="main-content-left">
              {trends.map(({ title, analysis }, index) => (
                <ul className="MarketShareUL" key={index}>
                  {title && <h1 className="trends-title">{title}</h1>}
                  {analysis.map(({ text, superScript, hyperLink }, index) => (
                    <li className="MarketShareBullet" key={index}>
                      {text}{" "}
                      {superScript && (
                        <sup>
                          [<a href={hyperLink}>{superScript}</a>]
                        </sup>
                      )}
                    </li>
                  ))}
                </ul>
              ))}
            </div>
            <div className="main-content-right">
              {graphs[0] && <h3>{graphs[0].heading}</h3>}
              <Pie
                axes={false}
                data={{ data: graphs[0].data }}
                id={`id${slideNo}1`}
                headers={graphs[0].heading}
              />
            </div>
          </div>
        </div>
        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Footer
            slug={slug}
            slideNo={slideNo}
            slideNoElementId={slideNoElementId}
          />
        ) : null}
      </>
    );
  }
}

export default MarketShare;
