class Subscript {
  static get isInline() {
    return true;
  }
  static get sanitize() {
    return {
      sub: {},
    };
  }

  constructor() {
    this.button = null;
    this.state = false;
  }

  render() {
    this.button = document.createElement("button");
    this.button.type = "button";
    this.button.style.display = "flex";
    this.button.style.alignItems = "center";
    this.button.style.justifyContent = "center";
    this.button.style.width = "30px";
    this.button.style.height = "30px";
    this.button.innerHTML = `<svg width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M7.54 4L.849 19h1.09l2.24-5h7.645l2.239 5h1.09L8.459 4zm-2.915 9L8 5.464 11.375 13zM23 22v1h-5.975v-1.49c4.736-4.126 4.93-4.138 4.93-5.01 0-1.962-3.614-1.869-3.985-.39l-.97-.243c.662-2.633 5.954-2.525 5.954.633 0 1.308-.423 1.58-4.93 5.5z"/><path fill="none" d="M0 0h24v24H0z"/></svg>`;

    return this.button;
  }

  surround(range) {
    if (this.state) {
      // If subscript is already applied, do nothing for now
      return;
    }

    const selectedText = range.extractContents();

    // Create SUB element
    const sub = document.createElement("sub");

    // Append to the SUB element selected TextNode
    sub.appendChild(selectedText);

    // Insert new element
    range.insertNode(sub);
  }

  checkState(selection) {
    const text = selection.anchorNode;

    if (!text) {
      return;
    }

    const anchorElement = text instanceof Element ? text : text.parentElement;

    this.state = !!anchorElement.closest("sub");
  }
}
export default Subscript;
