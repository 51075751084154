import styled from "styled-components";

export const ProteinsVCWrapper = styled.div`
  .proteins_title {
    font-family: Avenir Roman;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    color: #002f75;
    text-transform: uppercase;
  }

  .section1_list {
    top: 46%;
    left: 3%;
    width: 22%;
    position: absolute;
    word-break: break-word;
  }

  .section2_list {
    top: 46%;
    left: 29%;
    width: 14%;
    position: absolute;
    word-break: break-word;
  }

  .section3_list {
    top: 46%;
    left: 47%;
    width: 25%;
    position: absolute;
    word-break: break-word;
  }

  .section4_list {
    top: 46%;
    left: 77%;
    width: 20%;
    position: absolute;
    word-break: break-word;
  }
`;
