import React, { Component } from "react";
import styled from "styled-components";
import {
  Modal,
  Button,
  Upload,
  Tree,
  Select,
  notification,
  Switch,
  Table,
} from "antd";
import { InboxOutlined, DownOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "./../config/Api";
import Item from "antd/lib/list/Item";
import { BulkUploadComponentWrapper } from "./UploadSheet/BulkUpload.style";

const { Dragger } = Upload;
const { Option } = Select;

const formData = new FormData();

const openNotification = (msg) => {
  let args,
    key = msg;
  if (key === true) {
    args = {
      message: "Success",
      description: "Sheet upload successful.",
      duration: 0,
      placement: "bottomLeft",
    };
    notification.success(args);
  } else if (key === false) {
    args = {
      message: "Failure",
      description: "Failed to upload sheet.",
      duration: 3.5,
      placement: "bottomLeft",
    };
    notification.error(args);
  } else {
    args = {
      message: "Warning",
      description: key,
      duration: 3.5,
      placement: "bottomLeft",
    };
    notification.warning(args);
  }
};

const CustomTable = styled(Table)`
  .ant-table-header {
    background-color: #f0f0f0;
  }

  .ant-table-thead > tr > th {
    background: #fafafa;
    color: #333;
  }

  .ant-table-tbody > tr:hover > td {
    background: #fff;
  }

  .ant-table-tbody > tr {
    background-color: #ffebeb;
  }

  .ant-table-tbody > tr:nth-child(even) {
    background-color: #f8f6f6;
  }
`;

export default class UploadSheet extends Component {
  state = {
    onGoingApiCall: false,
    selectedHub: null,
    loading: false,
    hubData: [],
    toShowModal: false,
    toShowErrors: false,
    branches: [],
    expandAllBranches: false,
    uploadedSheet: [],
    errors: null,
    validate_value: false,
    sheet_error_data: [],
  };

  toggleShowModal = () => {
    this.setState({ toShowModal: !this.state.toShowModal });
  };

  UploadSheet = async () => {
    try {
      this.setState({
        onGoingApiCall: true,
        errors: null,
        sheet_error_data: [],
      });
      if (this.state.uploadedSheet && this.state.uploadedSheet[0]) {
        if (
          //!this.state.selectedHub
          this.state.selectedHub === "Select Hub" ||
          this.state.selectedHub === null
        ) {
          this.setState({ onGoingApiCall: false });
          return openNotification("Please select Hub to continue");
        }

        await axios
          .get(`${config.api.base_url}/api/hubs/${this.state.selectedHub}`)
          .then((response) => {
            if (response.status === 200 && response.data && response.data.obj) {
              let hubData = response.data.obj;
              formData.set("file", this.state.uploadedSheet[0].originFileObj);
              formData.set("name", hubData.name);
              formData.set("base_year", hubData.baseYear);
              formData.set("start_year", hubData.startYear);
              formData.set("end_year", hubData.endYear);
              formData.set("id", hubData.id);
              formData.set("validate_value", this.state.validate_value);
            } else throw "No Data";
          })
          .catch((error) => {
            openNotification(false);
            this.setState({ onGoingApiCall: false });
          });

        axios
          .post(`${config.api.base_url}/api/parse-excel`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            openNotification(true);
            this.setState({
              onGoingApiCall: false,
              errors: null,
              // toShowModal: false,
              // toShowErrors: false,
              // uploadedSheet: [],
            });
            if (response?.data?.error_arr?.length) {
              let finalArr = [];
              //eslint-disable-next-line
              response?.data?.error_arr?.forEach((item) => {
                if (!finalArr[item.counter - 1])
                  finalArr[item.counter - 1] = {};
                finalArr[item.counter - 1] = {
                  ...finalArr[item.counter - 1],
                  ...item,
                };
              });
              this.setState({
                sheet_error_data: finalArr,
              });
            }
            //    this.closeModal();
          })
          .catch((error) => {
            openNotification(error?.response?.data?.message || false);
            if (
              error.response &&
              error.response.data &&
              error.response.data.length > 0
            ) {
              let errorArray = error.response.data,
                ItemCount,
                uniqueKey = 0,
                errorTree = [],
                branches = [];

              for (let errors of errorArray) {
                uniqueKey++;
                ItemCount = 0;

                if (errorTree)
                  errorTree.push({
                    title: errors[0],
                    key: `Tree ${uniqueKey}`,
                    children: [],
                  });
                branches.push(`Tree ${uniqueKey}`);

                if (errors.length > 0)
                  for (let error of errors) {
                    if (ItemCount)
                      errorTree[errorTree.length - 1].children.push({
                        title: error,
                        key: uniqueKey,
                      });
                    ItemCount++;
                    uniqueKey++;
                  }
                else errorTree = null;
              }

              this.setState({ branches: branches });
              this.setState({ errors: errorTree });
            }
            this.setState({ onGoingApiCall: false });
            openNotification(false);
          });
      } else {
        this.setState({ onGoingApiCall: false });
        openNotification("Please upload file to continue.");
      }
    } catch (e) {
      this.setState({ onGoingApiCall: false });
      openNotification("Sorry! Something went wrong.");
    }
  };

  // handleOnSelectTree = (e) => {
  // };

  getHubList = async () => {
    this.setState({ loading: true });
    if (this.state.hubData.length === 0) {
      let full_access = false;
      await axios
        .get(`${config.api.base_url}/api/hubs/list?full_access=${full_access}`)
        .then((response) => {
          if (response.status === 200) {
            this.setState({ hubData: response.data.obj });
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          openNotification(false);
          this.setState({ loading: false });
        });
    }
  };

  toTitleCase = (str) => {
    return (str + "").toUpperCase();
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  closeModal = () => {
    this.setState({
      onGoingApiCall: false,
      toShowModal: false,
      errors: null,
      toShowErrors: false,
      uploadedSheet: [],
      selectedHub: "Select Hub",
    });
  };

  createErrorSheetColumn = (data) => {
    try {
      let column = [
        {
          title: "Row",
          dataIndex: "counter",
          key: "counter",
        },
      ];

      data.forEach((obj) => {
        Object.keys(obj).forEach((key) => {
          if (!column.some((item) => item.key == key)) {
            column.push({
              title: key,
              dataIndex: key,
              key: key,
            });
          }
        });
      });

      return column;
    } catch (error) {
      notification.error({
        message: "Something went wrong.",
      });
      console.log("Error Ocurred", error);
    }
  };

  render() {
    const preventRequest = () => false;

    return (
      <>
        <Modal
          style={{ top: "55px" }}
          title="Errors during sheet upload"
          visible={this.state.toShowErrors}
          closable={false}
          maskClosable={false}
          onCancel={() => {
            this.setState({ toShowErrors: false, toShowModal: true });
          }}
          footer={[
            // <Button
            //   key="Expand/Collapse"
            //   onClick={() =>
            //     this.setState({
            //       expandAllBranches: !this.state.expandAllBranches,
            //     })
            //   }
            // >
            //   {this.state.expandAllBranches ? "Collapse All" : "Expand All"}
            // </Button>,

            <Button
              key="Exit"
              type="danger"
              onClick={() => {
                this.closeModal();
              }}
            >
              Exit
            </Button>,

            <Button
              key="Back"
              onClick={() =>
                this.setState({ toShowErrors: false, toShowModal: true })
              }
            >
              Back
            </Button>,
          ]}
        >
          <div style={{ maxHeight: "480px", overflowY: "auto" }}>
            <Tree
              showLine
              switcherIcon={<DownOutlined />}
              // onSelect={(e) => {
              //   this.handleOnSelectTree(e);

              // }}
              // expandedKeys={
              //   this.state.expandAllBranches ? this.state.branches : []
              // }
              // defaultExpandedKeys={["Errors"]}
              treeData={this.state.errors}
            />
          </div>
        </Modal>

        <Modal
          title="Upload sheet to parse"
          visible={this.state.toShowModal}
          onCancel={() =>
            this.state.onGoingApiCall ? null : this.closeModal()
          }
          maskClosable={false}
          footer={[
            <Button
              disabled={this.state.onGoingApiCall}
              key="View Upload Errors"
              type="danger"
              onClick={() =>
                this.setState({
                  toShowErrors: true,
                  toShowModal: false,
                  expandAllBranches: false,
                })
              }
              hidden={this.state.errors ? false : true}
            >
              View Upload Errors
            </Button>,
            <Button
              key="Upload Sheet"
              type="primary"
              onClick={() => this.UploadSheet()}
              loading={this.state.onGoingApiCall}
            >
              {this.state.onGoingApiCall ? "Uploading" : "Upload Sheet"}
            </Button>,
          ]}
        >
          <Dragger
            disabled={this.state.onGoingApiCall}
            accept=".csv, .xlsx"
            maxCount={1}
            fileList={this.state.uploadedSheet}
            // showUploadList={false}
            onChange={(e) => {
              this.setState({ uploadedSheet: e.fileList });
            }}
            beforeUpload={preventRequest}
            //customRequest={() => {}}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">Only .CSV or .XLSX file types</p>
          </Dragger>
          <div
            style={{
              "padding-left": "10px",
              "padding-bottom": "15px",
              "padding-top": "30px",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Select
              disabled={this.state.onGoingApiCall}
              style={{ display: "block", width: "200px" }}
              // id="SelectHub"
              placeholder="Select Hub"
              defaultValue="Select Hub"
              //{!this.state.toShowModal ? "Select Hub" : null}
              value={
                !this.state.toShowModal ? "Select Hub" : this.state.selectedHub
              }
              loading={this.state.loading}
              onFocus={() =>
                this.state.hubData.length === 0 ? this.getHubList() : null
              }
              maxLength=""
              onSelect={(e) => this.setState({ selectedHub: e })}
            >
              {this.state.hubData.map((item, index) => {
                // if (item.name !== "ZEROHUB")
                return (
                  <Option key={item.id} value={item.id}>
                    {this.toTitleCase(item.name)}
                  </Option>
                );
              })}
            </Select>

            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <label htmlFor="validate_value">Validate Dimension Values</label>
              <Switch
                id="validate_value"
                checked={this.state.validate_value}
                onChange={() =>
                  this.setState({
                    validate_value: !this.state.validate_value,
                  })
                }
                title="Validate Dimension Values"
              />
            </div> */}
          </div>

          <BulkUploadComponentWrapper>
            {this.state.sheet_error_data?.length ? (
              <div className="errorTableContainer">
                <h3>
                  Incorrect dimension Values were found in below mentioned rows.
                </h3>
                <h3>Please check and re upload below mentioned data.</h3>
                <CustomTable
                  style={{
                    width: "95%",
                    overflow: "auto",
                    margin: "auto",
                    textTransform: "capitalize",
                  }}
                  columns={this.createErrorSheetColumn(
                    this.state.sheet_error_data
                  )}
                  dataSource={this.state.sheet_error_data}
                />
              </div>
            ) : (
              ""
            )}
          </BulkUploadComponentWrapper>
        </Modal>
      </>
    );
  }
}
