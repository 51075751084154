import React from "react";

export const maintainTableText = (str = "", width = 100, styles = {}) => {
  str = str + " ";
  let strArr = [];
  let strLen = Math.floor(width / 10);

  for (let i = 0; i < str.length; i += strLen) {
    let x = str.substr(i, strLen + 1);
    if (x[x.length - 1] === " ") {
      strArr.push(str.substr(i, strLen));
    } else {
      strArr.push(str.substr(i, strLen - 1) + "-");
      i = i - 1;
    }
  }

  return (
    <>
      <div
        style={{
          display: "inline-block",
          width: "fit-content",
          backgroundColor: "none",
        }}
      >
        {strArr.map((str) => {
          return (
            <>
              <h2
                style={{
                  fontSize: styles.fontSize || "14px",
                  fontWeight: styles.fontWeight || "400",
                  color: styles.color || "#1F4986",
                  width: styles.width || "fit-content",
                  lineHeight: styles.lineHeight || ".25",
                  letterSpacing: styles.letterSpacing || "2px",
                  wordSpacing: styles.wordSpacing || "8px",
                  backgroundColor: styles.backgroundColor || "none",
                }}
              >
                {str}
              </h2>
            </>
          );
        })}
      </div>
    </>
  );
};
