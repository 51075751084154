import styled from "styled-components";

export const SatelliteVCWrapper = styled.div`
  .section_data1 {
    width: 25%;
    max-width: 285px;
    position: absolute;
    word-break: break-word;
  }
  .section_data2 {
    width: 25%;
    max-width: 175px;
    position: absolute;
    word-break: break-word;
  }

  .section1_list {
    top: 52%;
    left: 9.5%;
  }

  .section2_list {
    top: 58%;
    left: 38%;
  }

  .section3_list {
    top: 65%;
    left: 67.5%;
  }
  .section4_list {
    top: 65%;
    left: 82%;
  }

  .font_design {
    font-size: 12px !important;
  }
`;
