import styled from "styled-components";

export const LubricantVCWrapper = styled.div`
  .section1_list1 {
    left: ${(props) => (props.fromInterface ? "11%" : "6%")};
    width: ${(props) => (props.fromInterface ? "12%" : "14%")};
    position: absolute;
    top: 60%;
    max-width: 180px;
    word-break: break-word;
  }

  .section1_list2 {
    left: ${(props) => (props.fromInterface ? "24%" : "21%")};
    width: ${(props) => (props.fromInterface ? "12%" : "14%")};
    position: absolute;
    top: 60%;
    max-width: 180px;
    word-break: break-word;
  }
  .section2_list {
    position: absolute;
    left: ${(props) => (props.fromInterface ? "40%" : "38%")};
    top: 62%;
    max-width: 180px;
    word-break: break-word;
  }
  .section3_list1 {
    position: absolute;
    left: ${(props) => (props.fromInterface ? "65%" : "67%")};
    top: 74%;
    width: 14%;
    word-break: break-word;
  }

  .section3_list2 {
    position: absolute;
    left: ${(props) => (props.fromInterface ? "78%" : "82%")};
    top: 74%;
    width: 14%;
    word-break: break-word;
  }
`;
