import React, { useEffect } from "react";
import "./comment.css";

const optionsList = [
  {
    name: "Status",
    child: [
      {
        name: "Pending",
      },
      {
        name: "Addressed",
      },
      {
        name: "Satisfactory",
      },
      {
        name: "Re-addressed",
      },
    ],
  },
  {
    name: "Reply",
  },
  {
    name: "Send as mail",
  },
];
function Options({
  handleButtonClick,
  chat,
  handleBoxClose,
  handleMakeComment,
  user,
  openChatId,
  isEditedDraft,
}) {
  const [show, setShow] = React.useState(openChatId === chat._id);
  const ref = React.useRef(null);

  useEffect(() => {
    setShow(openChatId === chat._id);
  }, [openChatId]);

  const [extraOptions, setExtraOptions] = React.useState("Status");

  return (
    <>
      {show ? (
        <div className="chat-container">
          <div
            className="box"
            style={{ border: "1px solid grey", width: "100px" }}
          >
            {chat.mentions.some((mention) => mention.includes(user.email_id)) ||
            chat.user_id === user.uid ? (
              optionsList.map((option) => {
                if (
                  option.name === "Send as mail" &&
                  chat.mentions.length <= 0
                ) {
                  return null;
                }
                return (
                  <div
                    key={option.name}
                    onMouseEnter={() => setExtraOptions(option.name)}
                    className="options"
                  >
                    <div
                      onClick={() =>
                        handleMakeComment(option.name, null, chat._id, chat)
                      }
                    >
                      {option.name}
                    </div>
                    <div>
                      {extraOptions === option.name && option.child && (
                        <div className="extra-options">
                          {option.child.map((child) => {
                            if (child.name === chat.status) {
                              return null;
                            } else if (
                              chat.user_id !== user.uid &&
                              child.name !== "Addressed"
                            ) {
                              return null;
                            } else if (
                              chat.user_id !== user.uid &&
                              child.name === "Addressed" &&
                              !isEditedDraft
                            ) {
                              return (
                                <div
                                  key={child.name}
                                  style={{ cursor: "pointer" }}
                                >
                                  please edit the draft
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  key={child.name}
                                  onClick={() =>
                                    handleMakeComment(
                                      option.name,
                                      child.name,
                                      chat._id
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  {child.name}
                                </div>
                              );
                            }
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div>Not allowed</div>
            )}
            {/* {!["Pending", "Addressed", "Satisfactory", "Re-addressed"].some(
              (status) =>
                (status === "Addressed" &&
                  chat.mentions.some((mention) => 
                    mention.includes(user.email_id)
                    )) ||
                (chat.user_id === user.uid && !status.includes(chat.status))
            ) && <p>You cannot edit</p>} */}
          </div>
        </div>
      ) : (
        <div onClick={() => setShow(true)}>
          <div
            ref={ref}
            onMouseEnter={() => handleButtonClick(chat._id)}
            className="chat-actions"
          >
            <button
              className="button-three-dots"
              onClick={() => handleButtonClick(chat._id)}
            >
              ...
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Options;
