import React, { useState } from "react";
import styled from "styled-components";

// Hub Details

export const HubDetailsContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  margin-top: 64px;
`;
export const HubDetailsHeader = styled.div`
  margin-top: 6rem;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const HubLandingPagesList = styled.ul`
  margin-top: 25px;
  padding-top: 8px;
  background-color: #46cdcf;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;
export const HLPCard = styled.li`
  position: relative;
  background-color: #ffffff;
  min-height: 4rem;
  list-style: none;
  margin-top: -9px;
  &:first-child {
    margin-top: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  & > div:first-child {
    display: flex;
    align-items: center;
    margin-left: 10px;
    flex: 0 1 45%;
    min-height: 4rem;
  }
  & > div:last-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 4rem;
    margin-left: 35px;
    padding-right: 4rem;
    flex: 0 1 35%;
  }
  & > div {
    & > h4 {
      padding: 0;
      margin-top: -12px;
      margin-left: 2px;
      margin-right: 4px;
      line-height: 1.2;
      font-size: 16px !important;
    }
    & > p {
      padding: 0;
      margin-top: 2px;
      margin-left: 0px;
      margin-right: 5px;
      color: gray;
      line-height: 1;
    }
    & > button {
      border: 1px solid #46cdcf;
      background-color: white;
      color: #46cdcf;
      padding: 2px 8px;
      border-radius: 15px;
      margin-top: -20px;
      font-size: 16px;
      &:hover {
        cursor: pointer;
        background-color: #46cdcf;
        color: #ffffff;
      }
    }
    & > img {
      height: 1.8rem;
      width: 1.8rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;
export const SpinBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
`;
export const Flag = styled.div`
  background-color: #e52562;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 8px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
`;
export const ModalContainer = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 1);
  overflow: auto;
`;
export const CloseModal = styled.button`
  position: absolute;
  right: 8px;
  top: 8px;
  color: rgba(255, 0, 0, 0.35);
  background-color: #ffffff;
  border: 2px solid rgba(255, 0, 0, 0.35);
  border-radius: 50%;
  font-size: 0.8rem;
  height: 1.5rem;
  width: 1.5rem;
  &:hover {
    cursor: pointer;
    color: rgb(255, 0, 0);
    border-color: rgb(255, 0, 0);
  }
`;

// HLP Form
export const Form = styled.form`
  padding: 20px;
  background-color: rgba(255, 255, 255, 1);
`;

export const Header = styled.legend`
  color: #1f4986;
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  font-family: "Avenir Heavy";
`;

export const Fieldset = styled.fieldset`
  border: 1px solid gray;
  padding: 20px;
  margin-bottom: 20px;
`;

export const Legend = styled.legend`
  display: inline-block;
  width: fit-content;
  padding: 0 5px;
  color: #47cccf;
`;

export const Label = styled.label`
  color: #47cccf;
  display: inline-block;
  min-width: 250px;
`;

export const Input = styled.input`
  border: 1px solid lightgrey;
  padding: 0px 5px;
  border-radius: 2px;
  min-width: 300px;
  margin-right: 20px;
  &[type="file"] {
    padding: 0;
    height: 25px;
    &::file-selector-button {
      font-size: 11px;
    }
  }
  &[type="checkbox"] {
    padding: 0;
    height: 15px;
    width: 15px;
    margin-right: -100px;
    margin-left: -100px;
  }
  &:focus {
    border: 1px solid #47cccf;
    outline: none;
  }
`;

export const TextArea = styled.textarea`
  border: 1px solid lightgrey;
  padding: 0px 5px;
  border-radius: 2px;
  margin-right: 20px;
  min-width: 300px;
  &:focus {
    border: 1px solid #47cccf;
    outline: none;
  }
`;

export const AddButton = styled.button`
  border: 1px solid #47cccf;
  padding: 4px 8px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  color: #47cccf;
  border-radius: 4px;
  margin-right: 20px;
  font-weight: 500;
  &:hover {
    border: 1px solid #47cccf;
    background-color: #47cccf;
    color: #ffffff;
    cursor: pointer;
  }
`;

export const DeleteButton = styled.button`
  position: absolute;
  top: 10px;
  right: -10px;
  border: 1px solid #ff0000;
  padding: 4px 8px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  color: #ff0000;
  border-radius: 4px;
  margin-right: 20px;
  font-weight: 500;
  &:hover {
    border: 1px solid #ff0000;
    background-color: #ff0000;
    color: #ffffff;
    cursor: pointer;
  }
`;

export const Separator = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
`;

export const Indentor = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  padding: 10px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  position: relative;
`;

export const Instruction = styled.span`
  font-size: 12px;
  color: #ff9900;
  margin-left: 15px;
  img {
    width: 22px;
    height: 22px;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const Warning = styled.span``;

export const Error = styled.span`
  font-size: 12px;
  color: #ff0000;
  margin-left: 20px;
`;

export const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;
  color: black;
  list-style-type: disc;
`;

export const DeleteImageButton = styled.span`
  position: absolute;
  top: -22px;
  right: -22px;
  color: #e8a0a0;
  border-radius: 50%;
  width: 24px;
  text-align: center;
  border: 1px solid #dd6565;
  &:hover {
    cursor: pointer;
    color: red;
    border: 1px solid red;
  }
`;

export const Li = styled.ul`
  color: black;
  margin-right: 10px;
`;
export const Submit = styled.button`
  pointer-events: ${(props) => (props.disabled ? "none" : null)};
  position: absolute;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);
  color: ${(props) => (props.disabled ? "lightgray" : "#46cdcf")};
  font-weight: bold;
  background-color: #ffffff;
  border: ${(props) =>
    props.disabled ? "1px solid lightgray" : "1px solid #46cdcf"};
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  &:hover {
    color: ${(props) => (props.disabled ? "lightgray" : "#ffffff")};
    background-color: ${(props) => (props.disabled ? "white" : "#46cdcf")};
  }
`;

export const UpdateMetaContainer = styled.form`
  position: fixed;
  top: 50%;
  left: 50%;
  /* height:450px; */
  width: 1200px;
  padding: 2.5rem;
  background-color: #ffffff;
  box-shadow:
    rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  transform: translate(-50%, -50%);
  & > fieldset {
    & > legend {
      width: 100%;
      text-align: center;
      font-size: 1.6rem;
      font-weight: bold;
      color: #46cdcf;
      margin-top: -2rem;
    }
    & > div {
      display: flex;
      flex-direction: column;

      & > div {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 1rem;

        & > label {
          flex: 0 1 15%;
          color: darkgray;
          font-size: 1.1rem;
        }
        & > input {
          flex: 0 1 85%;
          border: 1px solid lightgray;
          &:focus {
            border: 1px solid #46cdcf;
            outline: none;
          }
        }
        & > textarea {
          flex: 0 1 85%;
          border: 1px solid lightgray;
          &:focus {
            border: 1px solid #46cdcf;
            outline: none;
          }
        }
      }
    }
  }
`;

export const MultipleSelect = (
  options,
  selectedOptions,
  setSelectedOptions,
  toggle,
  setToggle
) => {
  let handleToggle = () => {
    setToggle(!toggle);
  };
  let handleCheck = (event, option) => {
    let tempArr = [...selectedOptions];
    if (tempArr.includes(option)) {
      let index = tempArr.findIndex((opt) => {
        return opt === option;
      });
      tempArr.splice(index, 1);
    } else {
      tempArr.push(option);
      tempArr.sort();
    }
    setSelectedOptions(tempArr);
  };

  return (
    <>
      <div>
        <div
          className="select"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          onClick={handleToggle}
        >
          <span>Select Countries</span>
          {toggle ? <span>-</span> : <span>+</span>}
        </div>

        <div
          className={
            toggle ? "option-container visible" : "option-container invisible"
          }
        >
          {options?.map((option) => {
            return (
              <p
                className={
                  selectedOptions?.includes(option)
                    ? "option selected-option"
                    : "option"
                }
                onClick={(event) => handleCheck(event, option)}
              >
                {" "}
                <input
                  type="checkbox"
                  checked={
                    selectedOptions?.length && selectedOptions?.includes(option)
                  }
                />{" "}
                <span> {option}</span>{" "}
              </p>
            );
          })}
        </div>
      </div>
    </>
  );
};

export const SingleSelect = (
  options,
  selectedOption,
  setSelectedOption,
  toggle,
  setToggle
) => {
  let handleToggle = () => {
    setToggle(!toggle);
  };

  let handleCheck = (event, option) => {
    setSelectedOption(option);
  };

  return (
    <>
      <div>
        <div
          className="select"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          onClick={handleToggle}
        >
          <span>Select Region</span>
          {toggle ? <span>-</span> : <span>+</span>}
        </div>

        <div
          className={
            toggle ? "option-container visible" : "option-container invisible"
          }
        >
          {options?.map((option) => {
            return (
              <p
                className={
                  selectedOption === option
                    ? "option selected-option"
                    : "option"
                }
                onClick={(event) => handleCheck(event, option)}
              >
                {" "}
                <input
                  type="checkbox"
                  checked={selectedOption === option}
                />{" "}
                <span> {option}</span>{" "}
              </p>
            );
          })}
        </div>
      </div>
    </>
  );
};
