import { Button, notification, Popover, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import configApi from "../../config/Api";
import { countries } from "../../countries";
const suggestionsOptions = {
  country: countries.map((itm) => {
    return { label: itm, value: itm };
  }),
  region: [
    { label: "All", value: "All" },
    { label: "APAC", value: "APAC" },
    { label: "Africa", value: "Africa" },
    { label: "Asia Pacific", value: "Asia Pacific" },
    { label: "Europe", value: "Europe" },
    { label: "GCC", value: "GCC" },
    { label: "Middle East", value: "Middle East" },
    { label: "Middle East And Africa", value: "Middle East And Africa" },
    { label: "North America", value: "North America" },
    { label: "South America", value: "South America" },
  ],
  sub_region: [
    { label: "All", value: "All" },
    { label: "APAC", value: "APAC" },
    { label: "ASEAN", value: "ASEAN" },
    { label: "Nordics", value: "Nordics" },
    { label: "Asia Pacific", value: "Asia Pacific" },
    { label: "Europe", value: "Europe" },
    { label: "GCC", value: "GCC" },
    { label: "Middle East", value: "Middle East" },
    { label: "Middle East And Africa", value: "Middle East And Africa" },
    { label: "North America", value: "North America" },
    { label: "South America", value: "South America" },
  ],
};

function getToken() {
  return window.localStorage.getItem("token");
}
const openNotification = (msg, type) => {
  let args;
  if (type === true) {
    args = {
      message: "Success",
      description: msg,
      duration: 1,
      placement: "TopRight",
    };
    notification.success(args);
  } else if (type === false) {
    args = {
      message: "Failure",
      description: msg,
      duration: 1,
      placement: "TopRight",
    };
    notification.error(args);
  } else {
    args = {
      message: "Warning",
      description: msg,
      duration: 3.5,
      placement: "TopRight",
    };
    notification.warning(args);
  }
};
function ValidDimensions({ fetchedDimensions, selectedHub, savedDimValid }) {
  const [loading, setLoading] = useState(false);
  const [openpop, setOpenPop] = useState(false);
  const [currDim, setCurrDim] = useState("");
  const hide = () => {
    setOpenPop(false);
  };

  const handleOpenpopChange = (newOpen) => {
    setOpenPop(newOpen);
  };

  const handleChange = (dim, value) => {
    setValidDimensions((p) => {
      return { ...p, [dim]: value };
    });
  };
  const [valid_dimensions, setValidDimensions] = useState(savedDimValid);
  const addMorDim = () => {
    setOpenPop(false);
    setValidDimensions((p) => {
      return { ...p, [currDim]: [] };
    });
    setCurrDim("");
  };
  const removeDim = (dim) => {
    let validDim = valid_dimensions;
    delete validDim[dim];
    setValidDimensions((p) => {
      return { ...validDim };
    });
  };
  const SaveValues = async () => {
    setLoading(true);
    try {
      await axios.patch(
        `${configApi.api.base_url}/api/domainInfo/updateValidDimensions`,
        {
          hub_id: selectedHub,
          valid_dimension: valid_dimensions,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      openNotification("Values save successfully", true);
      setLoading(false);
    } catch (e) {
      openNotification("Error occured please try again", false);
      setLoading(false);
    }
  };
  useEffect(() => {
    setValidDimensions(savedDimValid);
  }, [savedDimValid]);
  return fetchedDimensions.length === 0 ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #00000024",
        borderRadius: "10px",
        padding: "19px",
        width: "48%",
        height: "100%",
      }}
    >
      <Spin />
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "10px",
        border: "1px solid #00000024",
        borderRadius: "10px",
        padding: "19px",
        width: "48%",
      }}
    >
      <h4>Valid Dimensions</h4>
      <div
        style={{
          width: "-webkit-fill-available",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          opacity: loading ? "0.5" : "1",
          pointerEvents: loading ? "none" : "unset",
        }}
      >
        {Object.keys(valid_dimensions).map((itm, i) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: "10px",
              }}
              key={itm}
            >
              <label
                style={{
                  maxWidth: "35%",
                  wordWrap: " break-word",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                }}
              >
                {itm.replaceAll("_", " ")}
              </label>
              <div style={{ width: "-webkit-fill-available" }}>
                {" "}
                <Select
                  mode="tags"
                  style={{
                    width: "100%",
                  }}
                  value={valid_dimensions[itm]}
                  placeholder="Add values"
                  onChange={(value) => handleChange(itm, value)}
                  options={
                    suggestionsOptions[itm] ? suggestionsOptions[itm] : []
                  }
                  notFoundContent="No suggestions available"
                />
              </div>
              <CloseCircleOutlined color="red" onClick={() => removeDim(itm)} />
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%",
          marginTop: "10px",
          opacity: loading ? "0.5" : "1",
          pointerEvents: loading ? "none" : "unset",
        }}
      >
        <Popover
          content={
            <Pop
              hide={hide}
              addMorDim={addMorDim}
              currDim={currDim}
              setCurrDim={setCurrDim}
              options={fetchedDimensions}
            />
          }
          trigger="click"
          style={{
            boxShadow: "0px 0px 9px 0px #0000002b",
            borderRadius: "10px",
          }}
          open={openpop}
          onOpenChange={handleOpenpopChange}
        >
          <Button disabled={loading} type="primary">
            + Add more Dimensions
          </Button>
        </Popover>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          marginTop: "15px",
        }}
      >
        <Button
          disabled={Object.keys(valid_dimensions).length === 0 || loading}
          onClick={SaveValues}
          type="primary"
        >
          {loading ? <Spin /> : "Save Values"}
        </Button>
      </div>
    </div>
  );
}

export default ValidDimensions;

const Pop = ({ hide, addMorDim, currDim, setCurrDim, options }) => {
  const handleChange = (value) => {
    setCurrDim(value);
  };

  let opts = [];
  options.forEach((element) => {
    opts.push({
      label: element.replaceAll("_", " "),
      value: element,
    });
  });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        gap: "10px",
        minWidth: "200px",
      }}
    >
      <CloseCircleOutlined color="red" onClick={hide} />
      <div style={{ minWidth: "100%" }}>
        <Select
          showSearch
          style={{
            width: "100%",
          }}
          placeholder="Select a Dimension Name"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          value={currDim}
          onChange={handleChange}
          options={opts}
        />
      </div>
      <Button disabled={!currDim} type="primary" onClick={addMorDim}>
        ADD
      </Button>
    </div>
  );
};
