import styled from "styled-components";

export const CommercialAircraftCabinVCWrapper = styled.div`
  .section_data1 {
    top: 59%;
    position: absolute;
    word-break: break-word;
  }

  .section1_list {
    left: 5%;
    width: 22%;
    max-width: 280px;
  }

  .section2_list {
    left: 43%;
    width: 18%;
    max-width: 210px;
  }

  .section3_list1 {
    left: 67%;
    width: 14%;
    max-width: 180px;

    .font_design {
      color: #63c096;
    }
  }

  .section3_list2 {
    left: 83%;
    width: 14%;
    max-width: 180px;
  }
`;
