import React, { Component } from "react";
import { BulkUploadContainerWrapper } from "./BulkUpload.style";
import InterfaceHeader from "../InterfaceHeader";
import UploadSheetComponent from "./UploadSheetComponent";

class UploadSheetContainer extends Component {
  render() {
    return (
      <>
        <InterfaceHeader />
        <BulkUploadContainerWrapper>
          <UploadSheetComponent />
        </BulkUploadContainerWrapper>
      </>
    );
  }
}

export default UploadSheetContainer;
