import styled from "styled-components";

export const DairyAndDairyAlternativeVCWrapper = styled.div`
  .section_data1 {
    top: ${(props) => (props.dairy_alternatives ? "70%" : "70%")};

    max-width: 240px;
    position: absolute;
    word-break: break-word;
  }

  .section_data2 {
    top: ${(props) => (props.dairy_alternatives ? "70%" : "70%")};

    max-width: 240px;
    position: absolute;
    word-break: break-word;
  }

  .section1_list {
    left: ${(props) => (props.dairy_alternatives ? "4%" : "3.5%")};
  }

  .section2_list {
    left: ${(props) => (props.dairy_alternatives ? "29%" : "27%")};
  }

  .section3_list {
    left: ${(props) => (props.dairy_alternatives ? "76%" : "52%")};
  }

  .section4_list {
    left: 80%;
  }
`;
