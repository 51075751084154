import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import DataHidden from "../components/DataHidden";
import Heading from "../components/Heading";

var Base64 = require("js-base64").Base64;

export const sourcesDemoData = {
  slideName: "sources",
  id: "sources",
  subtitle: "References",
  slug: "US PROBIOTICS MARKET",
  sourceCountStart: 1,
  sources: [
    ' "Coronavirus very likely of animal origin, no sign of lab manipulation: WHO". Reuters. 21 April 2020. Retrieved 23 April 2020.',
    'Weiss, Todd R. (October 9, 2008). "Wikipedia simplifies IT infrastructure by moving to one Linux vendor". Computerworld. Archived from the original on October 5, 2012. Retrieved November 1, 2008.',
    'Paul, Ryan (October 9, 2008). "Wikipedia adopts Ubuntu for its server infrastructure". Ars Technica. Archived from the original on January 30, 2009. Retrieved November 1, 2008.',
    ' "Wikimedia sites to move to primary data center in Ashburn". Wikimedia Diff. January 19, 2013. Archived from the original on July 15, 2018. Retrieved July 14, 2018',
    ' "Wikimedia Endowment - Meta". July 15, 2019.',
  ],
};

export const newSourcesData = {
  title: "Appendix",
  subtitle: "References",
  slug: "US PROBIOTICS MARKET",
  type: "sources",
  template: "sources",
  data: [{ text: "somejkdlk", hyperLink: "somethig" }],
  sourceCountStart: 1,
  sample: true,
};

class Sources extends React.Component {
  state = {
    data: this.props.data || newSourcesData,
    reseller: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.meta) {
      if (props.data.meta.sample && props.data.meta.reseller) {
        return {
          reseller: true,
        };
      }
    }
    return null;
  }

  convertLinksToHref(string) {
    let textArr = string.split(" ");
    let text = textArr.map((item, index) => {
      if (item.includes("https://") || item.includes("http://")) {
        if (item.startsWith("https://") || item.startsWith("http://")) {
          let temp = `<a href="${item}" target="_blank" referrerPolicy="no-referrer" >${item}</a>`;
          return temp;
        } else {
          let temp = item.replace("http", " http");

          temp = temp.split(" ");
          temp = temp.map((subStr) => {
            if (subStr.startsWith("https://") || subStr.startsWith("http://")) {
              let str = `<a href="${subStr}" target="_blank" referrerPolicy="no-referrer" >${subStr}</a>`;
              return str;
            } else {
              return subStr;
            }
          });

          return temp.join(" ");
        }
      } else {
        return item;
      }
    });
    text = text.join(" ");
    return text;
  }

  render() {
    const { data, subtitle, title, section_id, slug, sourceCountStart } =
      this.state.data;

    const pageId = section_id ? Base64.encode(section_id) : undefined;
    const { slideNo } = this.props;

    let source = data.length ? (
      data.map((source, index) => {
        let new_source = (source.text + "").replace(/^source:/i, "");
        new_source = new_source.replaceAll("&nbsp;", "");

        return (
          <p id={source.hyperLink} className="sorceList" key={index}>
            {sourceCountStart + index + ". "}
            <span
              dangerouslySetInnerHTML={{
                __html: this.convertLinksToHref(new_source),
              }}
            />
          </p>
        );
      })
    ) : (
      <h3>No Data</h3>
    );

    return (
      <div className="page sources" id={pageId}>
        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Header slideTitle={this.state.data.data.type} />
        ) : null}
        <div className="row container">
          <div
            className="col-md-10"
            style={{ display: "flex", height: "100%" }}
          >
            <a target="_blank"></a>
            <div
              style={{
                padding: "0px 50px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* <h2 className="heading">{subtitle}</h2> */}
              <Heading heading={title} />
              {source}
              {this.state.data.sample ? <DataHidden /> : ""}
            </div>
          </div>
        </div>
        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Footer
            slug={slug}
            slideNo={slideNo}
            reseller={this.state.reseller}
          />
        ) : null}
      </div>
    );
  }
}

export default Sources;
