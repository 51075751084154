import styled from "styled-components";

export const PetFoodVCWrapper = styled.div`
  .section_data1 {
    top: 68%;
    width: 25%;
    max-width: 285px;
    position: absolute;
    word-break: break-word;
  }

  .section_data2 {
    top: 68%;
    width: 14%;
    max-width: 180px;
    position: absolute;
    word-break: break-word;
  }

  .section1_list {
    left: 8%;
  }

  .section2_list {
    left: 34%;
  }

  .section3_list {
    left: 54%;
  }

  .section4_list {
    left: 81%;
  }
`;
