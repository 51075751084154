import styled from "styled-components";

export const FoodServicesVCWrapper = styled.div`
  .vc_table_container {
    position: relative;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 12px;
    margin: 15px;
  }

  .vc_table_type {
    text-align: center;
    font-size: 16px;
    padding: 18px;
    color: black;
  }

  .vc_table_row {
    display: flex;
    justify-content: space-between;
    background: transparent;
    margin-bottom: 0;

    .consumer_buying_para {
      display: flex;
      font-size: 12px;
      font-weight: lighter;
      font-family: "Avenir Roman";
      color: #1f4986;
      text-align: justify;
    }
  }
`;
