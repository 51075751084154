import styled from "styled-components";

const ScopeStudyWrapper = styled.div`
  padding: 30px 50px 30px 50px;
  height: 626px;

  .inner_container {
    display: flex;
    margin-top: 30px;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .list_header {
      width: 185px;
      height: 60px;
      background: #002f75;
      font-family: "Avenir Roman";
      text-transform: uppercase;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      padding: 0 13px;
    }

    .list_header1 {
      width: 173px;
      height: 60px;
      background: #1cbec1;
      font-family: "Avenir Roman";
      text-transform: uppercase;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      padding: 0 15px;
    }

    .list_header2 {
      background: #0aa4f2;
      width: 163px;
      height: 43px;
      font-family: "Avenir Roman";
      text-transform: uppercase;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
    }

    .content {
      position: relative;
      border-left: 1px solid #8098ba;
      max-height: 498px;
      height: fit-content;
      flex-direction: column;
      width: fit-content;
      margin-bottom: 25px;
      img {
        position: absolute;
        z-index: -999;
        top: 80px;
      }

      p {
        font-family: "Avenir Roman";
        margin: 0px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;
        text-transform: uppercase;
        color: #002f75;
        padding-top: 7px;
        bottom: -15px;
        position: inherit;
      }

      p::after {
        bottom: -3px;
      }
      p:before {
        content: "-------- ";
        margin-right: 5px;
      }
    }
  }
`;

export default ScopeStudyWrapper;
