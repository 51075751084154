import React, { useState, useEffect, useRef } from "react";

import axios from "axios";
import config from "../../config/Api";
import HLPDataUpload from "./HLPDataUpload";
import UpdateMeta from "./updateMeta";
import { Button, Input, Select, Spin, Tooltip, notification } from "antd";
import {
  HubDetailsContainer,
  HubDetailsHeader,
  HubLandingPagesList,
  HLPCard,
  SpinBox,
  Flag,
  ModalContainer,
  CloseModal,
} from "./styles";
import { setLinkCounter } from "../../reducersAndActions/Actions";
import { connect } from "react-redux";
import InterfaceHeader from "../InterfaceHeader";
import { ArrowLeftOutlined } from "@ant-design/icons";

const base_url = config.api.base_url;
const website_url = config.api.website_url;
const preview_url = config.api.express_url;
const { Search } = Input;
const { Option } = Select;

function HubDetails(props) {
  // States & Refs
  let [loading, setLoading] = useState(false);
  let [hubList, setHubList] = useState([]);
  let [hubValue, setHubValue] = useState(null);
  let [hubValueForUpdate, setHubValueForUpdate] = useState(null);
  let [isUpdateHubValueSelected, setIsUpdateHubValueSelected] = useState(false);

  let [hlpDraftList, setHlpDraftList] = useState([]);
  let [hlpDraft, setHlpDraft] = useState({});
  let [currentHlpDraftList, setCurrentHlpDraftList] = useState([]);
  let [isFormModalOpen, setIsFormModalOpen] = useState(false);
  let [isModalOpen, setIsModalOpen] = useState([]);
  let [selectedReport, setSelectedReport] = useState({});
  let selectRef = useRef();
  let searchRef = useRef();

  useEffect(async () => {
    setLoading(true);
    let full_access = true;
    axios
      .get(`${base_url}/api/hubs/list?full_access=${full_access}`)
      .then((response) => {
        if (response.status === 200) {
          let hubs = [...response.data.obj].filter((hub) => {
            return hub.name.toLowerCase() !== "zero";
          });
          setHubList(hubs);
        }
      })
      .catch((error) => {
        let args = {
          message: error,
          description: "Hub List Not Found.",
          duration: 1,
          placement: "topRight",
        };
        notification.error(args);
        console.log(error);
      });

    axios
      .get(`${base_url}/api/hub_landing_page/draft`)
      .then((response) => {
        if (response.status === 200) {
          let arr = [...response.data.data];
          setHlpDraftList([...arr]);
          setCurrentHlpDraftList([...arr]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  let onclickOfSearch = (value) => {
    if (value) {
      let newFilteredDrafts = hlpDraftList.filter((draft) => {
        return draft.title.toLowerCase().includes(value.trim().toLowerCase());
      });
      setCurrentHlpDraftList([...newFilteredDrafts]);
    } else {
      setCurrentHlpDraftList([...hlpDraftList]);
    }
  };

  let hubChange = (value) => {
    setHubValue(value);
    setIsUpdateHubValueSelected(false);
    setLoading(true);
    axios
      .get(`${base_url}/api/hub_landing_page/draft?hub_id=${value}`)
      .then((response) => {
        if (response.status === 200) {
          setHlpDraft({ ...response.data.data });
          setLoading(false);
          setIsFormModalOpen(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setIsFormModalOpen(true);
        console.log(error);
      });
  };

  let updateDraft = (e, value) => {
    e.preventDefault();
    setHubValueForUpdate(value);
    setIsUpdateHubValueSelected(true);
    setLoading(true);
    axios
      .get(`${base_url}/api/hub_landing_page/draft?hub_id=${value}`)
      .then((response) => {
        if (response.status === 200) {
          setHlpDraft({ ...response.data.data });
          setLoading(false);
          setIsFormModalOpen(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  let updateMeta = (e, hub_id, slug) => {
    setSelectedReport({ hub_id, slug });
    setIsModalOpen(["updatemeta"]);
  };
  let publishDraft = (e, value, slug) => {
    e.preventDefault();
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${base_url}/api/hub_landing_page/publish`,
      data: { hub_id: value },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          let args = {
            message: "Draft Published Successfully",
            description: "Preview will open in new tab",
            duration: 2.5,
            placement: "topRight",
          };
          notification.success(args);

          let a = document.createElement("a");
          a.target = "_blank";
          a.href = `${website_url}hubs/${slug}`;
          a.click();
          window.location.reload();
        }
      })
      .catch((error) => {
        let args = {
          message: "Failed To Publish Draft!",
          description: "Some Error Occured In Publishing!",
          duration: 2,
          placement: "topRight",
        };
        notification.error(args);
        console.log(error);
      });
  };

  let handlePreview = async (e, value) => {
    e.preventDefault();

    let args = {
      message: "Wait A While!",
      description: "The preview is being loaded.",
      duration: 2.5,
      placement: "topRight",
    };
    notification.success(args);

    let data = await axios
      .get(`${base_url}/api/hub_landing_page/draft?hub_id=${value}`)
      .then((response) => {
        if (response.status === 200) {
          setHlpDraft({ ...response.data.data });
          return response.data.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${base_url}/api/hub_landing_page/hub_landin_page_preview`,
      data: {
        report_data: {
          hubLandingPageData: {
            ...data,
            hubId: value,
          },
        },
      },
    };

    let preview = await axios(config)
      .then((response) => {
        if (response.status === 200) {
          let args = {
            message: "Preview Ready!",
            description: "Preview will open in new tab.",
            duration: 2.0,
            placement: "topRight",
          };
          notification.success(args);
          let newWindow = window.open();
          newWindow.document.write(response.data);
          window.location.reload();
        }
      })
      .catch((error) => {
        let args = {
          message: "Failed To Show Preview!",
          description: "Data incomplete For Preview!",
          duration: 2,
          placement: "topRight",
        };
        notification.error(args);
        console.log(error);
      });
  };

  return (
    <>
      <InterfaceHeader />
      <HubDetailsContainer>
        <HubDetailsHeader>
          {/* <Button
            onClick={() => {
              //Go Back
              window.history.back();
            }}
          >
            Go Back <ArrowLeftOutlined />
          </Button> */}
          <Search
            ref={searchRef}
            placeholder="Enter Hub To Search Hub Landing Page Draft."
            allowClear
            onSearch={onclickOfSearch}
            style={{ width: 380 }}
          />
          <Select
            value={hubValue}
            ref={selectRef}
            placeholder={"Select Hub To Create Hub Landing Page Draft."}
            onSelect={hubChange}
            style={{ width: 380, marginRight: "10px" }}
          >
            {hubList.length &&
              hubList.map((hub) => {
                return (
                  <>
                    <Option value={`${hub.id}`}>
                      {hub.name.toUpperCase()}
                    </Option>
                  </>
                );
              })}
          </Select>
        </HubDetailsHeader>

        {loading ? (
          <SpinBox>
            <Spin />
          </SpinBox>
        ) : (
          <HubLandingPagesList>
            {!currentHlpDraftList.length ? (
              <HLPCard
                style={{
                  justifyContent: "center",
                  alignitems: "center",
                  paddingTop: "1.5rem",
                  fontSize: "1.1rem",
                  color: "#46cdcf",
                }}
              >
                No Hub Landing Page Drafts
              </HLPCard>
            ) : (
              currentHlpDraftList.map((draft) => {
                let flag = false;
                return (
                  <HLPCard
                    style={{
                      justifyContent: "center",
                      alignitems: "center",
                      paddingTop: "1.5rem",
                      fontSize: "1.1rem",
                      color: "#46cdcf",
                    }}
                  >
                    <div>
                      <h4>{draft.title}</h4>
                    </div>
                    <div
                      style={{
                        borderLeft: "1px solid #46cdcf",
                        borderRight: "1px solid #46cdcf",
                        padding: "0 15px",
                        flex: "0 1 25%",
                      }}
                    >
                      User:{" "}
                      <Tooltip title={draft.created_by}>
                        <span>{draft.user_name}</span>
                      </Tooltip>
                      <br />
                      On:{" "}
                      <span>{new Date(draft.createdAt).toLocaleString()}</span>
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      {!props.USER_DATA.obj.rd_publish_access && (
                        <>
                          <Tooltip title={"Update Hub Landing Page Draft"}>
                            <button
                              style={{ marginRight: "15px" }}
                              onClick={(e) => {
                                updateDraft(e, draft.hub_id);
                              }}
                            >
                              Update Draft
                            </button>
                          </Tooltip>
                        </>
                      )}

                      {props.USER_DATA.obj.rd_publish_access && (
                        <>
                          <Tooltip
                            title={"Update Hub Landing Page Meta"}
                            onClick={(e) =>
                              updateMeta(e, draft.hub_id, draft.slug)
                            }
                          >
                            <button style={{ marginRight: "15px" }}>
                              Update Meta
                            </button>
                          </Tooltip>
                        </>
                      )}
                      <Tooltip title={"Preview Hub Landing Page Draft"}>
                        <button
                          style={{ marginRight: "15px" }}
                          onClick={(e) => {
                            handlePreview(e, draft.hub_id);
                          }}
                        >
                          Preview
                        </button>
                      </Tooltip>
                      {props.USER_DATA.obj.rd_publish_access && (
                        <>
                          <Tooltip
                            title={"Publish Hub Landing Page To Website"}
                          >
                            <button
                              style={{ marginRight: "15px" }}
                              onClick={(e) => {
                                publishDraft(e, draft.hub_id, draft.slug);
                              }}
                            >
                              Publish
                            </button>
                          </Tooltip>
                        </>
                      )}

                      {flag && draft.active && (
                        <a
                          target={"_blank"}
                          href={`${website_url}industry-reports`}
                        >
                          <Flag>Published</Flag>
                        </a>
                      )}
                    </div>
                  </HLPCard>
                );
              })
            )}
          </HubLandingPagesList>
        )}

        {isFormModalOpen && (
          <ModalContainer>
            <HLPDataUpload
              hubId={isUpdateHubValueSelected ? hubValueForUpdate : hubValue}
              draft={hlpDraft}
              set={(response) => {
                setIsFormModalOpen(response);
              }}
            />
          </ModalContainer>
        )}
        {isModalOpen.includes("updatemeta") && (
          <UpdateMeta
            selectedHLP={selectedReport}
            set={(response) => {
              setIsModalOpen(response);
            }}
          />
        )}
      </HubDetailsContainer>
    </>
  );
}

function mapStateToPros(state) {
  return {
    USER_DATA: state.USER_DATA,
  };
}

const mapDispatchToProps = {
  setLinkCounter,
};

export default connect(mapStateToPros, mapDispatchToProps)(HubDetails);
