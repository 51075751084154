import styled from "styled-components";

export const LayoutWrapper = styled.div`
  overflow: hidden;
  .draft-tab-dropdown .ant-select-arrow .anticon {
    color: white;
  }
  .ant-tabs-content-holder {
    margin-bottom: 0px;
    /* margin-top: 105px; */
    /* height: calc(100vh - 105px); */
  }
  .antd-tabContainer {
    display: flex;
    justify-content: space-evenly;
    background: white;
    z-index: 99;
    width: 80%;
    padding: 5px;
    margin-left: 20%;
    position: fixed;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    top: ${(props) => (props.mobile ? "calc(100% - 42px)" : "71px")};
    box-shadow: 0 3px 3px 1px #f0f0f0;
  }
  .ant-switch-checked {
    background: #3ccad3;
  }
  .ant-tabs > .ant-tabs-nav {
    height: 37px;
    margin: 0;
  }
  .ant-tabs {
    height: 37px;
  }
  .empty-div {
    width: 15%;
  }
  .purchase-button {
    width: auto;
    margin: -2px 0 3px 10px;
  }
  .purchase-button button {
    background-image: linear-gradient(to right, #ff5f58, #ff7772) !important;
    color: #fff !important;
    border: 1px solid #fff;
    opacity: 1;
    font-size: 14px;
  }
  .purchase-button button:hover {
    opacity: 0.8;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: unset;
  }
  .body-section {
    margin-top: 105px;
    margin-bottom: 0px;
    height: calc(100vh - 105px);
  }

  .editing-controls-container {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .save-button,
  .direct-publish-button {
    background: ${(props) =>
      props.checked ? "#3CCAD3" : "rgba(0, 0, 0, 0.25)"};
    color: white;
  }
  .save-button {
    display: ${(props) =>
      props.published_tab_stage === "published" ? "none" : "block"};
  }

  .direct-publish-button {
    display: ${(props) =>
      props.published_tab_stage === "hubleader" ? "none" : "block"};
  }

  .draft-tab-dropdown .ant-select-selector {
    background: ${(props) =>
      props.checked ? "#4187DA" : "rgba(0, 0, 0, 0.25)"} !important;
    color: white !important;
  }

  .tab-buttons-container {
    display: ${(props) =>
      !props.template_type.includes("companyProfile") ? "flex" : "none"};
    gap: 20px;
  }

  @media only screen and (max-width: 960px) {
    .ant-tabs {
      width: 90%;
    }
    .body-section {
      margin-top: 54px;
      height: auto;
    }
    .antd-tabContainer {
      padding: 0 10px;
    }
  }
`;
