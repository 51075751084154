import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

var Base64 = require("js-base64").Base64;

export const ResearchMethodologyDemoData = {
  slideReference: "1. INTRODUCTION",
  id: "1.3 RESEARCH METHODOLOGY",
  title: "1.3 RESEARCH METHODOLOGY",
  slug: "North America Enzymes Market",
  slideName: "researchmethodology",
};

class ResearchMethodology extends React.Component {
  state = {
    data: this.props.data,
    reseller: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.meta) {
      if (props.data.meta.sample && props.data.meta.reseller) {
        return {
          reseller: true,
        };
      }
    }
    return null;
  }

  render() {
    let { section_id, slug } = this.state.data;
    const { slideNo } = this.props;
    const pageId = section_id ? Base64.encode(section_id) : undefined;
    let slideNoElementId = undefined;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }

    return (
      <div id={pageId} className="page">
        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Header slideTitle={this.state.data.type} />
        ) : null}

        <div className="research-methodology-container">
          {this.props.data.url ? (
            <img src={this.props.data.url} alt="Research Methodology" />
          ) : (
            this.props.data.data.map((item, idx) => (
              <img src={item.url} alt="Research Methodology" key={idx} />
            ))
          )}
        </div>

        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Footer
            slug={slug}
            slideNo={slideNo}
            reseller={this.state.reseller}
          />
        ) : null}
      </div>
    );
  }
}

export default ResearchMethodology;
