import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import config from "../../config/Api";
import { Input, Select, Spin, Tooltip, Button, notification } from "antd";
import {
  MarketActivityContainer,
  MarketActivityHeader,
  ReporstList,
  ReportCard,
  SpinBox,
  ViewMore,
  ModalContainer,
  FullPageLoader,
  Label,
} from "./styles";
import Published from "../../svg/Published.svg";
import NotPublished from "../../svg/NotPublished.svg";
import UpdateMeta from "./UpdateMeta";
import PublishRD from "./PublishRD";

import { setLinkCounter } from "../../reducersAndActions/Actions";
import { connect } from "react-redux";
import InterfaceHeader from "../InterfaceHeader";
import { ArrowLeftOutlined } from "@ant-design/icons";

const base_url = config.api.base_url;
const website_url = config.api.website_url;
const preview_url = config.api.express_url;
const { Search } = Input;
const { Option } = Select;

function MarketActivities(props) {
  // States & Refs
  let [hubList, setHubList] = useState([]);
  let [hubValue, setHubValue] = useState(null);
  let [rdList, setRdList] = useState([]);
  let [filteredRDList, setFilteredRDList] = useState([]);
  let [currentRDList, setCurrentRDList] = useState([]);
  let [loading, setLoading] = useState(false);
  let [fullPageLoading, setFullPageLoading] = useState(false);
  let [isModalOpen, setIsModalOpen] = useState([]);
  let [selectedReport, setSelectedReport] = useState({});

  let selectRef = useRef();
  let searchRef = useRef();

  let config = {
    method: "post",
    url: `${base_url}/api/previewRD`,
    //url:"https://www.mordorintelligence.com/preview",
    data: {},
  };

  // Initial API calls and State Setting
  useEffect(async () => {
    setLoading(true);
    let full_access = false;
    axios
      .get(`${base_url}/api/hubs/list?full_access=${full_access}`)
      .then((response) => {
        if (response.status === 200) {
          let hubs = [...response.data.obj].filter((hub) => {
            return hub.name.toLowerCase() !== "zero";
          });
          setHubList(hubs);
        }
      })
      .catch((error) => {
        let args = {
          message: error,
          description: "Hub List Not Found.",
          duration: 1,
          placement: "topRight",
        };
        notification.error(args);
        console.log(error);
      });

    axios
      .get(`${base_url}/api/interfaceQueries/getRdList`)
      .then((response) => {
        if (response.status === 200) {
          let arr = [...response.data];
          let currRD = arr.slice(0, 10);
          setRdList([...arr]);
          setFilteredRDList([...arr]);
          setCurrentRDList([...currRD]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //  Filter And Search Functionalities
  let expandRDList = () => {
    let currentLength = currentRDList.length;
    let newCurrRD = filteredRDList.slice(0, currentLength + 10);
    setCurrentRDList([...newCurrRD]);
  };

  let hubChange = (value) => {
    setHubValue(value);
    setLoading(true);
    axios
      .get(`${base_url}/api/interfaceQueries/getRdList?hub_id=${value}`)
      .then((response) => {
        if (response.status === 200) {
          let arr = [...response.data];
          let currRD = arr.slice(0, 10);
          setRdList([...arr]);
          setFilteredRDList([...arr]);
          setCurrentRDList([...currRD]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let onclickOfSearch = (value) => {
    let newFilteredRD = rdList.filter((rd) => {
      return rd.title.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredRDList([...newFilteredRD]);
    let newCurrentRD = filteredRDList.slice(0, 10);
    setCurrentRDList([...newCurrentRD]);
  };

  let resetList = () => {
    setLoading(true);
    setHubValue(null);
    setFilteredRDList([]);
    searchRef.current.state.value = "";
    axios
      .get(`${base_url}/api/interfaceQueries/getRdList`)
      .then((response) => {
        if (response.status === 200) {
          let arr = [...response.data];
          let currRD = arr.slice(0, 10);
          setRdList([...arr]);
          setFilteredRDList([...arr]);
          setCurrentRDList([...currRD]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Report Functions
  let updateMeta = (e, rd) => {
    setSelectedReport({ ...rd });
    setIsModalOpen(["updatemeta"]);
  };

  let publishToWebsite = (e, rd) => {
    setSelectedReport({ ...rd });
    setIsModalOpen(["publishrd"]);
  };

  let previewRD = async (e, rd) => {
    setSelectedReport({ ...rd });
    setFullPageLoading(true);
    await axios
      .get(
        `${base_url}/api/interfaceQueries/getRdList?specificRd=true&id=${rd.id}`
      )
      .then((response) => {
        if (response.status === 200) {
          let rdData = {
            template: "lubricants-template",
            report_data: {
              report_data: {
                ...response.data,
                // template: "lubricants-template",
                _id: {
                  $oid: response.data["_id"],
                },
              },
            },
          };
          config.data = rdData;
          let args = {
            message: "RD Data Found",
            duration: 2.5,
            placement: "topRight",
          };
          notification.success(args);
        }
      })
      .catch((error) => {
        let args = {
          message: "Can Not Find RD Data",
          duration: 2,
          placement: "topRight",
        };
        setFullPageLoading(false);
        notification.error(args);
        console.log(error);
      });

    await axios(config)
      .then((response) => {
        if (response.status === 200) {
          let args = {
            message: "RD Preview Is Ready",
            duration: 2.5,
            placement: "topRight",
          };
          notification.success(args);
          setFullPageLoading(false);
          if (response.data.data) {
            let newWindow = window.open();
            newWindow.document.write(response.data.data);
          }
        }
      })
      .catch((error) => {
        let args = {
          message: "RD Preview Not Found",
          duration: 2,
          placement: "topRight",
        };
        setFullPageLoading(false);
        notification.error(args);
        console.log(error);
      });
  };

  return (
    <>
      <InterfaceHeader />
      <MarketActivityContainer>
        <MarketActivityHeader>
          {/* <Button
            onClick={() => {
              //Go Back
              window.history.back();
            }}
          >
            Go Back <ArrowLeftOutlined />
          </Button> */}
          <div>
            <Select
              value={hubValue}
              ref={selectRef}
              style={{ width: 350, marginRight: "10px" }}
              onSelect={hubChange}
              placeholder={"Select Hub Name"}
            >
              {hubList.length &&
                hubList.map((hub) => {
                  return (
                    <>
                      <Option value={`${hub.id}`}>
                        {hub.name.toUpperCase()}
                      </Option>
                    </>
                  );
                })}
            </Select>
            <Search
              ref={searchRef}
              placeholder="Type Title To Search"
              allowClear
              onSearch={onclickOfSearch}
              style={{ width: 350 }}
            />
          </div>
          <Button onClick={resetList} style={{ color: "gray" }}>
            Reset
          </Button>
        </MarketActivityHeader>

        {loading ? (
          <SpinBox>
            <Spin />
          </SpinBox>
        ) : (
          <ReporstList>
            {!currentRDList.length ? (
              <ReportCard
                style={{
                  justifyContent: "center",
                  alignitems: "center",
                  paddingTop: "1.5rem",
                  fontSize: "1.1rem",
                  color: "#46cdcf",
                }}
              >
                No Report
              </ReportCard>
            ) : (
              currentRDList.map((rd) => {
                let flag = false;
                if (rd.is_published && rd.is_published === true) flag = true;
                if (rd.active && rd.active === true) flag = true;

                return (
                  <ReportCard>
                    <div>
                      <h4>{rd?.title} </h4>
                    </div>

                    <div>
                      <div
                        style={{
                          borderLeft: "1px solid #46cdcf",
                          borderRight: "1px solid #46cdcf",
                          padding: "0 15px",
                        }}
                      >
                        User:{" "}
                        <Tooltip title={rd.meta?.send_to_marketing_user}>
                          <span>
                            {rd.meta?.send_to_marketing_user
                              .split("@")[0]
                              .charAt(0)
                              .toUpperCase() +
                              rd.meta?.send_to_marketing_user
                                .split("@")[0]
                                .slice(1)}
                          </span>
                        </Tooltip>
                        <br />
                        On:{" "}
                        <span>
                          {new Date(rd?.publish_timestamp).toLocaleString()}
                        </span>
                      </div>

                      <Tooltip title={"Preiew RD Page Of Report"}>
                        <button onClick={(e) => previewRD(e, rd)}>
                          Preview RD
                        </button>
                      </Tooltip>
                      {props.USER_DATA.obj.rd_publish_access && (
                        <>
                          <Tooltip title={"Update Meta Of The Report"}>
                            <button onClick={(e) => updateMeta(e, rd)}>
                              Update Meta
                            </button>
                          </Tooltip>

                          <Tooltip title={"Publish Report To Website"}>
                            <button onClick={(e) => publishToWebsite(e, rd)}>
                              Publish
                            </button>
                          </Tooltip>
                        </>
                      )}

                      {flag && (
                        <Tooltip
                          title={
                            flag ? "Already Published" : "Not Published Yet"
                          }
                        >
                          <a
                            target={"_blank"}
                            href={`${website_url}industry-reports/${rd?.slug}`}
                          >
                            <Label>Published</Label>
                          </a>
                        </Tooltip>
                      )}
                    </div>
                  </ReportCard>
                );
              })
            )}
          </ReporstList>
        )}

        {currentRDList.length < filteredRDList.length && (
          <ViewMore>
            <button onClick={expandRDList}>View More</button>
          </ViewMore>
        )}

        {isModalOpen && isModalOpen.length !== 0 && (
          <ModalContainer>
            {isModalOpen.includes("updatemeta") && (
              <UpdateMeta
                selectedRD={selectedReport}
                set={(response) => {
                  setIsModalOpen(response);
                }}
              />
            )}
            {isModalOpen.includes("publishrd") && (
              <PublishRD
                selectedRD={selectedReport}
                set={(response) => {
                  setIsModalOpen(response);
                }}
              />
            )}
          </ModalContainer>
        )}

        {fullPageLoading && (
          <FullPageLoader>
            <Spin />
          </FullPageLoader>
        )}
      </MarketActivityContainer>
    </>
  );
}

function mapStateToPros(state) {
  return {
    USER_DATA: state.USER_DATA,
  };
}

const mapDispatchToProps = {
  setLinkCounter,
};

export default connect(mapStateToPros, mapDispatchToProps)(MarketActivities);
