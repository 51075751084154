import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import config from "../../config/Api";
import { UpdateMetaContainer, CloseModal, Submit } from "./styles";
import { Input, Spin, notification } from "antd";
const { TextArea } = Input;
const base_url = config.api.base_url;

export default function UpdateMeta(props) {
  let [loading, setLoading] = useState(false);
  let [blocker, setBlocker] = useState(false);
  let [rdMeta, setRdMeta] = useState({});

  useEffect(async () => {
    setLoading(true);
    await axios
      .get(
        `${base_url}/api/interfaceQueries/getRdList?specificRd=true&id=${props.selectedRD.id}`
      )
      .then((response) => {
        if (response.status === 200) {
          setRdMeta({ ...response.data.meta });
          setLoading(false);
          let args = {
            message: "RD Data Found",
            duration: 2.5,
            placement: "topRight",
          };
          notification.success(args);
        }
      })
      .catch((error) => {
        let args = {
          message: "Can Not Find RD Data",
          duration: 2,
          placement: "topRight",
        };
        setLoading(false);
        notification.error(args);
        console.log(error);
      });
  }, []);

  let closeModal = (e) => {
    e.preventDefault();
    props.set([]);
  };

  let onSubmit = (e) => {
    e.preventDefault();
    setBlocker(true);
    let data = {
      id: props.selectedRD.id,
      slug: props.selectedRD.slug,
      meta: {
        tags: rdMeta.tags,
        keywords: rdMeta.keywords,
        description: rdMeta.description,
      },
    };

    let config = {
      method: "put",
      url: `${base_url}/api/interfaceQueries/updateMeta`,
      data: data,
    };

    axios(config)
      .then((response) => {
        let args = {
          message: "Successfully Updated Meta Information",
          description: response.data.message,
          duration: 2.5,
          placement: "topRight",
        };
        notification.success(args);
        setBlocker(false);
        setTimeout(() => {
          props.set([]);
        }, 1000);
      })
      .catch((error) => {
        let args = {
          message: "Failed To Update Meta Information",
          description: "Sorry u don't have access to update RD's meta",
          duration: 2,
          placement: "topRight",
        };
        notification.error(args);
        setBlocker(false);
        props.set([]);
        console.log(error);
      });
  };

  return (
    <UpdateMetaContainer>
      <CloseModal onClick={closeModal}>X</CloseModal>
      <fieldset>
        <legend>Update Meta Information</legend>
        {loading ? (
          <div style={{ alignItems: "center" }}>
            <Spin />
          </div>
        ) : (
          <div>
            <div>
              <label for="keywords">Keywords :</label>
              <TextArea
                value={rdMeta.keywords}
                onChange={(e) =>
                  setRdMeta((p) => {
                    return { ...p, keywords: e.target.value };
                  })
                }
                defaultValue={Object.keys(rdMeta).length && rdMeta.keywords}
                id="keywords"
                style={{ flex: "0 1 85%" }}
                rows={2}
              />
            </div>

            <div>
              <label for="title">Tags :</label>
              <TextArea
                value={rdMeta.tags}
                onChange={(e) =>
                  setRdMeta((p) => {
                    return { ...p, tags: e.target.value };
                  })
                }
                defaultValue={Object.keys(rdMeta).length && rdMeta.tags}
                id="title"
                style={{ flex: "0 1 85%" }}
                rows={3}
              />
            </div>

            <div>
              <label for="description">Description :</label>
              <TextArea
                value={rdMeta.description}
                onChange={(e) =>
                  setRdMeta((p) => {
                    return { ...p, description: e.target.value };
                  })
                }
                id="description"
                style={{ flex: "0 1 85%" }}
                rows={4}
              />
            </div>
          </div>
        )}
      </fieldset>
      <Submit disabled={blocker || loading} onClick={(e) => onSubmit(e)}>
        Submit
      </Submit>
    </UpdateMetaContainer>
  );
}
