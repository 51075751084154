import React, { useEffect } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import { Select, Input, Button } from "antd";
import { Option } from "antd/lib/mentions";

function useSkipper() {
  const shouldSkipRef = React.useRef(true);

  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });
  return [shouldSkipRef.current, skip];
}

function CompanyTable({
  columns,
  data: entries,
  filterList,
  setResetFilter,
  resetFilter,
}) {
  const [data, setData] = React.useState(entries);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 100,
  });

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  useEffect(() => {
    setData([...entries]);
  }, [entries]);

  const multiSelectFilter = (rows, columnIds, value) => {
    let shouldReturn = false;

    if (value.length) {
      const countryList = rows.original[columnIds]?.split(",") || [];

      for (let country of countryList) {
        if (value.includes(country)) {
          shouldReturn = true;
          break;
        }
      }
      return shouldReturn;
    }
  };

  const dropdownFilter = (rows, columnIds, value) => {
    let shouldReturn = false;

    if (value.length) {
      const countryList = rows.original?.country
        ? [rows.original?.country]
        : [];

      for (let country of countryList) {
        if (value.includes(country)) {
          shouldReturn = true;
          break;
        }
      }
      return shouldReturn;
    }
  };

  const table = useReactTable({
    data,
    columns,
    defaultColumn: {
      cell: (cell) => <EditableField {...cell} />,
    },
    initialState: {
      columnPinning: {
        left: ["company_name"],
      },
    },
    filterFns: {
      multiSelectFilter,
      dropdownFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    autoResetPageIndex,
    meta: {
      updateData: (rowIndex, columnId, value) => {
        skipAutoResetPageIndex();
        let temp = data;
        temp[rowIndex][columnId] = value;
        setData(temp);
      },
    },
    state: {
      pagination,
    },
    debugTable: true,
  });

  useEffect(() => {
    if (resetFilter) {
      table.resetColumnFilters(true);
      setResetFilter(false);
    }
  }, [resetFilter]);

  return (
    <div className="p-2">
      <div className="h-2" />
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                let currentColumn = filterList?.filter(
                  (item) => item.id == header.id
                );
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ ...getCommonPinningStyles(header.column) }}
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter
                              column={header.column}
                              table={table}
                              type={
                                currentColumn?.length
                                  ? currentColumn[0].type
                                  : null
                              }
                              options={
                                currentColumn?.length && currentColumn[0].type
                                  ? currentColumn[0].options
                                  : null
                              }
                            />
                          </div>
                        ) : null}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  style={{ ...getCommonPinningStyles(cell.column) }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div
        style={{
          display: "flex",
          margin: "10px",
          gap: "20px",
          justifyContent: "flex-start",
        }}
      >
        <Button
          disabled={!table.getCanPreviousPage()}
          onClick={() => {
            skipAutoResetPageIndex();
            table.previousPage();
          }}
        >
          Previous Page
        </Button>
        <Button
          disabled={!table.getCanNextPage()}
          onClick={() => {
            skipAutoResetPageIndex();
            table.nextPage();
          }}
        >
          Next Page
        </Button>
      </div>
    </div>
  );
}

export default CompanyTable;

function Filter({ column, table, type, options }) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  switch (type) {
    case null:
      return null;
    case "text":
      return (
        <Input
          type="text"
          value={columnFilterValue || ""}
          onChange={(e) => column.setFilterValue(e.target.value)}
          placeholder={`Search...`}
          className="w-36 border shadow rounded"
        />
      );
    case "dropdown":
      return (
        <MultiSelectDropdown
          columnFilterValue={columnFilterValue}
          options={options}
          handleChange={column.setFilterValue}
        />
      );
    default:
      return null;
  }
}

export function EditableField({
  getValue,
  row: { index },
  column: { id },
  table,
}) {
  const initialValue = getValue();
  const [value, setValue] = React.useState(initialValue);

  const onBlur = () => {
    //eslint-disable-next-line
    table.options.meta?.updateData(index, id, value);
  };

  React.useEffect(() => {
    setValue(initialValue || "");
  }, [initialValue]);

  return (
    <textarea
      style={{
        border: "none",
        background: "inherit",
        resize: "none",
        minWidth: "100px",
      }}
      rows={5}
      value={value}
      contentEditable
      title={value}
      onChange={(e) => setValue(e.target.value)}
      onInput={(e) => setValue(e.target.value)}
      onBlur={onBlur}
    />
  );
}

function getCommonPinningStyles(column) {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn =
    isPinned === "left" && column.getIsLastColumn("left");
  const isFirstRightPinnedColumn =
    isPinned === "right" && column.getIsFirstColumn("right");

  return {
    boxShadow: isLastLeftPinnedColumn
      ? "-4px 0 4px -4px gray inset"
      : isFirstRightPinnedColumn
        ? "4px 0 4px -4px gray inset"
        : undefined,
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
    opacity: isPinned ? 0.95 : 1,
    position: isPinned ? "sticky" : "relative",
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
    background: "inherit",
  };
}

export function MultiSelectDropdown({
  columnValue,
  options,
  handleChange,
  isFilter = true,
  index,
  id,
  table,
}) {
  const [value, setValue] = React.useState(columnValue || []);

  const onBlur = () => {
    if (!isFilter) {
      //eslint-disable-next-line
      table.options.meta?.updateData(
        index,
        id,
        Array.isArray(value) && value.length ? value.join(",") : ""
      );
    }
  };

  React.useEffect(() => {
    setValue(columnValue || "");
  }, [columnValue]);

  const handleOnChange = (list) => {
    setValue(list);
    handleChange && handleChange(list);
  };

  return (
    <Select
      mode="multiple"
      placeholder="Select Country"
      className="dropdown-css"
      value={value || []}
      onChange={handleOnChange}
      onBlur={onBlur}
      onDeselect={onBlur}
    >
      {options?.map((item) => (
        <Option key={item} value={item}>
          {item}
        </Option>
      ))}
    </Select>
  );
}
