import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Pie from "../Graphs/Pie";
import Barline from "../Graphs/BarLine";

var Base64 = require("js-base64").Base64;

export const regionOverviewDemoData = {
  slideName: "regionoverview",
  title: "Region overview",
  slideNo: 10,
  slug: "US PROBIOTICS MARKET",
  slideReference: "marketsegment",
  graphs: [
    {
      graphType: "barline",
      data: [
        {
          label: "2002",
          value: 36,
          volume: 6,
        },
        {
          label: "2003",
          value: 27,
          volume: 7,
        },
        {
          label: "2004",
          value: 45,
          volume: 9,
        },
      ],
      url: "https://i.ibb.co/FWnR6Jn/barline.png",
      heading: "graph heading1",
    },
    {
      graphType: "pie",
      data: [
        {
          label: "Poultry",
          value: 7,
        },
        {
          label: "Swine",
          value: 92,
        },
        {
          label: "Aquaculture",
          value: 36,
        },
      ],
      url: "https://i.ibb.co/xz5vcK5/Graph-1.png",
      heading: "graph heading2",
    },
    {
      graphType: "pie",
      data: [
        {
          label: "Poultry",
          value: 7,
        },
        {
          label: "Swine",
          value: 92,
        },
        {
          label: "Aquaculture",
          value: 36,
        },
      ],
      url: "https://i.ibb.co/xz5vcK5/Graph-1.png",
      heading: "graph heading3",
    },
    {
      graphType: "pie",
      data: [
        {
          label: "Poultry",
          value: 7,
        },
        {
          label: "Swine",
          value: 92,
        },
        {
          label: "Aquaculture",
          value: 36,
        },
      ],
      url: "https://i.ibb.co/xz5vcK5/Graph-1.png",
      heading: "graph heading4",
    },
  ],
};

class RegionOverview extends React.Component {
  state = {
    graphFooterText:
      "Source: Mordor Intelligence Analysis based on Alltech Feed Survey, FAO, Other Sources & Primary Research",
    data: this.props.data ? this.props.data : regionOverviewDemoData,
    reseller: false,
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.data.title !== this.props.data.title) {
      this.setState({
        data: this.props.data,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.meta) {
      if (props.data.meta.sample && props.data.meta.reseller) {
        return {
          reseller: true,
        };
      }
    }
    return null;
  }

  render() {
    const { id, title, subtitle, slug, graphs } = this.state.data;
    const { slideNo } = this.props;
    const pageId = id ? Base64.encode(id) : undefined;
    let slideNoElementId = undefined;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }
    return (
      <div className="page" id={pageId}>
        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Header
            heading={title}
            subheading={subtitle}
            slideTitle={this.state.data.slideName}
          />
        ) : null}
        <div
          className="container"
          style={{ height: this.props.fromInterface ? "auto" : "626px" }}
        >
          <div className="main-content-region-overview">
            {graphs[0] && (
              <Barline
                width={697}
                axes={false}
                labels={true}
                id={`id${slideNo}1`}
                data={{ data: graphs[0].data }}
                headers={graphs[0].heading}
                footers={this.state.graphFooterText}
              />
            )}
            <div className="region_overview_bottom_images">
              {graphs[1] && (
                <Pie
                  axes={false}
                  labels={false}
                  id="hel11"
                  width={350}
                  data={{ data: graphs[1].data }}
                  headers={graphs[1].heading}
                  footers={this.state.graphFooterText}
                />
              )}
              {graphs[2] && (
                <Pie
                  axes={false}
                  labels={false}
                  id="hel1"
                  width={350}
                  data={{ data: graphs[2].data }}
                  headers={graphs[2].heading}
                  footers={this.state.graphFooterText}
                />
              )}
              {graphs[3] && (
                <Pie
                  axes={false}
                  labels={false}
                  id="hel12"
                  width={350}
                  data={{ data: graphs[3].data }}
                  headers={graphs[3].heading}
                  footers={this.state.graphFooterText}
                />
              )}
            </div>
          </div>
        </div>
        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Footer
            slug={slug}
            slideNo={slideNo}
            slideNoElementId={slideNoElementId}
            reseller={this.state.reseller}
          />
        ) : null}
      </div>
    );
  }
}

export default RegionOverview;
