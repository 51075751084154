export const regions = [
  "Africa",
  "APAC",
  "ASEAN",
  "Asia-Pacific",
  "Europe",
  "GCC",
  "Global",
  "Latin America",
  "Middle East",
  "Middle East and Africa",
  "Nordics",
  "North America",
  "Rest of World",
  "South Africa",
  "South America",
  "South East Asia",
];

export const countries = [
  "Algeria",
  "Argentina",
  "Australia",
  "Austria",
  "Bahrain",
  "Bangladesh",
  "Belgium",
  "Brazil",
  "Bulgaria",
  "Canada",
  "Chile",
  "China",
  "Colombia",
  "Czech Republic",
  "Denmark",
  "Egypt",
  "Estonia",
  "Ethiopia",
  "Finland",
  "France",
  "Germany",
  "Ghana",
  "Hong Kong",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Ireland",
  "Israel",
  "Italy",
  "Japan",
  "Kenya",
  "Kuwait",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Malaysia",
  "Mexico",
  "Morocco",
  "Myanmar",
  "Netherlands",
  "New Zealand",
  "Nigeria",
  "Nordics",
  "Norway",
  "Oman",
  "Pakistan",
  "Philippines",
  "Poland",
  "Portugal",
  "Rest of Africa",
  "Rest of APAC",
  "Rest of ASEAN",
  "Rest of Asia Pacific",
  "Rest of Europe",
  "Rest of GCC",
  "Rest of Latin America",
  "Rest of MEA",
  "Rest of Middle East",
  "Rest of Middle East and Africa",
  "Rest of Nordics",
  "Rest of North America",
  "Rest of South America",
  "Rest of South East Asia",
  "Rest of World",
  "Romania",
  "Russia",
  "Saudi Arabia",
  "Singapore",
  "South Africa",
  "South Korea",
  "Spain",
  "Sweden",
  "Switzerland",
  "Taiwan",
  "Tanzania",
  "Thailand",
  "Turkey",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Vietnam",
];

export const regionCountryConfig = {
  Africa: [
    "Egypt",
    "Ethiopia",
    "Ghana",
    "Kenya",
    "Morocco",
    "Nigeria",
    "Rest of Africa",
    "South Africa",
    "Tanzania",
  ],
  APAC: [
    "Australia",
    "China",
    "Hong Kong",
    "India",
    "Indonesia",
    "Japan",
    "Malaysia",
    "New Zealand",
    "Philippines",
    "Rest of APAC",
    "Singapore",
    "South Korea",
    "Taiwan",
    "Thailand",
    "Vietnam",
  ],
  ASEAN: [
    "Indonesia",
    "Malaysia",
    "Philippines",
    "Rest of ASEAN",
    "Thailand",
    "Vietnam",
  ],
  "Asia-Pacific": [
    "Australia",
    "ASEAN Countries",
    "Bangladesh",
    "China",
    "India",
    "Indonesia",
    "Japan",
    "Malaysia",
    "Myanmar",
    "New Zealand",
    "Pakistan",
    "Philippines",
    "Rest of APAC",
    "Rest of Asia-Pacific",
    "Rest of ASEAN",
    "Singapore",
    "South Korea",
    "Taiwan",
    "Thailand",
    "Vietnam",
  ],
  Europe: [
    "Austria",
    "Belgium",
    "Bulgaria",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Ireland",
    "Iceland",
    "Italy",
    "Latvia",
    "Lithuania",
    "Luxembourg",
    "Netherlands",
    "Nordics",
    "Norway",
    "Poland",
    "Portugal",
    "Rest of Europe",
    "Romania",
    "Russia",
    "Spain",
    "Sweden",
    "Switzerland",
    "Turkey",
    "Ukraine",
    "United Kingdom",
  ],
  GCC: [
    "Bahrain",
    "Kuwait",
    "Oman",
    "Qatar",
    "Rest of GCC",
    "Saudi Arabia",
    "United Arab Emirates",
  ],
  Global: [
    "Algeria",
    "Argentina",
    "Australia",
    "Austria",
    "Bahrain",
    "Bangladesh",
    "Belgium",
    "Brazil",
    "Bulgaria",
    "Canada",
    "Chile",
    "China",
    "Colombia",
    "Czech Republic",
    "Denmark",
    "Egypt",
    "Estonia",
    "Ethiopia",
    "Finland",
    "France",
    "Germany",
    "Ghana",
    "Hong Kong",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Ireland",
    "Israel",
    "Italy",
    "Japan",
    "Kenya",
    "Kuwait",
    "Latvia",
    "Lithuania",
    "Luxembourg",
    "Malaysia",
    "Mexico",
    "Morocco",
    "Myanmar",
    "Netherlands",
    "New Zealand",
    "Nigeria",
    "Nordics",
    "Norway",
    "Oman",
    "Pakistan",
    "Philippines",
    "Poland",
    "Portugal",
    "Rest of Africa",
    "Rest of APAC",
    "Rest of ASEAN",
    "Rest of Asia Pacific",
    "Rest of Europe",
    "Rest of GCC",
    "Rest of Latin America",
    "Rest of MEA",
    "Rest of Middle East",
    "Rest of Middle East and Africa",
    "Rest of Nordics",
    "Rest of North America",
    "Rest of South America",
    "Rest of South East Asia",
    "Rest of World",
    "Romania",
    "Russia",
    "Saudi Arabia",
    "Singapore",
    "South Africa",
    "South Korea",
    "Spain",
    "Sweden",
    "Switzerland",
    "Taiwan",
    "Tanzania",
    "Thailand",
    "Turkey",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Vietnam",
  ],
  "Latin America": ["Argentina", "Brazil", "Chile", "Rest of Latin America"],
  "Middle East": [
    "Bahrain",
    "Iran",
    "Israel",
    "Kuwait",
    "Oman",
    "Qatar",
    "Rest of Middle East",
    "Rest of MEA",
    "Saudi Arabia",
    "Turkey",
    "United Arab Emirates",
  ],
  "Middle East and Africa": [
    "Algeria",
    "Bahrain",
    "Egypt",
    "Ethiopia",
    "Ghana",
    "Iran",
    "Israel",
    "Kenya",
    "Kuwait",
    "Morocco",
    "Nigeria",
    "Oman",
    "Qatar",
    "Rest of Middle East and Africa",
    "Saudi Arabia",
    "South Africa",
    "Tanzania",
    "Turkey",
    "United Arab Emirates",
  ],
  Nordics: [
    "Denmark",
    "Finland",
    "Iceland",
    "Norway",
    "Rest of the Nordics",
    "Sweden",
  ],
  "North America": [
    "Canada",
    "Mexico",
    "Rest of North America",
    "United States",
  ],
  "Rest of World": [
    "Brazil",
    "Iran",
    "Rest of World",
    "Saudi Arabia",
    "United Arab Emirates",
  ],
  "South Africa": ["South Africa"],
  "South America": [
    "Argentina",
    "Brazil",
    "Chile",
    "Colombia",
    "Rest of South America",
  ],
  "South East Asia": [
    "Indonesia",
    "Malaysia",
    "Myanmar",
    "Philippines",
    "Rest of South East Asia",
    "Singapore",
    "Thailand",
    "Vietnam",
  ],
};

export const instructionConfig = [
  {
    name: "hubName",
    message: "Enter Name Of Hub.(Try to avoid Special Characters.)",
  },
  {
    name: "Data Points",
    message: "Data Points should be in integers.",
  },
  {
    name: "Industry Reports",
    message: "Industry Reports should be in integers.",
  },
  {
    name: "Market Segments",
    message: "Market Segments should be in integers.",
  },
  {
    name: "Companies With Market Shares",
    message: "Companies with Market Shares should be in Integers.",
  },
  {
    name: "clientsLogos",
    message:
      "Logos should be in .svg, .png, .jpg or .jpeg format. You can select multiple logos by pressing cmt/ctrl key.",
  },
  {
    name: "testimonialAuthor",
    message: "Enter name of Testimonial here.",
  },
  {
    name: "testimonialText",
    message: "Enter Feedback by Testimonial here.",
  },
  {
    name: "segmentName",
    message: "Enter the Segment here.",
  },
  {
    name: "segmentCategoryTitle",
    message: "Enter the Categoty Title here.",
  },
  {
    name: "segmentCategoryBullets",
    message:
      "Enter Categoty Bullets here. (Seperate bullets using semicolon(;).)",
  },
  {
    name: "expertInsightsTitle",
    message: "Enter Title for Expert Insights  here.",
  },
  {
    name: "expertInsightsDescription",
    message: "Enter Description for Expert Insights  here.",
  },
  {
    name: "expertInsightsProfile",
    message:
      "Upload Profile Picture of Expert here. Profile should be in .svg, .png, .jpg or .jpeg format.",
  },
  {
    name: "expertInsightsGraph",
    message:
      "Upload Bubble Graph Image here. Graph should be in .svg, .png, .jpg or .jpeg format.",
  },
  {
    name: "expertInsightsName",
    message: "Enter Name of Expert here.",
  },
  {
    name: "expertInsightsRole",
    message: "Enter Role of Expert here.",
  },
  {
    name: "expertInsightsIndustry",
    message: "Enter name of Industry here.",
  },
  {
    name: "mordorVsOthers",
    message: "Mordor Intelligence Vs Other Companies (Based on Given Keys)",
  },
  {
    name: "researchMethodologyText",
    message: "Write about the research Methodology.",
  },
  {
    name: "researchMethodologyTitle",
    message: "Enter Title for research Methodology data here.",
  },
  {
    name: "researchMethodologyBullets",
    message:
      "Enter Bullets for research Methodology data here. (Seperate bullets using semicolon(;).)",
  },
  {
    name: "customizedSolutionImage",
    message: "Upload desktop image for customised solutions.",
  },
  {
    name: "customizedSolutionMobileImage",
    message: "Upload mobile image for customised solutions.",
  },
  {
    name: "reportImage",
    message: "Upload Image of Report.",
  },
  {
    name: "reportVideo",
    message: "Upload Video of Report.",
  },
  {
    name: "sampleVideo",
    message: "Upload Video of Sample.",
  },
  {
    name: "rdTitle",
    message: "Enter Title for Report and Datasets.",
  },
  {
    name: "rdBullets",
    message:
      "Enter Bullets for Report and Datasets. (Separate each bullet by semicolon(;).)",
  },
  {
    name: "rbImage",
    message: "Upload Image for Report Buldels.",
  },
  {
    name: "rbSegments",
    message:
      "Enter segemnt bullets to be displayed in Report Buldels.(Each Segment should be separated by semicolon(;))",
  },
  {
    name: "rbRegions",
    message: "Select any Region To make a region-countries set.",
  },
  {
    name: "rbCountries",
    message: "Select country/countries to complete region-countries set.",
  },
];

// report && report.new_description && report.new_description[6] && report.new_description[6].images && report.new_description[6].images.url ||
