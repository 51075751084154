import React, { Component } from "react";
import Mordor_icon from "../favicon.png";
//import { Button} from "antd";
import config from "../../../config/Api";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";

import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";

import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";

import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";
import axios from "axios";
import { breakLabels } from "../../../Graphs/graphs.helperFunctions";
import font_html from "../../font_style.js";
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);
// FusionCharts.options.export.useCanvas = "true";
//Your react component
class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          label: "Venezuela",
          value: "290",
        },
        {
          label: "Saudi",
          value: "260",
        },
        {
          label: "Canada",
          value: "180",
        },
        {
          label: "Iran",
          value: "140",
        },
        {
          label: "Russia",
          value: "115",
        },
        {
          label: "UAE",
          value: "100",
        },
        {
          label: "US",
          value: "30",
        },
        {
          label: "China",
          value: "30",
        },
      ],
      datas: "",
      button_click: false,
      loading: false,
      loadingData: true,
      YMaxValue: 0,
    };
    this.resetChart = this.resetChart.bind(this);
    this.countMaxAxisRange = this.countMaxAxisRange.bind(this);
  }
  componentDidMount() {
    let datas = parseData(JSON.parse(JSON.stringify(this.props.data)));
    let YMaxValue = this.countMaxAxisRange(datas);
    this.setState({
      datas: datas,
      loadingData: false,
      data: this.props.data,
      YMaxValue,
    });
    setTimeout(() => {
      this.resetChart();
    }, 200);
  }
  componentDidUpdate(prevProps) {
    let datas = parseData(JSON.parse(JSON.stringify(this.props.data)));
    let YMaxValue = this.countMaxAxisRange(datas);
    if (this.props.data !== prevProps.data) {
      this.setState({
        datas: datas,
        loadingData: false,
        data: this.props.data,
        YMaxValue,
      });
      setTimeout(() => {
        this.resetChart();
      }, 500);
    }
  }
  resetChart(event) {
    let a = window.btoa(JSON.stringify(this.props.data));
    let data = "data";
    var revenueChart = FusionCharts(
      `${this.props.data.graph_type + this.props.data.component}`
    );
    // get chart xml data
    var svgString = revenueChart.getSVGString();
    let svgdata = new DOMParser().parseFromString(svgString, "text/xml");
    var doc = new DOMParser().parseFromString(font_html, "text/html");
    // svgdata.prepend(doc.documentElement)
    let defs_data = svgdata.getElementsByTagName("defs")[0];
    defs_data.prepend(doc.documentElement);
    axios
      .post(`${config.api.base_url}/api/graphs/${data}?data=${a}`, {
        stream: window.btoa(svgdata.documentElement.outerHTML),
      })
      .then((res) => {});
    // FusionCharts.batchExport({
    //   charts: [
    //     {
    //       id: this.props.data.graph_type + this.props.data.component,
    //     },
    //   ],
    //   exportFormat: "png",

    //   exportHandler: `${config.api.base_url}/api/graphs/${data}?data=${a}`,
    //   exportAction: "SAVE",
    //   exportMode: "client",
    // });
  }
  countMaxAxisRange(data) {
    let valArr = [],
      diff = 0,
      avgDiff = 0,
      yMaxVal = 0,
      yMinVal = 0;
    if (data) {
      valArr = [...data].map((item) => {
        if (isNaN(item.value)) item.value = 0;
        return item;
      });
      valArr = valArr.sort((a, b) => {
        return Number(a.value) - Number(b.value);
      });
      yMaxVal = valArr[valArr.length - 1].value;
      for (let item of valArr) {
        if (Number(item.value) !== 0) {
          yMinVal = item.value;
          break;
        }
      }
      // for (let i = 0; i < valArr.length - 2; i++) {
      //   diff = diff + (valArr[i + 1].value - valArr[i].value);
      // }
      if (yMinVal / yMaxVal > 0.7) avgDiff = Number(yMaxVal) * 0.3;
      else avgDiff = (Number(valArr[valArr.length - 1].value) - yMinVal) / 2;
      yMaxVal = Number(yMaxVal) + avgDiff;
    }
    return yMaxVal;
  }
  render() {
    let data = "",
      datas = "";
    if (!this.state.loadingData) {
      data = this.state.datas;
      datas = this.state.data;
    }
    let width = 1366,
      height = 655;
    if (datas.component && datas.component.toLowerCase() === "key_factor") {
      width = 508;
      height = 700;
    }
    let headers = "Countries With Most Oil Reserves [2017-18]";
    let footers = "Source:",
      reseller = false;
    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (datas && datas.heading) headers = datas.heading;
    if (datas && datas.heading) footers = datas.source;
    if (datas && datas.reseller) reseller = datas.reseller;

    let chartConfigs = {
      id: this.props.data.graph_type + this.props.data.component,
      type: "column2d",
      width: width,
      height: height,
      dataFormat: "json",
      dataSource: {
        chart: {
          caption: "",
          paletteColors: "#00CED1",
          captionFontColor: "#7f7f7f",
          showValues: this.props.data.labels === true ? true : false,
          divLineColor: "#ffffff",
          showPlotBorder: "0",
          maxBarHeight: 2,
          animation: false,
          labelFontSize: 32,
          showYAxisValues: false,
          captionFontSize: 36,
          valueFontBold: 1,
          theme: "fusion",
          plotSpacePercent: 40,
          chartLeftMargin: 40,
          captionAlignment: "center",
          valueFontColor: "#7f7f7f",
          placeValuesInside: "0",
          captionFont: "regular_bold_rd",
          yAxisNamePadding: 0,
          yAxisMaxValue: `${this.state.YMaxValue}`,
          xAxisNameFont: "regular_semibold_rd",
          chartBottomMargin: 90,
          chartTopMargin: 70,
          xAxisNameFontSize: 12,
          xAxisNameFontColor: "#7f7f7f",
          centerXaxisName: false,
          valueFont: "regular_semibold_rd",
          valueFontSize: data.length > 8 ? 24 : 32,
          formatNumberScale: 1,
          numberScaleValue: "1000,1000,1000,1000",
          numberScaleUnit: "K,M,B,T",
          labelFont: "regular_semibold_rd",
        },
        annotations: {
          autoScale: "0",
          groups: [
            {
              id: "caption",
              items: [
                {
                  id: "dyn-label",
                  type: "text",
                  align: "center",
                  fillcolor: "#7f7f7f",
                  fontsize: 36,
                  font: "regular_bold_rd",
                  text: headers,
                  bold: "1",
                  wrap: "1",
                  wrapWidth: `1270`,
                  x: `690`,
                  y: `45`,
                },
              ],
            },
            {
              id: "footer",
              items: [
                {
                  id: "dyn-label-bg",
                  showBorder: "0",
                  borderColor: "12345d",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#7f7f7f",
                  fontsize: 36,
                  font: "regular_semibold_rd",
                  text: "Source :",
                  bold: "1",
                  wrap: "1",
                  wrapWidth: "100%",
                  x: "85",
                  y: height - 32,
                },
              ],
            },
            {
              id: "sub-footer",
              items: [
                {
                  id: "dyn-label-bg",
                  showBorder: "0",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  font: "regular_regular_rd",
                  fillcolor: "#7f7f7f",
                  fontsize: 36,
                  text: footers,
                  bold: "0",
                  wrap: "1",
                  wrapWidth: "100%",
                  x: 215,
                  y: height - 32,
                },
              ],
            },
            {
              id: "mordor-icon",
              xScale_: "5",
              yScale_: "5",
              items: [
                {
                  id: "Brian-user-icon",
                  type: "image",
                  url: Mordor_icon,
                  x: "1180",
                  y: height - 72,
                  xScale: "200",
                  yScale: "200",
                },
              ],
            },
          ],
        },
        data: data,
      },
    };
    if (reseller === true) {
      chartConfigs.dataSource.annotations.groups = [];
    }

    return (
      <div className="bar" id="bar">
        <div>
          <ReactFC {...chartConfigs} />
        </div>
      </div>
    );
  }
}
function parseData(data) {
  if (data.full_data) return data.data;
  else return data.data.slice(0, 7);
}
export default Chart;
