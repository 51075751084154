import React from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import { AviationVCWrapper } from "./styles";

var Base64 = require("js-base64").Base64;

class Aviation_VC extends React.Component {
  state = {
    data: this.props.data ? this.props.data : "",
    section1_data: [],
    section2_data: [],
    section3_data: [],
    row1_data: [],
    row2_data: [],
    row3_data: [],
    reseller: false,
  };

  componentDidMount = () => {
    this.splitToChunks();
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.meta) {
      if (props.data.meta.sample && props.data.meta.reseller) {
        return {
          reseller: true,
        };
      }
    }
    return null;
  }

  splitToChunks = () => {
    const { data } = this.props.data ? this.props.data : this.state.data;
    const { template_part } = this.props.data.meta
      ? this.props.data.meta
      : this.state.data.meta;

    let row1_data = this.props.fromInterface
      ? data && data.length > 1 && template_part === "0"
        ? data.slice(0, 1)
        : []
      : data && data;

    let row2_data = this.props.fromInterface
      ? data && data.length > 1 && template_part === "0"
        ? data.slice(1, 2)
        : []
      : data && data;

    let row3_data = this.props.fromInterface
      ? data && data.length > 1 && template_part === "0"
        ? data.slice(2, 3)
        : []
      : data && data;

    let section1_data =
      data && data.length > 1 && template_part !== "0" ? data.slice(0, 2) : [];
    let section2_data =
      data && data.length > 1 && template_part !== "0" ? data.slice(2, 3) : [];
    let section3_data =
      data && data.length > 1 && template_part !== "0" ? data.slice(3, 5) : [];

    this.setState({
      row1_data,
      row2_data,
      row3_data,
      section1_data,
      section2_data,
      section3_data,
    });
  };

  render() {
    let item_num =
      this.state.data && this.state.data.item_num
        ? this.state.data.item_num
        : "";

    const { section_id } = this.state.data;
    const {
      section1_data,
      section2_data,
      section3_data,
      row1_data,
      row2_data,
      row3_data,
    } = this.state;

    const { slideNo } = this.props;
    const slug = this.state.data.slug;
    const pageId = Base64.encode(section_id);

    return (
      <div className="page" id={pageId}>
        <AviationVCWrapper
          templatePart={this.state.data.meta.template_part}
          fromInterface={
            !this.props.fromInterface || this.props.fromInterface === undefined
              ? true
              : false
          }
        >
          <div style={{ position: "relative" }}>
            {this.state.data.meta.template_part === "0" ? (
              this.state.data.multi_urls.map((img, i) => (
                <div style={{ position: "relative", height: "720px" }}>
                  {!this.props.fromInterface ||
                  this.props.fromInterface === undefined ? (
                    <div style={{ height: "90px" }}>
                      <Header slideTitle={this.state.data.type} />
                      <h2 className="heading valuechain_title">
                        {this.props.data
                          ? `${this.props.data.title}: ${
                              this.props.data.meta.multi_valueChain[
                                this.props.data.item_num
                              ].aircraft_type
                            }`
                          : `${this.state.data.title}: : ${
                              this.state.data.meta.multi_valueChain[
                                this.props.data.item_num
                              ].aircraft_type
                            }`}
                      </h2>
                    </div>
                  ) : (
                    <h2 className="heading valuechain_title">
                      {this.props.data
                        ? `${this.props.data.title}: ${this.props.data.meta.multi_valueChain[i].aircraft_type}`
                        : `${this.state.data.title}: : ${this.state.data.meta.multi_valueChain[i].aircraft_type}`}
                    </h2>
                  )}

                  <img
                    src={
                      this.state.data &&
                      this.state.data.meta &&
                      !this.state.data.meta.sample
                        ? img.url
                        : img.url
                    }
                    alt="Value Chain"
                    style={{
                      width: "100%",
                      height: "573px",
                      marginTop: "10px",
                    }}
                  />

                  <div>
                    {i === 0
                      ? row1_data.map((item) =>
                          this.state.data &&
                          this.state.data.meta &&
                          !this.state.data.meta.sample ? (
                            <div className="section_container">
                              {item.slice(0, 2).map((item, i) => (
                                <div
                                  className={[
                                    `section1_row${item_num + 1}_list${i + 1}`,
                                    "section_data1",
                                  ].join(" ")}
                                >
                                  {item.list.map((elem) => (
                                    <div className="font_design">
                                      <div>•</div>
                                      <div style={{ marginLeft: "5px" }}>
                                        {elem}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ))}

                              {item.slice(2, 3).map((item, i) => (
                                <div
                                  className={[
                                    `section2_row${item_num + 1}_list${i + 1}`,
                                    "section_data1",
                                  ].join(" ")}
                                >
                                  {item.list.map((elem) => (
                                    <div className="font_design">
                                      <div>•</div>
                                      <div style={{ marginLeft: "5px" }}>
                                        {elem}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ))}

                              {item.slice(3, 5).map((item, i) => (
                                <div
                                  className={[
                                    `section3_row${item_num + 1}_list${i + 1}`,
                                    "section_data1",
                                  ].join(" ")}
                                >
                                  {item.list.map((elem) => (
                                    <div className="font_design">
                                      <div>•</div>
                                      <div style={{ marginLeft: "5px" }}>
                                        {elem}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </div>
                          ) : (
                            ""
                          )
                        )
                      : i === 1
                        ? row2_data.map((item) =>
                            this.state.data &&
                            this.state.data.meta &&
                            !this.state.data.meta.sample ? (
                              <div className="section_container">
                                {item.slice(0, 2).map((item, i) => (
                                  <div
                                    className={[
                                      `section1_row2_list${i + 1}`,
                                      "section_data1",
                                    ].join(" ")}
                                  >
                                    {item.list.map((elem) => (
                                      <div className="font_design">
                                        <div>•</div>
                                        <div style={{ marginLeft: "5px" }}>
                                          {elem}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ))}

                                {item.slice(2, 3).map((item, i) => (
                                  <div
                                    className={[
                                      `section2_row2_list${i + 1}`,
                                      "section_data2",
                                    ].join(" ")}
                                  >
                                    {item.list.map((elem) => (
                                      <div className="font_design">
                                        <div>•</div>
                                        <div style={{ marginLeft: "5px" }}>
                                          {elem}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ))}

                                {item.slice(3, 5).map((item, i) => (
                                  <div
                                    className={[
                                      `section3_row2_list${i + 1}`,
                                      "section_data1",
                                    ].join(" ")}
                                  >
                                    {item.list.map((elem) => (
                                      <div className="font_design">
                                        <div>•</div>
                                        <div style={{ marginLeft: "5px" }}>
                                          {elem}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ))}
                              </div>
                            ) : (
                              ""
                            )
                          )
                        : i === 2
                          ? row3_data.map((item) =>
                              this.state.data &&
                              this.state.data.meta &&
                              !this.state.data.meta.sample ? (
                                <div className="section_container">
                                  {item.slice(0, 2).map((item, i) => (
                                    <div
                                      className={[
                                        `section1_row3_list${i + 1}`,
                                        "section_data1",
                                      ].join(" ")}
                                    >
                                      {item.list.map((elem) => (
                                        <div className="font_design">
                                          <div>•</div>
                                          <div style={{ marginLeft: "5px" }}>
                                            {elem}
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  ))}

                                  {item.slice(2, 3).map((item, i) => (
                                    <div
                                      className={[
                                        `section2_row3_list${i + 1}`,
                                        "section_data2",
                                      ].join(" ")}
                                    >
                                      {item.list.map((elem) => (
                                        <div className="font_design">
                                          <div>•</div>
                                          <div style={{ marginLeft: "5px" }}>
                                            {elem}
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  ))}

                                  {item.slice(3, 5).map((item) => (
                                    <div className="section3_row3_list1 section_data1">
                                      {item.list.map((elem) => (
                                        <div className="font_design">
                                          <div>•</div>
                                          <div style={{ marginLeft: "5px" }}>
                                            {elem}
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                ""
                              )
                            )
                          : ""}
                  </div>

                  {!this.props.fromInterface ||
                  this.props.fromInterface === undefined ? (
                    <Footer
                      slug={slug}
                      slideNo={slideNo}
                      reseller={this.state.reseller}
                    />
                  ) : null}
                </div>
              ))
            ) : (
              <div style={{ height: "720px" }}>
                {!this.props.fromInterface ||
                this.props.fromInterface === undefined ? (
                  <div style={{ height: "90px" }}>
                    <Header slideTitle={this.state.data.type} />
                    <h2 className="heading valuechain_title">
                      {this.props.data
                        ? this.props.data.title
                        : this.state.data.title}
                    </h2>
                  </div>
                ) : (
                  <h2 className="heading valuechain_title">
                    {this.props.data
                      ? this.props.data.title
                      : this.state.data.title}
                  </h2>
                )}
                <img
                  src={
                    this.state.data &&
                    this.state.data.meta &&
                    !this.state.data.meta.sample
                      ? this.state.data.url
                      : this.state.data.url
                  }
                  alt="Value Chain"
                  style={{ width: "100%", height: "573px", marginTop: "10px" }}
                />

                <div>
                  {section1_data.slice(0, 1).map((item) =>
                    this.state.data &&
                    this.state.data.meta &&
                    !this.state.data.meta.sample ? (
                      <div className="section1_list1 section_data1">
                        {item.list.map((elem) => (
                          <div className="font_design">
                            <div>•</div>
                            <div style={{ marginLeft: "5px" }}>{elem}</div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      ""
                    )
                  )}

                  {section1_data.slice(1, 2).map((item) =>
                    this.state.data &&
                    this.state.data.meta &&
                    !this.state.data.meta.sample ? (
                      <div className="section1_list2 section_data1">
                        {item.list.map((elem) => (
                          <div className="font_design">
                            <div>•</div>
                            <div style={{ marginLeft: "5px" }}>{elem}</div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      ""
                    )
                  )}

                  {section2_data.map((item) =>
                    this.state.data &&
                    this.state.data.meta &&
                    !this.state.data.meta.sample ? (
                      <div className="section2_list section_data2">
                        {item.list.map((elem) => (
                          <div className="font_design">
                            <div>•</div>
                            <div style={{ marginLeft: "5px" }}>{elem}</div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      ""
                    )
                  )}

                  {section3_data.slice(0, 1).map((item) =>
                    this.state.data &&
                    this.state.data.meta &&
                    !this.state.data.meta.sample ? (
                      <div className="section3_list1 section_data1">
                        {item.list.map((elem) => (
                          <div className="font_design">
                            <div>•</div>
                            <div style={{ marginLeft: "5px" }}>{elem}</div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      ""
                    )
                  )}

                  {section3_data.slice(1, 2).map((item) =>
                    this.state.data &&
                    this.state.data.meta &&
                    !this.state.data.meta.sample ? (
                      <div className="section3_list2 section_data1">
                        {item.list.map((elem) => (
                          <div className="font_design">
                            <div>•</div>
                            <div style={{ marginLeft: "5px" }}>{elem}</div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      ""
                    )
                  )}
                </div>

                {!this.props.fromInterface ||
                this.props.fromInterface === undefined ? (
                  <Footer
                    slug={slug}
                    slideNo={slideNo}
                    reseller={this.state.reseller}
                  />
                ) : null}
              </div>
            )}
          </div>
        </AviationVCWrapper>
      </div>
    );
  }
}

export default Aviation_VC;
