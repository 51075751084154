import React, { Component } from "react";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";
import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";
import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";
import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";

import MordorIcon from "../../RDGraphs/GraphComponents/favicon.png";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);
// FusionCharts.options.export.useCanvas = "true";

export default class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxValue: 0,
      data: [
        {
          label: "2015",
          value: "100",
        },
        {
          label: "2016",
          value: "30",
        },
        {
          label: "2017",
          value: "30",
        },
      ],
    };
    this.datavalidate = this.datavalidate.bind(this);
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        data: this.datavalidate(this.props.data),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState &&
      JSON.stringify(prevState.data) !== JSON.stringify(this.props.data)
    ) {
      if (this.props.data) {
        this.setState({
          data: this.datavalidate(this.props.data),
        });
      }
    }
  }

  datavalidate(data) {
    for (let item of data) {
      item.label = item.label + "";
    }
    return data;
  }

  render() {
    let diff = 0,
      avgDiff = 0,
      yMinVal = 0,
      yMaxVal = 0,
      valArr = [],
      width = 400,
      height = 240,
      headers = "";

    for (let i = 0; i < this.props.data.length; i++) {
      valArr.push(this.props.data[i]);
    }

    valArr = valArr.sort((a, b) => {
      return a.value - b.value;
    });

    for (let i = 0; i < valArr.length - 2; i++) {
      diff = diff + (valArr[i + 1].value - valArr[i].value);
    }

    avgDiff = diff / (valArr.length - 1);

    yMinVal = valArr[0].value;
    yMaxVal = valArr[valArr.length - 1].value;

    if (yMinVal - avgDiff < 0 && valArr[0].value > 0) {
      yMinVal = 0;
    } else {
      yMinVal = yMinVal - avgDiff;
    }
    yMaxVal = yMaxVal + avgDiff;

    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (this.props.headers) headers = this.props.headers;

    let chartConfigs = {
      type: "line",
      dataFormat: "json",
      width: width,
      height: height,
      dataSource: {
        chart: {
          theme: "fusion",
          animation: false,
          showanchors: 1,

          anchorRadius: 3,
          anchorBorderColor: "#9e0e0e",
          anchorBorderThickness: 1,
          anchorBgColor: "#ffffff",
          anchorSides: 0,

          adjustDiv: "0",
          divLineDashed: "0",
          divLineColor: "#C0C0C0",
          divLineAlpha: "80",
          divLineDashed: "0",

          paletteColors: "#9e0e0e",
          captionFontColor: "#7f7f7f",
          showValues: false,
          showPlotBorder: "0",
          maxBarHeight: 2,

          showLabels: true,
          labelDisplay: "WRAP",
          labelFontSize: 12,

          valueFont: "Avenir Medium",
          valueFontColor: "#7f7f7f",
          valueFontBold: false,
          valueFontSize: 14,
          valuePosition: "outside",
          placeValuesInside: "0",
          rotateValues: false,

          bgColor: "#ffffff",
          bgImageAlpha: "60",
          bgImageScale: "400",
          bgImageVAlign: "bottom",
          bgImageHAlign: "middle",
          bgImageDisplayMode: "center",

          showYAxisLine: true,
          yAxisLineColor: "#4A90E2",
          showYAxisValues: true,
          yAxisValueFont: "Avenir Medium",
          yAxisValueFontSize: 14,
          yAxisValueFontColor: "#7f7f7f",
          numbersuffix: this.props?.yAxisPercentage ? "%" : "",
          yAxisMinValue:
            (this.props?.yAxisPercentage || this.props?.maxMin) && `${yMinVal}`,
          yAxisMaxValue:
            (this.props?.yAxisPercentage || this.props?.maxMin) && `${yMaxVal}`,

          showXAxisLine: false,
          xAxisLineColor: "#4A90E2",
          showXAxisValues: true,
          xAxisValueFontColor: "#7f7f7f",
          xAxisValueFontSize: 14,
          centerXAxisName: false,

          chartTopMargin: "50",
          chartBottomMargin: 0,

          baseFont: "Avenir Medium",
          baseFontSize: 14,
          baseFontColor: "#7f7f7f",
        },
        annotations: {
          autoScale: "1",
          groups: [
            {
              id: "caption",
              items: [
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#002F75",
                  fontsize: 16,
                  font: "Avenir Medium",
                  text: (headers + "").toUpperCase(),
                  bold: "0",
                  wrap: "1",
                  wrapWidth: `${500 + this.props.wrapHeadingWidth}`,
                  x: `${this.props.xHeadingMargin}`,
                  y: `${this.props.yHeadingMargin}`,
                },
              ],
            },
          ],
        },
        data: [
          ...this.state.data,
          {
            vLine: "true",
          },
        ],
      },
    };
    return (
      <div>
        <ReactFC {...chartConfigs} />
      </div>
    );
  }
}
