import React, { Component } from "react";
import Mordor_icon from "../favicon.png";
import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";
import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";
import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";
import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";

import Config from "../../../config/Config";
import config from "../../../config/Api";
import {
  breakLabels,
  maximumStack,
  intToStringDynamic,
  firstColumnSumValueDynamic,
  divideByUnit,
} from "../../../Graphs/graphs.helperFunctions";
import axios from "axios";
import font_html from "../../font_style.js";
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);
const COLORS = Config.COLORS;

let COLOR = COLORS.split(",").map((item) => {
  return item.trim();
});

export default class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxValue: 0,
      total_legend_length: "",
      max_legend_length: 0,
      datas: "",
      button_click: false,
      loading: false,
      loadingData: false,
      decimalLimit: 2,
      data: [
        {
          seriesname: "Food Products",
          data: [
            {
              value: "121000",
            },
            {
              value: "135000",
            },
            {
              value: "123500",
            },
            {
              value: "145000",
            },
          ],
        },
        {
          seriesname: "Non-Food Products",
          data: [
            {
              value: "131400",
            },
            {
              value: "154800",
            },
            {
              value: "98300",
            },
            {
              value: "131800",
            },
          ],
        },
      ],
      catagory: [
        {
          label: "Q1",
          showValue: true,
        },
        {
          label: "Q2",
        },
        {
          label: "Q3",
        },
        {
          label: "Q4",
        },
      ],
    };
    this.bytesToString = this.bytesToString.bind(this);
    this.resetChart = this.resetChart.bind(this);
  }

  componentDidMount() {
    if (this.props.data && this.props.data.data.data) {
      let datas = this.props.data.data.data;
      let maxValue = maximumStack(datas),
        decimalLimit = 2;
      let catagory = this.props.data.data.catagory,
        new_data = [...JSON.parse(JSON.stringify(this.props.data.data.data))];
      // if (this.props.data?.graph_config?.decimal_formator) {
      //   let year_key = this.props.data?.graph_config?.forcastPeriod[0],
      //   index_key = 0;
      //   for (let [item, index] of catagory.entries()) {
      //     if (item.label == year_key)
      //       index_key = index
      //   }
      //   let arr = [];
      //   new_data.map(elm => {
      //     elm.data.map((item, i) => {
      //       if (arr[i] === undefined) {
      //         arr[i] = +(item.value);
      //       } else {
      //         arr[i] += +(item.value);
      //       }
      //     })
      //   });
      //   let format_digitLength=digitLength(arr[index_key]);

      //   if(format_digitLength<=2)
      //   decimalLimit=3
      // }
      let total_legend_length = "",
        max_legend_length = 0;
      new_data = new_data.map((item) => {
        if (item.seriesname.length > max_legend_length)
          max_legend_length = item.seriesname.length;
        // item.seriesname = this.bytesToString(item.seriesname, 35)
        item.seriesname = breakLabels(item.seriesname, 25);
        if (this.props.data.data.data.length <= 3)
          total_legend_length += item.seriesname;
        return item;
      });

      this.setState({
        datas: datas,
        loadingData: false,
        data: new_data,
        catagory: catagory,
        maxValue,
        total_legend_length,
        max_legend_length,
        decimalLimit,
      });
      setTimeout(() => {
        this.resetChart();
      }, 1000);
    }
  }

  resetChart(c) {
    let a = window.btoa(JSON.stringify(this.props.data));
    var revenueChart = FusionCharts(
      `${this.props.data.graph_type + this.props.data.component}`
    );
    // get chart xml data
    var svgString = revenueChart.getSVGString();
    let svgdata = new DOMParser().parseFromString(svgString, "text/xml");
    var doc = new DOMParser().parseFromString(font_html, "text/html");
    // svgdata.prepend(doc.documentElement)
    let defs_data = svgdata.getElementsByTagName("defs")[0];
    defs_data.prepend(doc.documentElement);
    axios
      .post(`${config.api.base_url}/api/graphs/data?data=${a}`, {
        stream: window.btoa(svgdata.documentElement.outerHTML),
      })
      .then((res) => {});
    // FusionCharts.batchExport({
    //   charts: [
    //     {
    //       id: this.props.data.graph_type + this.props.data.component
    //     }
    //   ],
    //   exportFormat: "svg",

    //   exportHandler: `${config.api.base_url}/api/graphs/data?data=${a}`,
    //   exportAction: "SAVE",
    //   exportTargetWindow: 'BLANK',

    //   exportMode: "client",
    // });
  }

  bytesToString(d, width) {
    let str = "";
    let computed_array = [];

    let string_arr = d.trim().split(" ");
    for (let item of string_arr) {
      if ((item + str).length >= width) {
        computed_array.push(str);
        str = "";
      }
      str += " " + item;
    }
    if (str !== "") computed_array.push(str);

    let caption = "";
    for (let i = 0; i < computed_array.length; i++) {
      if (i !== computed_array.length - 1)
        caption += computed_array[i] + "<br/>";
      else caption += computed_array[i];
    }
    return caption;
  }

  render() {
    let data = "",
      catagory = "",
      rotateValues = 0,
      legendsPositionDynamic = false,
      max_legend_length = 0,
      valueFontSize = 12,
      width = 1376,
      height = 665,
      id = "1",
      headers = "",
      legendLength = 14,
      seriesName = [],
      legendsData = [],
      others = [],
      newData = [];

    data = this.state.data;
    catagory = this.state.catagory;

    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (this.props.headers) headers = this.props.headers;
    if (this.props.valueFontSize) valueFontSize = this.props.valueFontSize;
    if (this.props.rotateValues === 1) rotateValues = this.props.rotateValues;
    if (this.props.legendsPositionDynamic)
      legendsPositionDynamic = this.props.legendsPositionDynamic;
    if (this.props.id) id = this.props.id;

    if (data.length > 5) legendLength = 11;
    if (valueFontSize === 11) legendLength = data.length > 5 ? 11 : 14;
    if (legendsPositionDynamic) legendLength = 11;
    if (this.state.max_legend_length < 35)
      max_legend_length = 35 - this.state.max_legend_length;

    data.map((item) => {
      seriesName.push({ seriesname: item.seriesname });
    });

    seriesName = seriesName
      .sort((a, b) => {
        return a.seriesname.localeCompare(b.seriesname);
      })
      .map((series, index) => {
        return { ...series, color: COLOR[index] };
      });
    let x1 = new RegExp("^other.*"),
      x2 = new RegExp("^rest.*");
    seriesName = seriesName.filter((series) => {
      if (
        x1.test(series.seriesname.toLowerCase()) ||
        x2.test(series.seriesname.toLowerCase())
      ) {
        others.push(series);
        return false;
      } else {
        return true;
      }
    });
    if (others.length !== 0)
      others.map((series_item) => seriesName.push(series_item));

    data.map((item) => {
      seriesName.map((series) => {
        if (item.seriesname === series.seriesname) {
          newData.push({ ...item, color: series.color });
        }
      });
    });

    data = [...newData];

    if (this.props.template_name === "1000") {
      seriesName.map((series, i) => {
        let yCoordinate = "0";

        if (i === 0) {
          yCoordinate = "65";
        } else if (i === 1) {
          yCoordinate = "90";
        } else if (i === 2) {
          yCoordinate = "115";
        } else if (i === 3) {
          yCoordinate = "140";
        } else if (i === 4) {
          yCoordinate = "165";
        } else if (i === 5) {
          yCoordinate = "190";
        } else if (i === 6) {
          yCoordinate = "215";
        } else if (i === 7) {
          yCoordinate = "240";
        } else if (i === 8) {
          yCoordinate = "265";
        } else if (i === 9) {
          yCoordinate = "290";
        } else {
          yCoordinate = "315";
        }

        let colorData = {
          id: `shape-${i}`,
          type: "polygon",
          startangle: "45",
          sides: "4",
          radius: "7",
          color: `${series.color}`,
          x: `${this.props.fromInterface === true ? 920 : 1030}`,
          y: yCoordinate,
        };

        let seriesData = {
          id: `label-${i}`,
          type: "text",
          align: "left",
          fillcolor: "#7f7f7f",
          fontsize: 11,
          // font: "Avenir Medium",
          text: `${series.seriesname}`,
          bold: "0",
          wrap: "1",
          x: `${this.props.fromInterface === true ? 930 : 1040}`,
          y: yCoordinate,
        };
        legendsData.push(colorData, seriesData);
      });
    }

    if (this.props.template_name === "1111") {
      seriesName.map((series, i) => {
        let yCoordinate = "0";

        if (i === 0) {
          yCoordinate = "65";
        } else if (i === 1) {
          yCoordinate = "85";
        } else if (i === 2) {
          yCoordinate = "105";
        } else if (i === 3) {
          yCoordinate = "125";
        } else if (i === 4) {
          yCoordinate = "145";
        } else if (i === 5) {
          yCoordinate = "165";
        } else if (i === 6) {
          yCoordinate = "185";
        } else if (i === 7) {
          yCoordinate = "205";
        } else if (i === 8) {
          yCoordinate = "225";
        } else if (i === 9) {
          yCoordinate = "245";
        } else {
          yCoordinate = "265";
        }

        let colorData = {
          id: `shape-${i}`,
          type: "polygon",
          startangle: "45",
          sides: "4",
          radius: "7",
          color: `${series.color}`,
          x: `${390}`,
          y: yCoordinate,
        };

        let seriesData = {
          id: `label-${i}`,
          type: "text",
          align: "left",
          fillcolor: "#7f7f7f",
          fontsize: 11,
          // font: "Avenir Medium",
          text: `${series.seriesname}`,
          bold: "0",
          wrap: "1",
          x: `${400}`,
          y: yCoordinate,
        };

        legendsData.push(colorData, seriesData);
      });
    }

    let chartConfigs = {
      type: "stackedcolumn2d",
      dataFormat: "json",
      width: width,
      height: height,
      id: this.props.data.graph_type + this.props.data.component,
      dataSource: {
        chart: {
          theme: "fusion",
          caption: this.props.data.heading,
          canvasTopPadding: 100,
          captionFontColor: "#6a6a6a",
          captionFont: "regular_bold_rd",
          captionFontSize: 34,
          animation: false,
          showAnchors: 1,

          formatNumberScale: 1,
          numberScaleValue: "1000,1000,1000",
          numberScaleUnit: "K,M,B",

          adjustDiv: "0",
          numDivLines: "4",
          divLineColor: "#E5E5E5",
          divLineAlpha: "80",
          divLineDashed: "0",

          decimals: 1,
          paletteColors: COLORS,
          showPlotBorder: "0",
          minPlotHeightForValue: 10,
          plotSpacePercent: 40,

          showsum: !this.props.data.data_hidden,
          sumPadding: "15",

          showValues: 0,
          valueFont: "regular_semibold_rd",
          valueFontColor: "#7f7f7f",
          valueFontSize: 22,
          valueFontBold: true,
          placeValuesInside: "0",
          rotateValues: rotateValues,

          labelFontColor: "#7f7f7f",
          labelFontSize: 28,

          showLegend: 1,
          legendItemFont: "regular_semibold_rd",
          legendItemFontSize: 24,
          legendItemFontColor: "#7f7f7f",
          legendPosition: "right",
          labelFont: "regular_semibold_rd",
          // legendXPosition:  470,
          // legendYPosition: 150 + this.props.legendsYPosition,

          showYAxisLine: false,
          yAxisNamePadding: 0,
          yAxisLineThickness: 1,
          showYAxisValues: !this.props.data.data_hidden,
          yAxisValueFontSize: 28,
          yAxisValueDecimals: 1,
          yAxisValueFontColor: "#7f7f7f",
          yAxisLineColor: "#4A90E2",
          // yAxisMaxValue: this.state.maxValue,

          showXAxisLine: false,
          xAxisLineThickness: 1,
          xAxisLineColor: "#4A90E2",
          // xAxisNameFont: "Avenir Medium",
          xAxisNameFontColor: "#444444",
          xAxisValueFontSize: width <= 566 ? 10 : 14,
          centerXaxisName: false,

          chartTopMargin: 50,
          chartLeftMargin: 70,
          chartBottomMargin: 90,

          baseFont: "regular_semibold_rd",
          baseFontSize: 14,
          baseFontColor: "#7f7f7f",
        },
        annotations: {
          autoScale: "1",
          groups: [
            {
              id: "caption",
              items: [
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#002F75",
                  fontsize: 16,
                  font: "regular_bold_rd",
                  text: (headers + "").toUpperCase(),
                  bold: "0",
                  wrap: "1",
                  wrapWidth: `${520 + this.props.wrapHeadingWidth}`,
                  x: `${this.props.xHeadingMargin}`,
                  y: `${this.props.yHeadingMargin + 4}`,
                },
              ],
            },
            {
              id: "legends",
              items: [],
            },
            {
              id: "footer",
              items: [
                {
                  id: "dyn-label-bg",
                  showBorder: "0",
                  borderColor: "12345d",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#7f7f7f",
                  fontsize: 36,
                  font: "regular_semibold_rd",
                  text: "Source: ",
                  bold: "1",
                  wrap: "1",
                  wrapWidth: "100%",
                  x: "85",
                  y: height - 32,
                },
              ],
            },
            {
              id: "sub-footer",
              items: [
                {
                  id: "dyn-label-bg",
                  showBorder: "0",
                  x: "$canvasEndY-245",
                  y: "$canvasEndY+45",
                  tox: "$canvasEndX+10",
                  toy: "$canvasEndY + 80",
                },
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  font: "regular_regular_rd",
                  fillcolor: "#7f7f7f",
                  fontsize: 36,
                  text: "Mordor Intelligence",
                  bold: "0",
                  wrap: "1",
                  wrapWidth: "100%",
                  x: 215,
                  y: height - 32,
                },
              ],
            },
            {
              id: "mordor-icon",
              xScale_: "5",
              yScale_: "5",
              items: [
                {
                  id: "Brian-user-icon",
                  type: "image",
                  url: Mordor_icon,
                  x: "1180",
                  y: height - 72,
                  xScale: "200",
                  yScale: "200",
                },
              ],
            },
          ],
        },
        categories: [
          {
            category: catagory,
          },
        ],
        dataset: data,
      },
    };
    if (this.props.data?.reseller === true) {
      chartConfigs.dataSource.annotations.groups = [];
    }
    if (this.props.data.data_hidden) {
      for (let [index, item] of catagory.entries()) {
        let marketUnit = this.props.data?.graph_config.marketUnit;
        if (
          this.props.data?.graph_config?.forcastPeriod.includes(
            Number(item.label)
          )
        ) {
          chartConfigs["dataSource"]["annotations"]["groups"].push({
            id: "infobar",
            items: [
              {
                id: "dyn-label",
                type: "text",
                align: "center",
                fillcolor: "#7f7f7f",
                fontsize: "22",
                font: "regular_semibold_rd",
                text:
                  divideByUnit(
                    firstColumnSumValueDynamic(data, index),
                    marketUnit,
                    2
                  ) + ` ${marketUnit}`,
                bold: "1",
                wrap: "1",
                x: `$dataset.${data.length - 1}.set.${index}.startx+25`,
                y: `$dataset.${data.length - 1}.set.${index}.starty-15`,
              },
            ],
          });
        }
      }
    }
    // if (legendsPositionDynamic) {
    //   chartConfigs['dataSource']['chart'] = {
    //     ...chartConfigs['dataSource']['chart'],
    //     legendPosition: "absolute",
    //     legendNumColumns: 1,
    //     chartRightMargin: this.state.max_legend_length > 35 ? 220 : 200,
    //   }
    // } else {
    //   chartConfigs['dataSource']['chart'] = {
    //     ...chartConfigs['dataSource']['chart'],
    //     legendPosition: "absolute",
    //     legendNumColumns: 1,
    //     chartRightMargin: 310 - max_legend_length,
    //     legendXPosition: 980 + max_legend_length
    //   }
    // }

    return (
      <div>
        <ReactFC {...chartConfigs} />
      </div>
    );
  }
}
