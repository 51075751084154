import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import sample from "./pdf_files/about_us_with_footer.pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/umd/Page/AnnotationLayer.css";
import "./displaypdf.css";
import pdf2base64 from "pdf-to-base64";

class displayPdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 0,
      checkedpdf: false,
      b64data: "",
    };
  }
  async componentDidMount() {
    let data = await pdf2base64(this.props.file);
    this.setState({ b64data: data });
  }
  render() {
    let start_pages = 1;
    // let end_pages = (this.props && this.props.end_page) ? this.props.end_page : 100;
    let pdf_file = this.props.file ? this.props.file : sample;
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    let pdf_pages = [];
    let onPageLoadSuccess = () => {
      this.setState({ checkedpdf: true });
    };
    for (let i = start_pages; i <= this.state.pageNumber; i++) {
      if (i === this.state.pageNumber)
        pdf_pages.push(
          <Page
            pageNumber={i}
            width={1280}
            height={720}
            onRenderSuccess={onPageLoadSuccess}
          />
        );
      else pdf_pages.push(<Page pageNumber={i} width={1280} height={720} />);
    }
    let classNames = "";
    if (this.state.checkedpdf) {
      classNames = "staticPdfs";
    }
    let onDocumentLoadSuccess = (pdf_file) => {
      ("reached");
      this.setState({ pageNumber: pdf_file.numPages });
    };
    return (
      <div className={classNames}>
        <Document
          file={`data:application/pdf;base64,${this.state.b64data}`}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => console.log("Inside Error", error)}
        >
          {pdf_pages}
        </Document>
      </div>
    );
  }
}
export default displayPdf;
