import React from "react";
import { ExecutiveWrapper } from "../../styles/Executive.style";
import Config from "../../config/Config";

export default function ExecSummaryBox(props) {
  const renderIcon = props?.icon?.hasOwnProperty("$binary")
    ? props.icon["$binary"]
    : props.icon;
  const re = new RegExp(/^http.*/);
  return (
    <ExecutiveWrapper
      url={props.icon}
      style={{ backgroundColor: props.bgColor }}
      className="excuSummeryBox"
    >
      <div className="exeSumryHead">
        {
          <>
            {props.specificDimension !== "company"
              ? props.block
                ? props.block
                : props.metric === "largest"
                  ? props.metric + " Segment by " + props.specificDimension
                  : props.metric +
                    " " +
                    "Growing Segment by" +
                    " " +
                    props.specificDimension
              : props.block
                ? props.block
                : `${
                    Config.ordinalNumberConfig[props.companycount + ""]
                  } leading market player`}
          </>
        }
      </div>

      <div className="flex">
        <div className="flex-item" style={{ width: "50%" }}>
          <div
            style={{ fontSize: "42px", color: "#1F4986", lineHeight: "normal" }}
          >
            {isNaN(props.percentage)
              ? props.percentage
              : Number(props.percentage).toFixed(2).replace(/\.00/g, "")}
            %
          </div>
          <div
            className="execSumSubHead"
            style={{ textTransform: "capitalize" }}
          >
            {props.label}
          </div>
        </div>

        <div className="svg-image" style={{ fill: props.logocolor }}>
          {props.icon || props.logoname ? (
            props.logoname && !re.test(props?.icon + "") ? (
              <div dangerouslySetInnerHTML={{ __html: atob(renderIcon) }} />
            ) : (
              <img
                src={props.icon}
                alt="img"
                className="flex-item img"
                style={{ width: "150px", height: "110px" }}
              />
            )
          ) : (
            <div></div>
          )}
        </div>
      </div>

      <hr
        className="hrtag"
        style={{
          border: `1px solid ${props.hrbgcolor}`,
          margin: "5px 0",
        }}
      />

      <div
        style={{
          marginTop: "10px",
          color: "#27518C",
          fontSize: "14px",
          lineHeight: "18px",
          fontFamily: "Avenir Roman",
          textAlign: "justify",
        }}
      >
        {props.analysis}
      </div>
    </ExecutiveWrapper>
  );
}
