import React, { Component } from "react";
import BulkExportsComponent from "./BulkExportsComponent";
import { BulkUploadContainerWrapper } from "./BulkExports.style";
import BulkExportsComponent2 from "./BulkExportsComponent2";
import InterfaceHeader from "../InterfaceHeader";

class BulkExportsContainer extends Component {
  render() {
    return (
      <>
        <InterfaceHeader />
        <BulkUploadContainerWrapper>
          <BulkExportsComponent />
          {/* <BulkExportsComponent2 /> */}
        </BulkUploadContainerWrapper>
      </>
    );
  }
}

export default BulkExportsContainer;
