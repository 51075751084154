import styled from "styled-components";

export const DataCenterVCWrapper = styled.div`
  .section_data1 {
    width: 20%;
    max-width: 220px;
    position: absolute;
    word-break: break-word;
  }

  .section1_list {
    top: 34%;
    left: 3.5%;
  }

  .section2_list {
    top: 34%;
    left: 27.5%;
  }

  .section3_list {
    top: 49%;
    left: 54%;
  }
`;
