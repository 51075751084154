import React from "react";
import Footer from "../../components/Footer";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Page, Container } from "./style";
import { setSelectedCustomSlideData } from "../../reducersAndActions/Actions";
import CustomTempOne from "./CustomTempOne";
import CustomTempTwo from "./CustomTempTwo";

const TEMPLATE_COMPONENTS = {
  Temp1: CustomTempOne,
  Temp2: CustomTempTwo,
};

const TemplateRenderer = ({
  template,
  data,
  edit,
  fromInterface,
  setSelectedCustomSlideData,
}) => {
  const TemplateComponent = TEMPLATE_COMPONENTS[template];
  return TemplateComponent ? (
    <TemplateComponent
      data={data}
      edit={edit}
      fromInterface={fromInterface}
      setSelectedCustomSlideData={setSelectedCustomSlideData}
    />
  ) : null;
};
function CustomSlideTemplate(props) {
  const { slideNo, data, setSelectedCustomSlideData, fromInterface } = props;

  return (
    <>
        <Page className="page">
        <Container fromInterface={fromInterface}>
        <TemplateRenderer
          template={data.data.template}
          data={data}
          fromInterface={fromInterface}
          edit={false}
          setSelectedCustomSlideData={setSelectedCustomSlideData}
        />
         {!fromInterface && (
        <Footer slug={"slug"} slideNo={slideNo} slideNoElementId={"2"} />
      )}
        </Container>
        </Page>
     
     
    </>
  );
}
function mapStateToPros(state) {
  return {
    SELECTED_CUSTOM_SLIDE_DATA: state.SELECTED_CUSTOM_SLIDE_DATA,
  };
}
const mapDispatchToProps = {
  setSelectedCustomSlideData,
};

export default withRouter(
  connect(mapStateToPros, mapDispatchToProps)(CustomSlideTemplate)
);
