import { Button, Checkbox } from "antd";
import moment from "moment";
import React from "react";

function UpdateTable({ item, selectTitles, selectedTitles }) {
  return (
    <tr
      onClick={(e) => selectTitles(e, item)}
      style={{ width: "100%", display: "flex" }}>
      <td
        style={{
          marginTop: "0px",
          width: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}>
        <input type="checkbox" checked={selectedTitles.includes(item.mi_id)} />{" "}
        {item.reportName}
      </td>

      <td style={{ width: "30%", marginTop: "0px" }}>
        {item.last_pushed_by || "N/A"}
      </td>
      <td style={{ width: "20%", marginTop: "0px" }}>
        {moment(item.last_pushed_on).format("MMMM Do YYYY") || "N/A"}
      </td>
    </tr>
  );
}

export default UpdateTable;
