import styled from "styled-components";

export const UploadStaticImageComponentWrapper = styled.div`
  .card {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    gap: 20px;
    padding: 0 50px 50px;
    background: #fff;
    transition: all 0.7s ease-out;
    border-radius: 50px;
    -webkit-box-shadow: 0 0 30px 0 rgba(43, 86, 112, 0.1);
  
    position: relative;
  }

  .info {
    position: absolute;
    top: 20px;
    right: 50px;
  }

  .infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    width: 30%;
    margin: 20px 0;
  }

  .infoContainer button {
    align-self: flex-end;
  }

  .infoContent {
    background-color: rgb(235 232 232 / 50%);
    padding: 10px 20px;
    overflow: scroll;
    max-height: 350px;
    width: 100%
    height: 100%
  }

  .leftContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;

    >.contentWrapper{
      // outline: 1px solid black;
    }
  }

  .sectionContainer{
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;

    >div{
      width: calc(45% - 20px);
    }

    >div:first-child{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 30px;
     
    }

    >div:nth-child(2){
      // margin-top: 40px;
      border: 1px solid lightgray;
      box-shadow: 0 0 4px 0 lightgray; 
      border-radius: 10px;
    }
  }

  div:has(>.dropdownContainer) > span{
    display: block;
    width: 100% !important;

    >div:first-child{
      max-height: 150px
    }

    >div:nth-child(2){
      max-height: 150px;
      overflow: auto;
    }
  }

  .dropdownContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

  }

  .selectType > div {
    width: 85%;
  }

  .selectType {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .selectType > div > label {
    margin: 0 25px;
  }
`;
