import React, { Component } from "react";

import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";
import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";
import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";
import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";

import { roundOfMaxYAxisValue } from "../graphs.helperFunctions";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);
// FusionCharts.options.export.useCanvas = "true";

export default class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxYaxisValue: 0,
      cagr_year: [2, 3],
      data: [
        {
          label: "Venezuela",
          value: "290",
        },
        {
          label: "Saudi",
          value: "260",
        },
      ],
    };
    this.datavalidate = this.datavalidate.bind(this);
  }

  componentDidMount() {
    if (this.props.data && this.props.data) {
      this.setState({
        data: this.datavalidate(this.props.data),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState &&
      JSON.stringify(prevState.data) !== JSON.stringify(this.props.data)
    ) {
      if (this.props.data) {
        this.setState({
          data: this.datavalidate(this.props.data),
        });
      }
    }
  }

  datavalidate(data) {
    let arr = [],
      cagr_year = [];
    for (let [index, item] of data.entries()) {
      if (
        this.props.data &&
        this.props.cagr_year &&
        this.props.cagr_year.length > 0
      ) {
        if (
          item.label === this.props.cagr_year[0] + "" ||
          item.label === this.props.cagr_year[1] + ""
        ) {
          cagr_year.push(index);
        }
      }
      arr.push(item.value);

      if (index === 0 && this.props.dataHidden) {
        item["showValue"] = this.props.allow_cagr ? false : true;
      }
    }
    arr.sort(function (a, b) {
      if (a > b) return -1;
      else return 1;
    });
    this.setState({ maxYaxisValue: roundOfMaxYAxisValue(arr[0]), cagr_year });
    return data;
  }

  render() {
    let valArr = [],
      diff = 0,
      avgDiff = 0,
      yMinVal = 0,
      yMaxVal = 0,
      yMaxPaddedValue = 0,
      width = 500,
      height = 240,
      headers = "",
      cagr_year = [2, 6],
      cagr_year_median = 0;

    if (this.props.width) width = this.props.width;
    if (this.props.height) height = this.props.height;
    if (this.props.headers) headers = this.props.headers;
    if (this.state.cagr_year.length > 0) cagr_year = this.state.cagr_year;
    if (this.props?.data) {
      valArr = [...this.props.data];
      valArr = valArr.sort((a, b) => {
        return a.value - b.value;
      });

      valArr.length && (yMaxPaddedValue = valArr[valArr.length - 1]);

      yMinVal = valArr[0].value;
      yMaxVal = valArr[valArr.length - 1].value;
      for (let i = 0; i < valArr.length - 2; i++) {
        diff = diff + (valArr[i + 1].value - valArr[i].value);
      }
      avgDiff = diff / (valArr.length - 1);
      yMinVal = yMinVal - avgDiff;
      if (yMinVal - avgDiff < 0 && valArr[0].value > 0) {
        yMinVal = 0;
      } else {
        yMinVal = yMinVal - avgDiff;
      }
      yMaxVal = yMaxVal + avgDiff;
    }

    cagr_year_median = Math.ceil((cagr_year[0] + cagr_year[1]) / 2);

    let chartConfigs = {
      type: "column2d",
      dataFormat: "json",
      width: width - 50,
      height: height,
      dataSource: {
        chart: {
          theme: "fusion",
          animation: false,
          showAnchors: 1,

          formatNumberScale: 1,
          numberScaleValue: "1000,1000,1000",
          numberScaleUnit: "k,M,B",

          adjustDiv: "0",
          numDivLines: "4",
          divLineColor: "#E5E5E5",
          divLineAlpha: "80",
          divLineDashed: "0",

          labelFontSize:
            this.props.width <= 566 && this.state.data.length >= 4
              ? "12"
              : "14",
          rotateLabels: "0",
          labelDisplay: "WRAP",

          showPlotBorder: "0",
          plotSpacePercent: 50,
          decimals: this.props.decimalLimit
            ? this.props.decimalLimit
            : yMinVal < 1
              ? 3
              : 1,
          paletteColors: "#4A90E2",

          showValues: !this.props.dataHidden,
          rotateValues: 0,
          valuePosition: "outside",
          placeValuesInside: "0",
          valueFont: "Avenir Heavy",
          valueFontSize: 12,
          valueFontBold: false,
          valueFontColor: "#002F75",

          showYAxisLine: false,
          yAxisLineColor: "#4A90E2",
          showYAxisValues: false,
          yAxisValueFontColor: "#7f7f7f",
          yAxisNamePadding: 0,
          yAxisValueFontSize: 14,
          yAxisValueDecimals: 1,
          yAxisMinValue: this.props?.maxMin ? `${yMinVal}` : 0,
          yAxisMaxValue: `${1.13 * yMaxVal}`,
          // yAxisMaxValue: this.props?.maxMin ? `${yMaxVal}` : `${1.25 * yMaxPaddedValue}`,  // 1.05 * Number(this.state.maxYaxisValue),

          showXAxisLine: false,
          xaxisLineColor: "#4A90E2",
          showXAxisValues: true,
          xAxisValueFontColor: "#7f7f7f",
          xAxisValueFontSize: 14,
          centerXAxisName: false,

          chartTopMargin: this.props.allow_cagr
            ? 100 + this.props.chartHeadingGap
            : 50 + this.props.chartHeadingGap,
          chartBottomMargin: 0,

          baseFont: "Avenir Medium",
          baseFontSize: 14,
          baseFontColor: "#7f7f7f",
        },
        annotations: {
          autoScale: "1",
          groups: [
            {
              id: "caption",
              items: [
                {
                  id: "dyn-label",
                  type: "text",
                  align: "left",
                  fillcolor: "#002F75",
                  fontsize: 16,
                  font: "Avenir Medium",
                  text: (headers + "").toUpperCase(),
                  bold: "0",
                  wrap: "1",
                  wrapWidth: `${500 + this.props.wrapHeadingWidth}`,
                  x: `${this.props.xHeadingMargin}`,
                  y: `${this.props.yHeadingMargin}`,
                },
              ],
            },
          ],
        },
        data: this.state.data,
      },
    };

    if (this.props.allow_cagr) {
      chartConfigs["dataSource"]["annotations"]["groups"].push({
        id: "infobar",
        items: [
          {
            id: "2",
            type: "line",
            x: `$dataset.0.set.${cagr_year[0]}.startx+25`,
            y: `$dataset.0.set.${cagr_year[0]}.starty-30`,
            tox: `$dataset.0.set.${cagr_year[0]}.startx+25`,
            toy: `$dataset.0.set.${cagr_year[1]}.starty-50`,
            color: "#2F9AC4",
            dashed: "0",
            thickness: "1",
          },
          {
            id: "2.1",
            type: "line",
            x: `$dataset.0.set.${cagr_year[0]}.startx+25`,
            y: `$dataset.0.set.${cagr_year[1]}.starty-50`,
            tox: `$dataset.0.set.${cagr_year_median}.startx-3`,
            toy: `$dataset.0.set.${cagr_year[1]}.starty-50`,
            color: "#2F9AC4",
            dashed: "0",
            thickness: "1",
          },
          {
            id: "2.2",
            type: "line",
            x: `$dataset.0.set.${cagr_year_median}.startx+53`,
            y: `$dataset.0.set.${cagr_year[1]}.starty-50`,
            tox: `$dataset.0.set.${cagr_year[1]}.startx+25`,
            toy: `$dataset.0.set.${cagr_year[1]}.starty-50`,
            color: "#2F9AC4",
            dashed: "0",
            thickness: "1",
          },
          {
            id: "3",
            type: "line",
            x: `$dataset.0.set.${cagr_year[1]}.startx+25`,
            y: `$dataset.0.set.${cagr_year[1]}.starty-50`,
            tox: `$dataset.0.set.${cagr_year[1]}.startx+25`,
            toy: `$dataset.0.set.${cagr_year[1]}.starty-30`,
            color: "#2F9AC4",
            dashed: "0",
            thickness: "1",
          },
          {
            id: "shape",
            type: "polygon",
            startangle: "270",
            sides: "3",
            radius: "6",
            color: "#2F9AC4",
            x: `$dataset.0.set.${cagr_year[0]}.startx+25`,
            y: `$dataset.0.set.${cagr_year[0]}.starty-30`,
          },
          {
            id: "shape",
            type: "polygon",
            startangle: "270",
            sides: "3",
            radius: "6",
            color: "2F9AC4",
            x: `$dataset.0.set.${cagr_year[1]}.startx+25`,
            y: `$dataset.0.set.${cagr_year[1]}.starty-30`,
          },
          {
            id: "shape",
            type: "arc",
            radius: "26",
            innerRadius: "27.5",
            color: "2F9AC4",
            x: `$dataset.0.set.${cagr_year_median}.startx+25`,
            y: `$dataset.0.set.${cagr_year[1]}.starty-50`,
          },
          {
            id: "cagr",
            type: "text",
            align: "center",
            fillcolor: "#002F75",
            fontsize: 12,
            font: "Avenir Medium",
            text: `${
              this.props.cagr_value ? this.props.cagr_value + "%" : "0%"
            } CAGR`,
            bold: "0",
            wrap: "1",
            wrapWidth: `${40}`,
            x: `$dataset.0.set.${cagr_year_median}.startx+25`,
            y: `$dataset.0.set.${cagr_year[1]}.starty-50`,
          },
        ],
      });
    }

    return (
      <div>
        <ReactFC {...chartConfigs} />
      </div>
    );
  }
}
