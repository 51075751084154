import styled from "styled-components";

export const SeedsVCWrapper = styled.div`
  .section_data1 {
    max-width: 130px;
    position: absolute;
    word-break: break-word;

    .font_design {
      font-size: 12px;
    }
  }

  .section1_list1 {
    top: 59%;
    left: 3%;
    width: 9%;
  }

  .section1_list2 {
    top: 59%;
    left: 14%;
    width: 9%;
  }

  .section1_list3 {
    top: 59%;
    left: 23%;
    width: 9%;
  }

  .section2_list1 {
    top: 69%;
    left: 35%;
    width: 9%;
  }

  .section2_list2 {
    top: 69%;
    left: 45%;
    width: 9%;
  }

  .section2_list3 {
    top: 69%;
    left: 54%;
    width: 8%;
  }

  .section3_list1 {
    top: 50%;
    left: 66%;
    width: 10%;
  }

  .section3_list2 {
    top: 50%;
    left: 78%;
    width: 10%;
  }

  .section3_list3 {
    top: 50%;
    left: 89%;
    width: 10%;
  }
`;
