import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import decisionmaker from "../images/Decision_Maker_Analysis.svg";

var Base64 = require("js-base64").Base64;

export const DecisionMakerDemoData = {
  slideReference: "2. KEY INDUSTRY TRENDS",
  id: "2.4 DECISION MAKER ANALYSIS",
  title: "2.4 DECISION MAKER ANALYSIS",
  slug: "North America Enzymes Market",
  slideName: "decisionmaker",
};

class DecisionMaker extends React.Component {
  state = {
    data: this.props.data || DecisionMakerDemoData,
    reseller: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.meta) {
      if (props.data.meta.sample && props.data.meta.reseller) {
        return {
          reseller: true,
        };
      }
    }
    return null;
  }

  render() {
    const { id, slug } = this.state.data;
    const { slideNo } = this.props;
    const pageId = Base64.encode(id);
    let slideNoElementId = undefined;
    return (
      <div id={pageId} className="page">
        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Header slideTitle={this.state.data.slideName} />
        ) : null}

        <div className="decision_maker">
          <img src={decisionmaker} alt="Decision Maker" />
        </div>
        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Footer
            slideNo={slideNo}
            slug={slug}
            reseller={this.state.reseller}
          />
        ) : null}
      </div>
    );
  }
}

export default DecisionMaker;
