import styled from "styled-components";

export const FoodServicesVCWrapper = styled.div`
  .vc_table_container {
    position: relative;
    height: 560px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 12px;
    margin: 1px;
  }

  .vc_table_type {
    text-align: center;
    font-size: 15px;
    color: black;
  }

  .vc_table_row {
    display: flex;
    justify-content: space-between;
    background: transparent;
    margin-bottom: 0;

    th:first-child {
      flex: 0 1 55%;
      border-right: none;
    }

    th {
      border: 2px solid white;
      padding: 8px;
      color: black !important;
      font-size: 15px !important;
      flex: 0 1 45%;
      font-family: Avenir Heavy;
    }

    td:first-child {
      flex: 0 1 55%;
      border-right: none;
      border-top: none;
    }

    td {
      flex: 0 1 45%;
      border: 2px solid white;
      border-top: none;
      color: black;
      padding: 8px;
      text-align: left;
      margin-top: 0px;
    }
  }
`;
