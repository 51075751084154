import React, { useState, useEffect } from "react";
import "./style.css";

function DragList({ dragList, setDragList, preview }) {
  const [list, setList] = useState([]);
  const [draggedUrl, setDraggedUrl] = useState(null);

  useEffect(() => {
    setList(dragList);
  }, [dragList]);

  const handleDragStart = (e, url) => {
    setDraggedUrl(url);
    e.dataTransfer.effectAllowed = "move";
    e.target.classList.add("dragging");
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    const draggedOverItem = e.target;
    if (!draggedUrl || draggedOverItem.dataset.url === draggedUrl) return;

    const newList = Array.from(list);
    const draggedIndex = newList.findIndex((item) => item.url === draggedUrl);
    const overIndex = newList.findIndex(
      (item) => item.url === draggedOverItem.dataset.url
    );
    newList.splice(overIndex, 0, newList.splice(draggedIndex, 1)[0]);
    setList(newList);
    setDragList(newList);
  };

  const handleDragEnd = () => {
    setDraggedUrl(null);
    const draggedItemElement = document.querySelector(".list-item.dragging");
    if (draggedItemElement) {
      draggedItemElement.classList.remove("dragging");
    }
  };

  const handleDelete = (url) => {
    const newList = list.filter((item) => item.url !== url);
    setList(newList);
    setDragList(newList);
  };

  return (
    <div className="list-container">
      {list.map((item) => (
        <div
          key={item.url}
          className="list-item"
          draggable="true"
          onDragStart={(e) => handleDragStart(e, item.url)}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          data-url={item.url}
        >
          <p title={item.name}>{item.name}</p>
          <button
            className="delete-button"
            onClick={() => handleDelete(item.url)}
          >
            Delete
          </button>
          {preview && (
            <button
              className="prv-button"
              onClick={() => window.open(item.url, "_blank")}
            >
              Preview
            </button>
          )}
        </div>
      ))}
    </div>
  );
}

export default DragList;
