import styled from "styled-components";

export const VehiclesVCWrapper = styled.div`
  .section_data2 {
    width: 18%;
    max-width: 250px;
    position: absolute;
    word-break: break-word;
  }

  .section1_list {
    top: 78%;
    left: 9%;
  }

  .section2_list {
    top: 66%;
    left: 42%;
  }

  .section3_list {
    top: 81%;
    left: 76%;
  }
`;
