import React from "react";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import config from "../config/Config";
import { useAuth } from "../firebase/contexts/AuthContext";
import { auth } from "../firebase/Firebase";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { currentUser } = useAuth();
  const history = useHistory();

  function AllowOnlyLoggedIn(props) {
    if (
      window.localStorage.getItem(config.LOCAL_STORAGE_TOKEN.Name) &&
      window.localStorage.getItem(config.LOCAL_STORAGE_TOKEN.meta)
    ) {
      var signInTime = new Date(
        window.localStorage.getItem(config.LOCAL_STORAGE_TOKEN.meta)
      );

      var currentTime = new Date();
      const milliseconds = Math.abs(currentTime - signInTime);
      const hours = milliseconds / 36e5;

      if (hours > 1) {
        auth.updateCurrentUser(currentUser);
      }

      return (
        <>
          <Component {...rest} {...props} />
        </>
      );
    } else {
      return <>{history.push("/login")}</>;
    }
  }

  return <Route {...rest} render={(props) => AllowOnlyLoggedIn(props)} />;
};

export default ProtectedRoute;
