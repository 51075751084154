import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { SoftDrinkVCWrapper } from "./styles";
var Base64 = require("js-base64").Base64;
let color_array = ["#E3F2F9", "#D5F1E7", "#E2E0F6", "#DBE7F2"];

function IndicatorsThree(props) {
  const [state, setState] = useState({
    data: props.data ? props.data : "",
    reseller: false,
  });
  const col = {
    0: "#45c3d9",
    1: "#d76cf5",
    2: "#6cf5b5",
    3: "#ffff96",
    4: "#81daf7",
  };
  const addNext = (str) => {
    let finalstr = "";
    for (let i = 0; i < str.length; i++) {
      if (str[i] === ":") {
        finalstr += ":</br>";
      } else if (`${str[i] + str[i + 1]}` === "/n") {
        finalstr += "<div style='height:8px' ></div>";
      } else if (`${str[i] + str[i + 1]}` === "<b") {
        finalstr += `• <`;
      } else if (`${str[i - 1] + str[i]}` === "/n") {
        finalstr += "";
      } else {
        finalstr += str[i];
      }
    }
    return finalstr;
  };
  return (
    <>
      <div className="page" id={""}>
        {!props.fromInterface || props.fromInterface === undefined ? (
          <Header slideTitle={state.data.type} />
        ) : null}
        <div>
          <h2 className="heading valuechain_title">
            {props.data && props.data
              ? props.data.title.toUpperCase()
              : state.data.title.toUpperCase()}
          </h2>

          <SoftDrinkVCWrapper
            style={{
              position: "relative",
              justifyContent: "space-between",
              display: "flex",
              gap: "5px",
              columnGap: "12px",
              margin: "15px",
              height: "540px",
              overflow: "hidden",
            }}
            className="vc_table_container"
          >
            {state.data?.data?.indicatorSoftBSAO
              ? Object.keys(state.data?.data?.indicatorSoftBSAO).map(
                  (cb, index) => {
                    if (cb === `Company ID's`) {
                      return (
                        <div
                          style={{
                            width: " 39%",
                            display: "flex",
                          }}
                        >
                          {state.data?.data?.indicatorSoftBSAO[cb].map(
                            (elem, i) => {
                              return (
                                <div
                                  style={{
                                    width: " 100%",
                                    overflow: "hidden",
                                    marginTop: `${i * 10}%`,
                                  }}
                                >
                                  <img
                                    style={{
                                      width: "100%",
                                    }}
                                    src={elem}
                                    alt="compaly"
                                  />
                                  <div
                                    style={{
                                      background: col[i],
                                      minHeight: "15px",
                                      height: "100%",
                                    }}
                                  ></div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    } else if (cb === `Brand Shares Overview (2022)`) {
                      return (
                        <div
                          style={{
                            padding: "12px",
                            width: "60%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "12px",
                          }}
                        >
                          <h1
                            style={{
                              fontSize: "16px",
                              textAlign: "center",
                              color: "#fff",
                              background: "#002f75",
                              padding: "0px 12px",
                            }}
                          >
                            {(cb + "").toUpperCase()}
                          </h1>
                          <div
                            style={{
                              background: color_array[index],
                              padding: "12px",
                            }}
                          >
                            {state.data?.data?.indicatorSoftBSAO[cb]
                              .split(";")
                              .filter((item) => (item + "").trim() != "")
                              .map((elem) => (
                                <div
                                  className="consumer_buying_para"
                                  style={{
                                    textAlign: "justify",
                                    fontSize: "13.5px",
                                    fontWeight: "bold",
                                    color: " #1f4986",
                                  }}
                                >
                                  {/* <div
                                    style={{
                                      marginLeft: "5px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    • {(elem + "").trim()}
                                  </div> */}
                                  <div
                                    style={{
                                      marginLeft: "5px",
                                      marginBottom: "10px",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: `${(addNext(elem) + "").trim()}`,
                                    }}
                                  ></div>
                                </div>
                              ))}
                          </div>
                        </div>
                      );
                    }
                  }
                )
              : null}
          </SoftDrinkVCWrapper>

          {!props.fromInterface || props.fromInterface === undefined ? (
            <Footer
              // slug={slug}
              // slideNo={slideNo}
              reseller={state.reseller}
            />
          ) : null}
        </div>
      </div>
      <div style={{ marginTop: "20px" }} className="page" id={""}>
        <div>
          <SoftDrinkVCWrapper
            style={{
              position: "relative",
              height: "620px",
              display: "grid",

              overflow: "hidden",
              columnGap: "12px",
              margin: "15px",
            }}
            className="vc_table_container"
          >
            {state.data?.data?.indicatorSoftBSAO
              ? Object.keys(state.data?.data?.indicatorSoftBSAO).map(
                  (cb, index) => {
                    if (
                      cb !== `Company ID's` &&
                      cb !== `Brand Shares Overview (2022)`
                    ) {
                      return (
                        <div
                          style={{
                            padding: "12px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "12px",
                          }}
                        >
                          <h1
                            style={{
                              fontSize: "16px",
                              textAlign: "center",
                              color: "#fff",
                              background: "#002f75",
                              padding: "0px 12px",
                            }}
                          >
                            {(cb + "").toUpperCase()}
                          </h1>
                          <div
                            style={{
                              background: color_array[index],
                              padding: "12px",
                            }}
                          >
                            {state.data?.data?.indicatorSoftBSAO[cb]
                              .split(";")
                              .filter((item) => (item + "").trim() != "")
                              .map((elem) => (
                                <div
                                  className="consumer_buying_para"
                                  style={{
                                    textAlign: "justify",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    color: " #1f4986",
                                  }}
                                >
                                  <div
                                    style={{
                                      marginLeft: "5px",
                                      marginBottom: "10px",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: `${(addNext(elem) + "").trim()}`,
                                    }}
                                  ></div>
                                </div>
                              ))}
                          </div>
                        </div>
                      );
                    }
                  }
                )
              : null}
          </SoftDrinkVCWrapper>

          {!props.fromInterface || props.fromInterface === undefined ? (
            <Footer
              // slug={slug}
              // slideNo={slideNo}
              reseller={state.reseller}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}

export default IndicatorsThree;
