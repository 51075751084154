import styled from "styled-components";

const CompanyOverviewWrapper = styled.div`
  .profile_container {
    margin-top: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 100%;
  }

  h2 {
    font-family: "Avenir Roman";
    font-size: 26px;
    color: #002f75 !important;
    font-weight: 800 !important;
    margin: 0px;
  }

  h3 {
    font-family: "Avenir Roman";
    font-size: 18px;
    color: #002f75 !important;
    font-weight: 800;
    text-transform: uppercase;
  }

  p1 {
    font-family: "Avenir Roman";
    font-size: 14px;
    color: #002f75;
    font-weight: 300;
  }

  p2 {
    font-family: "Avenir Roman";
    font-weight: 300;
    font-style: normal;
    font-size: 36px;
    color: #002f75;
  }

  .company_bio {
    height: 100%;
    background: #e6e3fc;
    padding: 20px;

    .company_date {
      display: flex;
      font-family: "Avenir Roman";
      font-size: 14px;
      color: #002f75;
      font-style: normal;
      font-weight: normal;
    }

    img {
      width: 330px;
      height: 135px;
      margin-bottom: 20px;
    }

    .company_content {
      margin-top: 18px;
      font-family: "Avenir Roman";
      font-size: 14px;
      line-height: 18px;
      color: #002f75;
      font-style: normal;
      font-weight: normal;
    }
  }

  .company_stats {
    width: 381px;
    height: 530px;

    .inner-stats-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 10px;
    }
    .stats_content {
      font-family: "Avenir Roman";
      font-size: 14px;
      color: #002f75;
      font-style: normal;
      font-weight: normal;
      margin-bottom: 5px;
    }

    li {
      color: #00a9ff;
    }
  }

  .company_chart {
    width: 382px;
    height: 530px;
  }
`;

export default CompanyOverviewWrapper;
