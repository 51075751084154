import React, { useState } from "react";
import downArrowIcon from "../../images/down.png";
import { TOCWrapper } from "./DocumentationComponent.style";

function TOC({ data, setContent, selectedSection, setSelectedSection }) {
  const [expand, setExpand] = useState(false);
  if (data.children.length) {
    return (
      <TOCWrapper>
        <p
          onClick={() => {
            setExpand(!expand);
          }}
        >
          {data.title}{" "}
          {expand ? (
            <img src={downArrowIcon} alt="icon" />
          ) : (
            <img
              src={downArrowIcon}
              style={{
                rotate: "-90deg",
              }}
              alt="icon"
            />
          )}
        </p>
        <div
          style={{
            display: expand ? "flex" : "none",
            paddingLeft: "15px",
          }}
        >
          {data.children.map((item) => (
            <TOC
              data={item}
              setContent={setContent}
              selectedSection={selectedSection}
              setSelectedSection={setSelectedSection}
            />
          ))}
        </div>
      </TOCWrapper>
    );
  } else {
    return (
      <TOCWrapper>
        <p
          style={{
            cursor: "pointer",
          }}
          className={selectedSection === data._id ? "active" : ""}
          onClick={() => {
            setContent(data.content);
            setSelectedSection(data._id);
          }}
        >
          {data.title}
        </p>
      </TOCWrapper>
    );
  }
}

export default TOC;
