import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { GrowthStrategyContainer } from "../styles/GrowthStrategyMatrix.style";
import Heading from "../components/Heading";

var Base64 = require("js-base64").Base64;

export const growthstrategymatricsDemoData = {
  slideName: "growthstrategymatrics",
  title: "6 Competitive Landscape​ | 6.3 Company Profiles",
  subtitle: "6.2 - GROWTH STRATEGY MATRIX",
  slideReference: "4 COMPETITIVE LANDSCAPE",
  slideNo: 10,
  slug: "US PROBIOTICS MARKET",
  data: {
    penetration: `The company has been relying on the existing product lines – Natugrain ® and
        Natuphos ® to expand into new markets and segments. The company expanded the
        Natuphos product line to poultry in important emerging markets like India and
        Indonesia.`,
    market_development: `Diversification is not a strategy that is currently been adopted by the company in
         the Feed Enzymes Market`,
    diversification: `In strong markets like China where the company has a well-established presence,
        they are focusing on expanding reach through greater distribution, in order to reach
        out to smaller feed mills, which have traditionally not been their focus segment.`,
    product_development: `Product Developments have been focused on incremental advancements to existing
        products and hence while there have been additional variants of same products
        being added, there has not been much focus on new product development`,
  },
};

class GrowthStrategyMatrix extends React.Component {
  state = {
    data: this.props.data || growthstrategymatricsDemoData,
    reseller: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.meta) {
      if (props.data.meta.sample && props.data.meta.reseller) {
        return {
          reseller: true,
        };
      }
    }
    return null;
  }

  render() {
    const { id, title, subtitle, slug, country, data } = this.state.data;
    const { slideNo } = this.props;
    const pageId = id ? Base64.encode(id) : undefined;
    let slideNoElementId = undefined;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }
    return (
      <GrowthStrategyContainer className="page">
        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Header slideTitle={this.state.data.slideName} />
        ) : null}
        <div
          className="container"
          style={{ height: this.props.fromInterface ? "auto" : "626px" }}
        >
          {/* <h2 className="heading">{title}</h2> */}
          <Heading heading={title} />
          <h3 className="subheading align-center">{subtitle}</h3>
          <div className="main-container">
            {Object.keys(growthstrategymatricsDemoData.data).map((item, id) => (
              <div className="matrix-block" id={"color" + id}>
                <h3 className="block-title">{item.replace(/_/g, " ")}</h3>
                <p className="block-content">{data[item]}</p>
              </div>
            ))}
          </div>
        </div>
        {!this.props.fromInterface || this.props.fromInterface === undefined ? (
          <Footer
            slideNo={slideNo}
            slug={slug}
            reseller={this.state.reseller}
          />
        ) : null}
      </GrowthStrategyContainer>
    );
  }
}

export default GrowthStrategyMatrix;
