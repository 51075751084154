import React, { useState, useEffect } from "react";
import { HubConfigComponentWrapper } from "./hubConfig.style";
import InterfaceHeader from "../InterfaceHeader";
import { Button, Select, Spin, notification } from "antd";
import axios from "axios";
import configApi from "../../config/Api";
import ValidDimensions from "./ValidDimensions";

const openNotification = (msg, type) => {
  let args;
  if (type === true) {
    args = {
      message: "Success",
      description: msg,
      duration: 1,
      placement: "bottomLeft",
    };
    notification.success(args);
  } else if (type === false) {
    args = {
      message: "Failure",
      description: msg,
      duration: 1,
      placement: "bottomLeft",
    };
    notification.error(args);
  } else {
    args = {
      message: "Warning",
      description: msg,
      duration: 3.5,
      placement: "bottomLeft",
    };
    notification.warning(args);
  }
};
function HubConfig() {
  const [hubData, setHubData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [onGoingApiCall, setOnGoingApiCall] = useState(false);
  const [selectedHub, setSelectedHub] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [infoTabVis, setInfoTabVis] = useState(false);
  const [fetchedDimensions, setFetchedDimensions] = useState([]);
  const [savedDimValid, setSavedDimValid] = useState({});
  const toTitleCase = (str) => {
    return (str + "").toUpperCase();
  };
  const getHubList = async () => {
    setLoading(true);
    if (!hubData) {
      let full_access = false;
      await axios
        .get(
          `${configApi.api.base_url}/api/hubs/list?full_access=${full_access}`
        )
        .then((response) => {
          if (response.status === 200) {
            setHubData(response.data.obj);
          }
          setLoading(false);
        })
        .catch((error) => {
          openNotification("Unable to load hub list", false);
          setLoading(false);
        });
    }
  };
  function getToken() {
    return window.localStorage.getItem("token");
  }
  useEffect(() => {
    getHubList();
  }, []);
  const getDomainInfo = async () => {
    setOnGoingApiCall(true);
    axios
      .get(`${configApi.api.base_url}/api/domainInfo?hub_id=${selectedHub}`)
      .then((response) => {
        if (response.status === 200) {
          console.log("response.data", response.data);
          setSelectedDomain(response.data.domainInfo);
          setSavedDimValid(response.data.valid_dimension);
        }
        setOnGoingApiCall(false);
      })
      .catch((error) => {
        openNotification("Unable to load", false);
        setOnGoingApiCall(false);
      });
  };
  const getDimensions = async () => {
    axios
      .get(
        `${configApi.api.base_url}/api/editing/hub-dimensions/${selectedHub}`
      )
      .then((response) => {
        if (response.status === 200) {
          setFetchedDimensions(response.data.data);
        }
        setOnGoingApiCall(false);
      })
      .catch((error) => {
        openNotification("Unable to load", false);
        setOnGoingApiCall(false);
      });
  };

  useEffect(() => {
    if (selectedHub) {
      getDomainInfo();
      getDimensions();
    }
  }, [selectedHub]);
  const saveConfig = () => {
    console.log("selectedDomain", selectedDomain);
    setLoading(true);
    axios
      .patch(
        `${configApi.api.base_url}/api/domainInfo`,
        {
          hub_id: selectedHub,
          ...selectedDomain,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          openNotification("Config saved successfully", true);
        }
      })
      .catch((error) => {
        setLoading(false);
        openNotification("Unable to save", false);
      });
  };
  return (
    <>
      <InterfaceHeader />
      <HubConfigComponentWrapper>
        <div
          style={{
            marginTop: "8rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="card">
            <div
              className="leftContainer"
              style={{
                width: infoTabVis ? "70%" : "100%",
              }}
            >
              <h2
                style={{
                  textAlign: "center",
                  flexGrow: 1,
                  margin: "20px 0 30px",
                }}
              >
                Hub's Configurations
              </h2>
              <div className="selectDropdownContainer">
                <p>
                  Select Hub{"    "}
                  <span className="mandatory">*</span> :{" "}
                </p>
                <Select
                  disabled={onGoingApiCall}
                  id="SelectHub"
                  placeholder="Select Hub"
                  defaultValue="Select Hub"
                  value={selectedHub}
                  loading={loading}
                  maxLength=""
                  onSelect={(e) => {
                    setSelectedHub(e);
                    setSelectedDomain(null);
                  }}
                  options={
                    hubData
                      ?.filter((item, index) => item.name !== "ZEROHUB")
                      ?.map((option) => ({
                        value: option.id,
                        label: toTitleCase(option.name),
                      })) || []
                  }
                />
              </div>
              {selectedDomain && (
                <div
                  style={{
                    margin: "5px 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    gap: "4%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      gap: "10px",
                      border: "1px solid #00000024",
                      borderRadius: "10px",
                      padding: "19px",
                      width: "48%",
                    }}
                  >
                    <h4>Hub Year Config</h4>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        fontWeight: "bold",
                        alignItems: "flex-start",
                      }}
                    >
                      <label>Base Year</label>
                      <input
                        disabled
                        style={{ width: "90px" }}
                        type="number"
                        value={Number(selectedDomain.baseYear)}
                        onChange={(e) => {
                          setSelectedDomain({
                            ...selectedDomain,
                            baseYear: Number(e.target.value),
                          });
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        fontWeight: "bold",
                        alignItems: "flex-start",
                      }}
                    >
                      <label>Companies Market Year</label>
                      <input
                        type="number"
                        style={{ width: "90px" }}
                        value={selectedDomain.company_market_year}
                        onChange={(e) => {
                          setSelectedDomain({
                            ...selectedDomain,
                            company_market_year: Number(e.target.value),
                          });
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        fontWeight: "bold",
                        alignItems: "flex-start",
                      }}
                    >
                      <label>Study Period</label>
                      <input
                        type="number"
                        disabled
                        style={{ width: "90px" }}
                        value={selectedDomain.studyPeriod[0]}
                        onChange={(e) => {
                          setSelectedDomain({
                            ...selectedDomain,
                            studyPeriod: [
                              Number(e.target.value),
                              Number(selectedDomain.studyPeriod[1]),
                            ],
                          });
                        }}
                      />
                      -
                      <input
                        type="number"
                        disabled
                        style={{ width: "90px" }}
                        value={selectedDomain.studyPeriod[1]}
                        onChange={(e) => {
                          setSelectedDomain({
                            ...selectedDomain,
                            studyPeriod: [
                              Number(selectedDomain.studyPeriod[0]),
                              Number(e.target.value),
                            ],
                          });
                        }}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        disabled={loading}
                        type="primary"
                        onClick={saveConfig}
                      >
                        {loading ? <Spin /> : "Save Config"}
                      </Button>
                    </div>
                  </div>
                  <ValidDimensions
                    selectedHub={selectedHub}
                    fetchedDimensions={fetchedDimensions}
                    savedDimValid={savedDimValid}
                  />
                </div>
              )}
            </div>

            <div className={infoTabVis ? "infoContainer" : "info"}>
              {/* <InfoCircleOutlined title="Company profile only .png and Executive summary only .svg" /> */}
              <Button
                type="default"
                danger={infoTabVis}
                onClick={() => setInfoTabVis(!infoTabVis)}
              >
                {infoTabVis ? "Close Guide" : "Open Guide"}
              </Button>
              {infoTabVis && (
                <div className="infoContent">
                  <b>
                    Configure Hub's base year, company market year and study
                    period
                  </b>
                  <br />
                </div>
              )}
            </div>
          </div>
        </div>
      </HubConfigComponentWrapper>
    </>
  );
}

export default HubConfig;
