import React, { useEffect, useState } from "react";
import InterfaceHeader from "../InterfaceHeader";
import { Button, Checkbox, Select, notification } from "antd";
import axios from "axios";
import config from "../../config/Api";
import { UploadImageComponentWrapper } from "./UploadImages.style";
import Dragger from "antd/lib/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";

const openNotification = (msg) => {
  let args,
    key = msg;
  if (key === true) {
    args = {
      message: "Success",
      description: "Image upload successful.",
      duration: 0,
      placement: "bottomLeft",
    };
    notification.success(args);
  } else if (key === false) {
    args = {
      message: "Failure",
      description: "Failed to upload image.",
      duration: 3.5,
      placement: "bottomLeft",
    };
    notification.error(args);
  } else {
    args = {
      message: "Warning",
      description: key,
      duration: 3.5,
      placement: "bottomLeft",
    };
    notification.warning(args);
  }
};
const formData = new FormData();

function UploadImageComponent() {
  const [hubData, setHubData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [onGoingApiCall, setOnGoingApiCall] = useState(false);
  const [selectedType, setSelectedType] = useState("cp");
  const [selectedImage, setSelectededImage] = useState(null);
  const [errors, setErrors] = useState(null);

  const [infoTabVis, setInfoTabVis] = useState(false);

  const getHubList = async () => {
    setLoading(true);
    if (!hubData) {
      let full_access = false;
      await axios
        .get(`${config.api.base_url}/api/hubs/list?full_access=${full_access}`)
        .then((response) => {
          if (response.status === 200) {
            setHubData(response.data.obj);
          }
          setLoading(false);
        })
        .catch((error) => {
          openNotification("Unable to load hub list");
          setLoading(false);
        });
    }
  };

  const preventRequest = () => false;

  const toTitleCase = (str) => {
    return (str + "").toUpperCase();
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  function getToken() {
    return window.localStorage.getItem("token");
  }

  const uploadImage = async () => {
    try {
      setOnGoingApiCall(true);
      setErrors(null);

      if (selectedImage && selectedImage[0]) {
        formData.append("type", selectedType);
        selectedImage.forEach((file, index) => {
          formData.append("image", file.originFileObj);
        });
        axios
          .post(
            `${config.api.base_url}/api/additional_routes/upload_files`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getToken()}`,
              },
            }
          )
          .then((response) => {
            const urls = response.data.urls;
            axios
              .post(
                `${config.api.base_url}/api/additional_routes/storeUrls`,
                {
                  data: urls,
                },
                {
                  headers: {
                    Authorization: `Bearer ${getToken()}`,
                  },
                }
              )
              .then((response) => {
                openNotification(true);
                setOnGoingApiCall(false);
                setErrors(null);
              })
              .catch((error) => {
                console.error(error);
                setOnGoingApiCall(false);
                openNotification("Please upload images to continue.");
              });
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        setOnGoingApiCall(false);
        openNotification("Please upload images to continue.");
      }
    } catch (e) {
      console.log("ERROR", e);
      setOnGoingApiCall(false);
      openNotification("Sorry! Something went wrong.");
    }
  };

  useEffect(() => {
    getHubList();
  }, []);

  return (
    <>
      <InterfaceHeader />
      <UploadImageComponentWrapper>
        <div
          style={{
            marginTop: "8rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="card">
            <div
              className="leftContainer"
              style={{
                width: infoTabVis ? "70%" : "100%",
              }}
            >
              <h2
                style={{
                  textAlign: "center",
                  flexGrow: 1,
                  margin: "20px 0 30px",
                }}
              >
                Upload Logos
              </h2>{" "}
              <div className="selectType">
                <p>Select Type : </p>

                <div>
                  <Checkbox
                    checked={selectedType === "cp"}
                    onChange={(e) => {
                      setSelectededImage(null);
                      setSelectedType(e.target.value);
                    }}
                    name="type"
                    value="cp"
                  >
                    Company Profile
                  </Checkbox>
                  <Checkbox
                    checked={selectedType === "es"}
                    onChange={(e) => {
                      setSelectededImage(null);
                      setSelectedType(e.target.value);
                    }}
                    name="type"
                    value="es"
                  >
                    Executive Summary
                  </Checkbox>
                </div>
              </div>
              <Dragger
                style={{ width: "80%", margin: "20px auto 0" }}
                disabled={onGoingApiCall}
                accept={`${selectedType === "cp" ? ".png" : ".svg"}`}
                maxCount={20}
                multiple={true}
                fileList={selectedImage}
                // showUploadList={false}
                onChange={(e) => setSelectededImage(e.fileList)}
                beforeUpload={preventRequest}
                //customRequest={() => {}}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                {selectedType && (
                  <p className="ant-upload-hint">
                    Only {selectedType === "cp" ? ".PNG" : ".SVG"}
                    {`   `}
                    image types
                  </p>
                )}
              </Dragger>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Button
                  key="Upload Logo"
                  type="primary"
                  onClick={uploadImage}
                  loading={onGoingApiCall}
                >
                  {onGoingApiCall ? "Uploading" : "Upload Image"}
                </Button>
              </div>
            </div>

            <div className={infoTabVis ? "infoContainer" : "info"}>
              {/* <InfoCircleOutlined title="Company profile only .png and Executive summary only .svg" /> */}
              <Button
                type="default"
                danger={infoTabVis}
                onClick={() => setInfoTabVis(!infoTabVis)}
              >
                {infoTabVis ? "Close Guide" : "Open Guide"}
              </Button>
              {infoTabVis && (
                <div className="infoContent">
                  <b>
                    Upload logos for company profile and executive summary.
                    <br />
                    <div>
                      <p>⏺ Select the type of logo you want to upload.</p>
                      <p>⏺ You can upload multiple logos at once. (Max 20)</p>
                      <p>
                        ⏺ Only .png images are allowed for Company Profile.
                      </p>
                      <p>
                        ⏺ for croping and resizing images, read this{" "}
                        <a
                          target="_blank"
                          href="https://mordorintelligence1-my.sharepoint.com/:w:/g/personal/jan_basha_mordorintelligence_com/EfM0jL4kShdIiCyNeyRHIywBskvhp8nEQo1V5EzgCyS0AQ?e=rnbkBa"
                        >
                          Document
                        </a>
                      </p>
                      <p>
                        ⏺ Only .svg images are allowed for Executive Summary.
                      </p>
                      <p>
                        ⏺ Click on the "Upload Image" button to upload the
                        images.
                      </p>
                    </div>
                  </b>
                </div>
              )}
            </div>
          </div>
        </div>
      </UploadImageComponentWrapper>
    </>
  );
}

export default UploadImageComponent;
