import React, { Component } from "react";
import Footer from "../components/Footer";
import FeedbackAndAuditframe from "../images/feedback.svg";
import Link from "../images/link.svg";
import { FeedbackAndAuditWrapper } from "../styles/FeedbackAndAudit.style";

var Base64 = require("js-base64").Base64;

export const FeedbackAndAuditDemoData = {
  title: "Key Strategic Themes For Feed Additive CEOS",
  slideName: "FeedbackAndAudit",
  id: "1. FeedbackAndAudit",
  slug: "North America",
};

class FeedbackAndAudit extends Component {
  state = {
    data: this.props.data || FeedbackAndAuditDemoData,
    reseller: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.meta) {
      if (props.data.meta.sample && props.data.meta.reseller) {
        return {
          reseller: true,
        };
      }
    }
    return null;
  }

  render() {
    let { id, slug } = this.state.data;
    const { slideNo } = this.props;
    const pageId = id ? Base64.encode(id) : undefined;
    let slideNoElementId;
    if (pageId) {
      slideNoElementId = "slideNO" + pageId;
    }

    return (
      <>
        <FeedbackAndAuditWrapper>
          <div className="FeedbackAndAudit page">
            <img
              className="FeedbackAndAuditframe"
              src={FeedbackAndAuditframe}
              alt="img"
              style={{ height: "auto", width: "100%" }}
            />
            <div className="linkContainer">
              <img
                className="feedBacklink"
                src={Link}
                alt="img"
                style={{ width: "20px" }}
              />
              <a
                href="https://forms.office.com/Pages/ResponsePage.aspx?id=H_qPBVFc00WJpc_1PoRN6-Ema5wYovZOkrCa3Hrpet1URVBOREFWNzYzTEQ3R1NNMzdCNU1QVzM2TS4u"
                target="_blank"
                style={{
                  marginLeft: "10px",
                  color: "#002f75",
                  textDecoration: "underline",
                }}
              >
                LINK
              </a>
            </div>
            {!this.props.fromInterface ||
            this.props.fromInterface === undefined ? (
              <Footer
                className="FeedbackAndAuditFooter"
                slug={slug}
                slideNo={slideNo}
                reseller={this.state.reseller}
              />
            ) : null}
          </div>
        </FeedbackAndAuditWrapper>
      </>
    );
  }
}

export default FeedbackAndAudit;
