import styled from "styled-components";

export const MeatsAndMeatSubstituteVCWrapper = styled.div`
  .section_data1 {
    width: 20%;
    max-width: 250px;
    position: absolute;
    word-break: break-word;
  }

  .section_data2 {
    width: 20%;
    max-width: 250px;
    position: absolute;
    word-break: break-word;
  }

  .section1_list {
    top: ${(props) =>
      props.templatePart
        ? props.templatePart === "1"
          ? "77%"
          : props.templatePart === "2" || props.templatePart === "3"
            ? "80%"
            : ""
        : ""};

    left: ${(props) =>
      props.templatePart
        ? props.templatePart === "1"
          ? "2%"
          : props.templatePart === "2" || props.templatePart === "3"
            ? "4%"
            : ""
        : ""};
  }

  .section2_list1 {
    top: ${(props) =>
      props.templatePart
        ? props.templatePart === "1"
          ? "77%"
          : props.templatePart === "2" || props.templatePart === "3"
            ? "80%"
            : ""
        : ""};

    left: ${(props) =>
      props.templatePart
        ? props.templatePart === "1"
          ? "27%"
          : props.templatePart === "2" || props.templatePart === "3"
            ? "48%"
            : ""
        : ""};
  }

  .section3_list1 {
    top: ${(props) =>
      props.templatePart
        ? props.templatePart === "1"
          ? "77%"
          : props.templatePart === "2" || props.templatePart === "3"
            ? "80%"
            : ""
        : ""};

    left: ${(props) =>
      props.templatePart
        ? props.templatePart === "1"
          ? "52%"
          : props.templatePart === "2" || props.templatePart === "3"
            ? "74%"
            : ""
        : ""};
  }

  .section3_list2 {
    top: ${(props) =>
      props.templatePart
        ? props.templatePart === "1"
          ? "77%"
          : props.templatePart === "2" || props.templatePart === "3"
            ? "80%"
            : ""
        : ""};

    left: ${(props) =>
      props.templatePart
        ? props.templatePart === "1"
          ? "77%"
          : props.templatePart === "2" || props.templatePart === "3"
            ? "74%"
            : ""
        : ""};
  }
`;
