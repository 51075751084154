import styled from "styled-components";

const ProductAndServicesWrapper = styled.div`
  padding: 25px 50px 0 50px;
  height: ${(props) => (props.interface ? "auto" : "625px")};

  h2 {
    font-family: "Avenir Roman";
    font-size: 26px;
    font-style: normal;
    color: #002f75 !important;
    font-weight: 800 !important;
    margin: 0px;
  }

  .card-container {
    display: flex;
    width: 100%;
    display: grid;
    grid-template-columns: ${(props) =>
      props.oneproduct ? "unset" : "1fr 1fr"};
    grid-column-gap: 10px;
  }

  .Container-label {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 20px 0;
  }

  .left-text-label-container {
    width: ${(props) => (props.oneproduct ? "100%" : "50%")};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text-label {
    font-family: "Avenir";
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    color: #002f75;

    :nth-child(odd) {
      width: 40%;
      padding: 0 5px;
    }

    :nth-child(even) {
      width: 60%;
      padding: 0 5px;
    }
  }

  .container-cards {
    display: flex;
    justify-content: space-between;
    background: #e7f7ff;
    padding: 20px;
    margin-bottom: 10px;
    align-items: center;

    :nth-child(odd) {
      background: #e7f7ff;
    }

    :nth-child(even) {
      background: #d7f6eb;
    }

    .card-text-left {
      width: 40%;
      font-family: "Avenir Roman";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #002f75;
      line-height: 18px;
    }

    .card-text-right {
      width: 60%;
      font-family: "Avenir Roman";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #002f75;
      line-height: 18px;
    }
  }
`;
export default ProductAndServicesWrapper;
