import styled from "styled-components";

export const LEDVCWrapper = styled.div`
  .section_data1 {
    width: 18%;
    max-width: 200px;
    position: absolute;
    word-break: break-word;
  }

  .section1_list {
    top: 78%;
    left: 41%;
  }
`;
