import React, { Component } from "react";
import "../App.css";
import HeaderWrapper from "../styles/Header.style";
import { HomeFilled } from "@ant-design/icons";
import { connect } from "react-redux";
import { setReportHeaderData } from "../reducersAndActions/Actions";

var Base64 = require("js-base64").Base64;

class Header extends Component {
  state = {
    introduction: "",
    keyindustrytrends: "",
    marketsegmentation: "",
    compitativelandscape: "",
    toc: "",
  };

  componentDidMount() {
    this.props.REPORT_HEADER_DATA.header_navigation &&
      this.props.REPORT_HEADER_DATA.header_navigation.length &&
      this.props.REPORT_HEADER_DATA.header_navigation.map((item, i) => {
        switch (item.title) {
          case "Introduction": {
            return this.setState({
              introduction: "#" + Base64.encode(item.meta.section_id),
            });
          }

          case "Key Industry Trends": {
            return this.setState({
              keyindustrytrends: "#" + Base64.encode(item.meta.section_id),
            });
          }

          case "Market Segmentation": {
            return this.setState({
              marketsegmentation: "#" + Base64.encode(item.meta.section_id),
            });
          }

          case "Competitive Landscape": {
            return this.setState({
              compitativelandscape: "#" + Base64.encode(item.meta.section_id),
            });
          }

          default: {
            return this.setState({ toc: "#" + Base64.encode("toc") });
          }
        }
      });
  }

  render() {
    return (
      <HeaderWrapper data={this.props.slideTitle}>
        <div className="header-title">
          <a
            style={{ textDecoration: "none" }}
            href={"#" + Base64.encode("toc")}
          >
            <HomeFilled style={{ color: "#189CDE" }} />
          </a>
        </div>

        <div className="header-title intro">
          <a style={{ textDecoration: "none" }} href={this.state.introduction}>
            1. INTRODUCTION
          </a>
        </div>

        <div className="header-title keyindustry">
          <a
            style={{ textDecoration: "none" }}
            href={this.state.keyindustrytrends}
          >
            2. KEY INDUSTRY TRENDS
          </a>
        </div>

        <div className="header-title marketsegment">
          <a
            style={{ textDecoration: "none" }}
            href={this.state.marketsegmentation}
          >
            3. MARKET SEGMENTATION
          </a>
        </div>

        <div className="header-title complandscape">
          <a
            style={{ textDecoration: "none" }}
            href={this.state.compitativelandscape}
          >
            4. COMPETITIVE LANDSCAPE
          </a>
        </div>
      </HeaderWrapper>
    );
  }
}
function mapStateToPros(state) {
  return {
    REPORT_HEADER_DATA: state.REPORT_HEADER_DATA,
  };
}

const mapDispatchToProps = {
  setReportHeaderData,
};

export default connect(mapStateToPros, mapDispatchToProps)(Header);
