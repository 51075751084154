import styled from "styled-components";

const IntroductionWrapper = styled.div`
  background-color: #002f75;
  width: 100%;
  position: relative;
  height: 663px;
  padding: 80px;
  display: flex;
  align-items: center;

  .bg1 {
    position: absolute;
    bottom: 0;
    right: 1.39%;
  }

  .bg2 {
    position: absolute;
    left: 36.09%;
    top: 25.81%;
    width: 73.38px;
    height: 112.63px;
  }

  .img_frame {
    width: 349px;
    height: 283.3px;
  }

  h1 {
    font-family: "Avenir Roman";
    font-style: normal;
    font-weight: 800;
    font-size: 44px;
    line-height: 66px;
    color: #ffffff;
    margin-bottom: 20px;
  }

  p {
    font-family: "Avenir Roman";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 32px;
    color: #ffffff;
  }
  .intro-list-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-height: 550px;
    width: 52%;
  }
`;
export default IntroductionWrapper;
