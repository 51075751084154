import React, { Component } from "react";
import "../../App.css";
import { InterfaceHeaderWrapper } from "../../styles/InterfaceHeader.style";
import Desktoplogo from "../../images/desktop_logo.png";
import mobileLogo from "../../images/mobile_logo.png";
import {
  Badge,
  Dropdown,
  Menu,
  Spin,
  Image,
  Button,
  Checkbox,
  Modal,
  Select,
  notification,
} from "antd";
import {
  UserOutlined,
  ShoppingCartOutlined,
  GlobalOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import axios from "axios";
import configApi from "../../config/Api";
import { DownloadOutlined } from "@ant-design/icons";
import Logout from ".././Logout";
import UploadSheet from ".././UploadSheet";
import UploadBrochure from ".././UploadBrochure";
import { Link } from "react-router-dom";
import { withWindowSizeHOC } from "../../Common/withWindowSizeHOC";
import { connect } from "react-redux";
import Login from "../../pages/Login";
import config from "../../config/Config";
import BulkUploadContainer from ".././BulkUpload/BulkUploadContainer";
import HLPDataUpload from ".././HLPDataUpload";
import { BulkUploadComponentWrapper } from "./BulkExports.style";
import { InfoCircleOutlined } from "@ant-design/icons";

import {
  setTocData,
  setSearchParams,
  setDraftStage,
  setMarketActivityParams,
  setMasterMEParams,
  setDownloadReportsParams,
  setHLPParams,
} from "../../reducersAndActions/Actions";

const CheckboxGroup = Checkbox.Group;
let Base64 = require("js-base64").Base64;
let { returnProfilePicture } = Login;
const { Option } = Select;
let CancelToken = axios.CancelToken.source();
let cancelTokenOfTitles = axios.CancelToken.source();

class BulkExportsComponent extends Component {
  constructor(props) {
    super(props);
    this.uploadSheet = React.createRef();
    this.uploadBrochure = React.createRef();
    this.titles = [];
    this.counter = 0;

    this.menu = (
      <Menu>
        {this.state.user ? (
          <Menu.Item selectable="false" key={this.state.user.name}>
            {this.state.user.name}
          </Menu.Item>
        ) : (
          ""
        )}
        <Menu.Item
          key="upload-sheet"
          onClick={
            () => this.uploadSheet.current.toggleShowModal()
            // this.setState({ toShowModal: true }, () =>
            // )
          }
        >
          Upload Sheet
        </Menu.Item>
        {/* <Menu.Item key="change-password">Change Password</Menu.Item> */}
        <Menu.Item key="BulkExport" onClick={this.OpenBulkExportModal}>
          Bulk Export
        </Menu.Item>

        <Menu.Item key="sampleMeUpload" onClick={this.OpenUploadModal}>
          Sample MEs Upload
        </Menu.Item>
        <Menu.Item
          key="brochureUpload"
          onClick={() => this.uploadBrochure.current.toggleShowModal()}
        >
          Brochure Upload
        </Menu.Item>

        {/* <Menu.Item key="upload" onClick={this.OpenHLPDataUploadModal}>
        Hubs Landing Page Data Upload
        </Menu.Item> */}

        <Menu.Item key="logout">
          <Logout />
        </Menu.Item>
      </Menu>
    );
    this.downloadMenu = (
      <Menu>
        <Menu.Item key="1">Purchase Report</Menu.Item>
        <Menu.Item
          key="2"
          onClick={() => {
            this.props.generatePdf("Report");
          }}
        >
          Generate Report
        </Menu.Item>
        <Menu.Item
          key="3"
          onClick={() => {
            this.props.generatePdf("Sample");
          }}
        >
          Generate Sample
        </Menu.Item>
        <Menu.Item
          key="4"
          onClick={() => {
            this.props.generatePdf("Rd");
          }}
        >
          RD Send To Marketing
        </Menu.Item>
        <Menu.Item
          key="5"
          onClick={() => {
            this.props.generatePdf("reset_urls");
          }}
        >
          Purge Title
        </Menu.Item>
        {/* {window.location.href.indexOf("mordorintelligence") == -1? */}
        <Menu.Item
          key="6"
          onClick={() => {
            this.props.generatePdf("pptgen");
          }}
        >
          Generate PPT
        </Menu.Item>
        {/* :""} */}
        {/* {window.location.href.indexOf("mordorintelligence") == -1? */}
        <Menu.Item
          key="6"
          onClick={() => {
            this.props.generatePdf("pptgensample");
          }}
        >
          Generate PPT Sample
        </Menu.Item>
        {/* :""} */}
      </Menu>
    );
    this.newDownloadMenu = (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => {
            // this.props.generatePdf("Report");
            // this.handleMarketActivityButton()
            this.handleDownloadReportsButton();
          }}
        >
          Download Reports
        </Menu.Item>

        {this.props?.USER_DATA?.obj?.hasOwnProperty("master_me_access") ? (
          this.props.USER_DATA.obj.master_me_access ? (
            <Menu.Item
              key="2"
              onClick={() => {
                // //   this.props.generatePdf("Report");
                this.handleMasterMEsButton();
              }}
            >
              Download Master MEs
            </Menu.Item>
          ) : null
        ) : (
          <Menu.Item
            key="2"
            onClick={() => {
              // //   this.props.generatePdf("Report");
              this.handleMasterMEsButton();
            }}
          >
            Download Master MEs
          </Menu.Item>
        )}
      </Menu>
    );
  }

  state = {
    menuOpend: false,
    limits: {
      report: 5,
      sample: 5,
      rds: 15,
    },
    toShowModal: false,
    mobile: window.screen.width > 960 ? false : true,
    user: window.localStorage.getItem(config.LOCAL_STORAGE_TOKEN.Name)
      ? window.localStorage.getItem(config.LOCAL_STORAGE_TOKEN.meta)
        ? JSON.parse(window.localStorage.getItem("user"))
        : null
      : null,
    modalForBulkExport: false,
    modalTitlesLoading: false,
    countries: [],
    selected_countries: [],
    default_regions: [],
    selectedRegions: [],
    Select_country_disabled: true,
    hubs: [],
    selected_hubs: null,
    reportChecked: true,
    sampleChecked: false,
    titlesList: [],
    defaultTitlesList: [],
    titlesChecked: true,
    hasTitles: false,
    disableBulkExptBtn: true,
    disableCheckBox: false,
    minTiltes: true,
    listTitlesBtn: true,
    exportloader: false,
    showUploadModal: false,
    showBrochureModal: false,
    infoTabVis: false,
    selectReportDisable: false,
  };

  toggleInfoTab = () => {
    this.setState({ infoTabVis: !this.state.infoTabVis });
  };

  operateMenu = () => {
    this.setState({
      menuOpend: !this.state.menuOpend,
    });
    this.props.openmenu(!this.state.menuOpend);
  };

  componentDidMount() {
    let hubdata, regions;
    let full_access = false;
    axios
      .get(`${configApi.api.base_url}/api/hubs/list?full_access=${full_access}`)
      .then((res) => {
        this.setState({
          hubs: res.data.obj,
        });
      })
      .catch((error) => {
        console.log("err", error);
      });
    regions = Object.keys(config.COUNTRY_MAP);
    regions.unshift("All");
    this.setState({
      default_regions: regions,
    });
    // this.buttonClickRef.current.focus();
  }

  regionMenu = () =>
    this.state.default_regions.map((regn, i) => {
      return (
        <Option value={regn} key={regn + i}>
          {regn}
        </Option>
      );
    });

  onChangeRegion = (value) => {
    if (value.length) {
      this.setState(
        {
          selectedRegions: value,
          Select_country_disabled: false,
        },
        () => {
          this.regionOnblur();
        }
      );
    } else {
      this.setState({
        selectedRegions: [],
        selected_countries: [],
        Select_country_disabled: true,
      });
    }

    if (value.length === 1 && value[0] === "All") {
      this.setState({
        selected_countries: [],
        Select_country_disabled: true,
      });
    }
  };

  regionOnblur = () => {
    let arr = [];

    if (
      this.state.selectedRegions.length === 1 &&
      this.state.selectedRegions[0] === "All"
    ) {
      this.setState({
        selected_countries: [],
        Select_country_disabled: true,
      });
    } else {
      let regions = this.state.selectedRegions.filter((regn, k) => {
        return regn !== "All";
      });
      regions.map((item, i) => {
        arr.push(...config.COUNTRY_MAP[item]);
      });
      this.setState({
        countries: arr,
        Select_country_disabled: false,
      });
    }
  };

  countriesMenu = () => {
    if (this.state.countries.length) {
      return this.state.countries.map((country, k) => {
        return (
          <Option value={country} key={country + k}>
            {country}
          </Option>
        );
      });
    }
  };

  onChangeCountry = (value) => {
    if (value.length) {
      this.setState({
        selected_countries: value,
      });
    } else {
      this.setState({
        selected_countries: [],
      });
    }
  };

  hubIdMenu = () => {
    if (this.state.hubs.length) {
      return this.state.hubs.map((item, i) => {
        return <Option value={item.id}>{item.name}</Option>;
      });
    }
  };

  onChangehub = (value) => {
    if (value !== undefined || value !== null) {
      this.setState({
        selected_hubs: value,
        listTitlesBtn: false,
      });
    } else {
      this.setState({
        selected_hubs: null,
        listTitlesBtn: true,
      });
    }
  };

  onSearchHub = (val) => {};

  OpenUploadModal = () => {
    this.setState({
      showUploadModal: true,
    });
  };

  // OpenHLPDataUploadModal = () => {
  //   this.setState({
  //     showHLPDataUploadModal: true,
  //   });
  // };

  OpenBrochureModal = () => {
    this.setState({
      showBrochureModal: true,
    });
  };

  startTour = () => {};

  OpenBulkExportModal = () => {
    cancelTokenOfTitles = axios.CancelToken.source();
    CancelToken = axios.CancelToken.source();
    this.setState({
      modalForBulkExport: true,
    });
  };

  setModal2Visible = (prop) => {
    let url =
      this.state.sampleChecked || this.state.reportChecked
        ? `${configApi.api.puppeteer_ms_url}/api/exportBulkPdfs`
        : this.state.rdChecked
          ? `${configApi.api.base_url}/api/generateRds`
          : "";
    CancelToken = axios.CancelToken.source();
    let data = {
      meta: {
        sample: this.state.sampleChecked,
      },
      data: this.state.defaultTitlesList,
      hub_id: this.state.selected_hubs,
    };
    this.setState({
      exportloader: true,
      listTitlesBtn: true,
      disableCheckBox: true,
    });
    axios
      .post(url, data, {
        cancelToken: CancelToken.token,
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.message) {
            if (this.state.sampleChecked || this.state.reportChecked) {
              window.open(res.data.message, "_blank");
            }
          }
          this.counter = 0;
          Modal.success({
            content: res.data.message,
          });
          this.setState({
            modalForBulkExport: prop,
            modalTitlesLoading: false,
            selected_countries: [],
            selectedRegions: [],
            Select_country_disabled: true,
            selected_hubs: null,
            reportChecked: true,
            sampleChecked: false,
            rdChecked: false,
            titlesList: [],
            defaultTitlesList: [],
            titlesChecked: true,
            hasTitles: false,
            disableBulkExptBtn: true,
            disableCheckBox: false,
            minTiltes: true,
            listTitlesBtn: true,
            exportloader: false,
          });
        } else {
          this.setState({
            modalForBulkExport: prop,
            modalTitlesLoading: false,
            selected_countries: [],
            selectedRegions: [],
            Select_country_disabled: true,
            selected_hubs: null,
            reportChecked: true,
            sampleChecked: false,
            titlesList: [],
            defaultTitlesList: [],
            titlesChecked: true,
            hasTitles: false,
            disableCheckBox: false,
            minTiltes: true,
            listTitlesBtn: true,
            exportloader: false,
          });
          this.counter = 0;
          Modal.error({
            content: "something went wrong",
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        CancelToken = axios.CancelToken.source();
        this.setState({
          modalForBulkExport: prop,
          modalTitlesLoading: false,
          selected_countries: [],
          selectedRegions: [],
          Select_country_disabled: true,
          selected_hubs: null,
          reportChecked: true,
          sampleChecked: false,
          rdChecked: false,
          titlesList: [],
          defaultTitlesList: [],
          titlesChecked: true,
          hasTitles: false,
          disableBulkExptBtn: true,
          disableCheckBox: false,
          minTiltes: true,
          listTitlesBtn: true,
          exportloader: false,
        });
        this.counter = 0;

        if (error.response.status === 400) {
          Modal.error({
            content:
              error?.response?.data?.message ||
              "Something went wrong. Please try again.",
          });
        } else {
          Modal.error({
            content: "An error occurred: " + error.response.status,
          });
        }
      });
  };

  onCloseModal = (prop) => {
    this.counter = 0;
    this.setState({
      modalForBulkExport: prop,
      modalTitlesLoading: false,
      selected_countries: [],
      selectedRegions: [],
      Select_country_disabled: true,
      selected_hubs: null,
      reportChecked: false,
      sampleChecked: false,
      rdChecked: true,
      titlesList: [],
      defaultTitlesList: [],
      titlesChecked: true,
      hasTitles: false,
      disableBulkExptBtn: true,
      minTiltes: true,
      listTitlesBtn: true,
      exportloader: false,
    });
    if (!this.state.listTitlesBtn) {
      cancelTokenOfTitles.cancel();
    } else {
      CancelToken.cancel("Operation canceled by the user");
    }
  };

  handleLogoClick = () => {
    this.props.setHLPParams(false);
    this.props.setSearchParams(false);
    this.props.setMarketActivityParams(false);
    this.props.setDownloadReportsParams(false);
    this.props.setMasterMEParams(false);
    this.props.setDraftStage("INITIAL");
  };

  handleMarketActivityButton = () => {
    this.props.setHLPParams(false);
    this.props.setSearchParams(true);
    this.props.setMasterMEParams(false);
    this.props.setMarketActivityParams(true);
    this.props.setDownloadReportsParams(false);
    this.props.setDraftStage("INITIAL");
  };

  handleHubsLandingPageButton = () => {
    this.props.setHLPParams(true);
    this.props.setSearchParams(true);
    this.props.setMasterMEParams(false);
    this.props.setMarketActivityParams(false);
    this.props.setDownloadReportsParams(false);
    this.props.setDraftStage("INITIAL");
  };

  handleMasterMEsButton = () => {
    this.props.setHLPParams(false);
    this.props.setSearchParams(true);
    this.props.setMarketActivityParams(false);
    this.props.setMasterMEParams(true);
    this.props.setDownloadReportsParams(false);
    this.props.setDraftStage("INITIAL");
  };

  handleDownloadReportsButton = () => {
    this.props.setHLPParams(false);
    this.props.setDownloadReportsParams(true);
    this.props.setSearchParams(true);
    this.props.setMarketActivityParams(false);
    this.props.setMasterMEParams(false);
    this.props.setDraftStage("INITIAL");
  };

  selectTitles = (e, item) => {
    // in this code we're checking if the selected item already exists or not
    // if it already exists it will be removed from the reportAlreadySelected array
    // (hence resulting in mismatch in length of reportAlreadySelected and defaultTitlesList)
    // if the length is equal it means the item was not already selected we push the item in the state
    // and then we can save the reportAlreadySelected array in the state

    let reportAlreadySelected = this.state.defaultTitlesList.filter(
      (report) => report._id != item._id
    );

    if (reportAlreadySelected.length == this.state.defaultTitlesList.length) {
      reportAlreadySelected.push(item);
    }
    const limit = this.state.sampleChecked
      ? this.state.limits.sample
      : this.state.reportChecked
        ? this.state.limits.report
        : this.state.limits.rds;
    if (reportAlreadySelected.length >= limit) {
      this.setState({
        selectReportDisable: true,
        defaultTitlesList: reportAlreadySelected,
        disableBulkExptBtn: !Boolean(reportAlreadySelected.length),
        minTiltes: Boolean(reportAlreadySelected.length),
      });
      notification.warn({
        message: "Warning",
        description: `Limit Reached!!! Cannot select more than ${limit} Titles at a time.`,
      });
      return;
    }

    this.setState({
      selectReportDisable: false,
      defaultTitlesList: reportAlreadySelected,
      disableBulkExptBtn: !Boolean(reportAlreadySelected.length),
      minTiltes: Boolean(reportAlreadySelected.length),
    });
  };

  checkReportRSample = (value) => {};

  onListTitles = () => {
    if (this.counter === 0) {
      this.counter = 1;
    }
    let payload = {
      hub_id: this.state.selected_hubs,
      dimensions_array: {
        region: this.state.selectedRegions,
        country: this.state.selected_countries,
      },
      meta: {
        sample: this.state.sampleChecked,
      },
    };
    let arr = [];
    this.setState({
      titlesList: [],
      defaultTitlesList: [],
      modalTitlesLoading: true,
    });

    axios
      .post(`${configApi.api.base_url}/api/getPdfTitles`, payload, {
        cancelToken: cancelTokenOfTitles.token,
      })
      .then((res) => {
        if (res.data.data.length) {
          // res.data.data.map((item,i)=>{
          //     arr.push(item.title)
          // });
          this.setState({
            titlesList: res.data.data,
            defaultTitlesList: [],
            modalTitlesLoading: false,
            hasTitles: true,
            minTiltes: true,
            disableBulkExptBtn: false,
            selectReportDisable: false,
          });
        } else {
          this.setState({
            titlesList: [],
            defaultTitlesList: [],
            modalTitlesLoading: false,
            hasTitles: false,
            disableBulkExptBtn: true,
            selectReportDisable: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        cancelTokenOfTitles = axios.CancelToken.source();
        this.setState({
          titlesList: [],
          defaultTitlesList: [],
          modalTitlesLoading: false,
          hasTitles: false,
          disableBulkExptBtn: true,
          modalTitlesLoading: false,
        });
      });
  };
  // filterOptionhub =(input, option)=>{

  //                   // option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  // }

  render() {
    const { width } = this.props;
    return (
      <BulkUploadComponentWrapper>
        <div style={{ marginTop: "6rem" }} className="card">
          <div
            className="leftContainer"
            style={{
              width: this.state.infoTabVis ? "70%" : "100%",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <h2
                style={{
                  textAlign: "center",
                  flexGrow: 1,
                  marginTop: "20px",
                }}
              >
                Bulk Exports
              </h2>
            </div>
            <div>
              <div className="uploadsection">
                <p className="exportLabels">
                  <span className="mandatory">*</span>Select Hub
                </p>
                <Select
                  placeholder="Select Hub"
                  optionFilterProp="children"
                  showSearch
                  onChange={this.onChangehub}
                  value={this.state.selected_hubs}
                  onSearch={this.onSearchHub}
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.children &&
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.hubIdMenu()}
                </Select>
              </div>
              <div className="uploadsection">
                <p className="exportLabels">Select Region</p>
                <Select
                  placeholder="Select Region"
                  allowClear
                  value={this.state.selectedRegions}
                  onChange={this.onChangeRegion}
                  style={{ width: "100%", maxHeight: "60px" }}
                  mode="multiple"
                  onBlur={this.regionOnblur}
                >
                  {this.regionMenu()}
                </Select>
              </div>
              <div className="uploadsection">
                <p className="exportLabels">Select Country</p>
                <Select
                  placeholder="Select Country"
                  allowClear
                  disabled={this.state.Select_country_disabled}
                  value={this.state.selected_countries}
                  onChange={this.onChangeCountry}
                  style={{
                    width: "100%",
                    maxHeight: "88px",
                    overflow: "auto",
                  }}
                  mode="multiple"
                >
                  {this.countriesMenu()}
                </Select>
              </div>

              <div className="reportCheckContainer uploadsection">
                <span className="mandatory">*</span>
                <Checkbox
                  disabled={this.state.disableCheckBox}
                  checked={this.state.reportChecked}
                  onChange={(e) => {
                    if (e.target.checked) {
                      this.setState({
                        sampleChecked: false,
                        rdChecked: false,
                        reportChecked: e.target.checked,
                        listTitlesBtn: false,
                        titlesList: [],
                        defaultTitlesList: [],
                      });
                    } else {
                      this.setState({
                        sampleChecked: false,
                        rdChecked: false,
                        reportChecked: e.target.checked,
                        listTitlesBtn: true,
                        titlesList: [],
                        defaultTitlesList: [],
                      });
                    }
                  }}
                >
                  Report
                </Checkbox>
                <Checkbox
                  disabled={this.state.disableCheckBox}
                  checked={this.state.sampleChecked}
                  onChange={(e) => {
                    if (e.target.checked) {
                      this.setState({
                        sampleChecked: e.target.checked,
                        reportChecked: false,
                        rdChecked: false,
                        listTitlesBtn: false,
                        titlesList: [],
                        defaultTitlesList: [],
                      });
                    } else {
                      this.setState({
                        sampleChecked: e.target.checked,
                        reportChecked: false,
                        rdChecked: false,
                        listTitlesBtn: true,
                        titlesList: [],
                        defaultTitlesList: [],
                      });
                    }
                  }}
                >
                  Sample
                </Checkbox>
                <Checkbox
                  disabled={this.state.disableCheckBox}
                  checked={this.state.rdChecked}
                  onChange={(e) => {
                    if (e.target.checked) {
                      this.setState({
                        rdChecked: e.target.checked,
                        reportChecked: false,
                        sampleChecked: false,
                        listTitlesBtn: false,
                        titlesList: [],
                        defaultTitlesList: [],
                      });
                    } else {
                      this.setState({
                        rdChecked: e.target.checked,
                        reportChecked: false,
                        sampleChecked: false,
                        listTitlesBtn: true,
                        titlesList: [],
                        defaultTitlesList: [],
                      });
                    }
                  }}
                >
                  RD's
                </Checkbox>
              </div>

              <div
                className="uploadsection"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  onClick={this.onListTitles}
                  className="lustTitlesBtn"
                  type="primary"
                  disabled={this.state.listTitlesBtn}
                  loading={this.state.modalTitlesLoading}
                >
                  List Titles
                </Button>
              </div>
              {this.state.hasTitles ? (
                <div className="uploadsection">
                  <Button
                    onClick={() => this.setModal2Visible(false)}
                    type="primary"
                    loading={this.state.exportloader}
                    disabled={
                      this.state.disableBulkExptBtn ||
                      !this.state.defaultTitlesList.length
                    }
                  >
                    Export
                  </Button>
                </div>
              ) : null}
              {this.counter !== 0 ? (
                <div className="export-antd-container uploadsection">
                  <Spin
                    tip="Loading..."
                    spinning={this.state.modalTitlesLoading}
                  >
                    <div className="titlesContainer">
                      {this.state.hasTitles ? (
                        <div className="titlesWrapper">
                          {this.state.titlesList &&
                            this.state.titlesList.map((item, i) => {
                              return (
                                <Checkbox
                                  onChange={(e) => this.selectTitles(e, item)}
                                  defaultChecked={false}
                                  disabled={
                                    !this.state.defaultTitlesList.some(
                                      (title) => title._id == item._id
                                    ) && this.state.selectReportDisable
                                  }
                                >
                                  {item.title}
                                </Checkbox>
                              );
                            })}

                          {/* <CheckboxGroup options={this.state.defaultTitlesList} defaultValue={this.state.defaultTitlesList} defaultChecked={true} onChange={this.selectTitles} /> */}
                        </div>
                      ) : !this.state.hasTitles &&
                        this.state.modalTitlesLoading ? (
                        ""
                      ) : (
                        <h4>* No titles are present</h4>
                      )}
                    </div>
                    {this.state.titlesList.length && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        Maximum{" "}
                        {this.state.sampleChecked
                          ? this.state.limits.sample
                          : this.state.reportChecked
                            ? this.state.limits.report
                            : this.state.limits.rds}{" "}
                        Reports can be selected at a time.
                      </p>
                    )}
                    {!this.state.minTiltes ? (
                      <p className="bulkExportError">
                        {" "}
                        * Select at least one title
                      </p>
                    ) : null}
                  </Spin>
                </div>
              ) : null}
            </div>
          </div>

          <div className={this.state.infoTabVis ? "infoContainer" : "info"}>
            {/* <InfoCircleOutlined title="Company profile only .png and Executive summary only .svg" /> */}
            <Button
              type="default"
              danger={this.state.infoTabVis}
              onClick={() => this.toggleInfoTab()}
            >
              {this.state.infoTabVis ? "Close Guide" : "Open Guide"}
            </Button>
            {this.state.infoTabVis && (
              <div
                className="infoContent"
                style={{ overflow: "scroll", maxHeight: "350px" }}
              >
                <b>
                  Bulk Export Allows you to export the reports, samples and RD's
                  in Bulk
                </b>
                <br />
                <br />
                <div>
                  <p>⏺ Select the Hub from the dropdown</p>
                  <p>
                    ⏺ if you want to export all titles Click list titles button
                    or Select the Region and Country from the dropdown
                  </p>
                  <p>⏺ Select the type of export you want to do</p>
                  <p>⏺ Click on the "List Titles" button to list the titles</p>
                  <p>⏺ Select the titles you want to export</p>
                  <p>⏺ Click on the "Export" button to export the titles</p>
                  <br />
                  <br />
                  <h3>-----INFO-----</h3>
                  <p>
                    <b>Reports</b>: are the full reports
                  </p>
                  <p>
                    <b>Samples</b>: are the sample reports
                  </p>
                  <p>
                    <b>RD's</b>: are the Report Descriptions witch is visible on
                    the website.Post the RD's Generation you can find the RDs{" "}
                    <a
                      target="_blank"
                      href="https://lorien.mordorintelligence.com/market-activities"
                    >
                      here{" "}
                    </a>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </BulkUploadComponentWrapper>
    );
  }
}

function mapStateToPros(state) {
  return {
    IS_AUTH: state.IS_AUTH,
    USER_DATA: state.USER_DATA,
    SEARCH_PARAMS: state.SEARCH_PARAMS,
    MARKET_ACTIVITY_PARAMS: state.MARKET_ACTIVITY_PARAMS,
    DOWNLOAD_REPORTS_PARAMS: state.DOWNLOAD_REPORTS_PARAMS,
    HLP_PARAMS: state.HLP_PARAMS,
    MASTER_ME_PARAMS: state.MASTER_ME_PARAMS,
    TOC_DATA: state.TOC_DATA,
  };
}

const mapDispatchToProps = {
  setTocData,
  setSearchParams,
  setDraftStage,
  setMarketActivityParams,
  setMasterMEParams,
  setDownloadReportsParams,
  setHLPParams,
};

export default withWindowSizeHOC(
  connect(mapStateToPros, mapDispatchToProps)(BulkExportsComponent)
);
