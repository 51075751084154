import styled from "styled-components";

export const FeedAdditiveVCWrapper = styled.div`
  .section_data1 {
    top: 69%;
    width: 25%;
    max-width: 305px;
    position: absolute;
    word-break: break-word;
  }

  .section_data2 {
    top: 69%;
    width: 13%;
    max-width: 160px;
    position: absolute;
    word-break: break-word;
  }

  .section1_list {
    left: 5%;
  }

  .section2_list {
    left: 34%;
  }

  .section3_list {
    left: 52%;
  }

  .section4_list {
    left: 80%;
  }
`;
