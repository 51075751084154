import React, { useState } from "react";
import "../../App.css";
import { Tabs, Row, Col } from "antd";
import {
  LoadingOutlined,
  GlobalOutlined,
  CloudUploadOutlined,
  UploadOutlined,
  AreaChartOutlined,
} from "@ant-design/icons";
import { LayoutWrapper } from "../../styles/Layout.style";
import { withRouter } from "react-router";
import { withWindowSizeHOC } from "../../Common/withWindowSizeHOC";
import { connect } from "react-redux";
import UploadBrochure from "../UploadBrochure";
import HubSampleMEupload from "./HubSampleMEupload";
import HubReportListupload from "./HubReportListupload";
import { useHistory } from "react-router-dom";
import InterfaceHeader from "../InterfaceHeader";
const { TabPane } = Tabs;
const antIcon = <LoadingOutlined style={{ fontSize: 16 }} />;

function LeadAutomationComponent(props) {
  const [state, setState] = useState({
    currentHover: null,
    onGoingApiCall: false,
    toShowModal: false,
  });
  const history = useHistory();
  const user = props?.USER_DATA;
  let uploadBrochure = React.createRef();
  let hubSampleMEupload = React.createRef();
  let hubReportListupload = React.createRef();
  // const { width, parent_id, template_type } = props;

  const squares = [
    {
      border: "4px solid #FEC015",
      margin: "10px",
      text: "Hub Sample ME Upload",
      backgroundColor: "#FEC015",
      path: "/hub-sample-me",
      onclick: () => hubSampleMEupload.current.toggleShowModal(),
      icon: <UploadOutlined />,
      permissionTo: ["ALL", "TECH", "RESEARCH"],
    },
    {
      border: "4px solid rgb(30 156 190)",
      margin: "10px",
      text: "Brochure Upload",
      backgroundColor: "rgb(30 156 190)",
      path: "/brochure Upload",
      onclick: () => uploadBrochure.current.toggleShowModal(),
      icon: <GlobalOutlined />,
      permissionTo: ["ALL", "TECH", "RESEARCH"],
    },
    {
      border: "4px solid rgb(233 117 142)",
      margin: "10px",
      text: "Report List upload",
      backgroundColor: "rgb(233 117 142)",
      path: "/report List upload",
      icon: <AreaChartOutlined />,
      onclick: () => hubReportListupload.current.toggleShowModal(),
      permissionTo: ["ALL", "TECH", "RESEARCH"],
    },
    {
      border: "4px solid rgb(1 176 179)",
      margin: "10px",
      text: "Sample Reports ME Upload",
      backgroundColor: "rgb(1 176 179)",
      onclick: () => history.push("/sample-mes-upload"),
      icon: <CloudUploadOutlined />,
      permissionTo: ["ALL", "TECH", "RESEARCH"],
    },
  ];

  return (
    <>
      <InterfaceHeader />
      <div>
        <div
          className="layoutContainer"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <div
            style={{
              width: "60%",
              margin: "auto",
              height: "50%",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                padding: "15px",
                boxShadow: "rgb(0 136 255 / 10%) 1px 1px 20px 20px",
                borderRadius: "15px",
              }}
              className="searchContainer"
            >
              <div className="new-Container">
                <h1
                  className="gradient-text"
                  style={{
                    marginLeft: "9px",

                    fontSize: "30px",
                  }}
                >
                  Lead Automation
                </h1>
                <Row style={{ width: "100%" }} gutter={16}>
                  {squares.map((square, index) => {
                    if (square.permissionTo.includes(user?.obj?.department)) {
                      return (
                        <Col key={index}>
                          <div
                            className="square"
                            style={{
                              border: square.border,
                              margin: square.margin,
                              cursor: "pointer",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              width: "100%",
                              minWidth: "250px",
                              height: "112px",
                              padding: "6%",
                              backgroundColor:
                                state.currentHover === index
                                  ? square.backgroundColor
                                  : "transparent",
                            }}
                            onMouseEnter={() =>
                              setState((prevState) => ({
                                ...prevState,
                                currentHover: index,
                              }))
                            }
                            onMouseLeave={() =>
                              setState((prevState) => ({
                                ...prevState,
                                currentHover: "",
                              }))
                            }
                            onClick={() => {
                              square.onclick && square.onclick();
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              {square.icon && (
                                <div
                                  style={{
                                    color:
                                      state.currentHover === index
                                        ? "white"
                                        : square.backgroundColor,
                                    fontSize: "clamp(25px, 1.6vw, 50px)",
                                    display: "inline-block",
                                    marginRight: "30px",
                                  }}
                                >
                                  {React.cloneElement(square.icon, {
                                    width: "64px",
                                  })}
                                </div>
                              )}
                              <div
                                style={{
                                  color:
                                    state.currentHover === index
                                      ? "white"
                                      : square.backgroundColor,
                                  fontSize: "clamp(15px, 1.5vw, 30px)",
                                  fontFamily: "Avenir Medium",
                                }}
                              >
                                {square.text}
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Row>
              </div>
            </div>
          </div>
        </div>
        <UploadBrochure ref={uploadBrochure} />
        <HubSampleMEupload ref={hubSampleMEupload} />
        <HubReportListupload ref={hubReportListupload} />
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    IS_AUTH: state.IS_AUTH,
    SEARCH_PARAMS: false,
    USER_DATA: state.USER_DATA,
  };
}

export default withWindowSizeHOC(
  withRouter(connect(mapStateToProps, null)(LeadAutomationComponent))
);
