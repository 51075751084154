class Switchtexttitlecase {
  static get isInline() {
    return true;
  }

  constructor() {
    this.button = null;
  }

  render() {
    this.button = document.createElement("button");
    this.button.type = "button";
    this.button.style.display = "flex";
    this.button.style.alignItems = "center";
    this.button.style.justifyContent = "center";
    this.button.style.width = "30px";
    this.button.style.height = "30px";
    this.button.innerHTML = `<svg width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">

    <defs>
    
    <style>.cls-1{fill:none;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style>
    
    </defs>
    
    <g id="ic-editor-capitalize">
    
    <line class="cls-1" x1="2" y1="2" x2="18" y2="2"/>
    
    <line class="cls-1" x1="10" y1="2" x2="10" y2="22"/>
    
    <line class="cls-1" x1="13.02" y1="11.1" x2="21.74" y2="11.1"/>
    
    <line class="cls-1" x1="17.38" y1="11.1" x2="17.38" y2="22"/>
    
    </g>
    
    </svg>`;
    return this.button;
  }

  surround(range) {
    function toTitleCase(text) {
      return text.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase());
    }
    const selectedText = range.extractContents();
    const text = selectedText.textContent;

    let transformedText = toTitleCase(text);

    const span = document.createElement("span");
    span.textContent = transformedText;
    span.setAttribute("data-case", this.state);
    range.insertNode(span);
  }

  checkState(selection) {
    const text = selection.anchorNode;

    if (!text) {
      return;
    }
    this.button.classList.toggle("active", this.state !== "none");
  }
}

export default Switchtexttitlecase;
