import styled from "styled-components";
import BgImage from "../images/ForMoreInformationPleaseContact.svg";

export const ForMoreInformationPleaseContactWrapper = styled.div`
  .ForMoreInformationPleaseContactContact .contact {
    display: flex;
    align-items: flex-start;
    color: white;
    font-family: "Avenir Roman";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    width: 70%;
    margin-top: 25px;
  }
  .ForMoreInformationPleaseContactContact .anticon svg {
    height: 30px;
    width: 50px;
    color: #00a9ff;
  }
  .formoreinformationFooter {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 57px;
    padding: 0 15px;
  }
  .ForMoreInformationPleaseContact {
    background: url(${BgImage});
    height: 663px;
    padding: 120px 50px;
  }
  .ForMoreInformationPleaseContactContact {
    width: 70%;
  }
  .ForMoreInformationPleaseContact h1 {
    font-family: "Avenir Roman";
    font-style: normal;
    font-weight: 800;
    font-size: 44px;
    line-height: 66px;
    color: white;
  }
  .contact1 img {
    margin-left: 10px;
    margin-right: 10px;
    height: 30px;
    width: 50px;
  }
  .footerurl {
    font-family: "Avenir Roman";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #002f75;
  }
  .footerurl span {
    margin: 0 15px;
  }
  .leaf-images {
    position: relative;
  }
  .leaf-images .leaf1 {
    position: absolute;
    left: 52.42%;
    right: 37.15%;
    top: 42.08%;
    bottom: 29.45%;
  }
  .leaf-images .leaf2 {
    position: absolute;
    left: 86.72%;
    bottom: 30.58%;
    height: 74px;
    width: 113px;
  }
`;
