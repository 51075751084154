import styled from "styled-components";

export const DocumentationComponentWrapper = styled.div`
  padding: 30px;

  > h1 {
    text-align: center;
    text-decoration: underline;
    margin-top: 60px;
  }

  .accordian-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: 30px 0;

    display: block;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 lightgray;

    > h2 {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 900;
      user-select: none;
    }

    > p {
      padding: 10px;
      margin: 10px 0 0;
      background-color: rgb(243 240 240);
    }
  }
  .accordian-body {
    width: 100%;
    overflow: scroll;
    max-height: 60vh;
  }
  .toc-container {
    border-right: 2px solid #00000033;
    position: sticky;
    top: 0px;
    max-height: 60vh;
    overflow: scroll;
  }

  div.accordian-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;

    > div {
      width: calc(75% - 10px);
      margin-top: 10px;
    }

    > div.toc-container {
      padding: 10px 0;
      width: calc(25% - 10px);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
      // box-shadow: 0 0 4px 0 lightgray;
    }
  }

  div.toc-container > div {
    display: block;
    width: calc(100% - 15px);
    border-radius: 2px;
    outline: none;

    > div {
      background: rgb(250, 250, 250);
    }
  }

  div.documentation-container {
    height: 100%;
    min-height: 100px;
  }

  .hub-dropdown {
    margin: 30px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    > div {
      width: 20%;
    }
  }

  .documentation-container li {
    color: var(--blue);
    padding-left: 15px;
  }

  .active {
    border-right: 2px solid black;
    background-color: rgb(230, 230, 230);
  }
`;

export const TOCWrapper = styled.div`
  transition: all 3s ease-in-out;
  > p {
    padding-left: 15px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
  }

  > div {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    gap: 10px;
    user-select: none;
    transition: all 500ms ease-in-out;
  }
`;
