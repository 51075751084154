import React, { useEffect, useRef, useState } from "react";
import EditorJS from "@editorjs/editorjs";
import { EDITOR_JS_TOOLS } from "../constants";
import {
  setLinkCounter,
  setSourcesEditorState,
} from "../reducersAndActions/Actions";
import { connect } from "react-redux";

const EDITTOR_HOLDER_ID = "sources-editor";

const SourcesEditor = (props) => {
  const ejInstance = useRef();

  useEffect(() => {
    props.setSourcesEditorState(
      props.draft_data_sources && props.draft_data_sources.length
        ? props.draft_data_sources.map((item) => item)
        : []
    );
    if (!ejInstance.current) {
      ejInstance.current = new EditorJS({
        data: {
          time: new Date().getTime(),
          blocks: [
            {
              type: "list",
              data: {
                style: "unordered",
                items:
                  props.draft_data_sources && props.draft_data_sources.length
                    ? props.draft_data_sources.map((item) => item)
                    : [],
              },
            },
          ],
        },
        holder: EDITTOR_HOLDER_ID,
        autofocus: true,
        tools: EDITOR_JS_TOOLS,
        onChange: handleSave,
      });
    } else {
      if (ejInstance && ejInstance.current && ejInstance.current.isReady) {
        ejInstance.current.isReady
          .then(() => {
            ejInstance.current.blocks.render({
              blocks: [
                {
                  type: "list",
                  data: {
                    style: "unordered",
                    items:
                      props.draft_data_sources &&
                      props.draft_data_sources.length
                        ? props.draft_data_sources.map((item) => item)
                        : [],
                  },
                },
              ],
              holder: EDITTOR_HOLDER_ID,
            });
          })
          .catch(() => {});
      }
    }
  }, [JSON.stringify(props.draft_data_sources)]);

  async function handleSave() {
    ejInstance.current
      .save()
      .then((outputData) => {
        let mergedArray = outputData.blocks
          .map((item) => item.data.items)
          .flat(Infinity);
        props.setSourcesEditorState(mergedArray);
      })
      .catch((error) => {
        console.log("Saving failed: ", error);
      });
  }

  return (
    <React.Fragment>
      <div id={EDITTOR_HOLDER_ID}></div>
    </React.Fragment>
  );
};

function mapStateToPros(state) {
  return {
    draft_data_sources: state.draft_data_sources,
    sources_editor_state: state.sources_editor_state,
  };
}
const mapDispatchToProps = {
  setLinkCounter,
  setSourcesEditorState,
};
export default connect(mapStateToPros, mapDispatchToProps)(SourcesEditor);
